/* eslint-disable no-param-reassign */
import React, { createRef } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { withLastLocation } from 'react-router-last-location';
import Categories from './Categories';
import CategoriesNav from './CategoriesNav';
import CatalogMeta from './CatalogMeta';

import CartContainer from '../../containers/CartContainer';
import ModalWrapper from '../shared/ModalWrapper';

import * as actions from '../../actions/actions';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import FilterContainer from '../../containers/FilterContainer';
import MainSeoText from './MainSeoText';
import ProductAddBar from "../Cart/ProductAddBar";

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.filterNames = {
      allergens: 'allergens_',
      ingredients: 'ingredients_',
    };
    this.today = new Date().getTime();
    this.intersectionMargin = '-39.8% 0% -60% 0%';

    this.state = {
      isFilterActive: false,
    };

    this.intersectionObserver = null;
    this.allCategoriesRef = createRef();
    this.categoriesRefs = this.createCategoriesRefs(this.props.categories);
    this.activeCategory = '';
    this.categoriesWrap = createRef();
  }

  componentDidMount() {
    let prefix = '';
    if (this.props.languagePrefix.indexOf('/') > -1) {
      prefix = `${this.props.languagePrefix.slice(this.props.languagePrefix.indexOf('/') + 1)}/`;
    }
    if (this.props.location.pathname === `${this.props.languagePrefix}/cart`) {
      return;
    }
    this.intersectionInit();
    if (this.props.lastLocation
        && this.props.lastLocation.pathname.search(this.props.location.pathname) !== -1) {
      return;
    }
    if (this.props.history.location.pathname !== `${this.props.languagePrefix}/` && this.categoriesRefs) {
      const alias = this.props.history.location.pathname.slice(1);
      if (alias === 'menu') {
        setTimeout(() => { this.scrollTo(alias); this.setActiveLink(alias); }, 100);
        return;
      }
      const category = this.props.categories.find((cat) => `${prefix}${cat.alias}` === alias);
      if (category && category.id) {
        setTimeout(() => { this.scrollTo(category.id); this.setActiveLink(category.id); }, 100);
      }
    } else if (this.props.history.location.pathname === `${this.props.languagePrefix}/`) {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isFilterActive !== this.state.isFilterActive) {
      return true;
    }

    if (nextState.activeMeta !== this.state.activeMeta) {
      return true;
    }

    return nextProps.activeCatalogLink && nextProps.activeCatalogLink.shouldCatalogUpdate;
  }

  componentWillUnmount() {
    this.props.setActiveCatalogLink({
      shouldCatalogUpdate: true,
    });
    if (this.props.location.pathname === `${this.props.languagePrefix}/cart`) {
      return;
    }
    this.intersectionObserver.disconnect();
  }

  createFilterField = (dataArray, type) => dataArray.reduce((result, filterItem) => {
    result[`${type}${filterItem.id}`] = true;
    return result;
  }, {})

  renderFilterFields = (ingredients, allergens) => ({

    ...this.createFilterField(ingredients, this.filterNames.ingredients),
    ...this.createFilterField(allergens, this.filterNames.allergens),
  });

  createCategoriesRefs = (categories) => categories.reduce((res, category) => {
    res[category.id] = React.createRef();
    return res;
  }, { menu: this.allCategoriesRef })

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterActive: !prevState.isFilterActive,
    }));
  }

  isProductAllowed = (arr, type) => {
    let isAllowed = true;
    if (Array.isArray(arr) && arr.length > 0) {
      isAllowed = arr.every((item) => this.props.filterData[`${type}${item}`]);
    }
    return isAllowed;
  }

  isTimeActive = (product) => {
    const since = new Date(product.visible_since).getTime();
    const till = new Date(product.visible_till).getTime();
    if (!product.visible_since && !product.visible_till) {
      return true;
    }
    return this.today >= since && this.today <= till;
  }

  filterCategories = (categories) => categories.map((category) => ({
    ...category,
    products: category.products.filter((product) => this.isProductAllowed(
      product.ingredients,
      this.filterNames.ingredients,
    ) && this.isProductAllowed(
      product.allergens,
      this.filterNames.allergens,
    ) && this.isTimeActive(product)),
  }));

  setActiveCategory = (id) => {
    const category = this.props.categories.find((cat) => cat.id === id);
    if (category) {
      const meta = Object
        .entries(category)
        .filter((item) => (item[0].includes('meta') && item[0].includes(`${this.props.currentLanguage}`) && !item[0].includes('image'))
          || (item[0].includes('seo_text') && item[0].includes(`${this.props.currentLanguage}`)));

      const metaByLang = meta.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const getMetaValue = (keyWord) => {
        let value;
        Object.keys(metaByLang).forEach((item) => {
          if (item.includes(keyWord)) {
            value = metaByLang[item];
          }
        });
        return value;
      };


      this.setState(() => ({
        activeMeta: {
          title: getMetaValue('title'),
          keywords: getMetaValue('keywords'),
          description: getMetaValue('description'),
        },
        seoText: getMetaValue('seo_text') || '',
      }));
    }
  }

  observerCallback = (entities) => {
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      return;
    }
    const active = entities.find((entity) => entity.isIntersecting);
    if (active && active.target.clientHeight !== 0) {
      this.setActiveLink(active.target.dataset.id || null);
      this.setActiveCategory(active.target.dataset.id || null);
      return;
    }
    if (window.pageYOffset < 220) {
      this.setActiveLink(null);
    }
  }

  observeCategories = () => {
    const keys = Object.keys(this.categoriesRefs);
    keys.forEach((key) => {
      this.intersectionObserver.observe(this.categoriesRefs[key].current);
    });
  }

  intersectionInit = () => {
    const options = {
      rootMargin: this.intersectionMargin,
    };
    this.intersectionObserver = new IntersectionObserver(this.observerCallback, options);
    this.observeCategories();
  }

  getActiveCategory = (categoryId) => {
    this.activeCategory = categoryId;
  }

  changeRouteByScroll = (categoryId) => {
    let alias = '';
    if (categoryId === 'menu') {
      this.props.history.replace(`${this.props.languagePrefix}/`);
      return;
    }
    if (categoryId) {
      alias = `${this.props.categories.find((category) => category.id === categoryId).alias}` || '';
    }
    this.props.history.replace(`${this.props.languagePrefix}/${alias}`);
  }

  setActiveLink = (categoryId) => {
    this.props.setActiveCatalogLink({
      activeCategory: categoryId,
      shouldCatalogUpdate: false,
    });
    this.changeRouteByScroll(categoryId);
  }

  scrollTo = (categoryId) => {
    // const size = window.innerWidth;
    if (this.categoriesRefs[categoryId].current) {
      const difference = this.categoriesRefs[categoryId].current.offsetTop
        + this.categoriesWrap.current.offsetTop;
      window.scrollTo({ top: difference - 100, left: 0, behavior: 'auto' });
    }
  }

  render() {
    const {
      activeCatalogLink,
      categories,
      ingredients,
      allergens,
      catIdFromAlias,
      addToCart,
      currentLanguage,
      languagePrefix,
      user,
      filterData,
      // filteredCategories,
    } = this.props;
    let categoriesToShow = categories;

    if (filterData) {
      categoriesToShow = this.filterCategories(categories, filterData);
    }

    return (
      <StyledCatalogPage className="page-catalog">
        <CatalogMeta
          activeMeta={this.state.activeMeta}
        />
        <ProductAddBar />
        <div className="page-content">
          <div className="page-content__sidebar page-content__sidebar--left">
            <CategoriesNav
              filteredCategories={categoriesToShow}
              getActiveCategory={this.getActiveCategory}
              catIdFromAlias={catIdFromAlias}
              languagePrefix={languagePrefix}
              scrollTo={this.scrollTo}
            />
          </div>
          <div className="page-content__container" ref={this.categoriesWrap}>
            <Categories
              allCategoriesRef={this.allCategoriesRef}
              filteredCategories={categoriesToShow}
              categoriesRefs={this.categoriesRefs}
              currentLanguage={this.props.currentLanguage}
              addToCart={addToCart}
              acticvateFilter={this.toggleFilter}
              user={user}
            />
            <MainSeoText categorySeoText={this.state.seoText} />
          </div>
          <div className="page-content__sidebar page-content__sidebar--right">
            <CartContainer isSidebarMode />
          </div>
        </div>
        {this.state.isFilterActive && ingredients && allergens ? (
          <ModalWrapper handleModalClose={this.toggleFilter} modalId="FilterContainer">
            <FilterContainer
              closeFilter={this.toggleFilter}
            />
          </ModalWrapper>
        ) : null}
      </StyledCatalogPage>
    );
  }
}

const StyledCatalogPage = styled.div`
  --padding-top: 80px;
  --background-color: #0A0A0A;
  --cart-background: #020202;

  .page-content {
    display: block;
    
    &__container {
      max-width: 1230px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
    
    &__sidebar {
      position: relative;
      background-color: var(--background-color);
      
      &--left {
        padding: 0;
      }
    
      &--right {
        background-color: var(--cart-background);
      }
    }
    
    &__text {
      text-align: justify;
      padding-bottom: 90px;
    }
  }
  
  @media all and (min-width: 769px) {
    
    .page-content {
      display: grid;
      grid-template-columns: minmax(180px, 15%) minmax(280px, 1230px) minmax(300px, 32%);
      justify-content: center;
      
      &__sidebar {
        padding-top: var(--padding-top);
      }
      
      &__sidebar--left {
        padding-left: 20px;
      }
      
      &__sidebar--right {
        padding: 0 0 0 65px;
      }
    }
  }
  
  @media all and (min-width: 1024px) {
    .page-content {
      display: grid;
      justify-content: center;
      &__sidebar--left {
        padding-left: 30px;
      }
    }
  }
  @media all and (min-width: 1280px) {
    .page-content {
      grid-template-columns: minmax(240px, 14%) minmax(280px, 1230px) minmax(280px, 32%);
    }
  }
  
  @media all and (min-width: 1366px) {
    .page-content {
      grid-template-columns: minmax(240px, 14%) minmax(280px, 1230px) minmax(280px, 30%);
      &__sidebar--right {
         padding: 0 10px 0 65px;
      }
    }
  }
  
  @media all and (min-width: 1440px) {
    .page-content {
      grid-template-columns: minmax(240px, 13%) minmax(280px, 1230px) minmax(280px, 28%);
    }
  }
  
  @media all and (min-width: 1441px) {
    .page-content {
      grid-template-columns: minmax(240px, 13%) minmax(280px, 1230px) minmax(280px, 24%);
      &__sidebar--right {
         padding: 0 30px 0 65px;
      }
      &__sidebar--left {
        padding-left: 40px;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  activeCatalogLink: state.activeCatalogLink ? state.activeCatalogLink : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  filterData: state.filterData ? state.filterData : null,
  filteredCategories: state.filteredCategories,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const MemorizedCatalog = connect(mapStateToProps, mapDispatchToProps)(React.memo(Catalog));

export default withRouter(withLastLocation(MemorizedCatalog));
