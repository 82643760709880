import React from 'react';
import { Translate } from 'react-localize-redux';

const emailValidation = (value) => {
  const errors = {};
  const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!value.email) {
    errors.email = <Translate id="FillEmail" />;
  }
  if (!emailRegExp.test(value.email)) {
    errors.email = <Translate id="IncorrectEmail" />;
  }
  return errors;
};

export default emailValidation;
