import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import { ru, uk } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate ? new Date(props.startDate) : new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { handleDeferredChange = null } = this.props;

    this.setState(prevState => ({
      startDate: date,
    }));

    let chosenDate;
    if (typeof date.getMonth === 'function') {
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      chosenDate = `${date.getFullYear()}-${month}-${date.getDate()}`;
    }

    if (handleDeferredChange) {
      handleDeferredChange({ date: chosenDate });
    }
  }

  render() {
    const { inline = null, className, lang } = this.props;
    const { startDate } = this.state;

    const today = new Date();
    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    if (lang === 'ru') {
      registerLocale('ru', ru);
    } else if (lang === 'ua') {
      registerLocale('uk', uk);
    }

    return (
      <StyledDatePickerComponent className="checkout-calendar">
        <DatePicker
          selected={startDate}
          onChange={this.handleChange}
          minDate={today}
          maxDate={addDays(today, 7)}
          popperPlacement="bottom-start"
          inline={inline}
          locale={lang === 'ua' ? 'uk' : 'ru'}
        />
      </StyledDatePickerComponent>
    );
  }
}

const StyledDatePickerComponent = styled.div`
  --background-color: #0A0A0A;
  --text-color: #FFFFFF;
  --secondary-text-color: #8E8E8E;
  --disabled-text-color: #313131;
  --border-color: #E74E1A;

  .checkout-calendar {
    width: 100%;
  }
  
  .react-datepicker {
    border: none;
    width: 100%;
    
    &__triangle {
    
    }
     
    &__navigation {
      user-select: none;
      &--next {
      }
     
      &--previous {
      }
    }
     
    &__month-container {
      width: 100%;
    }
    
    &__header {
      background-color: var(--background-color);
      margin: none;
      border-bottom: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    
    &__current-month {
      color: var(--secondary-text-color);
      padding-bottom: 1.25rem;
      font-weight: 100;
      width: 100%;
    }
    
    &__day-names {

    }
    
    &__day-name {
      color: var(--secondary-text-color);
      margin: .6rem;
      font-size: 1.1rem;
    }
    
    &__month {
      background-color: var(--background-color);
      margin: 0;
    }
    
    &__week {
      width: 100%;
    }
    
    &__day {
      color: var(--text-color);
      box-sizing: border-box;
      font-weight: 100;
      font-size: 1.1rem;
      margin: .6rem;
      border: 1px solid transparent;
      
      &--outside-month {
        color: var(--secondary-text-color);
      }
      
      &--disabled {
        color: var(--disabled-text-color);
      }
      
      &--selected {
        border: 1px solid var(--border-color);
        color: var(--border-color);
        background-color: transparent;
      }
      
      &:not(.react-datepicker__day--disabled):hover {
        cursor: pointer;
        padding: 0;
        border: 1px solid var(--border-color);
        color: var(--border-color);
        background-color: transparent;
      }
    }
  }
  
@media all and (min-width: 769px) { 
  .react-datepicker {
    width: auto;
    &__day {
      font-size: 1rem;
      margin: .2rem;
    }
    
    &__day-name {
      margin: .2rem;
    }
    
    &__current-month {
      font-size: 1rem;
    }
  }
}
`;

export default DatePickerComponent;
