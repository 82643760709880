 const bestSetsProductsList = ( arrayIds = [], categoriesObj = [] ) => {
    let matchProductsArr = [];
   // eslint-disable-next-line no-unused-expressions
    (categoriesObj.length && arrayIds.length)
    ?   categoriesObj.map( catItem =>
            catItem.products.map( product =>
                arrayIds.map( passedId =>
                    ( parseInt(passedId) === parseInt(product.id) ) ? matchProductsArr.push(product) : null
                )
            )
        )
    : false;
  return matchProductsArr;
}
export default bestSetsProductsList;
