import React from 'react';
import styled from 'styled-components';

import MapStores from './MapStores';
import StoresList from './StoresList';
import keyByLang from '../../modules/shared/keyByLang';

import pinImage from '../../assets/images/pin.svg';
import storeImg from '../../assets/icons/header_stores.svg';

import { search } from '../../assets/icons/svgIcons';
// import fixImagePath from '../../modules/shared/fixImagePath';

class Stores extends React.Component {
  constructor(props) {
    super(props);
    this.time = new Date();

    this.state = {
      searchValue: '',
    };

    this.checkStoreStatus = this.checkStoreStatus.bind(this);
    this.changeSearchValue = this.changeSearchValue.bind(this);
    this.filterStores = this.filterStores.bind(this);
  }

  checkStoreStatus(store) {
    const hours = this.time.getHours();
    const minutes = this.time.getMinutes();
    const openTimeHours = +store.opened_since_hour;
    const workTillHours = +store.opened_till_hour;
    const openTimeMinutes = +store.opened_since_minute;
    const workTillMinutes = +store.opened_till_minute;
    if (openTimeHours > hours || (openTimeHours === hours && openTimeMinutes > minutes)) {
      return false;
    } if (hours > workTillHours || (workTillHours === hours && minutes > workTillMinutes)) {
      return false;
    }
    return true;
  }

  changeSearchValue(event) {
    const { value } = event.target;
    this.setState({
      searchValue: value,
    });
  }

  filterStores(stores) {
    if (this.state.searchValue) {
      const address = keyByLang('address', this.props.currentLanguage);
      return stores.filter(store => store[address].toLowerCase()
        .includes(this.state.searchValue.toLowerCase()));
    }
    return stores;
  }

  render() {
    const {
      stores, city, currentLanguage, languagePrefix, translate,
    } = this.props;
    const { searchValue } = this.state;

    let storesToShow = null;
    let storesCountName = translate('Stores');

    if (stores && stores.length === 1) {
      storesCountName = 'магазин';
    } else if (stores && stores.length > 1 && stores.length < 5) {
      storesCountName = 'магазина';
    }

    if (stores && stores[0]) {
      storesToShow = this.filterStores(stores);
    }

    return (
      <StyledDiv className="stores-main-content">
        <section className="search">
          <div className="search__input-wrap">
            <input
              className="search__input"
              type="text"
              name="store_search"
              placeholder={translate('StoreSearch')}
              value={searchValue}
              onChange={this.changeSearchValue}
            />
            {search}
          </div>
          <h6 className="search__heading">{translate('OurStore')}</h6>
          <hr className="solid search__line" />
        </section>
        <div className="map">
          {stores && stores[0] ? (
            <MapStores
              stores={storesToShow}
              languagePrefix={languagePrefix}
              currentLanguage={currentLanguage}
              checkStoreStatus={this.checkStoreStatus}
              translate={translate}
            />
          ) : null}
        </div>
        <div className="cities">
          <figure className="cities__current">
            <img
              className="cities__marker"
              src={pinImage}
              alt="Marker"
              decoding="async"
              loading="lazy"
            />
            <figcaption className="cities__text-wrap">
              <h6 className="cities__name">{city}</h6>
              <p className="cities__amount">{stores.length} {storesCountName}</p>
            </figcaption>
          </figure>
          {storesToShow && storesToShow[0] ? (
            <StoresList
              stores={storesToShow}
              currentLanguage={currentLanguage}
              languagePrefix={languagePrefix}
              checkStoreStatus={this.checkStoreStatus}
              translate={translate}
            />
          ) : (
            <p className="cities__empty-list">{translate('CoincidencesNotFound')}</p>
          )}
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  --text-color: #8E8E8E;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .search {
    padding: 1.250rem;
    color: #999;
    display: flex;
    flex-direction: column-reverse;
    
    &__line {
      display: none;
    }

    &__heading {
      font-weight: 400;
      text-transform: uppercase;
      padding-bottom: 1rem;

      &::before {
        content: '';
        background-image: url(${storeImg});
        background-repeat: no-repeat;
        padding-right: 1.75rem;
      }
    }
    
    &__input-wrap {
      position: relative;
      width: 100%;
    }
    
    & svg {
      position: absolute;
      top: .8rem;
      right: .5rem;
    }

    &__input {
      width: 100%;
      background-color: #222;
      color: #fff;
      border-radius: 3px;
      border: 1px solid #333;
      line-height: 1.5;
      padding: .5rem 1.7rem .5rem .5rem;
    }
  }
  
  .map {
      width: 100%;
      height: 20rem;
  }

  .cities {
    margin: 0 0 5rem;
  
    &__text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    &__current {
      display: flex;
      padding: .5rem 1rem;
      box-sizing: border-box;
      position: relative;
      
      :after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent 10%, #555 50%, transparent 90%);
      }
    }

    &__name {
      margin: 0 .4rem .4rem .5rem;
      font-weight: 200;
    }

    &__amount {
      color: #999;
      margin: 0 .5rem;
    }
    
    &__empty-list {
      color: var(--text-color);
      padding-top: 2.5rem;
      text-align: center;
    }
  }

  @media (min-width: 768px) {
    .map {
      height: 24rem;
    }
    
    .cities {
      margin: 0;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);

    .search {
      grid-column: 3 / -1;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 5rem 0 2rem;
      
      &__line {
        display: block;
      }

      &__heading {
        padding: 1.25rem 0;
      }
    }
    
    .cities {
      padding: 0 4rem 0 1rem;
    }

    .map {
      height: calc(100vh - 250px);
      min-height: 100%;
      grid-column: 1 / 3;
      grid-row: 1 / -1;
    }
  }
`;

export default React.memo(Stores);
