import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TextPage from './TextPage';
import FooterContainer from "../../containers/FooterContainer";

const TextPages = ({
  currentLanguage,
  textPages,
  languagePrefix,
}) => (
  textPages ? (
    <Switch pages={textPages}>
      {textPages.map(page => (
        <Route
          exact
          path={`${languagePrefix}/pages/${page.alias}`}
          key={page.id}
          render={() => (
            <React.Fragment>
              <TextPage
                page={page}
                currentLanguage={currentLanguage}
              />
              <FooterContainer />
            </React.Fragment>
          )}
        />
      ))}
    </Switch>
  ) : null
);

const MemorizedTextPages = React.memo(TextPages);

export default MemorizedTextPages;
