import { orderBy } from 'lodash-es';
import * as types from '../actions/actionTypes';

const sortPromotions = (news) => {
  if (news && news[0]) {
    return orderBy(news.map(page => ({
      ...page,
      id: parseInt(page.id, 10),
    })), ['publish_date', 'id'], ['desc', 'desc']);
  }
  return [];
};

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROMOTIONS_SUCCESS:
      return sortPromotions(action.payload);
    default:
      return state;
  }
};

const initialState = [];

export default promotionsReducer;
