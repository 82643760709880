import React from 'react';
import styled from 'styled-components';

import AnimatedButton from '../shared/buttons/AnimatedButton';
import CartEmpty from './CartEmpty';
import CartUpSaleBlock from './CartUpSaleBlock';
import CartCrossSaleBlock from './crossSale/CartCrossSaleBlock';
import CartAccessories from './CartAccessories';

import logo from '../../assets/images/logo.svg';
import emptyCart from '../../assets/icons/empty_cart.svg';
import { cart, filterDone } from '../../assets/icons/svgIcons';
import plus from '../../assets/icons/plus.svg';


class CartContentModal extends React.Component {
  componentWillUnmount() {
    this.props.cartSubmitInProgressHandler(false);
  }

  render() {
    const {
      cartData,
      piecesCount,
      upSaleProducts,
      crossSaleCategories,
      collectedAccessories,
      qtyHandler,
      removeCartItemHandler,
      currentLanguage,
      languagePrefix,
      nextStep,
      redirectToCategory,
      order,
      openAccessoriesModal,
      setAccessories,
      isAccessoriesChanging,
      translate,
    } = this.props;

    let addPrice = 0;

    if (!cartData || !cartData.content.length) {
      return (
        <CartEmpty emptyCartImage={emptyCart} logo={logo} translate={translate} isModal />
      );
    }

    function countExtraPrice(extraAccessories, acc) {
      return acc.reduce((res, accessorie) => {
        res += extraAccessories[accessorie.id]
          ? extraAccessories[accessorie.id]
          * accessorie.price : 0;
        return res;
      }, 0);
    }


    if (cartData && cartData.id && order && order.extraAccessories && collectedAccessories && collectedAccessories[0]) {
      addPrice = countExtraPrice(order.extraAccessories, collectedAccessories);
    }

    return (
      <StyledDiv
        isAccessoriesChanging={isAccessoriesChanging}
        className="cart-modal"
        currentLanguage={currentLanguage}
      >
        <div className="cart-modal__logo-wrap">
          <img className="cart-modal__logo-image" src={logo} alt="alt text" decoding="async" loading="lazy" />
          <hr className="solid line--mobile" />
        </div>
        <div className="cart-modal__header">
          {!isAccessoriesChanging ? (
            <div className="cart-modal__title-wrap">
              {cart}
              <p className="cart-modal__title">
                <span className="cart-modal__title--mobile">{translate('YourCart')}</span> {translate('YourCart1')}
              </p>
              <div className="cart-modal__break-line" />
              <p className="cart-modal__pieces">{piecesCount} шт</p>
            </div>
          ) : null}
          <div className="">
            <div className="cart-modal__button-wrap">
              <AnimatedButton
                minWidth={currentLanguage === 'ua' ? '210px' : '180px'}
                text={!isAccessoriesChanging ? translate('CheckoutButton') : translate('ConfirmButton')}
                icon={filterDone}
                handleClick={isAccessoriesChanging ? setAccessories : nextStep}
              />
            </div>
            {isAccessoriesChanging ? (
              <p className="cart-modal__mobile-heading">{translate('ItemsIncluded')}</p>
            ) : (
              <p className="cart-modal__mobile-heading">{translate('TotalAmount')} {cartData.total + addPrice} грн</p>
            )}
          </div>
        </div>
        <hr className="solid line--desktop" />
        {!isAccessoriesChanging || !collectedAccessories ? (
          <React.Fragment>
            <div className="cart-modal__content">
              <CartUpSaleBlock
                upSaleProducts={upSaleProducts}
                icon={plus}
                languagePrefix={languagePrefix}
                currentLanguage={currentLanguage}
                qtyHandler={qtyHandler}
                removeCartItemHandler={removeCartItemHandler}
                redirectToCategory={redirectToCategory}
                translate={translate}
              />
              <CartCrossSaleBlock
                currentLanguage={currentLanguage}
                icon={plus}
                languagePrefix={languagePrefix}
                crossSaleCategories={crossSaleCategories}
                qtyHandler={qtyHandler}
                removeCartItemHandler={removeCartItemHandler}
                redirectToCategory={redirectToCategory}
                translate={translate}
              />
            </div>
            <div className="cart-modal__accessories-header">
              <h3 className="cart-modal__block-title cart-modal__block-title--no-padding">{translate('ItemsIncluded')}</h3>
              <p className="cart-modal__link" onClick={openAccessoriesModal}>
                {translate('Edit')}
              </p>
            </div>
            <CartAccessories
              collectedAccessories={collectedAccessories}
              currentLanguage={currentLanguage}
              languagePrefix={languagePrefix}
              isAccessoriesChanging={isAccessoriesChanging}
              order={order}
            />
          </React.Fragment>
        ) : /*(
          <AccessoriesModal
            collectedAccessories={collectedAccessories}
            currentLanguage={currentLanguage}
            setAccessories={this.setAccessories}
            order={order}
            translate={translate}
          />
        )*/null}
      </StyledDiv>
    );
  }
}

export default React.memo(CartContentModal);

const StyledDiv = styled.div`
  --text-color: #8E8E8E;
  --links-color: #E74E1A;
  --active-text-color: #FFFFFF;
  --blocks-padding: 1.5rem;
  
  max-width: ${props => (props.isAccessoriesChanging ? '1230px' : '630px')};
  margin: 0 auto;

  color: var(--text-color);
  position: sticky;
  top: 0;
  z-index: 2;
  
  .line {
    &--desktop {
      width: 100%;
      position: sticky;
      top: 137px;
    }
    &--mobile {
      display: block;
      margin-top: .5rem;
    }
  }
    
  .cart-modal {
    
    &__logo-wrap {
      padding: .9rem 15px 0;
      text-align: left;
      width: 100%;
      top: 0;
      left: 0;
      position: sticky;
      background-color: #0A0A0A;
      z-index: 2;
    }
    
    &__logo-image {
      width: 4.5rem;
    }
    
    &__header-placeholder {
      height: 142px;
      width: 100%;
    }
      
    &__header {
      justify-content: ${props => (props.isAccessoriesChanging ? 'center' : 'space-between')};
      padding: 24px 15px 24px;
      display: flex;
      align-items: flex-end;
      width: 100%;
      position: sticky;
      top: 69.9px;
      left: 0;
      background-color: #0A0A0A;
      z-index: 2;
    }
    
    &__content {
      position: relative;
      padding: 0 15px var(--blocks-padding);
    }
    
    &__accessories-header {
      display: flex;
      justify-content: space-between;
      padding: var(--blocks-padding) 1rem 1rem;
      align-items: center;
    }
    
    &__block-title {
      text-transform: none;
      color: var(--text-color);
      font-weight: 200;
      font-size: 1.2rem;
      padding-top: var(--blocks-padding);
      
      &--no-padding {
        padding-top: 0;
      }
    }
      
    &__title-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      //padding-bottom: 1.5rem;
      
      & path {
        fill: #8E8E8E;
      }
    }
      
    &__title {
      padding-top: .2rem;
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 100;
      margin-left: .3rem;
      
      &--mobile {
        display: none;
      }
    }
    
    &__break-line {
      width: 2px;
      min-height: 18px;
      background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
      background-size: 1px 9px;
      opacity: .8;
      margin: 0 5px;
    }
      
    &__pieces {
      font-size: .9rem;
      padding-top: .2em;
    }
      
    &__total-row {
      padding: 1.5rem 0 3.1rem;
      font-size: 1rem;
      
      width: 50%;
      float: right;
      text-align: right;
    }
    
    &__link {
      text-decoration: none;
      font-weight: 100;
      font-size: .9rem;
      color: var(--links-color);
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__button-wrap {
      position: fixed;
      width: 100%;
      padding: 0 10px;
      bottom: 0;
      left: 0;
      z-index: 11;
      
      .button {
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
      }
    }
    
    &__price {
      display: flex;
      font-size: 1rem;
      color: var(--active-text-color);
      font-weight: 700;
      justify-content: flex-end;
    }
    
    &__line {
      display: block;
      margin-top: 15rem;
    }
    
    &__mobile-heading {
      font-size: 1rem;
      font-weight: 100;
      color: #fff;
    }
  }
  
  .align-center {
    text-align: center;
  }
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  
@media all and (min-width: 640px) {
  padding-bottom: 0;
  
  .line {
    &--desktop {
      max-width: ${props => (props.isAccessoriesChanging ? '1230px' : '630px')};
      top: 187px;
    }
    &--mobile {
      display: none;
    }
  }

  .cart-modal {
  
    &__title {
      font-size: 1.2rem;
      margin-left: .6rem;
      &--mobile {
        display: inline;
      }
    }
    
    &__break-line {
      margin: 0 8px;
    }
  
    &__logo-wrap {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      max-width: ${props => (props.isAccessoriesChanging ? '1230px' : '630px')};
      left: auto;
    }
    
    &__logo-image {
      width: auto;
    }
  
    &__header {
      flex-direction: row;
      align-items: flex-start;
      display: flex;
      width: 100%;
      font-size: 1rem;
      padding: 10px 0;
      max-width: ${props => (props.isAccessoriesChanging ? '1230px' : '630px')};
      top: 83px;
      left: auto;
    }
    
    &__header-placeholder {
      height: 187px;
    }
    
    &__content {
      padding-left: 0;
      padding-right: 0;
    }
    
    &__title-wrap {
      padding: .5rem 0 0;
    }
    
    &__total-row {
      display: flex;
      float: none;
      align-items: flex-end;
      flex-direction: row;
      padding: var(--blocks-padding) 0;
      align-items: center;
      width: 100%;
      font-size: 1.2rem;
      justify-content: flex-end;
    }
    
    &__price {
      padding-top: 2rem;
      font-size: 1.4em;
    }
    
    &__pieces {
      font-size: 1.2em;
    }
    
    &__button-wrap {
      position: static;
      ${props => (props.currentLanguage === 'ua' ? '210px' : '180px')};
      padding: 0;
    
      .button {
        width: auto;
        border-radius: 4px;
        box-shadow: none;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    
    &__block-title {
      font-size: 1.4rem;
    }
    
    &__link {
      font-size: 1rem;
    }
    
    &__mobile-heading {
      padding-top: 15px;
      text-align: right;
      font-size: 1.5rem;
    }
  }
}
  
@media all and (min-width: 1440px) {
  .cart-modal {
    &__logo-wrap {
      padding-top: 60px;
    }
    
   &__header {
      top: 123px;
      padding: var(--blocks-padding) 0 2.5rem 0;
    }
    
    &__header-placeholder {
      height: 304px;
    }
    
    &__mobile-heading {
      padding-top: 2rem;
    }
    
    &__content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .line {
    &--desktop {
      top: 304px;
    }
  }
}
`;
