/* eslint-disable no-else-return */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { withRouter } from 'react-router';

import * as actions from '../actions/actions';
import NewsListPage from '../components/news/NewsListPage';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import keyByLang from '../modules/shared/keyByLang';
import NewsItemPage from '../components/news/NewsItemPage';

class NewsPageContainer extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  fetchData() {
    return this.props.actions.getNews();
  }

  render() {
    const {
      news, currentLanguage, languagePrefix, translate,
    } = this.props;

    const pageIdFromUrl = this.props.match.params.alias.trim();
    let newsData = null;
    let dateToShow = null;

    if (news) {
      newsData = news.find(newsItem => newsItem.alias === pageIdFromUrl.trim());
    }

    if (newsData && newsData.publish_date) {
      dateToShow = newsData.publish_date.split('-').reverse().join('.');
    }

    if (!newsData) {
      return null;
    } else {
      return (
        <NewsItemPage
          newsData={newsData}
          currentLanguage={currentLanguage}
          languagePrefix={languagePrefix}
          dateToShow={dateToShow}
          translate={translate}
        />
      );
    }
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  news: state.news ? state.news : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const MemorizedNewsPage = React.memo(NewsPageContainer);

export default connect(mapStateToProps, mapDispatchToProps)(MemorizedNewsPage);
