export { default as Portal } from './Portal';
export { default as PortalProvider } from './PortalProvider';

export function removeUniversalPortals() {
  const portals = document.querySelectorAll('[data-react-universal-portal]');
  // `forEach` on `NodeList` is not supported in Googlebot, so use a workaround
  Array.prototype.forEach.call(
    portals,
    (portal) => portal.parentNode.removeChild(portal),
  );
}
