import * as types from '../actions/actionTypes';

const initialState = null;

const mobileSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MOBILE_SIZE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default mobileSizeReducer;
