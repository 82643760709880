import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import coins from '../../../assets/icons/form/coins.svg';
import message from '../../../assets/icons/form/message2.svg';

const OrderFooter = ({
  isMobile, footerFaqData, title, description, languagePrefix, translate,
}) => (
  <StyledOrderFooter className="order-footer">
    <div className="order-footer__content">
      <hr className="solid" />
      <div className="order-footer__data-wrap">
        <div className="order-footer__info">
          <div className="order-footer__icon-wrap">
            <img
              className="order-footer__icon"
              src={coins}
              alt="coins"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div className="order-footer__text-wrap">
            <p className="order-footer__text order-footer__text--light">
              {footerFaqData ? footerFaqData[title] : null}
            </p>
            <p className="order-footer__text">
              {footerFaqData ? footerFaqData[description] : null}
            </p>
          </div>
        </div>
        <hr className="order-footer__line solid" />
        {isMobile ? (
          <div className="order-footer__faq-wrap">
            <div className="order-footer__faq-block">
              <h3 className="order-footer__heading">{translate('LinkSupport')}</h3>
              <a className="order-footer__link" href="https://t.me/Budusushibot" target="_blank" rel="noreferrer">{translate('AskQuestion')}</a>
            </div>
            <div className="order-footer__faq-block order-footer__faq-block--second">
              <div className="order-footer__icon-wrap">
                <img className="order-footer__icon" src={message} alt="coins" decoding="async" loading="lazy" />
              </div>
              <p className="order-footer__text order-footer__text--light order-footer__text--padded">
                {translate('DoYouHaveAnyQuestionsForUs')}
              </p>
            </div>
          </div>
        ) : (
          <div className="order-footer__faq-wrap">
            <div className="order-footer__icon-wrap">
              <img className="order-footer__icon" src={message} alt="coins" decoding="async" loading="lazy" />
            </div>
            <div className="order-footer__text-wrap">
              <h3 className="order-footer__heading">{translate('LinkSupport')}</h3>
              <p className="order-footer__text order-footer__text--light order-footer__text--padded">
                {translate('DoYouHaveAnyQuestionsForUs')}
              </p>
              <a className="order-footer__link" href="https://t.me/Budusushibot" target="_blank" rel="noreferrer">{translate('AskQuestion')}</a>
            </div>
          </div>
        )}
      </div>
    </div>
  </StyledOrderFooter>
);

const StyledOrderFooter = styled.ul`
  --border-color: #313131;
  --price-color: #FFFFFF;
  --border-color: #8E8E8E;
  --link-color: #E74E1A;
  
  width: 100%;
  flex-grow: 0;
  min-height: 7.5rem;
  
  .order-footer {
    &__content {
      max-width: 1230px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 auto;
      height: 100%;
    }
    
    &__data-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }
    
    &__line {
      display: block;
      margin-top: 1.25rem;
    }
    
    &__info {
      padding-top: 1.25rem;
      flex-basis: 50%;
      display: flex;
    }
    
    &__faq-wrap {
      padding: 1.25rem 0 3rem;
      width: 100%;
    }
    
    &__faq-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .5rem 0;
      
      &--second {
        justify-content: flex-start;
      }
    }
    
    &__icon-wrap {
      border: 1px solid var(--border-color);
      width: 4rem;
      height: 4rem;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
    
    &__text-wrap {
      flex-basis: 20%;
      flex-grow: 1;
      padding-left: 1.25rem;
    }
    
    &__text {
      font-size: .9rem;
      padding-top: .6rem;
      
      &--light {
        color: var(--price-color);
        padding-top: 0;  
      }
      
      &--padded {
        padding-bottom: .3rem;
      }
    }
    
    &__heading {
      text-transform: none;
      font-weight: 200;
      font-size: 1rem;
    }
    
    &__link {
      font-size: .9rem;
      color: var(--link-color);
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .order-footer {
      &__data-wrap {
        flex-direction: row;
      }
      
      &__faq-wrap {
        flex-basis: 50%;
        padding: 1.25rem 12%;
        display: flex;
      }
      
      &__heading {
        font-size: 1.25rem;
        padding-bottom: .3rem;
      }
      
      &__line {
        display: none;
      }
      
      &__icon-wrap {
        margin-right: 0;
      }
      
      &__link {
        padding-top: .3rem;
      }
    }
  }
  
  @media all and (min-width: 1024px) {
    
  }
`;

export default OrderFooter;
