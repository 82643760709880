import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import call from '../../assets/icons/footer_call.svg';
import marker from '../../assets/icons/footer_marker.svg';
import facebook from '../../assets/icons/facebook.svg';
import telegram from '../../assets/icons/telegram.svg';
import instagram from '../../assets/icons/instagram.svg';
import gPlay from '../../assets/icons/gplay.svg';
import appStore from '../../assets/icons/appstore.svg';
import keyByLang from '../../modules/shared/keyByLang';
import CitySelector from '../CitySelector';
import { citiesData } from '../../utils/constants';

const FooterTop = ({
  currentLanguage, languagePrefix, translate, footerLinks, currentCity,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const title = keyByLang('title', currentLanguage);

  function toggleOpened() {
    setIsOpened(!isOpened);
  }

  return (
    <StyledFooterTop className="wide-container">
      <div className="footer-top">
        <hr className="solid" />
        <img
          className="footer-logo"
          src={logo}
          alt="BuduSushi Logo"
          decoding="async"
          loading="lazy"
          hidden
        />
        <div className="hugebox">
          <img
            className="footer-logo"
            src={logo}
            alt="BuduSushi Logo"
            decoding="async"
            loading="lazy"
            hidden
          />
          <div className="hugebox__help">
            <figure className="hugebox__help__figure">
              <img
                className="hugebox__help__icon"
                src={marker}
                alt="Your city"
                decoding="async"
                loading="lazy"
              />
              <figcaption>
                <p>{translate('YourCity')}</p>
                <button type="button" className="hugebox__help__link open-city-list-btn" onClick={toggleOpened}>
                  {currentCity && translate(citiesData[currentCity].translationName)}
                </button>
              </figcaption>
            </figure>
            <figure className="hugebox__help__figure">
              <img
                className="hugebox__help__icon"
                src={call}
                alt="Faq Icon"
                decoding="async"
                loading="lazy"
              />
              <figcaption>
                <p>{translate('TextSupport')}</p>
                <a
                  className="hugebox__help__link"
                  href="https://t.me/Budusushibot"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translate('HaveQuestions')}
                </a>
              </figcaption>
            </figure>
          </div>
          <hr className="solid" />
          <section className="hugebox__nav">
            <p hidden>{translate('SiteSections')}</p>
            <ul className="hugebox__nav__list">

              <li className="hugebox__nav__list__item">
                <Link to={`${languagePrefix}/stores`}>{translate('OurStore')}</Link>
              </li>
              {/* <li className="hugebox__nav__list__item"> */}
              {/* <Link to={`${languagePrefix}/pages/bonus-club`}>{translate('BonusClub')}</Link> */}
              {/* </li> */}
              <li className="hugebox__nav__list__item"><a href={`${languagePrefix}/promotions`}>{translate('Promotions')}</a></li>
              {/* <li className="hugebox__nav__list__item"> */}
              {/*  <Link to={`${languagePrefix}/news`}>{translate('News')}</Link> */}
              {/* </li> */}
              {/* <li className="hugebox__nav__list__item"> */}
              {/* <Link to={`${languagePrefix}/pages/about-us`}>{translate('AboutUs')}</Link> */}
              {/* </li> */}
              <li className="hugebox__nav__list__item">
                <Link to={`${languagePrefix}/vacancies`}>{translate('Vacancies')}</Link>
              </li>
              {footerLinks ? (footerLinks.map((page) => (
                <li className="hugebox__nav__list__item" key={page.id}>
                  {page.alias !== 'franchise' ? (
                    <Link to={`${languagePrefix}/pages/${page.alias}`}>{page[title]}</Link>
                  ) : (
                    <a href="https://franchise.budusushi.ua" rel="noreferrer" target="_blank">{page[title]}</a>
                  )}
                </li>
              ))) : null}
            </ul>
          </section>
        </div>
        <hr className="solid" />
        <div className="social">
          <a
            className="social__link"
            href="https://www.facebook.com/Budusushi-486323755141964/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="icon" src={facebook} alt="Facebook" />
          </a>
          <a
            className="social__link"
            href="https://www.instagram.com/budusushi/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="icon" src={instagram} alt="Isntagram" />
          </a>
          <a
            className="social__link"
            href="https://t.me/Budusushibot"
            target="_blank"
            rel="noreferrer"
          >
            <img className="icon" src={telegram} alt="Telegram" />
          </a>
          <p
            className="text"
          >
            {translate('FollowUs')}
          </p>
        </div>
        <div className="apps">
          <a
            className="apps__link"
            href="https://play.google.com/store/apps/details?id=ua.budusushi"
            target="_blank"
            rel="noreferrer"
          >
            <img className="icon" src={gPlay} alt="Google Play" />
          </a>
          <a
            className="apps__link"
            href="https://itunes.apple.com/ua/app/budusushi/id1439314469?l=ru&mt=8"
            target="_blank"
            rel="noreferrer"
          >
            <img className="icon" src={appStore} alt="Apple Store" />
          </a>
          <p className="text">{translate('DownloadMobileApp')}</p>
        </div>
      </div>
      {isOpened && <CitySelector translate={translate} currentCity={currentCity} modalClose={toggleOpened} />}
    </StyledFooterTop>
  );
};

export default FooterTop;

const StyledFooterTop = styled.div`
  color: #8f8f8f;
  font-size: 14px;
  
  .open-city-list-btn {
    background: none;
    border: none;
    text-align: left;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  .icon {
    width: 3rem;
    height: 3rem;
  }

  .footer-top {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .text {
    width: 100px;
  }

  .hugebox {
    display: flex;
    flex-direction: column-reverse;
    padding-top: .5rem;
    color: #8e8e8e; 
    
    &__help {
      padding-top: 1rem;
      display: flex;
      justify-content: space-around;
      flex-direction: row;

      &__figure {
        display: flex;
      }

      &__icon {
        align-self: flex-start;
        margin-right: 1rem;
        width: 1rem;
      }

      &__link {
        display: block;
        margin: 1rem 0; 
        color: #e74e1a ;
        text-decoration: none;
      }
    }

    &__nav {
      position: relative;
      padding-bottom: 1rem;
      display: flex;
      justify-content: center;

      > p {
        margin-bottom: .5rem;
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(2, minmax(100px, 200px));
        grid-template-rows: auto;
        grid-column-gap: 1rem;
       

        &__item{

          padding-left: 1.5rem;

          > a {
            display: block;
            margin: .5rem 0; 
            color: #e74e1a ;
            text-decoration: none;
            padding-right: 1rem;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 5%;
        left: 50%;
        width: 2px;
        height: 80%;
        display: block;
        background-image: linear-gradient(0deg, #555, #555 30%, transparent 40%, transparent 100%);
        background-size: 2px 5px;
      }
    }

  }
  
  .social {     
    grid-row-start: 1;
  }

  .apps, .social {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;

    &__link {
      margin-right: 1rem;
      opacity: .95;
      

      :hover {
        opacity: 1;
      }
    }
  }

  &-text {  
      flex-basis: 50%;
      font-size: 16px;
      align-self: center;
  }

  @media (min-width: 360px) {
    .text {
      width: 140px;
    }
  }
  
  @media (min-width: 768px) {

    .footer-top {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;

      &> hr:first-child {
        display: none;
      }
    }


    .hugebox {
      padding: 1rem 0 0;
      grid-column: 1 / -1;
      flex-direction: row;
      position: relative;

      > .footer-logo {
        display: block;
        align-self: flex-start;
        margin-right: 2rem;
        margin-left: 1rem;
      }
      
      &__help {
        flex-direction: column;
        padding-top: 0rem;
        padding-bottom: 1rem;
        flex-grow: 1;

        &__link {
          margin: .5rem 0;
          
          &:hover {
            text-decoration: underline;
          }
        }

        & + .solid {
          display: none;
        }
      }

      &__nav {
        
        &__list {
          align-items: center;
          
          &__item{

            padding-left: 1rem;

            > a {
              margin: 0;
              
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &:before {
          display: none;
        }
      }

      & + .solid {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 15%;
        left: 50%;
        width: 2px;
        height: 70%;
        background-image: linear-gradient(0deg, #555, #555 30%, transparent 40%, transparent 100%);
        background-size: 2px 5px;
      }
    }
    
    .social {     
      grid-row-start: 3;
      justify-content: flex-start;
    }

    .apps {
      justify-content: flex-end;
    }

    &-text {  
        
    }
  }

  @media (min-width: 1024px) {
    .footer-top {
      padding-top: 0.75rem;
      grid-template-columns: repeat(5, 1fr);
    }

    .footer-logo {
      grid-column: 1 / 2;
      grid-row: 2 span;
      align-self: center;
      display: block;
    }

    .hugebox {
      grid-column: 2 / 5;
      grid-row: 2 span;
      justify-content: center;

      > .footer-logo {
        display: none;
      }

      &:before{ 
        display: none;
      }

      &__help {
        flex-grow: 0;
        position: relative;

        &__link {
          margin: 1rem 0;
        }
      }

      & + .solid {
        display: none;
      }

      &__nav {
        flex-direction: column;
        margin-left: 3rem;
        justify-content: flex-start;

        p {
          display: block;
          margin-left: 1rem;
        }

        &:before {
          
          display: block;
          top: 5%;
          left: -5%;
          height: 75%;
        }
        
        &__list {
          max-height: 16rem;
          text-align: left;
          align-items: flex-start;
  
          &__item{
            
            padding-bottom: .1rem;
  
            > a {
              display: block;
              color: #e74e1a ;
              text-decoration: none;
              padding: .5rem 1rem .5rem 0;
            }
          }
        }
      }
    }

    .social {
      grid-row-start: 1;
      grid-column-start: 5 ;
    }

    .apps {
      grid-row-start: 2;
      grid-column-start: 5;
    }
  }
`;
