import React from 'react';
import styled from 'styled-components';

import ProfileOrderItemProduct from './ProfileOrderItemProduct';
import ProfileActiveOrderTimeLine from './ProfileActiveOrderTimeLine';
import BtnLink from '../../shared/BtnLink';

import { arrowUp, arrowDown } from '../../../assets/icons/svgIcons';

class ProfileOrdersHistoryItem extends React.Component {
  static setTimeToShow(date) {
    if (!date) {
      return '';
    }
    const dateArr = date.split(' ');
    const dayArr = dateArr[0].split('-');
    const timeArr = dateArr[1].split(':');
    return `${dayArr[2]}.${dayArr[1]}/${timeArr[0]}:${timeArr[1]}`;
  }

  constructor(props) {
    super(props);

    this.state = {
      isProductsListVisible: false,
    };
    this.showHideProductsHandler = this.showHideProductsHandler.bind(this);
  }

  showHideProductsHandler = () => {
    this.setState(prevState => ({
      isProductsListVisible: !prevState.isProductsListVisible,
    }));
  }

  render() {
    const {
      order,
      status,
      products,
      statuses,
      paymentMethods,
      deliveryMethods,
      isOrderActive,
      currentLanguage,
      addToCart,
      languagePrefix,
      isMobile,
      isTimeActive,
      isDayActive,
      redirectTo,
      translate,
    } = this.props;
    const { isProductsListVisible } = this.state;
    const { delivery_method_id: deliveryMethodId, payment_method_id: paymentMethodId } = order;

    const orderDate = order.created_at.split(' ')[0].split('-').join('.');
    const createdTime = ProfileOrdersHistoryItem.setTimeToShow(order.created_at);
    const deliveryTime = ProfileOrdersHistoryItem.setTimeToShow(order.delivery_date);

    /*const showHideOrdersBtn = isMobile ? (
      <div className="history-order__button">
        {isProductsListVisible ? arrowDown : arrowUp}
      </div>
    ) : (
      <BtnLink align="right" clickHandle={this.showHideProductsHandler}>
        {`${isProductsListVisible ? translate('Collapse') : translate('ViewOrder')}`}
      </BtnLink>
    );*/

    const orderFooterAction = isMobile ? (
      <div className="history-order__button">
        {isProductsListVisible ? arrowDown : arrowUp}
      </div>
    ) : (
      <BtnLink
        align="right"
        clickHandle={this.showHideProductsHandler}
        text={`${isProductsListVisible ? translate('Collapse') : translate('ViewOrder')}`}
      />
    );

    return (
      <StyledHistoryOrder className="history-order">
        <div className="history-order__content" onClick={isMobile ? this.showHideProductsHandler : null}>
          <div className="history-order__data">
            <div className="history-order__row history-order__row--title">Заказ № {order.id}</div>
            <div className="history-order__row">{translate('OrderDate')}: {order.created_at}</div>
            <div className="history-order__row">{translate('OrderPrice')}: {order.to_pay} грн</div>
          </div>
          <div className="history-order__action">
            {orderFooterAction}
          </div>
        </div>
        {isProductsListVisible ? (
          <ul className="order-list">
            {products && products[0] && order ? products.map((product, i) => (
              <ProfileOrderItemProduct
                product={product}
                isVisible={isProductsListVisible}
                currentLanguage={currentLanguage}
                order={order}
                addToCart={addToCart}
                languagePrefix={languagePrefix}
                isTimeActive={isTimeActive}
                isDayActive={isDayActive}
                key={`${order.id}-${product.id}-${i}`}
                i={i}
                productsLength={products.length}
                redirectTo={redirectTo}
              />
            )) : null}
          </ul>
        ) : (
          <hr className="solid padded" />
        )}
      </StyledHistoryOrder>
    );
  }
}

const StyledHistoryOrder = styled.li`
  
  .history-order {
    &__content {
      padding: .75rem 0;
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    &__row {
      padding: .2rem 0;
      
      &--title {
        color: var(--active-text-color);
      }
    }
    
    &__action {
      padding: 1rem 0;
    }
    
    &__button {
      
    }
  }
  
  @media all and (min-width: 769px) {
    .history-order {
    
      &__content {
        padding: 1.25rem 0;
      }
      &__row {
        &--title {
          color: var(--text-color);
        }
      }
    }
  }
`;

export default ProfileOrdersHistoryItem;
