import React from 'react';
import styled from 'styled-components';

const Radio = (props) => {
  const {
    label, id, input, handleClick,
  } = props;

  return (
    <StyledRadio>
      <input type="radio" id={id} {...input} />
      <label htmlFor={id} onClick={handleClick ? () => handleClick(id) : null}>{label}</label>
    </StyledRadio>
  );
};

const StyledRadio = styled.div`

  &:last-child:after {
    content: "";
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
  }
    
  input {
    display: none;

    &:checked ~ label {
      &:before {
        background-color: #8e8e8e;
      }
    }
  }
  label {
    position: relative;
    padding-left: 20px;
        
    &:hover {
      cursor: pointer;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid #8e8e8e;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      box-shadow: inset 0 0 0px 2px #131313;
      background-color: #131313;
    }
  }
  
  @media all and (min-width: 769px) {
    label {
      padding-left: 30px;
    }
  }
`;

export default Radio;
