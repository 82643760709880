import * as types from '../actions/actionTypes';

const initialState = null;

const seoHomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SEO_HOME_PAGE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default seoHomePageReducer;
