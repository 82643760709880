import { axiosInstance } from './axiosInstance';
import * as headers from './headers';
import _serialize from './helpers/serialize';

const FaqApi = {
  getFaq() {
    return axiosInstance.post('reviews-types',
      {},
      {
        headers: headers.json,
      });
  },
  sendReview(obj) {
    return axiosInstance.post('reviews/send',
      _serialize(obj),
      {
        headers: headers.urlEncoded,
      });
  },
};

export default FaqApi;
