import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import styled from 'styled-components';

import getProductsByIds from '../../modules/shared/getProductsByIds';
import ProfileOrderItemProductsListItem from './orders/ProfileOrderItemProductsListItem';

import * as actions from '../../actions/actions';

import userWish from '../../assets/icons/profile/lk_wishlist.svg';
import getlanguagePrefix from "../../modules/shared/getLanguagePrefix";
// import getProductById from "../../modules/shared/getProductById";
import filterAddonsByTime from "../../modules/shared/filterAddonsByTime";

class ProfileFeaturedProductsPage extends React.Component {
  componentDidMount() {
    this.fetchData();

    this.addToCart = this.addToCart.bind(this);
    this.checkAddonsBeforeAddToCart = this.checkAddonsBeforeAddToCart.bind(this);
    this.isTimeActive = this.isTimeActive.bind(this);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    this.isTimeActive = this.isTimeActive.bind(this);
    this.isDayActive = this.isDayActive.bind(this);
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getCategories(),
      this.props.actions.getUser(),
    ]);
  }

  addToCart(product) {
    const addons = (product.tabs && product.tabs.length > 0 && product.tabs[0].addons)
      ? product.tabs[0].addons
      : null;
    this.props.actions.cartAdd(product.id, addons);
  }

  checkAddonsBeforeAddToCart(product) {
    const { languagePrefix, categories } = this.props;
    let filteredAddons = null;
    if (product.tabs && product.tabs[0]) {
      filteredAddons = filterAddonsByTime(product.tabs);
    }
    if (product.tabs && product.tabs[0] && filteredAddons && filteredAddons[0]) {
      const productCategory = categories.find(cat => product.product_category_id === cat.id).alias;
      this.props.history.push(`${languagePrefix}/${productCategory}/${product.alias}`);
    } else {
      this.addToCart(product);
    }
  }

  isDayActive(product) {
    const today = new Date().getTime();
    if (!product.visible_since && !product.visible_till) {
      return true;
    }
    const since = new Date(product.visible_since).getTime();
    const till = new Date(product.visible_till).getTime();
    return today >= since && today <= till;
  }

  isTimeActive(product) {
    const hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const currentTime = +`${hours}${minutes}`;
    if (product.available_since && product.available_till) {
      const since = +product.available_since.split(':').join('');
      const till = +product.available_till.split(':').join('');
      return currentTime > since && currentTime < till;
    }
    return true;
  }

  render() {
    const {
      user, categories, currentLanguage, languagePrefix, isMobile, cart, translate,
    } = this.props;
    const products = user ? getProductsByIds(user.favourites, categories) : [];

    /*const products = user && categories && categories[0]
      ? user.favourites.map(prodId => getProductById(prodId, categories))
      : null;*/

    const featProducts = products && products.length
      ? products.map(product => Object.assign({}, product, { isFeatured: true }))
      : null;

    return (
      <StyledFavouriteProducts className="favourite-products">
        <div className="profile__heading-wrap">
          <img src={userWish} className="profile__heading-icon" alt="user" decoding="async" loading="lazy" />
          <h2 className="profile__heading">{translate('MyFavorites')}</h2>
        </div>
        <hr className="solid" />
        <ul className="favourite-products__content">
          {featProducts && featProducts[0] ? featProducts.map(product => (
            <ProfileOrderItemProductsListItem
              key={product.id}
              product={product}
              categories={categories}
              currentLanguage={currentLanguage}
              addToCart={this.checkAddonsBeforeAddToCart}
              removeFromFavourites={this.props.actions.removeFromFavourites}
              languagePrefix={languagePrefix}
              isTimeActive={this.isTimeActive}
              isDayActive={this.isDayActive}
              isMobile={isMobile}
              cartData={cart}
              translate={translate}
            />
          )) : (
            <p className="favourite-products__empty">{translate('FavoriteProductListIsEmpty')}</p>
          )}
        </ul>
      </StyledFavouriteProducts>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user ? state.user : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  categories: state.categories ? state.categories : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  isMobile: state.isMobile,
  cart: state.cart ? state.cart : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFeaturedProductsPage);

const StyledFavouriteProducts = styled.div`
  .favourite-products {
    &__content {
      padding: 1.25rem 0 6rem;
    }
    
    &__empty {
      color: var(--text-color);
      font-size: 1.5rem;
      text-align: center;
    }
  }
  
@media all and (min-width: 768px) {
  .favourite-products {
    &__content {
      padding: 2.5rem 0 6rem;
    }
  }
}
`;
