import React from 'react';
import styled from 'styled-components';
import TitleBlock from '../shared/TitleBlock';
import PageHeaderBlock from '../shared/PageHeaderBlock';
import headerImage from '../../assets/images/shop_header_image.png';
import NewsList from './NewsList';
import { orderBy, isEmpty } from 'lodash-es';

const NewsListPage = (props) => {
  const {
    news, currentLanguage, languagePrefix, bannerImage, translate,
  } = props;

  return (
    <StyledNewsListPage className="page news-list default">
      <PageHeaderBlock image={bannerImage || headerImage} addClass="" />
      <div className="news-list__content">
        <TitleBlock title={translate('News')} subtitle="" />
        <hr className="solid margin-line" />
        {news && !isEmpty(news) ? (
          <NewsList
            news={news}
            currentLanguage={currentLanguage}
            languagePrefix={languagePrefix}
            translate={translate}
          />
        ) : (
          <p className="news-list__empty">{translate('EmptyNewsList')}</p>
        )}
      </div>
    </StyledNewsListPage>
  );
};

const StyledNewsListPage = styled.div`
  --text-color: #8E8E8E;
  background-color: #0A0A0A;
    
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  
  .margin-line {
    margin-top: 1.4em;
  }
    
  .news-list {
    &__empty {
      color: var(--text-color);
      text-align: center;
      padding-top: 4rem;
      font-size: 1.5rem;
    }
    
    &__content {
      max-width: 1230px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
  }
`;

export default NewsListPage;
