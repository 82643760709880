/* eslint-disable no-trailing-spaces,camelcase,no-param-reassign */
import React from 'react';
import moment from 'moment';
import TimeSlotsList from './TimeSlotsList';

const DeferredOrderTimeSlots = (props) => {
  const {
    activePeriodId,
    timeSlots,
    timeslotInterval,
    handleDeferredChange,
    deliveryMethod,
    orderTime,
    day,
    chosenTimeStep,
    activeTimeArea,
    translate,
  } = props;
  const currentTimeSlot = timeSlots && (timeSlots
    .find((item) => parseInt(item.id, 10) === parseInt(activePeriodId, 10)) || timeSlots[0]);

  const getTimeToReady = (minutes) => {
    let hours = 0;
    if (minutes >= 60) {
      const difference = Math.floor(minutes / 60);
      hours += difference;
      minutes -= difference * 60;
    }
    minutes = +minutes < 10 ? `0${minutes}` : minutes;
    return hours
      ? `${hours} ${translate('Hour')} ${minutes} ${translate('Minutes')}`
      : `${minutes} ${translate('Minutes')}`;
  };

  const {
    starts_at,
    ends_at,
    order_total_time_delivery,
    order_total_time_pickup,
    order_cooking_time,
    order_delivery_time,
  } = currentTimeSlot;

  const interval = timeslotInterval;
  const mStart = moment(starts_at, 'HH:mm');
  const mEnd = moment(ends_at, 'HH:mm');
  const timeSlotsInPeriodArr = [];
  const additionalTime = deliveryMethod === 1 ? order_total_time_pickup : order_total_time_delivery;

  while (mStart.isBefore(mEnd) || mStart.isSame(mEnd)) {
    const tempM = mStart.clone();
    mStart.add(interval, 'Minutes');
    timeSlotsInPeriodArr.push(tempM.format('HH:mm'));
  }

  if (!timeSlotsInPeriodArr.length) {
    return null;
  }
  return +activePeriodId === 1 ? (
    <p className="deferred-order__message">
      {translate('ApproximateTime')}
      :
      <br />
      от
      {`${getTimeToReady(additionalTime)} до
      ${getTimeToReady(+additionalTime + 10)}`}
    </p>
  ) : (
    <TimeSlotsList
      timeSlotsInPeriodArr={timeSlotsInPeriodArr}
      handleDeferredChange={handleDeferredChange}
      chosenTimeStep={chosenTimeStep}
      orderTime={orderTime}
      day={day}
      activeTimeArea={activeTimeArea}
    />
  );
};

export default DeferredOrderTimeSlots;
