import React from 'react';
import styled from 'styled-components';
import BtnLink from '../shared/BtnLink';

const ProfileBlockTitle = ({
  children, action, actionText, icon, paddingTop, withoutBorder,
}) => (
  <StyledDiv className="cabinet-heading" paddingTop={paddingTop} withoutBorder={withoutBorder}>
    <div className="cabinet-heading__icon-wrap">
      {
        typeof icon === 'string' ? (
          <img
            className="cabinet-heading__icon"
            src={icon}
            alt="bonus-icon"
            decoding="async"
            loading="lazy"
          />
        ) : (
          <div className="cabinet-heading__icon">{icon}</div>
        )
      }
    </div>
    <h4 className="cabinet-heading__heading">{children}</h4>
    <BtnLink
      addClass="profile-block__action"
      text={actionText}
      align="right"
      clickHandle={action}
    />
  </StyledDiv>
);

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: .6rem;
    
  .cabinet-heading {
    &__icon-wrap {
      border: ${props => (props.withoutBorder ? 'none' : '1px solid var(--text-color)')};
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
    
    &__icon {
      opacity: .6;
    }
    
    &__heading {
      color: var(--text-color);
      text-transform: none;
      margin-left: 1rem;
      flex-basis: 10%;
      flex-grow: 1;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .profile-block__action {
    width: auto;
  }
  
  @media all and (min-width: 768px) {
    padding-top: ${props => (props.paddingTop ? `${props.paddingTop}px` : '40px')};
    
    .cabinet-heading {
      &__heading {
        font-size: 1.5rem;
        font-weight: 200;
      }
      
      &__icon-wrap {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

export default ProfileBlockTitle;
