import React from 'react';
import styled from 'styled-components';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';

import ProfileSidebar from './ProfileSidebar';
import ProfileMainPage from './ProfileMainPage';
import ProfileOrdersHistoryPage from './ProfileOrdersHistoryPage';
import ProfileFeaturedProductsPage from './ProfileFeaturedProductsPage';

import headerImage from '../../assets/images/LK_banner.jpg';

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pathNames = {
      profile: `${this.props.languagePrefix}/profile`,
      orders: `${this.props.languagePrefix}/profile/orders-history`,
      favouriteProducts: `${this.props.languagePrefix}/profile/favourites`,
    };

    this.handleLogOut = this.handleLogOut.bind(this);
    this.redirectToMain = this.redirectToMain.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user && !this.props.user) {
      this.props.history.push(`${this.props.languagePrefix}/`);
    }
  }

  handleLogOut() {
    this.props.logOut().then(() => this.props.getUser());
    this.props.history.push(`${this.props.languagePrefix}/`);
  }

  redirectToMain() {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/`);
  }

  render() {
    const {
      user, currentLanguage, languagePrefix, location, bonusData, isMobile, bannerImage, translate,
    } = this.props;

    if (user && user.code === 'ERROR') {
      this.redirectToMain();
    }

    this.pathNames = {
      profile: `${languagePrefix}/profile`,
      orders: `${languagePrefix}/profile/orders-history`,
      favouriteProducts: `${languagePrefix}/profile/favourites`,
    };

    return (
      <StyledProfilePage className="cabinet" headerImage={bannerImage || headerImage}>
        <div className="cabinet__header" />
        <div className="page-content">
          <div className="page-content__sidebar page-content__sidebar--left">
            <ProfileSidebar
              languagePrefix={languagePrefix}
              logOut={this.handleLogOut}
              pathNames={this.pathNames}
              location={location}
              user={user}
              isMobile={isMobile}
              redirectToMain={this.redirectToMain}
              translate={translate}
            />
          </div>
          <div className="page-content__container">
            <Switch>
              <Route
                exact
                path={this.pathNames.profile}
                render={props => (
                  <ProfileMainPage
                    {...props}
                    user={user}
                    currentLanguage={currentLanguage}
                    bonusData={bonusData}
                    isMobile={isMobile}
                    logOut={this.handleLogOut}
                    translate={translate}
                  />
                )}
              />
              <Route
                exact
                path={this.pathNames.orders}
                component={ProfileOrdersHistoryPage}
              />
              <Route
                exact
                path={this.pathNames.favouriteProducts}
                component={ProfileFeaturedProductsPage}
              />
              <Redirect to={`${languagePrefix}/`} />
            </Switch>
          </div>
          <div className="page-content__sidebar page-content__sidebar--right" />
        </div>
      </StyledProfilePage>
    );
  }
}

const StyledProfilePage = styled.div`
  --padding-top: 80px;
  --background-color: #0A0A0A;
  --cart-background: #020202;
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --link-color: #E84F1C;
  --secondary-background-color: #1C1C1C;
  --dashed-border-color: #313131;
  
  background-color: var(--background-color);
  position: relative;
  
  .cabinet {
    &__header {
      height: 130px;
      text-align: center;
      position: relative;
      background: linear-gradient(to top, rgba(0, 0, 0, .9) 0%, transparent 50%, rgba(0, 0, 0, .9) 100%),
                  url(${props => (props.bannerImage ? props.bannerImage : headerImage)});
      background-size: auto 100%;
      mix-blend-mode: normal;
      background-repeat: no-repeat;
      background-position: center;
    }
    
    &__icon-wrap {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--text-color);
      border-radius: 4px;
    }
    
    &__icon {
      opacity: .6;
    }
    
    &__content {
      padding: 2.5rem 0 2rem;
      display: flex;
    }
    
    &__block {
       flex-basis: 50%;
       display: flex;
       position: relative;
       
      &:first-child::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        background-image: linear-gradient(to top, var(--dashed-border-color) 20%, transparent 20%, transparent 80%, var(--dashed-border-color) 80%);
        background-size: 2px 6px;
      }
      
      &:last-child {
        flex-direction: column;
        margin-left: 2.5rem;
        justify-content: space-between;
      }
    }
  }
  
  .profile {
    &__heading-wrap {
      display: flex;
      padding-bottom: 1.3rem;
    }
    
    &__heading {
      text-transform: none;
      font-weight: 200;
      font-size: 1.5rem;
      margin-left: .6rem;
    }
  }

  .page-content {
    display: block;
    padding-top: 1.2rem;
    
    &__container {
      max-width: 1230px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
    
    &__sidebar {
      background-color: var(--background-color);
      
      &--left {
        padding-top: 0;
      }
    
      &--right {
        background-color: var(--background-color);
      }
    }
    
    &__text {
      text-align: justify;
      padding-bottom: 90px;
    }
  }
  
@media all and (min-width: 769px) {
  .cabinet {
    &__header {
      height: 250px;
    }
  }

  .page-content {
    display: grid;
    grid-template-columns: minmax(200px, 17%) minmax(280px, 1230px) minmax(0, 0);
    justify-content: center;
    padding-top: 2.4rem;
    
    &__sidebar {
      // padding-top: var(--padding-top);
      
      &--left {
        // padding: calc(var(--padding-top) / 3) 10px 0;
      }
    }
    
    &__sidebar--left {
      padding-left: 20px;
    }
  }
}
  
@media all and (min-width: 1024px) {
  .page-content {
    display: grid;
    grid-template-columns: minmax(220px, 17%) minmax(320px, 1230px) minmax(220px, 17%);
    justify-content: center;
    
    &__sidebar--left {
      padding-left: 40px;
    }
  }
}
`;

export default withRouter(Profile);
