import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import logo from '../../assets/icons/logo_with_bg.svg';


const AppBar = (props) => {
  const [showBar, setShowBar] = useState(false);

  function closeBar() {
    setShowBar(false);
    props.setShownBar(false);
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowBar(true);
      props.setShownBar(true);
    }
  }, []);

  return showBar && (
  <Translate>
    {(translate) => (
      <StyledAppBar>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button onClick={closeBar} className="app-bar__close" type="button" />
        <figure className="app-bar__figure">
          <img src={logo} alt="Logo" decoding="async" loading="lazy" className="app-bar__image" />
          <figcaption>
            <p className="app-bar__top-text">{translate('AppBarDownloadLong')}</p>
            <p className="app-bar__bottom-text">BUDUSUSHI</p>
          </figcaption>
        </figure>
        <a href="https://budusushi.page.link/menu" rel="noreferrer" target="_blank" className="app-bar__download">{translate('AppBarDownload')}</a>
      </StyledAppBar>
    )}
  </Translate>

  );
};

const StyledAppBar = styled.div`
  height: 60px;
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #232323;
  display: flex;
  align-items:center;
  z-index: 10;
  
  .app-bar {
  
    &__close {
      width: 16px;
      height: 16px;
      margin-right: 15px;
      background: none;
      border: none;
      outline: none;
      position: relative;
      
      &::before, &::after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        transform: rotate(var(--angle, 45deg));
        background-color: #8E8E8E;
        top: 0;
        left: calc(50% - 1px);
      }
      
      &::after {
        --angle: -45deg;
      }
    }
    
    &__figure {
      display:flex;
      align-items:center;
      flex-grow: 1;
    }
    
    &__image {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    
    &__top-text {
      font-size: 12px;
    }
    
    &__bottom-text {
      font-size: 18px;
    }
    
    &__download {
      background-color: #E74E1A;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 10px;
      border-radius: 4px;
      color: #fff;
      text-transform: uppercase;
      font-size: 10px;
      text-shadow: 0 4px 3px rgba(0,0,0,.2);
    }
  }
`;

export default AppBar;
