import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const CategoryApi = {
  getCategoryList() {
    return axiosInstance.post('product-categories',
      {},
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default CategoryApi;
