import React from 'react';
import styled from 'styled-components';
import { Link, NavLink, withRouter } from 'react-router-dom';
import MainBtn from '../shared/MainBtn';

import lkProfile from '../../assets/icons/profile/personal-discription.svg';
import lkWishList from '../../assets/icons/profile/lk_wishlist.svg';
import lkOrders from '../../assets/icons/profile/lk_order.svg';

class ProfileSidebar extends React.PureComponent {
  render() {
    const {
      languagePrefix, logOut, user, location, pathNames, isMobile, redirectToMain, translate,
    } = this.props;
    if (user && user.code === 'ERROR') {
      redirectToMain();
    }
    return (isMobile ? (
      <StyledMobilePagination className="mobile-pagination">
        <div className={location.pathname === pathNames.profile
          ? 'mobile-pagination__button mobile-pagination__button--active'
          : 'mobile-pagination__button'}
        >
          <div className="mobile-pagination__content">
            <img
              className="mobile-pagination__icon"
              src={lkProfile}
              alt="bonus-icon"
              decoding="async"
              loading="lazy"
            />
            <p className="mobile-pagination__text">{translate('MyProfile')}</p>
          </div>
          <Link
            className="mobile-pagination__link"
            to={`${pathNames.profile}`}
          />
        </div>
        <div className={location.pathname === pathNames.orders
          ? 'mobile-pagination__button mobile-pagination__button--active'
          : 'mobile-pagination__button'}
        >
          <div className="mobile-pagination__content">
            <img
              className="mobile-pagination__icon"
              src={lkOrders}
              alt="bonus-icon"
              decoding="async"
              loading="lazy"
            />
            <p className="mobile-pagination__text">{translate('MyOrders')}</p>
          </div>
          <Link
            className="mobile-pagination__link"
            to={`${pathNames.orders}`}
          />
        </div>
        <div className={location.pathname === pathNames.favouriteProducts
          ? 'mobile-pagination__button mobile-pagination__button--active'
          : 'mobile-pagination__button'}
        >
          <div className="mobile-pagination__content">
            <img
              className="mobile-pagination__icon"
              src={lkWishList}
              alt="bonus-icon"
              decoding="async"
              loading="lazy"
            />
            <p className="mobile-pagination__text">{translate('FavoriteProducts')}</p>
          </div>
          <Link
            className="mobile-pagination__link"
            to={`${pathNames.favouriteProducts}`}
          />
        </div>
      </StyledMobilePagination>
    ) : (
      <StyledProfileSidebar className="profile-sidebar">
        <div className="profile-sidebar__user-wrap">
          <p className="profile-sidebar__name">{user && user.name ? user.name : 'Гость'}</p>
          <p className="profile-sidebar__greeting">{translate('NiceToSeeYouAgain')}!</p>
          <MainBtn
            align="left"
            text={translate('ExitAccountButton')}
            clickHandle={logOut}
            addClass="profile-sidebar__button"
            minWidth="180px"
          />
        </div>
        <div className="profile-sidebar__navigation">
          <Link
            className={location.pathname === pathNames.profile
              ? 'profile-sidebar__link profile-sidebar__link--active'
              : 'profile-sidebar__link'}
            to={pathNames.profile}
          >
            {translate('MyProfile')}
          </Link>
          <Link
            className={location.pathname === pathNames.orders
              ? 'profile-sidebar__link profile-sidebar__link--active'
              : 'profile-sidebar__link'}
            to={pathNames.orders}
          >
            {translate('MyOrders')}
          </Link>
          <Link
            className={location.pathname === pathNames.favouriteProducts
              ? 'profile-sidebar__link profile-sidebar__link--active'
              : 'profile-sidebar__link'}
            to={pathNames.favouriteProducts}
          >
            {translate('FavoriteProducts')}
          </Link>
        </div>
      </StyledProfileSidebar>
    )
    );
  }
}

const StyledMobilePagination = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
  
  .mobile-pagination {
    &__button {
      position: relative;
      background-color: var(--secondary-background-color);
      height: 40px;
      width: 60px;
      border: 1px solid var(--dashed-border-color);
      text-align: center;
      box-shadow: 0 0 5px rgba(0, 0, 0, .5);
      
      &--active {
        background-color: var(--link-color);
        border: none;
        width: auto;
        border-radius: 4px 4px 0 0;
        z-index: 5;
        .mobile-pagination__text {
          display: block;
        }
      }
      
      &:first-child {
        border-radius: 4px 0 0 0;
      }
      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
    
    &__content {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 .5rem;
    }
    
    &__text {
      display: none;
      font-size: .8rem;
      margin-left: .4rem;
    }
    
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }
`;

const StyledProfileSidebar = styled.div`
  position: sticky;
  bottom: 0;
  font-family: 'fs_elliot_pro';
  font-weight: 100;
  color: var(--text-color);
  top: 110px;
  background-color: var(--background-color);
  width: auto;
  
  .profile-sidebar {
    
    &__name {
      font-size: 1.25rem;
    }
    
    &__greeting {
      padding-top: .5rem;
      font-size: .9rem;
    }
    
    &__button {
      margin-top: 1.25rem;
    }
    
    &__navigation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.75rem;
    }
    
    &__link {
      position: relative;
      color: var(--text-color);
      text-transform: uppercase;
      text-decoration: none;
      padding: .5rem 0 .2rem;
      margin-bottom: .3rem;
      transition: color .25s;
      font-weight: 700;
      font-size: .75rem;
      
      &::after {
        content: "";
        display: block;
        height: 2px;
        background-color: var(--active-text-color);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        transition: width .25s;
      }
      
      &:hover,
      &--active {
        color: var(--active-text-color);
        
        &::after {
          width: 100%;
          transition: width .25s;
        }
      }
    }
  }
  
/*@media all and (min-width: 768px) {
  position: sticky;
  top: 110px;
  background-color: var(--background-color);
  width: auto;
  
  .profile-sidebar {
    &__user-wrap {
      display: block;
    }
  }
}*/
`;

export default ProfileSidebar;
