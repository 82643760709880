import * as types from '../actions/actionTypes';

const accessoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCESSORIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = [];

export default accessoriesReducer;
