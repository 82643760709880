import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { addTranslation } from 'react-localize-redux';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

import './normalize.css';
import './index.css';
import './assets/fonts/fonts.css';
import './assets/fonts/Glyphter.css';

import { LastLocationProvider } from 'react-router-last-location';
import App from './App';
import { PortalProvider, removeUniversalPortals } from './components/Portal';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import configureStore from './store/configureStore';

// const history = createBrowserHistory();

// new history for react-router
function preserveQueryParameters(history, preserve, location) {
  const currentQuery = queryString.parse(history.location.search);
  if (currentQuery) {
    const preservedQuery = {};
    for (const p of preserve) {
      const v = currentQuery[p];
      if (v) {
        preservedQuery[p] = v;
      }
    }
    if (location.search) {
      Object.assign(preservedQuery, queryString.parse(location.search));
    }
    location.search = queryString.stringify(preservedQuery);
  }
  return location;
}

function createLocationDescriptorObject(location, state) {
  return typeof location === 'string' ? { pathname: location, state } : location;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
  return (options) => {
    const history = createHistory(options);
    const oldPush = history.push;
    const oldReplace = history.replace;
    history.push = (path, state) => oldPush.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
    history.replace = (path, state) => oldReplace.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
    return history;
  };
}
// new history will persist query params provided in second argument of createPreserveQueryHistory
const history = createPreserveQueryHistory(createBrowserHistory, ['gclid'])();

// eslint-disable-next-line no-underscore-dangle
const initialState = window.__INITIAL_STATE__;

// eslint-disable-next-line no-underscore-dangle
delete window.__INITIAL_STATE__;

const store = configureStore(history, initialState);

const json = require('./translations.json');

// const tagManagerArgs = {
//   gtmId: 'GTM-PG3T8S7',
//   dataLayer: [],
// };
//
// TagManager.initialize(tagManagerArgs);

if (!initialState) {
  store.dispatch(addTranslation(json));
}

if (history.location.pathname.indexOf('robots') < 0) {
  // remove static markup and allow React
  // to render only actual components
  removeUniversalPortals();

  // using correct method for rendering app
  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

  renderMethod(
    <PortalProvider>
      <Provider store={store}>
        <Router history={history}>
          <LastLocationProvider>
            <App />
          </LastLocationProvider>
        </Router>
      </Provider>
    </PortalProvider>,

    document.getElementById('root'),
  );
}
