const getStatusById = ( id, statuses ) => {
    let matchStatusObj = {};
  // eslint-disable-next-line no-unused-expressions
    ( statuses && id ) ? (
        statuses.map( status => {
          // eslint-disable-next-line no-unused-expressions
            ( parseInt(id) === parseInt(status.id) )
            ?   ( Object.assign(
                    matchStatusObj,
                    status,
                ))
            : null
        })
    )
    : false;
    return matchStatusObj;
  }
  export default getStatusById;
