import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';

import keyByLang from '../../../modules/shared/keyByLang';

import { cartAddIcon, cartAddStatic } from '../../../assets/icons/lottieIcons';

class BoughtProductPopup extends React.Component {
  constructor(props) {
    super(props);

    this.newProduct = null;
    this.isStopBlockAnimation = false;
    this.isStopTextAnimation = false;

    this.state = {
      isAnimationEnded: false,
      isTextActive: false,
    };
  }

  animationEnded = () => {
    if (!this.isStopBlockAnimation) {
      this.setState({ isAnimationEnded: true });
    }
    this.isStopBlockAnimation = true;
  };

  textTransitionEnd = () => {
    if (!this.isStopTextAnimation) {
      this.setState({ isTextActive: true });
    }
    this.isStopTextAnimation = true;
  };

  render() {
    const { currentLanguage, product, translate } = this.props;
    const title = keyByLang('title', currentLanguage);

    return (
      <StyledBoughtPopup className="bought-popup">
        <div className="bought-popup__image">
          <Lottie
            options={product.isAnimate ? cartAddIcon : cartAddStatic}
            eventListeners={[{
              eventName: 'complete',
              callback: this.animationEnded,
            }]}
          />
        </div>
        <div
          className={this.state.isAnimationEnded || !product.isAnimate
            ? 'bought-popup__content bought-popup__content--active'
            : 'bought-popup__content'}
          onTransitionEnd={this.textTransitionEnd}
        >
          <p
            className={this.state.isTextActive || !product.isAnimate
              ? 'bought-popup__text bought-popup__text--active'
              : 'bought-popup__text'
            }
          >
            {product[title]} {translate('AddedToCartMessage')}
          </p>
        </div>
      </StyledBoughtPopup>
    );
  }
}

const StyledBoughtPopup = styled.div`
  --popup-background: #8E8E8E;
  --page-background: #0A0A0A;
  --width-animation-time: .5s;
  --text-color-time: .2s;
  
  display: flex;
  width: 22rem;
  
  .bought-popup {
  
    &__image {
      position: relative;
      z-index: 110;
      width: 100px;
      height: 60px;
    }
  
    &__content {
      margin: 1px 0 5px -2px;
      max-width: 11rem;
      font-weight: 100;
      color: #fff;
      background-color: var(--popup-background);
      cursor: pointer;
      border-radius: 0 6px 6px 0;
      position: relative;
      display: flex;
      align-items: center;
      width: 0;
      transition: width var(--width-animation-time);
      overflow: hidden;
      
      &--active {
        width: 100%;
        overflow: visible;
        
        &>.bought-popup__text {
          font-size: .8rem;
        }
      }
      
      &::before,
      &::after {
        content: '';
        display: block;
        background-color: var(--popup-background);
        position: absolute;
      }
      
      &::before {
        top: 0;
        left: -16px;
        border: 8px solid var(--page-background);
        border-top: 3px solid var(--popup-background);
        border-right: 8px solid var(--popup-background);
      }
      
      &::after {
        bottom: 0;
        left: -16px;
        border: 8px solid var(--page-background);
        border-right: 8px solid var(--popup-background);
        border-bottom: 3px solid var(--popup-background);
      }
    }
    
    &__text {
      color: transparent;
      padding: 3px 3px 3px 2px;
      font-size: 0;
      transition: font-size var(--width-animation-time), color var(--text-color-time);
      
      &--active {
        color: #fff;
      }
    }
  }
`;

export default BoughtProductPopup;
