function checkIfItBusiness(categoryId, categories) {
  const isBusinessTime = (() => {
    const hours = new Date().getHours();
    return ((hours >= 10) && (hours < 16));
  })();
  const isBusinessCategory = categories.find((cat) => cat.id === categoryId).alias === 'business';
  if (isBusinessCategory) {
    return isBusinessTime;
  }
  return true;
}

export default checkIfItBusiness;
