import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import minilogo from '../../assets/images/minilogo.svg';
import keyByLang from '../../modules/shared/keyByLang';

const StoresList = ({
  stores, currentLanguage, checkStoreStatus, languagePrefix, translate,
}) => {
  const address = keyByLang('address', currentLanguage);
  const storeItems = stores && stores.map((store) => {
    const isOpen = checkStoreStatus(store);
    return (
      <Link className="store" key={store.id} to={`${languagePrefix}/stores/${store.id}`}>
        <img className="mini-logo" src={minilogo} alt="Mini Logo" decoding="async" loading="lazy" />
        <section className="store__section">
          <p className="store__address">{store[address]}</p>
          <div className="store__text">
            <p
              className={isOpen ? 'store__status store__status--works' : 'store__status store__status--closed'}
            >
              {checkStoreStatus(store) ? translate('IsOpen') : translate('IsClose')}
            </p>
            <p
              className="store__time-works"
            >
              {translate('Working hours')}: {store.opened_since_hour}
              :{store.opened_since_minute}-{store.opened_till_hour}
              :{store.opened_till_minute}
            </p>
          </div>
        </section>
      </Link>
    );
  });

  return (
    <StyledList>
      {storeItems}
    </StyledList>
  );
};

const StyledList = styled.ul`
  --active-background: #1C1C1C;
  
  padding: .5rem 1rem;
  position: relative;

  .store {
    display: flex;
    padding: .5rem;
    border-bottom: 1px dashed #555;
    cursor: pointer;
    transition: background-color .2s;
    
    &:hover {
      background-color: var(--active-background);
    }

    .mini-logo {
        margin-right: 1rem;
    }

    &__section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
      
    &__address {
      font-weight: 400;
      padding-bottom: .5rem;
      text-decoration: none;
      color: #fff;
    }

    &__text {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
    }

    &__status {
      color: #00ff15;
      
      &--works {
        color: #fff;
      }
      
      &--closed {
        color: #616161;
      }
    }

    &__time-works {
      color: #777;
      font-size: .9rem;
    }
  }
`;

export default StoresList;
