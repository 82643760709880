import React from 'react';
import styled from 'styled-components';
import StarRating from '../../starRating/StarRating';

const Rating = ({
  input: {
    value, onChange, name,
  }, meta, label, readOnly = false, size,
}) => (
  <div>
    <RatingWrapper>
      <StarRating value={value} onChange={onChange} name={name} readOnly={readOnly} size={size} />
      {label ? <StyledRatingLabel>{label}</StyledRatingLabel> : null}
    </RatingWrapper>
    {meta?.touched && meta.error && <ErrorText>{meta.error}</ErrorText>}
  </div>
);

const RatingWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledRatingLabel = styled.span`
    margin-left: 10px;
    color: #555555;
`;

const ErrorText = styled.span`
    color: #f00;
    font-size: 12px;
`;

export default Rating;
