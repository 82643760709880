import React from 'react';
import styled from 'styled-components';

import Item from '../catalog/Item';
import ItemAddition from './ItemAddition';
import keyByLang from '../../modules/shared/keyByLang';

const ProductsGrid = ({
  products,
  gridType,
  isTabFree,
  onChangeHandleClickPaid,
  onChangeHandleClickFree,
  currentState,
  currentLanguage,
  tabid,
}) => {
  // allowed grid types = small, default, product-addition-tabs
  const gridTypeClassname = gridType || 'default';
  const isTabGridIsFree = isTabFree || false;

  const isCheckedFreeItemIds = currentState && currentState.chosenAddItemFree
    ? currentState.chosenAddItemFree : [];
  const isCheckedPaidItemIds = currentState && currentState.chosenAddItemPaid
    ? currentState.chosenAddItemPaid : [];

  if (products) {
    const productsList = products ? (
      products
        .filter((p) => +p.is_active === 1)
        .map((product) => {
          const id = product.id ? product.id : null;
          const idStr = id.toString();
          const image = product.image_filename ? product.image_filename : null;
          const badge = product.badge ? product.badge : null;
          const title = keyByLang('title', currentLanguage);
          const price = product.price ? product.price : null;
          const pieces = product.portion_quantity ? product.portion_quantity : null;

          let isCheckedFreeItem = false;

          isCheckedFreeItemIds.length && isCheckedFreeItemIds.map((item) => {
            if (item.clickedItemVal === idStr) {
              isCheckedFreeItem = true;
            }
          });

          let isCheckedPaidItem = false;
          (isCheckedPaidItemIds.length && isCheckedPaidItemIds.includes(idStr))
            ? (isCheckedPaidItem = true)
            : (isCheckedPaidItem = false);

          return (
            <ItemAddition
              product={product}
              key={id}
              id={id}
              title={product[title]}
              image={image}
              price={price}
              isTabFree={isTabGridIsFree}
              onChangeHandleClickFree={onChangeHandleClickFree}
              onChangeHandleClickPaid={onChangeHandleClickPaid}
              isCheckedFreeItem={isCheckedFreeItem}
              isCheckedPaidItem={isCheckedPaidItem}
              tabid={tabid}
            />
          );
        })) : null;

    return (
      <StyledUl className="additional-products">
        {productsList}
      </StyledUl>
    );
  }
  return false;
};

const StyledUl = styled.ul`
  padding: 40px 0;
  
  @media all and (min-width: 360px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media all and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media all and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default ProductsGrid;
