export const json = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const urlEncoded = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-Requested-With': 'XMLHttpRequest',
};

export const userUpdateHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

export const sendHeaders = {
  'Content-Type': 'multipart/form-data',
  'X-Requested-With': 'XMLHttpRequest',
};
