/* eslint-disable react/no-children-prop */
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage } from 'react-localize-redux';
import * as actions from '../../actions/actions';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import getProductById from '../../modules/shared/getProductById';

import keyByLang from '../../modules/shared/keyByLang';

class CategoriesNav extends React.PureComponent {
  redirectToMain = () => {
    this.props.history.push(`${this.props.languagePrefix}/`);
  }

  render() {
    const {
      filteredCategories,
      currentLanguage,
      languagePrefix,
      scrollTo,
      user,
      activeCatalogLink,
      isMobile,
    } = this.props;
    const categoriesWithFavourites = filteredCategories;

    const title = keyByLang('title', currentLanguage);

    if (user && user.id && user.favourites) {
      categoriesWithFavourites[0].products = user.favourites.reduce((res, favourite) => {
        const product = getProductById(favourite, filteredCategories);
        if (product.id) {
          res.push(product);
        }
        return res;
      }, []);
    }

    return (isMobile ? null : (
      <StyledCategoriesNav className="sidebar left">
        <ul className="navigation">
          {categoriesWithFavourites.map(category => (category.products && category.products[0] ? (
            <NavLink
              key={category.id}
              to={`${languagePrefix}/${category.alias}`}
              isActive={() => category.id === activeCatalogLink.activeCategory}
              onClick={() => scrollTo(category.id)}
              className="navigation__category"
              activeClassName="navigation__category--active"
            >
              {category[title]}
            </NavLink>
          ) : null))}
        </ul>
      </StyledCategoriesNav>
    ));
  }
}

const mapStateToProps = state => ({
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  activeCatalogLink: state.activeCatalogLink ? state.activeCatalogLink : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  user: state.user ? state.user : null,
  isMobile: state.isMobile ? state.isMobile : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const StyledCategoriesNav = styled.div`
  --background-color: #0A0A0A;
  --text-color: #8E8E8E;
  --active-color: #fff;

  position: sticky;
  top: 11.5rem;
  background-color: var(--background-color);
  font-family: 'fs_elliot_pro';
  font-weight: 700;
  width: auto;
  
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 1rem;
    margin-left: ${props => (props.isMobile ? '2.5rem' : '0')};
  
    &__category {
      position: relative;
      color: var(--text-color);
      text-transform: uppercase;
      text-decoration: none;
      margin: 5px 10px;
      padding-bottom: 5px;
      transition: color .25s;
      font-size: 11px;
      
      &::after {
        content: "";
        display: block;
        height: 2px;
        background-color: var(--active-color);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        transition: width .25s;
      }
      
      &:hover,
      &--active {
        color: var(--active-color);
        
        &::after {
          width: 100%;
          transition: width .25s;
          
        }
      }
    }
  }
  
  @media all and (min-width: 768px) {
    .navigation {
      display: flex;
      align-items: flex-start;
    }
  }
  
  @media all and (min-width: 1024px) {
    .navigation {
      display: flex;
      align-items: flex-start;
    }
  }
  
  @media (hover: none) {
    .navigation__category:hover {
      color: var(--text-color);
      
      &::after {
        width: 0;
      }
    }
    .navigation__category.navigation__category--active {
      color: var(--active-color);

      &::after {
        width: 100%;
        transition: width .25s;
      }
    }
  }
}
`;

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesNav);
