import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import keyByLang from '../../modules/shared/keyByLang';

const CartAccessories = ({
  collectedAccessories,
  currentLanguage,
  isAccessoriesChanging,
  languagePrefix,
  order,
}) => {
  const title = keyByLang('title', currentLanguage);

  let accessories = collectedAccessories ? collectedAccessories.map(acc => (
    <li key={`accessories${acc.id}`} className="accessories__item">{acc.cartQuantity} шт. {acc[title]}</li>
  )) : null;

  if (order && order.accessories && collectedAccessories
    && (!order || !order.shouldAccessoriesUpdate)) {
    accessories = collectedAccessories.map(acc => (
      <li key={`accessories${acc.id}`} className="accessories__item">{order.accessories[acc.id]} шт. {acc[title]}</li>
    ));
  }

  return (
    <StyledAccessories className="accessories">
      <ul className="accessories__accessories-list">
        {accessories}
      </ul>
    </StyledAccessories>
  );
};

const StyledAccessories = styled.div`
  --text-color: #8E8E8E;
  --links-color: #E74E1A;
  --active-text-color: #FFFFFF;
  
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 1rem 5rem;
  align-items: flex-start;
  
  .accessories {
    
    &__title {
      text-transform: none;
      color: var(--text-color);
      font-weight: 200;
      font-size: 1.4rem;
    }
    
    &__accessories-list {
      color: var(--active-text-color);
      order: 1;
      margin-top: 1rem;
    }
    
    &__item {
      font-size: 1rem;
      padding: 0 1rem 1rem 0;
    }
    
    &__link {
      text-decoration: none;
      font-weight: 100;
      color: var(--links-color);
      height: auto;
      align-self: flex-end;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
    
@media all and (min-width: 768px) {

  .accessories {
    &__accessories-list {
      order: 0;
    }
    
    &__link {
      align-self: flex-start;
    }
    
    &__item {
      padding: 0;
      &:not(:first-child) {
        padding-top: .7rem;
      }
    }
  }
}
`;

export default React.memo(CartAccessories);
