/* eslint-disable no-param-reassign,no-return-assign */
import React from 'react';
import styled from 'styled-components';
import CheckoutPaymentForm from './CheckoutPaymentForm';

import { filterDone } from '../../../assets/icons/svgIcons';

class CheckoutPaymentComponent extends React.Component {
  state = {
    isCommentVisible: false,
    isPromocodeVisible: !!this.props.promocodeValue,
    isBonusVisible: false,
    isCashPaymentMethod: true,
  };

  makeFieldVisible = (type) => {
    this.setState(prevState => ({
      ...prevState[type] = true,
    }));
  };

  setChangeVisibility = (id) => {
    this.setState({ isCashPaymentMethod: +id === 1 });
  };

  render() {
    const {
      isDeliveryChosen,
      checkoutHandleSubmit,
      paymentMethods,
      currentLanguage,
      user,
      showSnackBar,
      form,
      buttonPrice,
      languagePrefix,
      translate,
      sendingData,
      changePromoStatus,
      emptyPreorder,
    } = this.props;
    const {
      isCommentVisible, isPromocodeVisible, isBonusVisible, isCashPaymentMethod,
    } = this.state;

    return (
      <StyledPayment className="user-name">
        <div className={isDeliveryChosen && user && user.id
          ? 'checkout__step-heading-wrap checkout__step-heading-wrap--active'
          : 'checkout__step-heading-wrap'}
        >
          {filterDone}
          <h2 className="checkout__step-heading">{translate('InformationPayment')}</h2>
        </div>
        {isDeliveryChosen && user && user.id ? (
          <CheckoutPaymentForm
            user={user}
            onSubmit={checkoutHandleSubmit}
            paymentMethods={paymentMethods}
            currentLanguage={currentLanguage}
            makeFieldVisible={this.makeFieldVisible}
            isCommentVisible={isCommentVisible}
            isPromocodeVisible={isPromocodeVisible}
            isBonusVisible={isBonusVisible}
            isCashPaymentMethod={isCashPaymentMethod}
            showSnackBar={showSnackBar}
            setChangeVisibility={this.setChangeVisibility}
            buttonPrice={buttonPrice}
            languagePrefix={languagePrefix}
            translate={translate}
            sendingData={sendingData}
            changePromoStatus={changePromoStatus}
            emptyPreorder={emptyPreorder}
          />
        ) : null}
      </StyledPayment>
    );
  }
}

export default CheckoutPaymentComponent;

const StyledPayment = styled.div`
  form {
    margin-top: 1.3rem;
  }
`;
