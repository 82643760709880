const getProductById = (id, categoriesObj) => {
  const matchProductObj = {};
  // eslint-disable-next-line no-unused-expressions
  (categoriesObj && id) ? (
    categoriesObj.map(catItem => catItem.products.map(catItemKey => ((parseInt(id) === parseInt(catItemKey.id))
      ? (Object.assign(
        matchProductObj,
        catItemKey,
        {
          parentCatId: catItem.id,
          parent_title_ru: catItem.title_ru,
          parent_title_ua: catItem.title_ua,
        },
      )
      )
      : null)))
  )
    : false;
  return matchProductObj;
};
export default getProductById;
