import * as types from "../actions/actionTypes";

const storesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_STORES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = [];

export default storesReducer;
