
const isProductInUpSaleCat = (product_id, categoriesObj) => {
	let result = false;
	// eslint-disable-next-line no-unused-expressions
	product_id && categoriesObj
	?	categoriesObj.map( cat => {
			return cat.products.map( product => {
				return parseInt(product.id) === parseInt(product_id)
				?	!parseInt(cat.is_crossale)
					?	result = true
					:	false
				:	false
			})
		})
	:	false;
	return result;
}

export default isProductInUpSaleCat;
