import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const ProductTagsApi = {
  getTags() {
    return axiosInstance.post('product-tags',
      {},
      { headers: headers.json });
  },
};

export default ProductTagsApi;
