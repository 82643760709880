import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/actions';

import CartItemSidebar from './CartItemSidebar';
import AnimatedButton from '../shared/buttons/AnimatedButton';
import CartPromocode from './promocode/CartPromocode';

import { cart } from '../../assets/icons/svgIcons';
import {getActiveLanguage} from "react-localize-redux";
import getlanguagePrefix from "../../modules/shared/getLanguagePrefix";

class CartSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cartItemsCount: CartSidebar.setInitialItemsCount(),
    };

    this.windowWidth = window.innerWidth;

    this.onResize = this.onResize.bind(this);
    this.onChangePromocode = this.onChangePromocode.bind(this);
  }

  static setInitialItemsCount() {
    const windowWidth = window.innerWidth;
    const isLandscape = windowWidth > window.innerHeight;
    if (windowWidth <= 1366 && isLandscape) {
      return 5;
    }
    if (windowWidth <= 1440 && isLandscape) {
      return 7;
    }
    return 9;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const cartItems = CartSidebar.setInitialItemsCount();
    if (this.state.cartItemsCount !== cartItems) {
      this.setState({
        cartItemsCount: cartItems,
      });
    }
  }

  onChangePromocode(value) {
    this.props.actions.setPromocode(value);
  }

  render() {
    const {
      cartData,
      productsCount,
      currentLanguage,
      qtyHandler,
      removeCartItemHandler,
      languagePrefix,
      cartSubmit,
      translate,
      openAccessoriesModal,
      promocodeValue,
    } = this.props;

    const { cartItemsCount } = this.state;

    return (
      <Fragment>
        <ul className="sidebar-cart-list">
          {cartData.content.slice(0, cartItemsCount).map(cartProd => (
            <CartItemSidebar
              key={cartProd.id}
              cartProduct={cartProd}
              product={cartProd.product}
              qtyHandler={qtyHandler}
              removeCartItemHandler={removeCartItemHandler}
              currentLanguage={currentLanguage}
              addonsList={cartProd.addons}
            />
          ))}
        </ul>
        <StyledPrice className="total">
          <div className="total__products">
            <p className="total__text">{translate('CartQuantityProducts')} {productsCount} {translate('CartQuantityProducts2')}</p>
            <Link
              className="total__link"
              to={{
                pathname: `${languagePrefix}/cart`,
                state: { modal: true },
              }}
            >
              {translate('ShowAll')}
            </Link>
          </div>
          <p className="total__link total__link--accessories" onClick={openAccessoriesModal}>{translate('ItemsIncluded')}</p>
          <CartPromocode
            translate={translate}
            onChange={this.onChangePromocode}
            value={promocodeValue}
          />
          <div className="total__price-wrap">
            <p>{translate('TotalAmount')}</p>
            <p>{cartData.total} грн</p>
          </div>
          <AnimatedButton
            minWidth="100%"
            text={translate('CheckoutButton')}
            icon={cart}
            handleClick={cartSubmit}
            addClass="paint-cart"
          />
        </StyledPrice>
      </Fragment>
    )
  }
}

const StyledPrice = styled.div`
  --active-color: #fff;
  --links-color: #E74E1A;
  --blocks-padding: 1.6rem;

  font-weight: 700;
  padding: 0 .6rem;
  
  .total {
    
    &__products {
      display: flex;
      justify-content: space-between;
      padding: 1.4rem 0 1rem;
      font-weight: 100;
    }
    
    &__link {
      text-decoration: none;
      font-weight: 100;
      color: var(--links-color);
      font-size: 0.75rem;
      
      &--accessories {
        margin: .4rem 0 1rem 0;
        cursor: pointer;
      }
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    &__text {
      font-size: 0.75rem;
    }
    
    &__price-wrap {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      font-size: 1.2em;
      color: var(--active-color);
      padding-bottom: 1rem;
    }
  }
  
  .paint-cart {
    & g {
      fill: #FFFFFF;
    }
  }
  
  @media all and (min-width: 1281px) {
    .total {
      &__text {
      
      }
      &__link {
      
      }
    }
  }
`;

const mapStateToProps = state => ({
  promocodeValue: state.promocodeValue,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CartSidebar));
