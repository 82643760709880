import * as types from '../actions/actionTypes';

const homeBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HOMEBANNER_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = [];

export default homeBannerReducer;
