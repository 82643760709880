import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import LocationSearchInputLoader from '../shared/LocationSearchInputLoader';

import * as storage from '../../utils/storage';
import MainBtn from '../shared/MainBtn';
import Input from '../shared/form/Input';

import mapMarker from '../../assets/icons/form/mapMarker.svg';

let GPlacesDeliveryForm = props => (
  <StyledSearch className="gplaces-form" onSubmit={props.handleSubmit}>
    <Field
      component={LocationSearchInputLoader}
      deliveryAddress={props.deliveryAddress}
      type="text"
      name={props.isCheckout ? 'locationNameInput' : 'locationNameInput2'}
      placeholder={props.placeholder}
      updateAddress={props.updateAddress}
      isCheckout={props.isCheckout}
      isProfile={props.isProfile}
    />
    {!props.isCheckout ? (
      <MainBtn
        text={props.translate('Find')}
        minWidth="100px"
        addClass="search-field__button"
        type="submit"
      />
    ) : null}
    {props.isCheckout ? (
      <Field
        name={props.isCheckout ? 'additionalDeliveryInfo' : 'additionalDeliveryInfo2'}
        component={Input}
        icon={mapMarker}
        type="text"
        label={props.isMobile
          ? props.translate('AdditionalInfo')
          : props.translate('EntranceFloorApartmentPlaceholder')
        }
        iconName="marker"
        updateAddress={props.updateAddress}
      />
    ) : null}
    {/*props.isCheckout && props.deliveryAddress ? (
      <Field />) : null*/}
  </StyledSearch>
);

const StyledSearch = styled.form`
  width: 100%;
  position: relative;
  
  & div:first-child {
    margin-bottom: 1rem;
  }
  
  & div:last-child {
    margin-bottom: 0;
  }
  
  .search-field {
    
    &__button {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      
      button {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`;

GPlacesDeliveryForm = reduxForm({
  // a unique name for the form
  form: 'GPlacesDeliveryForm',
  // destroyOnUnmount: false,
})(GPlacesDeliveryForm);

GPlacesDeliveryForm = connect(
  state => {
    const savedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    const addressToEdit = JSON.parse(storage.session.getItem('addressToEdit'));
    if (!savedAddress) {
      return {};
    }
    return {
      initialValues: {
        locationNameInput2: addressToEdit ? addressToEdit.address : '',
        locationNameInput: savedAddress.address ? savedAddress.address : '',
        additionalDeliveryInfo: savedAddress.additionalInfo ? savedAddress.additionalInfo : '',
      },
      isMobile: state.isMobile,
    };
  },
  {},
)(GPlacesDeliveryForm);

export default GPlacesDeliveryForm;
