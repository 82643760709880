import * as types from '../actions/actionTypes';

const deliveryPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_DELIVERY_POSITION:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {deliveryPositon: false};

export default  deliveryPositionReducer;