import * as types from "../actions/actionTypes";

const paymentMethodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PAYMENT_METHODS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const initialState = [];

export default paymentMethodsReducer;
