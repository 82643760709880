import { ADDED_PRODUCT } from '../actions/actionTypes';

const addedStateReducer = (state = false, action) => {
  switch (action.type) {
    case ADDED_PRODUCT:
      return action.payload;
    default:
      return state;
  }
};

export default addedStateReducer;
