import React from 'react';
import { Translate } from 'react-localize-redux';
import phoneValidation from '../../../utils/validation/phoneValidation';
import nameValidation from '../../../utils/validation/nameValidation';
import emailValidation from '../../../utils/validation/emailValidation';
import cvValidation from '../../../utils/validation/cvValidation';

const validateJobForm = (values, data) => {
  const phoneErrors = phoneValidation(values);
  const nameErrors = nameValidation(values);
  const emailErrors = emailValidation(values);
  const cvErrors = cvValidation(values);
  const errors = Object.assign({}, phoneErrors, nameErrors, emailErrors, cvErrors);
  if (!values.profession || !values.profession.length) {
    errors.profession = <Translate id="ChooseVacancy" />;
  }
  return errors;
};

export default validateJobForm;
