import React from 'react';
import styled from 'styled-components';

import ProfileBonusesBlock from './ProfileBonusesBlock';
import ProfileUserBlock from './ProfileUserBlock';
import ProfileAddressesBlock from './ProfileAddressesBlock';
import ProfileFavouriteStoreBlock from './ProfileFavouriteStoreBlock';
import ProfileNotificationsBlock from './ProfileNotificationsBlock';
import ProfileSocialsBlock from './ProfileSocialsBlock';

import profileIcon from '../../assets/icons/profile/personal-discription.svg';
import MainBtn from '../shared/MainBtn';

const ProfileMainPage = (props) => {
  const {
    user, currentLanguage, isMobile, logOut, translate,
  } = props;

  return (
    <StyledProfileMainPage className="profile">
      <div className="profile__heading-wrap">
        <img src={profileIcon} className="profile__heading-icon" alt="user" decoding="async" loading="lazy" />
        <h2 className="profile__heading">{translate('MyProfile')}</h2>
      </div>
      <hr className="solid is-visible" />
      <div className="profile-blocks">
        {isMobile ? (
          <div className="user-profile">
            <div className="user-profile__text">
              <p className="user-profile__name">{user ? user.name : 'Гость'}</p>
              <p className="user-profile__greeting">{translate('NiceToSeeYouAgain')}!</p>
            </div>
            <MainBtn
              align="left"
              text={translate('LogOut')}
              clickHandle={logOut}
              addClass="user-profile__button"
              minWidth="80px"
            />
          </div>
        ) : null}
        <hr className="dashed is-mobile" />
        <ProfileBonusesBlock currentLanguage={currentLanguage} isMobile={isMobile} />
        <ProfileUserBlock user={user} />
        <ProfileAddressesBlock user={user} isMobile={isMobile} />
        <ProfileFavouriteStoreBlock user={user} currentLanguage={currentLanguage} />
        {/* <ProfileNotificationsBlock user={user} currentLanguage={currentLanguage} /> */}
        {user ? (<ProfileSocialsBlock user={user} currentLanguage={currentLanguage} translate={translate} />) : null}
      </div>
    </StyledProfileMainPage>
  );
};

const StyledProfileMainPage = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --link-color: #E84F1C;
  --secondary-background-color: #1C1C1C;
  --dashed-border-color: #313131;
  --page-background-color: #0A0A0A;
  
  .is-visible {
    display: none;
  }
  
  .is-mobile {
    margin-top: 1.25rem;
    display: block;
  }
  
  .user-profile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    &__text {
      color: var(--text-color);
    }
    
    &__name {
      font-size: 1.25rem;
    }
    
    &__greeting {
      font-size: .9rem;
      padding-top: .3rem;
    }
    
    &__button {
      width: auto;
    }
  }
  
  @media all and (min-width: 769px) {
  
  .is-visible {
    display: block;
  }
  
  .is-mobile {
    display: none;
  }
}
`;

export default ProfileMainPage;
