import * as types from "../actions/actionTypes";

const allergensReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALLERGENS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = [];

export default allergensReducer;
