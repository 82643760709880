import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const CitiesApi = {
  getCities() {
    return axiosInstance.post('cities',
      {},
      { headers: headers.json });
  },
};

export default CitiesApi;
