import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../../modules/shared/keyByLang';

const OrderAmount = ({ lastOrder, paymentMethod, currentLanguage, translate }) => {
  const title = keyByLang('title', currentLanguage);

  return (
    <StyledOrderAmount className="order-amount">
      <div className="order-amount__line">
        <p className="order-amount__heading">{translate('TotalAmount')}</p>
        <p className="order-amount__price">{lastOrder.to_pay} грн</p>
      </div>
      <div className="order-amount__line">
        <p className="order-amount__field-name">{translate('BonuseAccruedForOrder')}:</p>
        <p className="order-amount__field-data">{lastOrder.bonuses_earned} грн</p>
      </div>
      <div className="order-amount__line">
        <p className="order-amount__field-name">{translate('PaymentMethod')}: </p>
        <p className="order-amount__field-data">{paymentMethod ? paymentMethod[title] : null}</p>
      </div>
    </StyledOrderAmount>
  );
};

const StyledOrderAmount = styled.ul`
  padding-top: 1.2rem;
  .order-amount {
    &__line {
      display: flex;
      justify-content: space-between;
      font-size: .9rem;
    }
    
    &__heading {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
      padding-bottom: .8rem;
    }
    
    &__price {
      font-size: 1.25rem;
      color: var(--active-text-color);
    }
    
    &__field-name {
      padding-bottom: .7rem;
    }
    
    &__field-data {
      font-weight: 700;
      text-align: right;
    }
  }
  
  @media all and (min-width: 768px) {
    .order-amount {
      &__line {
         font-size: 1rem;
      }
    }
  }
`;

export default OrderAmount;
