import React from 'react';
import styled from 'styled-components';

class SimpleInput extends React.Component {

  render() {
    const {
      value, label, icon, type, onChange, inputHeight, fontSize,
    } = this.props;

    return (
      <StyledInput icon={icon} inputHeight={inputHeight} fontSize={fontSize}>
        <input
          id={label}
          type={type}
          value={value}
          className={value && value.length ? 'not-empty' : 'empty'}
          onChange={onChange}
        />
        {icon ? (
          <div className="input-icon">
            {icon}
          </div>
        ) : null}
        <label htmlFor={label}>{label}</label>
        {icon ? (
          <div className="input-line" />
        ) : null}
      </StyledInput>
    );
  }
}

const StyledInput = styled.div`
    position: relative;
    height: ${props => props.inputHeight ? props.inputHeight : '60px'};
    box-sizing: border-box;
    width: 100%;
    background-color: #1C1C1C;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid ${props => (props.error ? 'red' : '#313131')};

    label {
        position: absolute;
        top: 50%;
        left: 77px;
        left: ${props => props.icon ? '77px' : '12px'};
        font-size: ${props => props.fontSize ? props.fontSize : '15px'};
        color: #616161;
        transform: translateY(-50%);
        transition: all 0.15s ease-in-out;
        transform-origin: left center;
        pointer-events: none;
        font-weight: 100;
    }
    input {
        height: 100%;
        background: inherit;
        border: none;
        width: 100%;
        padding-left: ${props => props.icon ? '77px' : '12px'};
        color: #8e8e8e;
        font-size: ${props => props.fontSize ? props.fontSize : '15px'};
        padding-top: 17px;
        padding-right: 15px;
        outline-color: ${props => (props.error ? 'red' : '')};

        &:focus ~ label,
        &.not-empty ~ label {
            transform: scale(0.85) translateY(-150%);
            color: ${props => (props.error ? 'red' : '#616161')};
        }
    }

    .input-line {
        position: absolute;
        top: 50%;
        left: 60px;
        height: 75%;
        width: 2px;
        transform: translateY(-50%);
        background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
        background-size: 100% 8px;
    }

    .input-icon {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%,-50%);
        opacity: .5;
    }

    &.default {
        input {
            padding-left: 15px;
        }
        label {
            left: 15px;
        }
    }
    
    .icon {
      display: inline-block;
      position: relative;
    }
    
    .empty {
      
    }
`;

export default SimpleInput;
