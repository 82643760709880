import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import * as actions from '../actions/actions';

// import PageHeaderBlock from '../components/shared/PageHeaderBlock';

import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

// import headerImage from '../assets/images/shop_header_image.png';
import Store from '../components/stores/Store';
import fixImagePath from '../modules/shared/fixImagePath';

class StoreContainer extends React.PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getStores(),
      this.props.actions.getUser(),
    ]);
  }

  render() {
    const {
      stores, currentLanguage, languagePrefix, settings, translate,
    } = this.props;

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find(page => page.page_const === 'store');
      bannerImagePath = fixImagePath(pageData);
    }

    const idFromRoute = parseInt(this.props.match.params.number, 10);
    const currentStoreArr = stores && stores[0] ? stores
      .filter(store => parseInt(store.id, 10) === parseInt(idFromRoute, 10)) : null;

    return (
      <div>
        {/* <PageHeaderBlock image={headerImage} addClass="stores stores-list" /> */}
        {currentStoreArr && currentStoreArr.length ? (
          <Store
            stores={currentStoreArr}
            currentLanguage={currentLanguage}
            languagePrefix={languagePrefix}
            bannerImage={bannerImagePath}
            translate={translate}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  stores: state.stores ? state.stores : null,
  settings: state.settings ? state.settings : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);
