import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';
import mobileAppPromo from '../assets/images/mobile-promo-app.png';
import ModalWrapper from './shared/ModalWrapper';
import { citiesData } from '../utils/constants';


const CitySelector = ({ modalClose, translate, currentCity }) => {
  const List = styled.ul`
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .city-list {
      &__item {
        padding-bottom: 12px;
      }
      
      &__link {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        
        &:hover {
          color: #E74E1A;
        }
        
        &--selected {
          display: block;
          position: relative;
          
          &::after {
            width: 100%;
            content: '';
            display: block;
            position: absolute;
            bottom: -3px;
            height: 2px;
            background-color: #E74E1A;
          } 
        }
      }
    }

    @media (min-width: 1024px) {
      .city-list {
        &__item {
          padding-bottom: 20px;
        }

        &__link {
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
      }
    }
  `;

  const Content = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
    
    .city-choose {
      &__title {
        margin-bottom: 20px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: #8E8E8E;
      }
      
      &__top {
        height: 60px;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      &__content {
        padding-top: 40px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      &__logo {
        display: block;
        height: 100%;
        max-height: 60px;
      }
      
      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        opacity: .1;
      }
    }
    
    @media (min-width: 1024px) {
      .city-choose {
        &__top {
          height: 100px;
        }
        &__content {
          padding-top: 150px;
        }
      }        
    }
  `;

  const AppLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 10px;
    
    & > .app-link-image {
      object-fit: contain;
      width: 100%;
      max-width: 576px;
    }
    
    @media (min-width: 768px) {
      display: none;
    }
  `;

  return (
    <ModalWrapper handleModalClose={modalClose} modalId="CitySelector" isFixed>
      <Content>
        <div className="city-choose__top">
          <img src={logo} decoding="async" loading="lazy" alt="Logo" className="city-choose__logo" />
        </div>
        <hr className="solid" />
        <div className="city-choose__content">
          <h4 className="city-choose__title">{translate('ChooseYourCity')}</h4>
          <List>
            {Object
              .entries(citiesData)
              .map(([key, value]) => (
                <li key={key} className="city-list__item">
                  <a
                    href={value.href}
                    rel="noreferrer"
                    onMouseDown={(e) => {
                      if (currentCity === key) {
                        modalClose(e);
                      }
                    }}
                    className={`city-list__link ${currentCity === key && 'city-list__link--selected'}`}
                  >
                    {translate(value.translationName)}
                  </a>
                </li>
              ))}
          </List>
        </div>
        <AppLink href="https://budusushi.page.link/oneu" target="_blank">
          <img
            className="app-link-image"
            src={mobileAppPromo}
            decoding="async"
            loading="lazy"
            alt="Mobile app promo"
          />
        </AppLink>
      </Content>
    </ModalWrapper>
  );
};

export default CitySelector;
