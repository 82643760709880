/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import keyByLang from '../../modules/shared/keyByLang';
import PageHeaderBlock from '../shared/PageHeaderBlock';
import headerImage from '../../assets/images/faq_header.jpg';
import FranchiseForm from './FranchiseFrom';

const TextPage = ({ page, currentLanguage }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const translatedTitle = keyByLang('title', currentLanguage);
  const translatedText = keyByLang('text', currentLanguage);
  let imagePath = page.image_filename_header;
  const isFranchise = page.alias === 'franchise';

  if (imagePath.indexOf('(') || imagePath.indexOf(')')) {
    const imageArr = imagePath.split('');
    for (let i = imageArr.length - 1; i >= 0; i--) {
      if (imageArr[i] === '(' || imagePath[i] === ')') {
        imageArr.splice(i, 0, '\\');
      }
    }
    imagePath = imageArr.join('');
  }

  return (
    <React.Fragment>
      <PageHeaderBlock image={imagePath || headerImage} addClass="" />
      <StyledTextPage>
        <h1 className="text-page__heading">{page[translatedTitle]}</h1>
        <div
          className="text-page__text-wrap"
          dangerouslySetInnerHTML={{ __html: page[translatedText] }}
        />
        {isFranchise ? (
          <FranchiseForm />
        ) : null}
      </StyledTextPage>
    </React.Fragment>
  );
};

const StyledTextPage = styled.div`
  --text-color: #8E8E8E;
  
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  padding: 40px 15px;
  font-family: 'fs_elliot_pro';
  
  .text-page__heading {
     font-size: 1.4rem;
     color: #fff;
     position: relative;
     padding-bottom: 20px;
     font-weight: 100;
     color: var(--text-color);
     
     &::after {
        content: ""; 
        width: 100%;
        height: 1px;
        display: block;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 50%, rgba(0,0,0,0) 100%);
        position: absolute;
        bottom: 0;
    }
  }
  
  .text-page__text-wrap {
    padding: 20px 0;
  }
  
  img {
    max-width: 100%;
    object-fit: contain;
    height: auto !important;
  }
`;

export default TextPage;
