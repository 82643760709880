/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import Sidebar from 'react-sidebar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import SidebarComponent from '../components/SidebarComponent';
import * as actions from '../actions/actions';

class RightSidebarMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };

    this.activateBtn = null;
  }

  componentDidMount() {
    this.activateBtn = document.querySelector('.nav__toggle-btn');
    this.activateBtn.addEventListener('click', this.openMenu);
  }

  componentWillUnmount() {
    this.activateBtn.removeEventListener('click', this.openMenu);
  }

  openMenu = () => {
    this.setState({ sidebarOpen: true });
  };

  closeMenu = () => {
    this.setState({ sidebarOpen: false });
  };

  toggleOpenedMenu = () => {
    if (this.state.sidebarOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  redirectToMain = () => {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/`);
  };

  render() {
    const {
      user, languagePrefix, translate, currentCity,
    } = this.props;
    const { sidebarOpen } = this.state;
    const sidebarStyles = {
      root: {
        position: 'absolute',
        left: '100%',
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      },
      sidebar: {
        position: 'fixed',
        zIndex: 25,
        top: this.props.isBarShown ? '60px' : '0',
      },
      overlay: {
        zIndex: 14,
        visibility: 'hidden',
        className: 'sidebar-overlay',
      },
    };

    return (
      <Sidebar
        sidebar={(
          <SidebarComponent
            closeMenu={this.closeMenu}
            user={user}
            languagePrefix={languagePrefix}
            logOut={this.props.actions.logOutUser}
            redirectToMain={this.redirectToMain}
            translate={translate}
            currentCity={currentCity}
          />
        )}
        open={sidebarOpen}
        onSetOpen={this.toggleOpenedMenu}
        styles={sidebarStyles}
        sidebarClassName="sidebar"
        pullRight
        children="null"
        shadow
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user ? state.user : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
  currentCity: state.settings?.google_places_city?.toLowerCase(),
  isBarShown: state.appBar,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RightSidebarMenuContainer));
