import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import keyByLang from '../../modules/shared/keyByLang';

import * as actions from '../../actions/actions';

import { rightOrangeArrow } from '../../assets/icons/svgIcons';

class MainSeoText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAllTextVisible: false,
    };

    this.toggleTextSize = this.toggleTextSize.bind(this);
  }

  toggleTextSize() {
    this.setState((prevState) => ({
      isAllTextVisible: !prevState.isAllTextVisible,
    }));
  }

  render() {
    const {
      seoHomePage, currentLanguage, translate, categorySeoText,
    } = this.props;
    const { isAllTextVisible } = this.state;

    let seoHomeText = null;
    let seoHomeTextShort = null;

    if (seoHomePage) {
      seoHomeText = seoHomePage[keyByLang('seo_text', currentLanguage)];
    }

    if (seoHomeText && seoHomeText.length > 807) {
      seoHomeTextShort = `${seoHomeText.slice(0, 808)}...`;
    }


    return (
      <StyledSeoText className="main-seo">
        <div
          className={`main-seo__text ${isAllTextVisible && 'main-seo__text--shown'}`}
          dangerouslySetInnerHTML={{ __html: categorySeoText }}
        />
        {seoHomeTextShort ? (
          <div
            className={isAllTextVisible ? 'main-seo__button main-seo__button--back' : 'main-seo__button'}
            onClick={this.toggleTextSize}
          >
            {isAllTextVisible ? rightOrangeArrow : null}
            <p className="main-seo__button-text">{isAllTextVisible ? translate('Hide') : translate('ReadMore')}</p>
            {isAllTextVisible ? null : rightOrangeArrow}
          </div>
        ) : null}
      </StyledSeoText>
    );
  }
}

const StyledSeoText = styled.div`
  --links-color: #E74E1A;

  padding-bottom: 90px;
  display: flex;
  flex-direction: column;

  .main-seo {
    &__text {
      font-size: .9rem;
      overflow: hidden;
      max-height: 60px;
       
      @media (min-width: 768px) {
        max-height: 100px;
      }

      &--shown {
        max-height: unset;
      }
    }
    
    &__button {
      display: flex;
      color: var(--links-color);
      padding: .5rem 0;
      align-items: center;
      align-self: flex-end;
      cursor: pointer;
      
      &--back {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    
    &__button-text {
      padding: 0 .5rem;
      font-size: .9rem;
    }
  }
`;

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  seoHomePage: state.seoHomePage && state.seoHomePage[0] ? state.seoHomePage[0] : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSeoText);
