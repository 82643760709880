import { axiosInstance } from './axiosInstance';
import * as headers from './headers';
import _serialize from './helpers/serialize';

const FranchiseApi = {
  sendFranchise(data) {
    return axiosInstance.post('franshiza-request',
      data,
      {
        headers: headers.json,
      });
  },
};

export default FranchiseApi;
