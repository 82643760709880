import React from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';

const CartEmpty = ({ emptyCartImage, logo, isModal }) => (
  <StyledCartEmpty className="empty-cart">
    {isModal ? (
      <div className="empty-cart__logo-wrap">
        <img
          className="empty-cart__logo-image"
          src={logo}
          alt="alt text"
          decoding="async"
          loading="lazy"
        />
      </div>
    ) : null}
    <figure className="empty-cart__figure">
      <img
        className="empty-cart__image"
        src={emptyCartImage}
        alt="empty-cart"
        decoding="async"
        loading="lazy"
      />
      <figcaption className="empty-cart__figcaption"><Translate id="YourCartIsEmptyTest" /></figcaption>
    </figure>
  </StyledCartEmpty>
);

const StyledCartEmpty = styled.div`
  text-align: center;
  
  .empty-cart {
  
    &__logo-wrap {
      padding-top: 60px;
      width: 100%;
      text-align: center;
    }
    
    &__figure {
      padding-top: 8rem;
    }
    
    &__image {
      padding-bottom: 10px;
    }
    
    &__figcaption {
      font-size: .9em;
      text-transform: uppercase;
    }
  }
`;

export default React.memo(CartEmpty);
