import emailIcon from './icons/form/email.svg';
import inputIcon from './icons/form/input.svg';
import nameIcon from './icons/form/name.svg';
import phoneIcon from './icons/form/phone.svg';
import pickupIcon from './icons/form/pickup.svg';
import problemIcon from './icons/form/problem.svg';

const faqFormIcons = {
  email: emailIcon,
  input: inputIcon,
  name: nameIcon,
  phone: phoneIcon,
  pickup: pickupIcon,
  problem: problemIcon,
};

export default faqFormIcons;
