/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';

class InputDatepicker2 extends React.Component {
  constructor(props) {
    super(props);
    this.prevValue = this.props.value;
  }

  componentDidUpdate(prevProps) {
    this.prevValue = prevProps.value;
    return true;
  }

  modifyValue = (value) => {
    if (!value) {
      return '';
    }
    if (value.length > 10) {
      return value.slice(value.length - 10);
    }
    return value;
  };

  render() {
    const {
      label, input, meta, icon, ...restProps
    } = this.props;
    const { error, touched } = meta;
    const { value } = input;
    const valueToShow = this.modifyValue(value);

    return (icon ? (
      <StyledInput error={touched && error}>
        <input
          type="date"
          {...input}
          value={valueToShow}
          className="not-empty"
        />
        <div className="input__icon-wrap">
          <img
            className="input__icon"
            src={icon}
            alt="icon"
            decoding="async"
            loading="lazy"
          />
        </div>
        <label>{label}</label>
        <div className="input-line" />
      </StyledInput>
    ) : (
      <StyledInput className="default" error={error}>
        <input
          type="date"
          {...input}
          value={valueToShow}
          className="not-empty"
        />
        <label>{label}</label>
      </StyledInput>
    ));
  }
}

const StyledInput = styled.div`
    position: relative;
    height: 60px;
    box-sizing: border-box;
    background-color: #1C1C1C;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid ${props => (props.error === 'empty' ? 'red' : '#313131')};

    label {
        position: absolute;
        top: 50%;
        left: 77px;
        font-size: 15px;
        color: #616161;
        transform: translateY(-50%);
        transition: all 0.15s ease-in-out;
        transform-origin: left center;
        pointer-events: none;
    }
    input {
        height: 100%;
        background: inherit;
        border: none;
        width: 100%;
        padding-left: 77px;
        color: #8e8e8e;
        font-size: 15px;
        padding-top: 17px;
        padding-right: 15px;
        border-style: none;
        overflow: hidden;
        position: relative;
        outline: none;

        &:focus ~ label,
        &.not-empty ~ label {
            transform: scale(0.85) translateY(-150%);
        }
    }
    
    input[type=date] {
      &::-webkit-datetime-edit {

      }
      
      &::-webkit-clear-button {
        cursor: pointer;
        font-size: 20px;
        opacity: 1;
        filter: hue-rotate(145deg);
        transform: translateY(-8px);
      }
      
      &::-webkit-inner-spin-button {
        display: none;
      }
      
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        font-size: 20px;
        opacity: 1;
        margin-top: -14px;
        margin-left: 10px;
        
        &:hover {
          background-color: transparent;
        }
      }
    }

    .input-line {
        position: absolute;
        top: 50%;
        left: 60px;
        height: 75%;
        width: 2px;
        transform: translateY(-50%);
        background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
        background-size: 100% 8px;
    }

    .input__icon-wrap {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translate(-50%,-50%);
    }
    
    .input__icon {
      opacity: .5;
    }

    &.default {
        input {
            padding-left: 15px;
        }
        label {
            left: 15px;
        }
    }
`;

export default InputDatepicker2;
