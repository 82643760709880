import React from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions/actions';
import AuthorizationModal from '../components/authorization/AuthorizationModal';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

class AuthorizationContainer extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getCart(),
      this.props.actions.getUser(),
    ]);
  }

  render() {
    const {
      currentLanguage,
      waitSms,
      smsValid,
      user,
      form,
      settings,
      languagePrefix,
      isMobile,
      translate,
    } = this.props;

    return (
      <AuthorizationModal
        currentLanguage={currentLanguage}
        waitSms={waitSms}
        smsValid={smsValid}
        user={user}
        actions={this.props.actions}
        form={form}
        settings={settings}
        languagePrefix={languagePrefix}
        isMobile={isMobile}
        translate={translate}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  waitSms: state.waitSms ? state.waitSms : null,
  smsValid: state.smsValid ? state.smsValid : null,
  settings: state.settings ? state.settings : null,
  user: state.user ? state.user : null,
  form: state.form ? state.form : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
  isMobile: state.isMobile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainer);
