import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/actions';
import Lottie from 'react-lottie';

import * as storage from '../../utils/storage';
import ProfileBlockTitle from './ProfileBlockTitle';
import DeliveryForm from '../delivery/DeliveryForm';
import ModalWrapper from '../shared/ModalWrapper';

import keyByLang from '../../modules/shared/keyByLang';

import store from '../../assets/icons/form/pickup.svg';
import { pickUpIcon } from '../../assets/icons/lottieIcons';
import { filterClose } from '../../assets/icons/svgIcons';
import { getTranslate } from 'react-localize-redux';

class ProfileFavouriteStoreBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStoreModalActive: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeStore = this.removeStore.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return this.props.actions.getStores();
  }

  toggleModal() {
    this.setState(prevState => ({
      isStoreModalActive: !prevState.isStoreModalActive,
    }));
  }

  updateAddress(e) {
    const newAddress = JSON.parse(storage.session.getItem('takeOutStore')).address.id_1c;
    this.props.actions.updateUser({ 'User[favourite_restaurant_id]': `${newAddress}` });
    storage.local.setItem('lastDeliveryType', JSON.stringify('takeOutStore'));
    this.toggleModal();
  }

  removeStore(storeData) {
    const savedStore = JSON.parse(storage.session.getItem('takeOutStore'));
    if (savedStore && savedStore.address && savedStore.address.id === storeData.id) {
      storage.session.removeItem('takeOutStore');
    }
    this.props.actions.updateUser({ 'User[favourite_restaurant_id]': null });
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const {
      user, stores, currentLanguage, translate,
    } = this.props;
    const { isStoreModalActive } = this.state;
    let favouriteStore = null;
    let takeOutStores = null;
    const title = keyByLang('title', currentLanguage);
    const address = keyByLang('address', currentLanguage);

    if (user && user.favourite_restaurant_id && stores && stores[0]) {
      favouriteStore = stores
        .find(storeData => storeData.id_1c === user.favourite_restaurant_id);
    }

    return (
      <StyledFavouriteStore className="store-item">
        <ProfileBlockTitle
          icon={store}
          headerBlockAction="action"
          paddingTop="60"
        >
          {translate('FavoriteShop')}
        </ProfileBlockTitle>
        <hr className="solid" />
        <div className="store-item__content">
          {user && user.id && favouriteStore ? (
            <div className="store-item__address">
              <div className="store-item__text-wrap">
                <p className="store-item__text">{favouriteStore[title]}</p>
              </div>
              <button
                className="store-item__delete"
                type="button"
                onClick={() => this.removeStore(favouriteStore)}
              >
                {filterClose}
              </button>
            </div>
          ) : (
            <p className="store-item__text">{translate('FavoriteStoreNotChosen')}</p>
          )}
          <p className="store-item__link" onClick={this.toggleModal}>
            {favouriteStore ? translate('Change') : translate('Add')}
          </p>
        </div>
        <hr className="solid" />
        {isStoreModalActive ? (
          <ModalWrapper handleModalClose={this.toggleModal} modalId="StoreModal">
            <StyledStoreModal>
              <header className="delivery__header">
                <div className="delivery__logo-wrap">
                  <div className="delivery__logo delivery__logo--house">
                    <Lottie
                      options={pickUpIcon}
                    />
                  </div>
                </div>
                <h2 className="delivery__heading">{translate('ChooseStoreForPickupText')}</h2>
              </header>
              <DeliveryForm
                placeholder={translate('EnterTheStoreAddress')}
                handleSubmit={this.updateAddress}
                takeOutStores={takeOutStores}
                currentLanguage={currentLanguage}
                stores={stores}
                translate={translate}
                showSnackBar={this.props.actions.showSnackBar}
                updateAddress={this.updateAddress}
                isCheckout
              />
            </StyledStoreModal>
          </ModalWrapper>
        ) : null}
      </StyledFavouriteStore>
    );
  }
}

const StyledFavouriteStore = styled.ul`
  --text-color: #8E8E8E;
  .store-item {
    &__content {
      padding: 1.25rem 0;
      justify-content: space-between;
    }
    
    &__address {
      display: flex;
      justify-content: space-between;
    }
    
    &__delete {
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      
      path {
        fill: var(--text-color);
      }
    }
    
    &__text {
      padding-bottom: .5rem;
    }
    
    &__link {
      color: var(--link-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  
@media all and (min-width: 769px) {
  .store-item {
    &__content {
      padding: 2.5rem 0;
    }
  }
}
`;

const StyledStoreModal = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;

  max-width: 470px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  
  .delivery {
    
    &__header {
      padding-top: 5rem;
      text-align: center;
    }
    
    &__content {
      padding-top: 1.2rem;
    }
    
    &__logo-wrap {
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__logo {
      
      &--house {
        width: 4.2rem;
      }
      
      &--moto {
        width: 6.8rem;
      }
    }
    
    &__heading {
      text-transform: none;
      font-size: 1.1rem;
      text-align: center;
      font-weight: 100;
      padding: 2.5rem 0 1rem;
      color: var(--text-color);
    }
    
    &__tabs-list {
      display: flex;
    }
      
    &__tab {
      padding: .8rem;
      background-color: transparent;
      border-radius: 4px;
      color: var(--active-text-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background);
      }
      
      &--active {
        background-color: var(--active-background);
      }
    }
  }
  
  .search-field {
    color: var(--text-color);
  
    &__input {
      flex-grow: 1;
      height: 42px;
      border-radius: 4px;
      border: none;
      padding: 0 10px;
      width: 100%;
      padding-right: 110px;
    }
    
    &__button {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      
      button {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`;

const mapStateToProps = state => ({
  stores: state.stores ? state.stores : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFavouriteStoreBlock);
