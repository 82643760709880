import React from 'react';
import styled from 'styled-components';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { orderBy, isEmpty } from 'lodash-es';
import { connect } from 'react-redux';
import TitleBlock from '../components/shared/TitleBlock';
import PageHeaderBlock from '../components/shared/PageHeaderBlock';
import headerImage from '../assets/images/shop_header_image.png';
import PromotionsList from '../components/promotions/PromotionsList';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import {bindActionCreators} from "redux";
import * as actions from "../actions/actions";

class PromotionsContainer extends React.Component {

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  fetchData() {
    return this.props.actions.getPromotions();
  }

  render() {
    const {
      promotions, currentLanguage, languagePrefix, bannerImage, translate,
    } = this.props;

    return (
      <StyledPromotionsListPage className="page news-list default">
        <PageHeaderBlock image={bannerImage || headerImage} addClass="" />
        <div className="news-list__content">
          <TitleBlock title={translate('Promotions')} subtitle="" />
          <hr className="solid margin-line" />
          {promotions && !isEmpty(promotions) ? (
            <PromotionsList
              promotions={promotions}
              currentLanguage={currentLanguage}
              languagePrefix={languagePrefix}
              translate={translate}
            />
          ) : (
            <p className="news-list__empty">{translate('EmptyPromotionsList')}</p>
          )}
        </div>
      </StyledPromotionsListPage>
    );
  }
};

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  promotions: state.promotions ? state.promotions : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  settings: state.settings ? state.settings : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const StyledPromotionsListPage = styled.div`
  --text-color: #8E8E8E;
  background-color: #0A0A0A;
    
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  
  .margin-line {
    margin-top: 1.4em;
  }
    
  .news-list {
    &__empty {
      color: var(--text-color);
      text-align: center;
      padding-top: 4rem;
      font-size: 1.5rem;
    }
    
    &__content {
      max-width: 1230px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsContainer);
