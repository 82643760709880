import { axiosInstance } from './axiosInstance';
import * as headers from './headers';
import _serialize from './helpers/serialize';

const ResumesApi = {
  sendCV(data) {
    return axiosInstance.post('resumes',
      data,
      { headers: headers.sendHeaders });
  },
};

export default ResumesApi;
