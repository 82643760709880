import React from 'react';
import styled from 'styled-components';

const AnimatedButton = ({
  minWidth,
  align,
  icon,
  isSubmit = false,
  text,
  handleClick,
  addClass,
  marginBottom,
  isDisabled,
}) => (
  <StyledAnimatedBtn
    align={align}
    minWidth={minWidth}
    className={`main-btn ${addClass || ''}`}
    marginBottom={marginBottom}
  >
    {isSubmit ? (
      <button
        onSubmit={handleClick}
        type="submit"
        disabled={isDisabled}
        className="button animated"
      >
        {typeof icon === 'string' ? (
          <img className="icon" src={icon} alt="button-icon" decoding="async" loading="lazy" />
        ) : (
          <div className="icon">{icon}</div>
        )}
        <span className="text">{text}</span>
      </button>
    ) : (
      <button
        onClick={handleClick}
        type="button"
        disabled={isDisabled}
        className="button animated"
      >
        {typeof icon === 'string' ? (
          <img className="icon" src={icon} alt="button-icon" decoding="async" loading="lazy" />
        ) : (
          <div className="icon">{icon}</div>
        )}
        <span className="text">{text}</span>
      </button>
    )}
  </StyledAnimatedBtn>
);

export default AnimatedButton;

const StyledAnimatedBtn = styled.div`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin-bottom: ${(props) => (props.marginBottom ? '20px' : 0)};
  display: inline-block;
  width: 100%;

  .button {
    position: relative;
    color: #fff;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #E74E1A;
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    padding: 13px 10px;
    text-align: center;
    box-sizing: border-box;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : 'auto')};
    overflow: hidden;
    transition: background-color 0.4s ease-in-out;

    &.animated {

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% + 40px));
        transition: transform 0.15s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        display: inline-block;
        transform: translateY(0);
        transition: transform 0.15s ease-in-out;
      }
    }

    &:disabled {
      background-color: #646464 !important;
      pointer-events: none !important;
    }
  }

  @media all and (min-width: 1025px) {
    .button {

      &:hover {
        background-color: #BB3517;
      }

      &:active {
        box-shadow: 0 0 15px rgba(0, 0, 0, .4) inset;
      }

      &.animated {
        &:hover {

          .text {
            transform: translateY(-40px);
          }

          .icon {
            transform: translate(-50%, calc(-50% + 0px));
          }
        }
      }
    }
  }
`;
