import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../modules/shared/keyByLang';
import AnimatedButton from '../shared/buttons/AnimatedButton';

import { buttonRight } from '../../assets/icons/svgIcons';

const NewsListItem = ({
  data, currentLanguage, languagePrefix, redirectTo, translate,
}) => {
  const title = keyByLang('title', currentLanguage);
  const text = keyByLang('shortdescr', currentLanguage);
  let dateToShow = null;

  if (data.publish_date) {
    dateToShow = data.publish_date.split('-').reverse().join('.');
  }

  return (
    <StyledNewsListItem
      className="news-item"
      onClick={() => redirectTo(`${languagePrefix}/news/${data.alias}`)}
    >
      <h5 className="title">
        {data[title]}
      </h5>
      <hr className="solid news-item__line" />
      <p className="publish-date">
        {translate('DateAdded')} - {dateToShow}
      </p>
      <img
        className="image"
        src={data.image_filename}
        alt={data.image_filename}
        decoding="async"
        loading="lazy"
      />
      {data && data[text].includes('<') ? (
        <div className="news-text" dangerouslySetInnerHTML={{ __html: data[text] }} />
      ) : (
        <p className="news-text">{data[text]}</p>
      )}
      <hr className="dashed" />
      <AnimatedButton
        align="center"
        minWidth="130px"
        iconName="store"
        text={translate('ReadMore')}
        icon={buttonRight}
      />
    </StyledNewsListItem>
  );
};

const StyledNewsListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  transition: .2s;

  &:hover {
    background-color: #1a1a1a;
    cursor: pointer;
  }
  
  .news-item {
    &__line {
      margin: .5rem 0 .75rem;
    }
  }

  .dashed {
    width: calc(100% - 2rem);
    margin-bottom: 1.5rem;
  }

  .title {
    font-weight: 600;
    font-size: 1.2rem;
    padding-bottom: .5rem;
    width: 100%;
    text-align: center;
  }

  .publish-date {
    color: #777;
  }

  .news-text {
    max-width: 100%;
    padding: 1rem 0;
    margin: 0 1rem 1rem;
    height: calc(8.6rem);
    overflow: hidden;
    color: #777;
  }

  .image {
    object-fit: contain;
    width: 100%;
    height: 18rem;
    padding: 1rem 0;
    flex-grow: 1;
  }

  @media (min-width: 768px) {
    
  }

  @media (min-width: 1024px) {
   
  }
`;

export default NewsListItem;
