import React from 'react';
import styled from 'styled-components';
import scriptLoader from 'react-async-script-loader';
import * as CONSTANTS from '../../modules/shared/mapApiConstants';
import NewProfileAddressInput from './NewProfileAddressInput';

class NewLocationSearchInputLoader extends React.Component {
  constructor(props) {
    super(props);
    this.initLoader = this.initLoader.bind(this);
    this.state = {
      isExternalScriptsLoaded: false,
    };
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.initLoader();
    }
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
      if (isScriptLoadSucceed) {
        this.initLoader();
      } else this.props.onError();
    }
  }

  initLoader = () => {
    this.setState({ isExternalScriptsLoaded: true });
  }

  render() {
    const { isExternalScriptsLoaded } = this.state;
    const content = isExternalScriptsLoaded
      ? <NewProfileAddressInput {...this.props} />
      : null;

    return (
      <StyledLocation checkout={this.props.isCheckout}>
        {content}
      </StyledLocation>
    );
  }
}

const StyledLocation = styled.div`
  flex-grow: 1;
  display: flex;
  border: ${(props) => (props.checkout ? '1px solid #313131' : 'none')};
  border-radius: ${(props) => (props.checkout ? '4px' : '0')};
  height: ${(props) => (props.checkout ? '60px' : '42px')};
};
  
`;

export default scriptLoader(
  [`${
    CONSTANTS.gMapsScript + CONSTANTS.gApiKey
  }&libraries=${
    CONSTANTS.gLibraries.places
  },${
    CONSTANTS.gLibraries.geometry
  }`,
  ],
)(NewLocationSearchInputLoader);
