import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const TextPagesApi = {
  getTextPages() {
    return axiosInstance.post('text-pages',
      {},
      { headers: headers.json });
  },
};

export default TextPagesApi;
