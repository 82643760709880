import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import pin from '../../assets/images/pin.svg';
import keyByLang from '../../modules/shared/keyByLang';

import { modalClose, filterClose } from '../../assets/icons/svgIcons';

class Pin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPinVisible: false,
    };

    this.showHidePinData = this.showHidePinData.bind(this);
  }

  showHidePinData = () => {
    this.setState(prevState => ({
      isPinVisible: !prevState.isPinVisible,
    }));
  }

  render() {
    const {
      languagePrefix,
      store,
      visibleStoreId,
      changeStoreDetails,
      currentLanguage,
      checkStoreStatus,
      translate,
    } = this.props;

    const address = keyByLang('address', currentLanguage);
    const title = keyByLang('title', currentLanguage);
    const isOpened = checkStoreStatus(store);

    const isStoreDataVisible = store.id === visibleStoreId;

    const pinData = isStoreDataVisible ? (
      <div className="store-info">
        <div className="store-info__heading">
          <p className="store-info__title">{store[title]}</p>
          <button
            className="store-info__close"
            type="button"
            onClick={() => changeStoreDetails(null)}
          >
            {filterClose}
          </button>
        </div>
        <hr className="solid store-info__line" />
        <p className="store-info__address">{store[address]}</p>
        <div className="store-info__status-wrap">
          <p className="store-info__status">{isOpened ? translate('IsOpen') : translate('IsClose')}</p>
          <p className="store-info__time">{translate('Working hours')}: {store.opened_since_hour}:{store.opened_since_minute}-{store.opened_till_hour}:{store.opened_till_minute}</p>
        </div>
        <Link className="store-info__link" to={`${languagePrefix}/stores/${store.id}`}>{translate('MoreAboutStore')}</Link>
      </div>
    ) : null;

    return (
      <StyledDiv className="pin">
        <img
          onMouseEnter={() => changeStoreDetails(store.id)}
          src={pin}
          alt="pin"
          decoding="async"
          loading="lazy"
        />
        {pinData}
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  --text-color: #8E8E8E;
  --triangle-height: 8px;
  --background-color: #313131;
  --active-text-color: #fff;
  --links-color: #E74E1A;

  position: absolute;
  bottom: -6px;
  left: -24px;
  background: transparent;
  cursor: pointer;

  .store-info {
    position: absolute;
    background-color: var(--background-color);
    padding: 10px 10px 18px;
    bottom: calc(100% + var(--triangle-height));
    left: 50%;
    transform: translateX(-50%);
    color: var(--text-color);
    font-size: 1rem;
    cursor: default;
    border-radius: 4px;
    box-shadow: 1px 2px 4px rgba(0,0,0, .5);
    min-width: 18rem;
    z-index: 5;
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      border: 8px solid transparent;
      border-top: 8px solid var(--background-color);
      left: calc(50% - var(--triangle-height) - 1px);
      background-color: transparent;
    }
    
    &__heading {
      display: flex;
      justify-content: space-between;
    }
    
    &__close {
      border: none;
      background-color: transparent;
      cursor: pointer;
      
      & path {
        fill: var(--text-color);
      }
    }
    
    &__title {
      padding: .8rem 0;
    }
    
    &__line {
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 60%, rgba(0,0,0,0) 100%);
    }
    
    &__address {
      padding-top: .5rem;
      color: var(--active-text-color);
    }
    
    &__status-wrap {
      padding: .5rem 0 .8rem;
      display: flex;
      justify-content: space-between;
      font-size: .8rem;
      font-weight: 100;
    }
    
    &__status {
      color: var(--active-text-color);
    }
    
    &__time {
    
    }
    
    &__link {
      color: var(--links-color);
      font-size: .9rem;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

`;

export default Pin;
