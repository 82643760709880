import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

import * as storage from '../utils/storage';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import ThankYouPage from '../components/thank-you/ThankYouPage';
import * as actions from '../actions/actions';
import getProductById from '../modules/shared/getProductById';
import { setOnThankYouPage } from "../actions/actions";

class ThankYouContainer extends React.PureComponent {
  static mapAccessoriesData(orderAccessories, accessories) {
    const accessoriesToShow = accessories.map(accessorie => ({
      ...accessorie,
      orderQuantity: orderAccessories[accessorie.id],
    }));
    return accessoriesToShow.filter(accessory => +accessory.orderQuantity > 0);
  }

  constructor(props) {
    super(props);

    this.state = {
      lastOrder: null,
    };

    this.showStore = this.showStore.bind(this);
    this.setTimeToShow = this.setTimeToShow.bind(this);
    this.calculateDistance = this.calculateDistance.bind(this);
    this.findClosestStore = this.findClosestStore.bind(this);
    this.getProductData = this.getProductData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.actions.setOnThankYouPage(true);
  }

  componentWillUnmount() {
    this.props.actions.setOnThankYouPage(false);
  }

  setTimeToShow(date) {
    if (!date) {
      return '';
    }
    const dateArr = date.split(' ');
    const dayArr = dateArr[0].split('-');
    const timeArr = dateArr[1].split(':');
    return `${dayArr[2]}.${dayArr[1]}/${timeArr[0]}:${timeArr[1]}`;
  }

  calculateDistance(x, y) {
    return Math.pow(x, 2) + Math.pow(y, 2);
  }

  findClosestStore(addressLocation, stores) {
    const closest = stores.reduce((res, store) => {
      const currentDistance = this.calculateDistance(
        +store.geoposition.split(',')[0] - addressLocation.lat,
        +store.geoposition.split(',')[1] - addressLocation.lng,
      );
      const prevDistance = this.calculateDistance(
        +res.geoposition.split(',')[0] - addressLocation.lat,
        +res.geoposition.split(',')[1] - addressLocation.lng,
      );
      if (currentDistance < prevDistance) {
        return store;
      }
      return res;
    });
    return closest;
  }

  showStore(store) {
    this.props.history.push(`${this.props.languagePrefix}/stores/${store.id}`);
  }

  getProductData(orderProduct) {
    const categoriesProduct = getProductById(orderProduct.id, this.props.categories);
    if (!categoriesProduct.id) {
      return orderProduct;
    }
    if (categoriesProduct.id) {
      return {
        ...categoriesProduct,
        price: categoriesProduct.price,
        quantity: categoriesProduct.quantity,
        id: categoriesProduct.id,
      };
    }
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getOrderStatuses(),
      this.props.actions.getAccessories(),
      this.props.actions.getOrdersHistory(),
      this.props.actions.getUser(),
      this.props.actions.getPaymentMethods(),
      this.props.actions.getDeliveryMethods(),
      this.props.actions.getStores(),
      actions.getLastOrder().then((res) => {
        this.setState({ lastOrder: res.data });
      }),
    ]);
  }

  render() {
    const {
      statuses,
      categories,
      currentLanguage,
      accessories,
      deliveryMethods,
      paymentMethods,
      stores,
      isMobile,
      settings,
      languagePrefix,
      translate,
    } = this.props;
    const { lastOrder } = this.state;

    let deliveryMethod = null;
    let paymentMethod = null;
    let currentStatuses = [];
    let orderAccessories = null;
    let currentRestaurant = null;
    const deliveryAddress = JSON.parse(storage.local.getItem('lastDeliveryAddress'));
    let storeToShow = null;

    if (lastOrder && deliveryMethods) {
      deliveryMethod = deliveryMethods.find(method => +method.id === +lastOrder.delivery_method_id);
    }

    if (lastOrder && paymentMethods) {
      paymentMethod = paymentMethods.find(method => +method.id === +lastOrder.payment_method_id);
    }

    if (deliveryMethod && +deliveryMethod.id === 1 && statuses && statuses[0]) {
      let filteredByDelivery = statuses.filter(item => item.for_self_delivery === '1');
      currentStatuses = [
        filteredByDelivery[filteredByDelivery.length - 1],
        ...filteredByDelivery.slice(0, filteredByDelivery.length - 2)
      ]
    } else {
      currentStatuses = [
        statuses[statuses.length - 1],
        ...statuses.slice(0, statuses.length - 2)
      ];
    }

    /*if (lastOrder && lastOrder.accessories && accessoriesTypes && accessoriesTypes[0]) {
      orderAccessories = ThankYouContainer.mapAccessoriesData(
        JSON.parse(lastOrder.accessories),
        accessoriesTypes,
      );
    }*/

    if (lastOrder && lastOrder.accessories && accessories && accessories[0]) {
      orderAccessories = ThankYouContainer.mapAccessoriesData(
        JSON.parse(lastOrder.accessories),
        accessories,
      );
    }

    if (lastOrder && lastOrder.restaurant_id && stores && stores[0]) {
      currentRestaurant = stores.find(store => store.id_1c === lastOrder.restaurant_id);
    }

    if (deliveryAddress && deliveryAddress.location
      && deliveryMethod && +deliveryMethod.id === 2
      && stores && stores[0]) {
      storeToShow = this.findClosestStore(deliveryAddress.location, stores);
    }

    return lastOrder ? (
      <ThankYouPage
        lastOrder={lastOrder}
        categories={categories}
        currentStatuses={currentStatuses}
        currentLanguage={currentLanguage}
        orderAccessories={orderAccessories}
        paymentMethod={paymentMethod}
        deliveryMethod={deliveryMethod}
        currentRestaurant={currentRestaurant}
        showStore={this.showStore}
        isMobile={isMobile}
        setTimeToShow={this.setTimeToShow}
        settings={settings}
        languagePrefix={languagePrefix}
        storeToShow={storeToShow}
        getProductData={this.getProductData}
        translate={translate}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  categories: state.categories ? state.categories : null,
  statuses: state.statuses ? state.statuses : null,
  accessories: state.accessories ? state.accessories : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  deliveryMethods: state.deliveryMethods ? state.deliveryMethods : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  paymentMethods: state.paymentMethods ? state.paymentMethods : null,
  stores: state.stores ? state.stores : null,
  isMobile: state.isMobile,
  settings: state.settings ? state.settings : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouContainer);
