import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../../modules/shared/keyByLang';
import Radio from '../../shared/form/Radio';

const DeferredOrderPeriods = ({
  activePeriodId, timeSlots, currentLanguage, handleDeferredChange,
}) => {
  const title = keyByLang('title', currentLanguage);
  const periodRadioList = timeSlots ? timeSlots.map(item => (
    <li key={item.id} className="period__item">
      <Radio
        label={item[title]}
        id={item.id}
        input={{
          checked: +activePeriodId === +item.id,
          name: 'deferredOrderPeriod',
          onChange: () => handleDeferredChange({ activePeriodId: item.id }),
        }}
      />
    </li>
  )) : null;

  if (!timeSlots) {
    return null;
  }
  return (
    <StyledDefferedOrderPeriods className="periods">
      <ul className="periods__list">
        {periodRadioList}
      </ul>
    </StyledDefferedOrderPeriods>
  );
};

const StyledDefferedOrderPeriods = styled.form`

  .periods {
    
    &__list {
      padding-top: 1rem;
      display: flex;
      justify-content: space-around;
    }
  }
  
  @media all and (min-width: 769px) {
    .periods {
      &__list {
        flex-direction: column;
      }
    }
  }
`;

export default DeferredOrderPeriods;
