import React from 'react';
import styled from 'styled-components';

const FooterBottom = (props) => {
  const { translate } = props;
  return (
    <StyledFooterBottom className="wide-container">
      <div className="footer-bottom flex">
        <p>{translate('JapaneseFoodDelivery')}</p>
        <p>{translate('DevelopmentCompany')} <a className="footer-bottom__link" href="https://dinarys.com/">{translate('DevelopmentCompanyName')}</a></p>
      </div>
    </StyledFooterBottom>
  );
};

const StyledFooterBottom = styled.div`
  font-size: 12px;
  color: #777;
    
    .footer-bottom {
      grid-column: 1 / -1;
      padding-bottom: 1rem;
      flex-direction: column;
      align-items: center;
      padding-top: .5rem;

      &__link {
        color: #e74e1a ;
        text-decoration: none;
      }
    }


    @media (min-width: 768px) {
      font-size: 14px;

      .footer-bottom {
        flex-direction: row;
        justify-content: space-between;
      } 
    }
`;
export default FooterBottom;
