/* eslint-disable prefer-destructuring,consistent-return */
/* global google */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import Lottie from 'react-lottie';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as actions from '../../actions/actions';

import * as storage from '../../utils/storage';
import ProfileBlockTitle from './ProfileBlockTitle';

import moto from '../../assets/icons/form/deliveryForm.svg';
import { like, activeLike, filterClose } from '../../assets/icons/svgIcons';
import ModalWrapper from '../shared/ModalWrapper';

import { deliveryIcon } from '../../assets/icons/lottieIcons';
import GPlacesDeliveryForm from '../checkout/GPlacesDeliveryForm';
import NewProfileAddressForm from './NewProfileAddressForm';

class ProfileAdressesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddressModalActive: false,
      isAddressEditing: false,
    };

    this.changeFavouriteAddress = this.changeFavouriteAddress.bind(this);
    this.removeAddress = this.removeAddress.bind(this);
    this.addNewAddress = this.addNewAddress.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.convertDeliveryZonesFormat = this.convertDeliveryZonesFormat.bind(this);
    this.isChosenLocationInDeliveryZones = this.isChosenLocationInDeliveryZones.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModalToAdd = this.openModalToAdd.bind(this);
    this.checkIfAddressExist = this.checkIfAddressExist.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    storage.session.removeItem('addressToEdit');
  }

  fetchData() {
    return this.props.actions.getDeliveryZones();
  }

  openModalToAdd() {
    storage.session.removeItem('addressToEdit');
    this.setState((prevState) => ({
      isAddressModalActive: true,
      isAddressEditing: false,
    }));
  }

  openModalToEdit(addressData) {
    storage.session.setItem('addressToEdit', JSON.stringify({ ...addressData }));
    this.setState((prevState) => ({
      isAddressModalActive: !prevState.isAddressModalActive,
      isAddressEditing: true,
    }));
    this.props.actions.getUser();
  }

  closeModal() {
    this.setState((prevState) => ({
      isAddressModalActive: false,
    }));
  }

  changeFavouriteAddress(data) {
    const newFavourite = {
      id: data.id,
      is_favourite: data.is_favourite === 1 ? 0 : 1,
    };
    this.props.actions.updateAddress(newFavourite).then(() => {
      this.props.actions.getUser();
    });
    const checkedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    if (data.is_favourite && checkedAddress.address && checkedAddress.address === data.address) {
      storage.session.removeItem('deliveryAddress');
      return;
    } if (data.is_favourite && checkedAddress.address
      && checkedAddress.address !== data.address) {
      return;
    }
    storage.session.setItem('deliveryAddress', JSON.stringify(data));
    storage.local.setItem('lastDeliveryAddress', JSON.stringify(data));
  }

  removeAddress(data) {
    const savedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    if (data && savedAddress && data.id === savedAddress.id) {
      storage.session.removeItem('deliveryAddress');
    }
    this.props.actions.removeAddress(data.id).then(() => this.props.actions.getUser());
  }

  handleSelect(address, placeId) {
    this.props.input.onChange({ address, placeId });
    this.setAddressToState(address);
    if (!placeId) {
      return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
    }
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.isChosenLocationInDeliveryZones(latLng))
      .catch((error) => console.error('Error', error));
  }

  convertDeliveryZonesFormat(arr) {
    const filteredArr = arr.split(';').filter((item) => item);
    return filteredArr.map((item) => {
      const itemElem = item && item.split(',');
      return { lat: parseFloat(itemElem[0]), lng: parseFloat(itemElem[1]) };
    });
  }

  isChosenLocationInDeliveryZones(loc, address) {
    /* storage.local.setItem('deliveryAddress', JSON.stringify({
      address, location: loc,
    })); */
    storage.session.setItem('deliveryAddress', JSON.stringify({
      address, location: loc,
    }));
    storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
    this.props.actions.addDeliveryAddress({
      address,
      is_favourite: 0,
    }).then(() => this.props.actions.getUser());
    this.toggleModal();
  }

  checkIfAddressExist(data) {
    if (!this.props.user) {
      this.closeModal();
      return;
    }
    return !!this.props.user.addresses.find((addressData) => addressData.address === data);
  }

  addNewAddress(addressData) {
    const { translate } = this.props;
    if (addressData && this.checkIfAddressExist(addressData.address)) {
      this.props.actions.showSnackBar(translate('AddressAlreadyChosen'), 4000, false);
      return;
    }
    this.props.actions.addDeliveryAddress({
      address: addressData.address,
      coordinates: addressData.coordinates,
      is_favourite: 0,
    }).then(() => this.props.actions.getUser());
    this.closeModal();
  }

  editAddress(newAddress) {
    const { translate } = this.props;
    const addressToEdit = JSON.parse(storage.session.getItem('addressToEdit'));
    if (newAddress && this.checkIfAddressExist(newAddress.address)) {
      this.props.actions.showSnackBar(translate('AddressAlreadyChosen'), 4000, false);
      return;
    }
    this.props.actions.updateAddress({
      id: addressToEdit.id,
      address: newAddress.address,
    }).then((res) => {
      storage.session.setItem('deliveryAddress', JSON.stringify(newAddress));
      this.props.actions.getUser();
    });
    this.closeModal();
  }

  render() {
    const { user, isMobile, translate } = this.props;
    const { isAddressModalActive, isAddressEditing } = this.state;

    let addresses = [];

    if (user && user.id) {
      addresses = [...user.addresses].reverse();
    }

    const savedAddressesList = addresses && addresses[0] ? addresses.map((data, i) => {
      const addressData = JSON.parse(data.address);
      return (
        <li key={`${data.id}-${i}`} className="addresses__item">
          <div className="addresses__data">
            <div className="addresses__title-text">{`${addressData.street}, ${addressData.house_number}`}</div>
            <p
              className="addresses__link"
              onClick={() => this.openModalToEdit(data)}
            >
              {translate('Edit')}
            </p>
          </div>
          <div className="addresses__actions">
            <button
              className="addresses__button"
              type="button"
              onClick={() => this.changeFavouriteAddress(data)}
            >
              {data.is_favourite ? activeLike : like}
            </button>
            <button
              className="addresses__button addresses__button--close"
              type="button"
              onClick={() => this.removeAddress(data)}
            >
              {filterClose}
            </button>
          </div>
        </li>
      );
    })
      : <p>{translate('NoSavedAddresses')}</p>;

    return (
      <StyledAddresses className="addresses">
        <ProfileBlockTitle
          icon={moto}
          headerBlockAction="action"
          actionText={isMobile ? translate('Add') : translate('AddAddress')}
          paddingTop="60"
          action={this.openModalToAdd}
        >
          {translate('SavedAddresses')}
        </ProfileBlockTitle>
        <hr className="solid" />
        <div className="addresses__content">
          <ul className="addresses__list">
            {savedAddressesList}
          </ul>
        </div>
        <hr className={isMobile ? 'dashed addresses--with-margin' : 'solid'} />
        {isAddressModalActive ? (
          <ModalWrapper handleModalClose={this.closeModal} modalId="address-change-modal">
            <StyledAddressModal>
              <header className="delivery__header">
                <div className="delivery__logo-wrap">
                  <div className="delivery__logo delivery__logo--moto">
                    <Lottie
                      options={deliveryIcon}
                    />
                  </div>
                </div>
                <h2 className="delivery__heading">{translate('EnterYourShippingAddressText')}</h2>
              </header>
              <NewProfileAddressForm
                translate={translate}
                updateAddress={isAddressEditing ? this.editAddress : this.addNewAddress}
                isProfile
              />
            </StyledAddressModal>
          </ModalWrapper>
        ) : null}
      </StyledAddresses>
    );
  }
}

const StyledAddresses = styled.div`
  .addresses {
    &__content {
      padding: 1.25rem 0 0;
    }
    
    &__data {
      display: flex;
      font-size: .9rem;
      justify-content: space-between;
      width: 100%;
    }
    
    &__title-text {
      padding-bottom: .5rem;
    }
    
    &__item {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    
    &__link {
      color: var(--link-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__actions {
      display: flex;
      min-width: 6rem;
      width: 100%;
      margin-top: 1.25rem;
    }
    
    &__button {
      flex-basis: 50%;
      height: 40px;
      background-color: var(--secondary-background-color);
      border: 1px solid var(--dashed-border-color);
      
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      
      &--close {
        path {
          fill: var(--text-color);
        }
      }
      
      &:hover {
        cursor: pointer;
      }
    }
    
    &--with-margin {
      margin-bottom: 1rem;
    }
  }
  
@media all and (min-width: 768px) {
  .addresses {
    &__content {
      padding: 1.25rem 0;
    }
    &__data {
      flex-direction: column;
      font-size: 1rem;
      width: auto;
    }
    &__link {
      font-size: .9rem;
    }
    
    &__item {
      flex-direction: row;
    }
    
    &__actions {
      display: block;
      width: auto;
      margin-top: 0;
      text-align: right;
    }
    
    &__button {
      margin-left: 1rem;
      background-color: transparent;
      border: none;
    }
  }
}

@media all and (min-width: 1025px) {
  .addresses {
    &__button {
       margin-left: 2rem;
    }
    
    &__actions {
      min-width: 10rem;
    }
  }
}
`;

const StyledAddressModal = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;

  max-width: 600px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  color: var(--text-color);
  
  .delivery {
    
    &__header {
      padding-top: 5rem;
      text-align: center;
    }
    
    &__content {
      padding-top: 1.2rem;
    }
    
    &__logo-wrap {
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__logo {
      
      &--house {
        width: 4.2rem;
      }
      
      &--moto {
        width: 6.8rem;
      }
    }
    
    &__heading {
      text-transform: none;
      font-size: 1.1rem;
      text-align: center;
      font-weight: 100;
      padding: 2.5rem 0 1rem;
      color: var(--text-color);
    }
    
    &__tabs-list {
      display: flex;
    }
      
    &__tab {
      padding: .8rem;
      background-color: transparent;
      border-radius: 4px;
      color: var(--active-text-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background);
      }
      
      &--active {
        background-color: var(--active-background);
      }
    }
  }
  
  //.search-field {
  //  color: var(--text-color);
  //
  //  &__input {
  //    flex-grow: 1;
  //    height: 42px;
  //    border-radius: 4px;
  //    border: none;
  //    padding: 0 10px;
  //    width: 100%;
  //    padding-right: 110px;
  //  }
  //  
  //  &__button {
  //    position: absolute;
  //    width: auto;
  //    top: 0;
  //    right: 0;
  //    
  //    button {
  //      border-radius: 0 4px 4px 0;
  //    }
  //  }
  //}
`;

const mapStateToProps = (state) => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAdressesBlock);
