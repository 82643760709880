import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getActiveLanguage } from 'react-localize-redux/lib/index';
import { Translate } from 'react-localize-redux';
import { debounce } from 'lodash-es';

import {
  faqPopUpTelegram,
  popupClose,
  telegram,
  viber,
} from '../../../assets/icons/svgIcons';

class FaqPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPopupVisible: false,
    };

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  showPopup() {
    this.setState({ isPopupVisible: true });
  }

  hidePopup() {
    this.setState({
      isPopupVisible: false,
    });
    if (this.props.isMobile) {
      this.props.removePopup();
    }
  }

  render() {
    const { faqPopupReducer, isMobile } = this.props;
    const { isOnThankYouPage, isPopupActive } = faqPopupReducer;
    const { isPopupVisible } = this.state;
    return isPopupActive && (!isMobile || (isMobile && isOnThankYouPage)) ? (
      <StyledFaqPopup
        className="faq-popup"
        isPopupVisible={isPopupVisible || (isMobile && isOnThankYouPage)}
        onMouseLeave={debounce(this.hidePopup, 500)}
      >
        <div className="faq-popup__header">
          <button className="faq-popup__header-btn" onClick={this.showPopup}>
            <span className="faq-popup__text"><Translate id="ChatHelp" /></span>
            {faqPopUpTelegram}
          </button>
        </div>
        <div className="faq-popup__content">
          <div className="faq-popup__heading-row">
            <p className="faq-popup__heading"><Translate id="HelpIsNeeded" /></p>
            <button className="faq-popup__close" type="button" onClick={this.hidePopup}>{popupClose}</button>
          </div>
          <div className="border-gradient-divider" />
          <div className="faq-popup__link-icons-wrapper">
            <a
              href="viber://pa?chatURI=budu"
              target="_blank"
              rel="noreferrer"
              className="faq-popup__link-icon"
            >
              {viber}
            </a>
            <a
              href="https://t.me/Budusushibot"
              target="_blank"
              rel="noreferrer"
              className="faq-popup__link-icon"
            >
              {telegram}
            </a>
          </div>
          <div className="border-gradient-divider" />
        </div>
      </StyledFaqPopup>
    ) : null;
  }
}

const StyledFaqPopup = styled.div`
  --popup-background: #313131;
  --text-color: #8E8E8E;
  --links-color: #E74E1A;
  --width: 18rem;
  
  position: fixed;
  bottom: 2.25rem;
  right: ${(props) => (props.isPopupVisible ? 'calc(100% - var(--width))' : '100%')};
  // right: ${(props) => (props.isPopupVisible ? 'auto' : '100%')};
  display: flex;
  min-width: var(--width);
  z-index: 11;
  min-height: 200px;
  
  transition: all .2s;
  
  .faq-popup {
    &__header {
      border-radius: 0 6px 6px 0;
      position: absolute;
      background-color: #E74E1A;
      height: 100%;
      left: 100%;
      top: 0;
    }    
    
    &__header-btn {
      padding: 0.5rem .6rem;
      font-weight: 600;
      cursor: pointer;
      outline: none !important;
      border: none;
      background: none;
      color: #fff;

      svg {
        flex-shrink: 0;
      }
    }
    
    &__text {
      writing-mode: vertical-rl;
      color: #fff;
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      transform: rotate(180deg);
    }
    
    &__content {
      border-right: 2px dotted var(--text-color);
      background-color: var(--popup-background);
      padding: 1.25rem;
      position: relative;
      box-sizing: border-box;
      width: 100%;
    }
    
    &__heading-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    &__heading {
      color: #fff;
      font-size: 1.25rem;
      margin: 0 auto;
      font-weight: 600;
      text-shadow: 0 3px 3px #000;
    }
    
    &__close {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    
    &__link-icons-wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .border-gradient-divider {
    margin: 1rem 0;
    border-image-slice: 1;
    border-style: solid;
    border-width: 1px;
    border-top: 0;
    border-image-source: linear-gradient(to left, transparent, #999, transparent);
  }
`;

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  isMobile: state.isMobile,
  faqPopupReducer: state.faqPopupReducer,
});

export default connect(mapStateToProps)(FaqPopup);
