const html = !process.env.IS_SERVER ? document.getElementById('root') : null;
const modalClass = {
  showModal() {
    if (!html) { return; }

    html.classList.add('modal-open');
  },
  hideModal() {
    if (!html) { return; }

    html.classList.remove('modal-open');
  },
};

export default modalClass;
