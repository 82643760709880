const getProductIngredientsByIds = (ingredientsIds, ingredientsObj) => {
    let matchIngredientsArr = [];
  // eslint-disable-next-line no-unused-expressions
    (ingredientsObj && ingredientsIds) ? (
        ingredientsIds.map( id =>
            ingredientsObj.map( ingredientItem =>
                ( ingredientItem.id === id ) ? (matchIngredientsArr = [...matchIngredientsArr, ingredientItem] ) : null
            )
    ))
    : false;
    return matchIngredientsArr;
  }
  export default getProductIngredientsByIds;
