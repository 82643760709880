/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import Sidebar from 'react-sidebar';
import { bindActionCreators } from 'redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import * as actions from '../actions/actions';
import close from '../assets/icons/sidebar/close.svg';
import { logoIcon } from '../assets/icons/lottieIcons';
import getProductById from '../modules/shared/getProductById';
import favouritesIcon from '../assets/images/sidebar-navigation/Favourites.jpg';

class LeftSidebarMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };

    this.sidebarStyles = {
      root: {
        position: 'absolute',
        top: 0,
        left: '100%',
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      },
      sidebar: {
        position: 'fixed',
        zIndex: 25,
      },
      overlay: {
        zIndex: 14,
        visibility: 'hidden',
        className: 'sidebar-overlay',
      },
    };

    this.activateBtn = null;
    this.categoriesRefs = null;
  }

  componentDidMount() {
    this.activateBtn = document.querySelector('.header__navigation-open');
    this.activateBtn.addEventListener('click', this.openMenu);
  }

  componentWillUnmount() {
    this.activateBtn.removeEventListener('click', this.openMenu);
  }

  openMenu = () => {
    if (this.props.isMobile) {
      this.setState({ sidebarOpen: true });
    } else {
      this.scrollToFirstCategory();
    }
  }

  closeMenu = () => {
    if (this.props.isMobile) {
      this.setState({ sidebarOpen: false });
    } else {
      this.scrollToFirstCategory();
    }
  }

  toggleOpenedMenu = () => {
    if (this.state.sidebarOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  redirectToMain = () => {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/`);
  }

  scrollToFirstCategory = () => {
    const { categories, languagePrefix } = this.props;
    if (!categories || !categories[0]) {
      return;
    }
    const { pathname } = this.props.history.location;
    const getAlias = () => {
      if (languagePrefix === '') {
        return pathname.slice(pathname.indexOf('/') + 1);
      }
      const arrFromPath = pathname.split('/');
      return arrFromPath[arrFromPath.length - 1];
    };
    const alias = getAlias();
    const foundCategory = categories.find((cat) => cat.alias === alias);
    if (foundCategory || alias === '' || alias === 'favourites') {
      const domCategories = document.querySelectorAll('div[data-id]');
      this.scrollTo(domCategories[0].dataset.id);
    } else {
      this.props.history.push(`${languagePrefix}/${categories[0].alias}`);
    }
  }

  mainPageDetect = () => {
    const { history, languagePrefix, categories } = this.props;
    const loc = history.location.pathname;
    if (loc === `${languagePrefix}/`) return true;
    // eslint-disable-next-line consistent-return
    return categories.find((cat) => loc.includes(cat.alias));
  };

  scrollTo = (categoryId) => {
    if (!this.mainPageDetect()) return;
    const categoriesWrap = document.querySelector('.categories');
    if (!categoriesWrap) return;
    const categories = categoriesWrap.querySelectorAll('div[data-id]');
    const category = [].find.call(categories, (cat) => cat.dataset.id === categoryId);
    if (category) {
      const difference = category.offsetTop
        + categoriesWrap.offsetTop;
      window.scrollTo(0, difference - 30);
    }
  };

  render() {
    const {
      languagePrefix, currentLanguage, filteredCategories, categories, isMobile, user, translate,
    } = this.props;
    const { sidebarOpen } = this.state;

    const favouriteCategory = {
      id: 'favouriteCategory',
      alias: 'favourites',
      products: [],
      title_ru: 'Избранные товары',
      title_ua: 'Обрані товари',
      image_filename: favouritesIcon,
    };

    let categoriesWithFavourites = categories && categories[0]
      ? [favouriteCategory, ...categories] : null;

    if (filteredCategories && filteredCategories[0]) {
      categoriesWithFavourites = [favouriteCategory, ...filteredCategories];
    }

    if (user && user.id && user.favourites && categoriesWithFavourites) {
      categoriesWithFavourites[0].products = user.favourites.reduce((res, favourite) => {
        const product = getProductById(favourite, filteredCategories || categories);
        if (product.id) {
          res.push(product);
        }
        return res;
      }, []);
    }

    return (isMobile ? (
      <Sidebar
        sidebar={(
          <StyledSidebarNavigation className="sidebar-navigation">
            <header className="sidebar-navigation__header">
              <Link className="sidebar-navigation__logo" to={`${languagePrefix}/`} onClick={this.closeMenu}>
                <Lottie options={logoIcon} />
              </Link>
              <button type="button" className="sidebar-navigation__close" onClick={this.closeMenu} />
            </header>
            <hr className="solid" />
            <ul className="sidebar-navigation__list">
              {categoriesWithFavourites && categoriesWithFavourites[0]
                ? categoriesWithFavourites.map((category) => (
                  category.products && category.products[0] ? (
                    <div className="sidebar-navigation__category" key={category.id}>
                      <Link
                        key={category.id}
                        to={`${languagePrefix}/${category.alias}`}
                      // isActive={() => category.id === activeCatalogLink.activeCategory}
                        onClick={() => {
                          this.closeMenu();
                          this.mainPageDetect();
                          this.scrollTo(category.id);
                        }}
                        className="sidebar-navigation__category-link"
                      />
                      <div
                        style={{
                          backgroundImage: `radial-gradient(166.79px at 50.41% 50.71%, rgba(0, 0, 0, 0.36246) 20%, #000000 100%),
                        url(${category.image_filename})`,
                        }}
                        className="sidebar-navigation__image"
                        src={category.image_filename}
                        alt="category icon"
                      />
                      <div className="sidebar-navigation__text-wrap">
                        <p className="sidebar-navigation__name">
                          {currentLanguage === 'ru' ? category.title_ru : category.title_ua}
                        </p>
                        <p className="sidebar-navigation__products-count">
                          {category.products.length}
                          {' '}
                          {category.products.length > 4 ? translate('Products') : 'продукта'}
                        </p>
                      </div>
                    </div>
                  ) : null)) : null}
            </ul>
          </StyledSidebarNavigation>
        )}
        open={sidebarOpen}
        onSetOpen={this.toggleOpenedMenu}
        styles={this.sidebarStyles}
        sidebarClassName="sidebar"
        pullRight={false}
        children="null"
        shadow
      />
    ) : null);
  }
}

const StyledSidebarNavigation = styled.div`
  --background-color: #0A0A0A;
  --text-color: #8E8E8E;
  --active-color: #fff;
  
  position: relative;
  background-color: var(--background-color);
  font-family: 'fs_elliot_pro';
  font-weight: 700;
  width: auto;
  z-index: 11;
  
  .sidebar-navigation {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: .5rem 1rem .5rem 2rem;
      align-items: center;
    }
    
    &__logo {
      padding: 0;
      width: 80px;
      height: 47px;
    }

    &__img {
      width: 100%;
    }
    
    &__close {
      cursor: pointer;
      width: 1.2rem;
      height: 1.2rem;
      margin-left: .5rem;
      margin-right: .3rem;
      background-image: url(${close});
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }
    
    &__list {
      padding: 1.25rem;
    }
    
    &__category {
      position: relative;
      height: 100px;
      margin-bottom: .5rem;
      width: 100%;
    }
    
    &__category-link {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    
    &__image {
      object-fit: contain;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 4px;
    }
    
    &__text-wrap {
      display: inline-block;
      position: absolute;
      right: 0;
      z-index: 1;
      height: 100%;
      width: 45%;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    &__name {
      font-size: 1rem;
      font-weight: 100;
      color: var(--active-color);
    }
    
    &__products-count {
      padding-top: .3rem;
      color: var(--text-color);
      font-weight: 100;
      font-size: .9rem;
    }
    
    &__empty {
      font-size: 1.2rem;
      text-align: center;
      color: #fff;
    }
  }
`;

const mapStateToProps = (state) => ({
  user: state.user ? state.user : null,
  isMobile: state.isMobile,
  categories: state.categories ? state.categories : null,
  filteredCategories: state.filteredCategories ? state.filteredCategories : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  currentLanguage: getActiveLanguage(state.locale).code,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftSidebarMenuContainer));
