import React from 'react';
import styled from 'styled-components';
import CrossSaleCategories from './CrossSaleCategories';

import AnimatedButton from '../../shared/buttons/AnimatedButton';

import logo from '../../../assets/images/logo.svg';
import { filterDone } from '../../../assets/icons/svgIcons';
import AccessoriesModal from '../AccessoriesModal';

class MemorizedCartCrossSaleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: this.props.crossSaleCategories[0] ? 0 : 1,
    };
  }

  componentWillUnmount() {
    this.props.cartSubmitInProgressHandler(false);
  }

  selectTab = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  }

  changeStep = () => {
    this.selectTab(this.state.activeTabIndex + 1);
    const cartWrap = document.querySelector('.modal-wrapper');
    cartWrap.scrollTop = 0;
  }

  buttonText = () => {
    if (this.state.activeTabIndex === 0 && this.props.currentState.chosenAddItemPaid.length === 0) {
      return this.props.translate('NoThanks');
    } if (this.state.activeTabIndex === 0 && this.props.currentState.chosenAddItemPaid.length > 0) {
      return this.props.translate('Add');
    }
    return this.props.translate('ConfirmButton');
  }

  buttonFunction = () => {
    if (this.state.activeTabIndex === 0 && this.props.currentState.chosenAddItemPaid.length === 0) {
      return this.changeStep;
    } if (this.state.activeTabIndex === 0 && this.props.currentState.chosenAddItemPaid.length > 0) {
      return () => {
        this.props.addToCart(this.props.currentState.chosenAddItemPaid);
        this.changeStep();
      };
    }
    return this.props.cartSubmit;
  }

  render() {
    const {
      crossSaleCategories,
      collectedAccessories,
      currentLanguage,
      order,
      currentState,
      onChangeHandleClickOnPaidItem,
      translate,
    } = this.props;

    return crossSaleCategories && collectedAccessories ? (
      <StyledCartCrossSale className="cross-sale">
        <div className="cross-sale__logo-wrap">
          <img
            className="cross-sale__logo-image"
            src={logo}
            alt="alt text"
            decoding="async"
            loading="lazy"
          />
        </div>
        <div className="cross-sale__button-wrap">
          <AnimatedButton
            minWidth="130px"
            align="center"
            icon={filterDone}
            text={this.buttonText()}
            handleClick={this.buttonFunction()}
          />
        </div>
        <div className="tabs-block">
          <ul className="tabs-block__list">
            <li
              className={this.state.activeTabIndex === 0
                ? 'tabs-block__tab tabs-block__tab--active'
                : 'tabs-block__tab'
              }
            >
              <p className="tabs-block__number">{1}</p>
              <p className="tabs-block__button-name">{translate('AdditionalDish')}</p>
            </li>
            <li
              className={this.state.activeTabIndex === 1
                ? 'tabs-block__tab tabs-block__tab--active'
                : 'tabs-block__tab'
              }
            >
              <p className="tabs-block__number">{2}</p>
              <p className="tabs-block__button-name">{translate('ItemsIncluded')}</p>
            </li>
          </ul>
        </div>
        {this.state.activeTabIndex === 0 ? (
          <CrossSaleCategories
            currentState={currentState}
            onChangeHandleClickOnPaidItem={onChangeHandleClickOnPaidItem}
            crossSaleCategories={crossSaleCategories}
            currentLanguage={currentLanguage}
          />
        ) : null}
        {this.state.activeTabIndex === 1 ? (
          <AccessoriesModal
            collectedAccessories={collectedAccessories}
            currentLanguage={currentLanguage}
            order={order}
            translate={translate}
          />
        ) : null}
      </StyledCartCrossSale>
    ) : null;
  }
}

const StyledCartCrossSale = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --active-background-color: #313131;

  max-width: 1230px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto 4rem;
  
  .cross-sale {
  
    &__button-wrap {
      padding-top: 2rem;
    }
    
    &__logo-wrap {
      padding: 1rem 15px 10px;
      text-align: left;
      position: sticky;
      width: 100%;
      left: 0;
      top: 0;
      background-color: #0A0A0A;
      z-index: 2;
    }
    
    &__logo-image {
      width: 4.5rem;
    }
    
    &__button-wrap {
      position: fixed;
      width: 100%;
      padding: 0 10px;
      bottom: 10px;
      left: 0;
      z-index: 11;
      
      .button {
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
      }
    }
  }
  
  .tabs-block {
    padding-top: 2rem;
    
    &__list {
      display: flex;
    }
    
    &__tab {
      padding: .7rem;
      font-size: .9rem;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 50%;
      text-transform: uppercase;
      color: var(--text-color);
      border: 1px solid var(--active-background-color);
      box-sizing: border-box;
      background-color: #1C1C1C;
      display: flex;
      justify-content: center;
      
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      
      &--active {
        color: var(--active-text-color);
        background-color: var(--active-background-color);
        
        & .tabs-block__number::after {
          background-color: var(--active-text-color);
        }
      }
    }
    
    &__number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: .5rem;
      
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin-left: .5rem;
        border-radius: 50%;
        background-color: var(--text-color);
      }
    }
    
    &__button-name {
      display: none;
    }
  }
  
@media all and (min-width: 640px) {
  .cross-sale {
  
    &__logo-wrap {
      padding-top: 20px;
      width: 100%;
      text-align: center;
    }

    &__logo-image {
      width: auto;
      max-width: 100%;
    }
    
    &__button-wrap {
      height: 80px;
      padding-top: 20px;
      width: 100%;
      position: sticky;
      left: 0;
      top: 93px;
      background-color: #0A0A0A;
      
      .button {
        width: auto;
        border-radius: 4px;
        box-shadow: none;
      }
    }
  }

  .tabs-block {
    &__tab {
      padding: 1.4rem;
      justify-content: flex-start;
    }
    
    &__button-name {
      display: block;
    }
  }
}

@media all and (min-width: 1440px) {
  .cross-sale {
  
    &__logo-wrap {
      padding-top: 60px;
    }
    
    &__button-wrap {
      height: 126px;
      padding-top: 4rem;
      width: 100%;
      position: sticky;
      left: 0;
      top: 133px;
      background-color: #0A0A0A;
      
      .button {
        width: auto;
        border-radius: 4px;
        box-shadow: none;
      }
    }
  }
}
`;

const CartCrossSaleModal = React.memo(MemorizedCartCrossSaleModal);

export default React.memo(CartCrossSaleModal);
