const getProductAllergensByIds = ( allergensIds, allergensObj ) => {
    let matchAllergensArr = [];
  // eslint-disable-next-line no-unused-expressions
    (allergensObj && allergensIds) ? (
        allergensIds.map( id =>
            allergensObj.map( allergenItem =>
                ( allergenItem.id === id ) ? (matchAllergensArr = [...matchAllergensArr, allergenItem] ) : null
            )
    ))
    : false;
    return matchAllergensArr;
  }
  export default getProductAllergensByIds;
