import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const StoresApi = {
  getStores() {
    return axiosInstance.post('restaurants',
      {},
      { headers: headers.json });
  },
  getDeliveryZones() {
    return axiosInstance.post('areas');
  },
};

export default StoresApi;
