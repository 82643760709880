import React from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';

const ItemAddition = (props) => {
  const {
    product,
    id,
    title,
    image,
    price,
    isTabFree,
    onChangeHandleClickFree,
    onChangeHandleClickPaid,
    isCheckedFreeItem,
    isCheckedPaidItem,
    tabid,
  } = props;
  const itemPaidType = parseInt(isTabFree, 10) ? 'free' : 'paid';

  const priceFreeOrPaid = itemPaidType === 'free'
    ? null
    : `${price} грн`;

  const clickAction = e => (itemPaidType === 'free'
    ? onChangeHandleClickFree(e, tabid, product.id)
    : onChangeHandleClickPaid(product.id));

  return (
    <StyledLi className="additional-product" onClick={clickAction} data-tabid={tabid}>
      <div className="additional-product__input-wrap">
        {
          itemPaidType === 'free' ? (
            <React.Fragment>
              <input
                className="additional-product__radio"
                type="radio"
                hidden="hidden"
                onChange={clickAction}
                id={product.id}
                checked={isCheckedFreeItem}
                name={`tab-${tabid}`}
              />
              <label
                className="additional-product__label additional-product__label--radio"
                htmlFor={product.id}
              >
                {title}
              </label>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <input
                className="additional-product__checkbox"
                type="checkbox"
                hidden="hidden"
                onChange={clickAction}
                id={product.id}
                checked={isCheckedPaidItem}
              />
              <label
                className="additional-product__label additional-product__label--checkbox"
                htmlFor={product.id}
              >
                {title}
              </label>
            </React.Fragment>
          )
        }
      </div>
      <div className="additional-product__image-wrap">
        <img
          className="additional-product__image"
          src={image}
          alt="alt text"
          decoding="async"
          loading="lazy"
        />
      </div>
      {
        priceFreeOrPaid ? (
          <p className="additional-product__price">{priceFreeOrPaid}</p>
        ) : (
          <p className="additional-product__price additional-product__price--free"><Translate id="Free" /></p>
        )
      }
      <hr className="dashed" />
    </StyledLi>
  );
};
const StyledLi = styled.li`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --hover-background: #151515;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
  max-width: 100%;
  padding: 3rem 1rem 1rem;
  box-sizing: border-box;
  transition: background-color .15s;
  border-radius: 4px;
  
  &:hover {
    cursor: pointer;
    background-color: var(--hover-background);
  }
   
  .additional-product {
    
    &__input-wrap {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    &__label {
      position: relative;
      font-weight: 700;
      
      &:hover {
        cursor: pointer;
        color: var(--active-text-color);
      }
    
      &--checkbox,
      &--radio {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -2rem;
        }
      }
      
      &--checkbox::before {
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 2px solid var(--text-color);
      }
      
      &--radio::before {
        left: calc(50% - 8px);
        width: 16px;
        height: 16px;
        border: 2px solid var(--text-color);
        border-radius: 50%;
      }
    }
    
    &__radio:checked + .additional-product__label::before,
    &__checkbox:checked + .additional-product__label::before {
      background-color: var(--text-color);
      background-clip: content-box;
      padding: 2px;
    }
    
    &__image-wrap {
      margin-top: 1rem;
      position: relative;
      width: 100%;
      padding-top: 100%;
    }
    
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
    &__price {
      color: var(--active-text-color);
      padding: 2rem 1rem 1rem;
      font-weight: 700;
      font-size: 1.1rem;
    
      &--free {
        font-weight: 100;
      }
    }
  }
`;

// this li elements styled in parent component - ProductsGrid

export default ItemAddition;
