import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from 'lodash-es';

import { getActiveLanguage } from 'react-localize-redux/lib/index';
import getlanguagePrefix from '../../../modules/shared/getLanguagePrefix';
import keyByLang from '../../../modules/shared/keyByLang';

class HeaderTracker extends React.PureComponent {
  static setTimeToShow(date) {
    if (!date) {
      return '';
    }
    const dateArr = date.split(' ');
    const dayArr = dateArr[0].split('-');
    const timeArr = dateArr[1].split(':');
    return `${dayArr[2]}.${dayArr[1]}/${timeArr[0]}:${timeArr[1]}`;
  }

  constructor(props) {
    super(props);

    this.state = {
      isProgressHovered: false,
      isShown: false,
    };

    this.ableToShow = true;
    this.isOutChecking = false;

    this.showFullInfo = this.showFullInfo.bind(this);
    this.hideFullInfo = this.hideFullInfo.bind(this);
    this.mouseOutListener = this.mouseOutListener.bind(this);
  }

  showFullInfo() {
    if (!this.ableToShow) {
      return;
    }
    this.isOutChecking = true;
    this.setState({ isShown: true, isProgressHovered: true });
    setTimeout(() => {
      this.isOutChecking = false;
    }, 100);
  }

  hideFullInfo() {
    this.ableToShow = false;
    this.isOutChecking = false;
    this.setState({ isShown: false });
    setTimeout(() => {
      this.ableToShow = true;
      this.setState({ isProgressHovered: false });
    }, 300);
  }

  mouseOutListener() {
    if (this.isOutChecking && this.ableToShow) {
      this.setState(() => {
        return { isShown: false, isProgressHovered: true, }
      });
    }
  }

  render() {
    const {
      lastActiveOrder, statuses, isMobile, currentLanguage, translate, history,
    } = this.props;
    const { isProgressHovered, isShown } = this.state;
    let isHovered = isProgressHovered;
    if (isShown) {
      isHovered = true;
    }
    const createdTime = HeaderTracker.setTimeToShow(lastActiveOrder.created_at);
    const deliveryTime = HeaderTracker.setTimeToShow(lastActiveOrder.delivery_date);
    let currentStatuses = null;
    let activeStatus = null;

    const isThankyou = this.props.history.location.pathname.includes('thankyou');

    if (parseInt(lastActiveOrder.delivery_method_id, 10) === 1 && statuses && statuses[0]) {
      let filteredByDelivery = statuses.filter(item => item.for_self_delivery === '1');
      currentStatuses = [
          filteredByDelivery[filteredByDelivery.length - 1],
        ...filteredByDelivery.slice(0, filteredByDelivery.length - 2)
      ]
    } else {
      currentStatuses = [
        statuses[statuses.length - 1],
        ...statuses.slice(0, statuses.length - 2)
      ];
    }

    const title = keyByLang('title', currentLanguage);
    let activePosition = 1;

    if (currentStatuses && currentStatuses[0] && lastActiveOrder) {
      activeStatus = currentStatuses.find(status => +status.id === lastActiveOrder.status_id);
      activePosition = currentStatuses
        .indexOf(activeStatus);
    }

    const dateToShow = lastActiveOrder.created_at.split(' ')[0].split('-').reverse().join('.');

    const checkClassName = (currentPosition, lastPosition) => {
      let isFirst = '';
      let isLast = '';
      let isActive = '';
      let isCompleted = '';
      if (currentPosition === 0) {
        isFirst = 'progress-bar__progress--first';
      }
      if (currentPosition === lastPosition) {
        isLast = 'progress-bar__progress--last';
      }
      if (currentPosition < activePosition) {
        isCompleted = 'progress-bar__progress--completed';
      }
      if (currentPosition === activePosition) {
        isActive = isHovered
          ? 'progress-bar__progress--hovered'
          : 'progress-bar__progress--active';
      }
      return `progress-bar__progress ${isFirst} ${isLast} ${isActive} ${isCompleted}`;
    };
    return !isThankyou ? (
      <StyledTracker
        className="progress-bar"
        onMouseEnter={debounce(this.showFullInfo, 250)}
        onMouseLeave={this.hideFullInfo}
        onMouseOut={debounce(this.mouseOutListener, 250)}
        isShown={isShown}
        length={currentStatuses.length}
      >
        <div className="progress-bar__closed-info progress-bar__closed-info--hovered">
          <p className="progress-bar__info progress-bar__info--hovered">{translate('OrderState')} № {lastActiveOrder.id} | {dateToShow}</p>
          <p className="progress-bar__status progress-bar__status--hovered">{translate('OrderStatus')}: {activeStatus[title]}</p>
        </div>
        <div className={isHovered ? 'progress-bar__line progress-bar__line--hovered' : 'progress-bar__line'}>
          {!isHovered ? (
            <div className="progress-bar__closed-info progress-bar__closed-info--not-active">
              <p className="progress-bar__info">{translate('OrderState')} № {lastActiveOrder.id} | {dateToShow}</p>
              <p className="progress-bar__status">{translate('OrderStatus')}: {activeStatus[title]}</p>
            </div>
          ) : null}
          {currentStatuses && currentStatuses[0] ? currentStatuses.map((status, i) => (
            <div className={isHovered ? 'progress-bar__block progress-bar__block--hovered' : 'progress-bar__block'} key={status.id}>
              <p className="progress-bar__action">
                {`${status[title]} `}
                {i === 0 ? createdTime : null}
                {i === currentStatuses.length - 1 ? deliveryTime : null}
              </p>
              <div
                className={checkClassName(i, currentStatuses.length - 1)}
              />
            </div>
          )) : null}
        </div>
      </StyledTracker>
    ) : null;
  }
}

const StyledTracker = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --disabled-text-color: #313131;
  --bar-background: #000000;
  --closed-text-color: #C4C4C4;
  
  background-color: var(--bar-background);
  color: var(--text-color);
  height: 14px;
  width: 100%;
  z-index: 26;
  position: relative;
  align-items: center;
  cursor: pointer;
  top: ${props => (props.isShown ? '0' : '-46px')};
  transition: .3s;
  
  .progress-bar {
    &__heading {
      text-transform: none;
      font-size: 1rem;
      font-weight: 600;
      min-width: 7.5rem;
      text-align: left;
      margin-bottom: .5rem;
    }
    
    &__line {
      display: flex;
      width: 100%;
      background-color: var(--bar-background);
      padding-top: .75rem;
      position: relative;
      
      &--hovered {
        width: 100%;
        padding: .75rem 0;
      }
    }
    
    &__block {
      flex-grow: 1;
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 14;
      max-width: calc(100% /  ${(props) => props.length});
      
      &--hovered {
        &:not(:last-child)::after {
          content: '';
          display: block;
          height: 50%;
          position: absolute;
          bottom: -1px;
          right: 0;
          width: 2px;
          background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
          background-size: 2px 8px;
        }
      }
    }
    
    &__action {
      padding-bottom: .3rem;
      margin-left: .5rem;
      flex-grow: 1;
      font-size: .75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 0;
    }
    
    &__progress {
      height: 14px;
      width: 100%;
      background-color: var(--disabled-text-color);
      position: relative;
      overflow: hidden;
      
      &--first {
        border-radius: 4px 0 0 4px;
      }
      
      &--last {
        border-radius: 0 4px 4px 0;
      }
      
      &--active {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 70%;
          background-color: var(--text-color);
          border-radius: 0 4px 4px 0;
        }
      }
      
      &--hovered {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 50%;
          background-color: var(--text-color);
          border-radius: 0 4px 4px 0;
        }
      }
      
      &--completed {
        background-color: var(--text-color);
      }
    }
    
    &__closed-info {
      position: absolute;
      color: var(--closed-text-color);
      z-index: 15;
      width: 100%;
      font-size: 11px;
      height: 14px;
      bottom: 0;
      justify-content: space-between;
      padding: 0 .2rem;
      display: flex;
      
      &--hovered {
        position: relative;
        padding: .5rem .2rem;
        background-color: var(--bar-background);
      }
    }
    
    &__info {
      font-size: 9px;
      
      &--hovered {
        font-size: 9px;
      }
    }
    
    &__status {
      &--hovered {
        font-size: 11px;
      }
    }
  }
  
@media all and (min-width: 360px) {
  .progress-bar {
    &__info {
      font-size: 11px;
      
      &--hovered {
        font-size: 11px;
      }
    }
  }
  
  &:hover {
    & .progress-bar {
      font-size: 11px;
    }
  }
}
  
@media all and (min-width: 530px) {
  top: ${props => (props.isShown ? '0' : '-46px')};
}
  
@media all and (min-width: 768px) {
  top: ${props => (props.isShown ? '0' : '-98px')};
  
  .progress-bar {
    &__heading {
      margin-bottom: 0;
    }
    &__line {
     padding-top: 2.5rem;
      
      &--hovered {
        padding: 1.25rem 2rem;
      }
    }
    
    &__block {
      overflow: hidden;
      &--hovered {
        &:not(:last-child)::after {
          height: 100%;
          bottom: 0;
        }
      }
    }
    
    &__action {
      font-size: 1rem;
      opacity: 1;
      padding-bottom: 0;
    }
    
    &__closed-info {
      padding: 0 7rem;
      &--hovered {
        padding: 1.25rem 7rem;
      }
    }
    
    &__info {
      font-size: 11px;
      
      &--hovered {
        font-size: 15px;
      }
    }
    
    &__status {
      &--hovered {
        font-size: 15px;
      }
    }
  }
}
@media all and (min-width: 1024px) {
  .progress-bar {
    &__line {
      
      &--hovered {
        padding: 1.25rem 7rem;
      }
    }
  }
}

`;

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
});

export default connect(mapStateToProps)(withRouter(HeaderTracker));
