import React from 'react';
import getDeliveryMethodById from '../../../modules/getDeliveryMethodById';
import getPaymentMethodById from '../../../modules/shared/getPaymentMethodById';
import keyByLang from '../../../modules/shared/keyByLang';

const ProfileActiveOrderTimeLine = (
  {
    status,
    statuses,
    deliveryMethodId,
    paymentMethodId,
    deliveryMethods,
    paymentMethods,
    currentLanguage,
    createdTime,
    deliveryTime,
    currentStatuses
  },
) => {
  const currentPaymentMethod = getPaymentMethodById(paymentMethodId, paymentMethods);

  const title = keyByLang('title', currentLanguage);

  const orderTimeLineHeadItems = currentStatuses[0] ? currentStatuses.map((statusData, i) => {
    let text = statusData[title];
    if (i === 0) {
      text = `${statusData[title]} ${createdTime}`;
    } else if (i === currentStatuses.length - 1) {
      text = `${statusData[title]} ${deliveryTime}`;
    }
    return (
      <div key={statusData.id} className="active-order__head-item">
        <p className="active-order__status-text">{text}</p>
      </div>
    );
  }) : null;

  const orderTimeLineVisualItems = currentStatuses && currentStatuses[0]
    ? currentStatuses.map(statusData => (
      <div
        key={statusData.id}
        className={`active-order__list-item ${parseInt(statusData.id, 10) === parseInt(status.id, 10) ? 'active-order__list-item--active' : ''}`}
      />
    )) : null;

  /* const orderTimeLineHeadItems = statuses.map(statusItem => ((parseInt(currentDeliveryMethod.id) === 1) && (parseInt(statusItem.id) !== 4)
    ? (
      <div key={statusItem.id} className="active-order__head-item">{statusItem.title_ru}</div>
    ) : (parseInt(currentDeliveryMethod.id) === 2) && (parseInt(statusItem.id) !== 3)
      ? (
        <div key={statusItem.id} className="active-order__head-item">{statusItem.title_ru}</div>
      )
      : null));

  const orderTimeLineVisualItems = statuses.map(statusItem => ((parseInt(currentDeliveryMethod.id) === 1) && (parseInt(statusItem.id) !== 4)
    ? <div key={statusItem.id} className={`active-order__list-item ${parseInt(statusItem.id) === parseInt(status.id) ? 'active-order__list-item--active' : ''}`} />
    : (parseInt(currentDeliveryMethod.id) === 2) && (parseInt(statusItem.id) !== 3)
      ? <div key={statusItem.id} className={`active-order__list-item ${parseInt(statusItem.id) === parseInt(status.id) ? 'active-order__list-item--active' : ''}`} />
      : null)); */

  return (
    <div className="active-order__timeline delivery_mode">
      <div className="active-order__timeline-head">
        {orderTimeLineHeadItems}
      </div>
      <div className="active-order__timeline-list">
        {orderTimeLineVisualItems}
      </div>
    </div>
  );
};

export default ProfileActiveOrderTimeLine;
