import React from 'react';
import styled from 'styled-components';

import PageHeaderBlock from '../shared/PageHeaderBlock';
import TitleBlock from '../shared/TitleBlock';
import headerImage from '../../assets/images/faq_header.jpg';
import TopicsList from './TopicsList';
import QuestionsList from './QuestionsList';
import AnswerBlock from './AnswerBlock';
import MainBtn from '../shared/MainBtn';
import FaqForm from './FaqForm';
import fixImagePath from "../../modules/shared/fixImagePath";

class FaqPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeTopicId: null,
      activeTopicTitle: null,
      activeQuestionId: null,
      isFaqFormVisible: false,
      storeId: null,
    };
    this.topicClickHandler = this.topicClickHandler.bind(this);
    this.questionClickHandler = this.questionClickHandler.bind(this);
    this.showHideFormHandler = this.showHideFormHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.chooseStoreId = this.chooseStoreId.bind(this);
  }

  topicClickHandler = (id, title) => {
    if (parseInt(id, 10) !== parseInt(this.state.activeTopicId, 10)) {
      this.setState(prevState => ({
        ...prevState,
        activeTopicId: id,
        activeTopicTitle: title,
      }));
    }
  };

  questionClickHandler = (id) => {
    if (parseInt(id, 10) !== parseInt(this.state.activeQuestionId, 10)) {
      this.setState(prevState => ({
        ...prevState,
        activeQuestionId: id,
      }));
    }
  };

  showHideFormHandler = () => {
    this.setState(prevState => ({
      isFaqFormVisible: !prevState.isFaqFormVisible,
    }));
  };

  chooseStoreId(id) {
    this.setState({ storeId: id });
  }

  submitForm(data, func, form) {
    const { translate } = this.props;
    const dataToSend = {
      'Reviews[review_type_id]': this.state.activeTopicId,
      'Reviews[order_num]': data.order || '',
      'Reviews[restaurant_id]': this.state.storeId,
      'Reviews[email]': data.email,
      'Reviews[phone]': data.phone,
      'Reviews[name]': data.userName,
      'Reviews[message]': data.message,
    };
    this.props.sendReview(dataToSend).then((res) => {
      if (res && res.status) {
        this.props.showSnackBar(translate('FeedbackWasSent'), 3000, true);
        form.reset();
      } else {
        this.props.showSnackBar(translate('FeedbackNotSent'), 4000, false);
      }
    });
  }

  render() {
    const {
      faq, currentLanguage, stores, languagePrefix, settings, redirectToVacancies, translate,
    } = this.props;
    const {
      activeTopicId, activeQuestionId, activeTopicTitle, isFaqFormVisible,
    } = this.state;

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find(page => page.page_const === 'feedback');
      bannerImagePath = fixImagePath(pageData);
    }

    return (
      <StyledFaqPage className="page faq">
        <PageHeaderBlock image={bannerImagePath || headerImage} addClass="" />
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="main-title__wrap">
                  <TitleBlock title={translate('HowCanWeHelp')} />
                  <hr className="solid" />
                </div>
                <TopicsList
                  faq={faq}
                  currentLanguage={currentLanguage}
                  clickHandler={this.topicClickHandler}
                  redirectToVacancies={redirectToVacancies}
                />
                {activeTopicTitle ? (
                  <h3 className="page__title">{activeTopicTitle}</h3>
                ) : null}
                <div className="question-list question-list--animated">
                  <QuestionsList
                    activeTopicId={activeTopicId}
                    faq={faq}
                    currentLanguage={currentLanguage}
                    questionClickHandler={this.questionClickHandler}
                  />
                </div>
                <AnswerBlock
                  faq={faq}
                  activeTopicId={activeTopicId}
                  currentLanguage={currentLanguage}
                  activeQuestionId={activeQuestionId}
                />
                {isFaqFormVisible ? (
                  <div className="form-wrap">
                    <FaqForm
                      onSubmit={this.submitForm}
                      faq={faq}
                      stores={stores}
                      currentLanguage={currentLanguage}
                      activeTopic={activeTopicTitle}
                      activeTopicId={activeTopicId}
                      languagePrefix={languagePrefix}
                      chooseStoreId={this.chooseStoreId}
                      translate={translate}
                    />
                  </div>
                ) : null}
                <div className="button-wrap">
                  {activeQuestionId && !isFaqFormVisible ? (
                    <MainBtn
                      align="center"
                      text={translate('FeedbackFormButton')}
                      clickHandle={this.showHideFormHandler}
                      addClass="show-hide-form-btn"
                      iconName="store"
                      animated
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledFaqPage>
    );
  }
}

const StyledFaqPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  .page__title {
    text-transform: none;
    font-size: 1.25rem;
    text-align: center;
    padding: 40px 0 20px;
    font-weight: 600;
  }

  &.page {
    .container {
      max-width: 730px;
      width: 100%;
    }
  }

  .show-hide-form-btn {
    margin-bottom: 20px;
  }
  
  .main-title__wrap {
    padding: 20px 0;
  }
  
  .page-content {
    flex-grow: 1;
  }
  
  .button-wrap {
    padding: 20px;
  }
  
  .form-wrap {
    max-width: 440px;
    margin: 0 auto;
    padding: 20px 0;
    box-sizing: border-box;
    
    button {
      min-width: 12.5rem;
    }
  }
`;

export default FaqPage;
