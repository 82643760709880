import React from 'react';

import sale from '../../assets/icons/sale.svg';
import vegan from '../../assets/icons/vegan.svg';
import newIcon from '../../assets/icons/new.svg';
import hit from '../../assets/icons/hit.svg';
import spicy from '../../assets/icons/spicy.svg';

function getIconByTag(tag) {
  switch (tag) {
    case '27':
      return hit;
    case '28':
      return newIcon;
    case '29':
      return vegan;
    case '30':
      return sale;
    case '31':
      return spicy;
    default:
      return null;
  }
}

export default getIconByTag;
