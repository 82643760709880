import moment from 'moment';

const getTimeToCompare = (time) => {
  const hours = time.getHours();
  let minutes = time.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return +`${hours}${minutes}`;
};

const filterAddonsByTime = (addons) => {
  const currentTime = moment();
  return addons.filter((addon) => {
    if (
      (!parseInt(addon.addons_since_hour, 10) && addon.addons_since_hour.length === 1
        && !parseInt(addon.addons_since_minute, 10) && addon.addons_since_minute.length === 1)
      || (!parseInt(addon.addons_till_hour, 10) && addon.addons_till_hour.length === 1
        && !parseInt(addon.addons_till_minute, 10) && addon.addons_till_minute.length === 1)
      || `${addon.addons_since_hour}${addon.addons_since_minute}` === `${addon.addons_till_hour}${addon.addons_till_minute}`
    ) {
      return true;
    }
    if (addon.addons_since_hour && addon.addons_till_hour
      && addon.addons_since_minute && addon.addons_till_minute) {
      return currentTime > moment().set({
        hour: addon.addons_since_hour,
        minute: addon.addons_since_minute,
      })
          && currentTime < moment().set({
            hour: addon.addons_till_hour,
            minute: addon.addons_till_minute,
          });
    }
    return true;
  });
};

export default filterAddonsByTime;
