import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setActiveLanguage, getLanguages, getActiveLanguage } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as actions from '../actions/actions';

const languageAlias = (currentLang) => (currentLang === 'ru' ? '' : currentLang);

const languageBtnClickHandler = (code, actions, pathNameStr, activeLanguage, activeAlias) => {
  const codeRU = 'ru';
  const codeUA = 'ua';
  const pathNameArr = pathNameStr.split('/');
  const tempPathNameArr = [];
  let newPathNameArr = [];
  let newPathNameStr = '';

  if (activeLanguage === code) {
    return false;
  }

  // если текущий язык РУ а выбрали УКР

  if (activeLanguage === codeRU && code === codeUA) {
    pathNameArr.shift();
    tempPathNameArr.push('');
    tempPathNameArr.push(code);
    newPathNameArr = tempPathNameArr.concat(pathNameArr);
    newPathNameStr = newPathNameArr.join('/');
  }

  // если текущий язык УКР а выбрали РУ

  if (activeLanguage === codeUA && code === codeRU) {
    pathNameArr.shift();
    pathNameArr.shift();
    newPathNameArr = pathNameArr;
    newPathNameArr.unshift('');
    newPathNameStr = newPathNameArr.join('/');
  }

  actions.changeLanguage(code);
  actions.changeLocale(newPathNameStr);
};

class LanguageSelector extends React.PureComponent {
  render() {
    const {
      languages, actions, router, activeLanguage, activeAlias,
    } = this.props;
    const pathNameStr = this.props.location.pathname;
    return (
      <StyledList>
        {languages.map((language) => (
          <li key={language.code} className="language">
            <input type="radio" id={`lang-${language.code}`} name="language-select" className="language__input" hidden />
            <label
              className={activeLanguage === language.code
                ? 'language__button language__button--active'
                : 'language__button'}
              htmlFor={`lang-${language.code}`}
              onClick={() => languageBtnClickHandler(
                language.code,
                actions,
                pathNameStr,
                activeLanguage,
                activeAlias,
              )}
            >
              {language.code}
            </label>
          </li>
        ))}
      </StyledList>
    );
  }
}

const StyledList = styled.ul`
  display: flex;
  position: relative;
  font-size: 14px;
  padding: 1rem .5rem;
  box-sizing: border-box;
  border-radius: 4px;
  transition: background-color .3s ease-out;

  &:hover {
    background-color: #222;
  }
  
  .language {
    padding: 0 .5rem;
    
    &__button {
      text-transform: uppercase;
      border : none;
      background-color: transparent;
      color: #999;
      cursor: pointer;
      transition: color .3s ease-out;
      
      &--active {
        color: #FFFFFF;
      }

      &:hover {
        color: #E74E1A;
      }
    }    
  }
`;


const mapStateToProps = (state) => ({
  languages: getLanguages(state.locale),
  router: state.router ? state.router : null,
  activeLanguage: getActiveLanguage(state.locale).code,
  activeAlias: languageAlias(getActiveLanguage(state.locale).code),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LanguageSelector));
