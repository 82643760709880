import React from 'react';
import styled from 'styled-components';

class CheckoutInput extends React.Component {
  /* componentDidUpdate() {
    if (this.props.meta.error && this.props.showSnackBar) {
      this.props.showSnackBar(this.props.meta.error, 3000);
    }
  } */

  render() {
    const {
      icon, label, storeOnFocus, storeOnChange, placeholder, inputValue, storeRef,
    } = this.props;
    return (
      <StyledInput className="styled-input">
        <input
          ref={storeRef}
          type="text"
          className={inputValue
            ? 'styled-input__field styled-input__field--not-empty'
            : 'styled-input__field styled-input__field--empty'
          }
          onFocus={storeOnFocus}
          onChange={storeOnChange}
          value={inputValue}
        />
        <div className="styled-input__image-wrap">
          <img className="styled-input__image" src={icon} alt="icon" decoding="async" loading="lazy" />
        </div>
        <label className="styled-input__label" htmlFor={label}>{placeholder}</label>
        <div className="styled-input__line" />
      </StyledInput>
    );
  }
}

export default CheckoutInput;

const StyledInput = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  background-color: #1C1C1C;
  border-radius: 4px;
  border: 1px solid ${props => (props.error ? 'red' : '#313131')};
  width: 100%;
  
  .styled-input {
    &__field {
      height: 100%;
      background: inherit;
      border: none;
      width: 100%;
      padding-left: 77px;
      color: #8e8e8e;
      font-size: 15px;
      padding-top: 17px;
      padding-right: 15px;
      outline-color: ${props => (props.error ? 'red' : '')};
      
      &:focus ~ .styled-input__label,
      &--not-empty ~ .styled-input__label {
        transform: scale(0.85) translateY(-150%);
        color: ${props => (props.error ? 'red' : '#616161')};
      }
    }
    
    &__label {
      position: absolute;
      top: 50%;
      left: 77px;
      font-size: 13px;
      color: #616161;
      transform: translateY(-50%);
      transition: all 0.15s ease-in-out;
      transform-origin: left center;
      pointer-events: none;
    }
    
    &__line {
      position: absolute;
      top: 50%;
      left: 60px;
      height: 75%;
      width: 2px;
      transform: translateY(-50%);
      background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
      background-size: 100% 8px;
    }
    
    &__image-wrap {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translate(-50%,-50%);
    }
    
    &__image {
      opacity: .5;
    }
  }
  
  @media all and (min-width: 360px) {
    .styled-input {
      &__label {
        font-size: 15px;
      }
    }
  }
`;
