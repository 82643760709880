/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';

import AnimatedButton from '../shared/buttons/AnimatedButton';
import keyByLang from '../../modules/shared/keyByLang';

import {
  filterIcon, filterDone, filterClose,
} from '../../assets/icons/svgIcons';

class CatalogFilterModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.filterNames = {
      allergens: 'allergens_',
      ingredients: 'ingredients_',
    };

    this.state = {
      currentFilterData: { ...this.props.filterData },
    };
  }

  changeFilterData = (id) => {
    this.setState(prevState => ({
      currentFilterData: {
        ...prevState.currentFilterData,
        [id]: !prevState.currentFilterData[id],
      },
    }));
  }

  delegateFilterFields = (currentState) => {
    const keys = Object.keys(this.props.filterData);
    const checkedAllFields = keys.reduce((result, key) => {
      result[key] = currentState;
      return result;
    }, {});
    this.setState({
      currentFilterData: checkedAllFields,
    });
  }

  handleFilterSubmit = () => {
    this.props.setProductsFilterData(this.state.currentFilterData);
    this.props.closeFilter();
  }

  render() {
    const {
      ingredients,
      allergens,
      currentLanguage,
      filterNames,
      translate,
    } = this.props;
    const title = keyByLang('title', currentLanguage);

    const createListOfCheckboxes = (checkboxesList, type) => checkboxesList.map((checkboxData) => {
      const currentID = `${type}${checkboxData.id}`;
      return (
        <div className="filter__checkbox-wrap" key={currentID}>
          <input
            className="filter__input"
            type="checkbox"
            id={currentID}
            checked={this.state.currentFilterData[currentID]}
            onChange={() => this.changeFilterData(currentID)}
          />
          <label className="filter__label" htmlFor={currentID}>{checkboxData[title]}</label>
        </div>
      );
    });

    return (
      <StyledFilter className="filter">
        <header className="filter__header">
          <div className="filter__title-wrap">
            {filterIcon}
            <h3 className="filter__title">{translate('Filter')}</h3>
          </div>
          <div className="filter__controls-wrap">
            <button
              className="filter__controls filter__controls--done"
              type="button"
              onClick={() => this.delegateFilterFields(true)}
            >
              {filterDone} {translate('ChooseAll')}
            </button>
            <button
              className="filter__controls filter__controls--clear"
              type="button"
              onClick={() => this.delegateFilterFields(false)}
            >
              {filterClose} {translate('CancelSelection')}
            </button>
          </div>
        </header>
        <div className="filter__content">
          <h3 className="filter__field-name">{translate('MainIngredients')}</h3>
          <div className="filter__inputs-wrap">
            {createListOfCheckboxes(ingredients, filterNames.ingredients)}
          </div>
          {/*<h3 className="filter__field-name">{translate('Allergens')}</h3>*/}
          {/*<div className="filter__inputs-wrap">*/}
          {/*  {createListOfCheckboxes(allergens, filterNames.allergens)}*/}
          {/*</div>*/}
          <div className="filter__button-wrap">
            <AnimatedButton
              text={translate('ApplyFilterButton')}
              icon={filterDone}
              handleClick={this.handleFilterSubmit}
            />
          </div>
        </div>
      </StyledFilter>
    );
  }
}

const StyledFilter = styled.div`
  --filter-background: #0A0A0A;
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --hover-color: #E74E1A;
  --transition-time: .15s;

  background-color: var(--filter-background);
  padding-bottom: 4rem;

  .filter {
  
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 0 0 1rem;
      position: relative;
      max-width: 1500px;
      margin: 0 auto;
    }
    
    &__title-wrap {
      display: flex;
    }
    
    &__controls-wrap {
      padding: 1rem 0;
      display: flex;
    }
    
    &__controls {
      flex-basis: 50%;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      color: var(--text-color);
      font-size: .9rem;
      text-align: left;
      padding: 0;
      outline: none;
      
      svg {
        height: .9rem;
      }
      
      &:hover {
        cursor: pointer;
        color: var(--active-text-color);
        transition: .s;
        
        &.filter__controls--done {
          path {
            stroke: var(--active-text-color);
          }
        }
        
        &.filter__controls--clear {
          
          path {
            fill: var(--active-text-color);
          }
        }
      }
      
      &--done {
        path {
          stroke: var(--text-color);
        }
      }
      
      &--clear {
        path {
          fill: var(--text-color);
        }
      }
    }
    
    &__title {
      text-transform: uppercase;
      color: var(--active-text-color);
      font-weight: 100;
      font-size: 1rem;
      padding-left: 15px;
      padding-bottom: 20px;
    }
    
    &__content {
      max-width: 1500px;
      height: 100%;
      margin: 0 auto;
      padding: 15px;
    }
    
    &__field-name {
      color: var(--text-color);
      font-size: 1.4rem;
      font-weight: 100;
      text-transform: none;
      text-align: center;
    }
    
    &__inputs-wrap {
       padding: 20px 0 40px;
       color: var(--text-color);
       font-size: 1.2rem;
       display: flex;
       flex-direction: column;
       flex-wrap: wrap;
       justify-content: flex-start;
    }
    
    &__label {
      margin: 10px 0;
      display: flex;
      
      &:hover {
        cursor: pointer;
        color: var(--active-text-color);
        
        &::before {
          border-color: var(--active-text-color);
        }
      }
      
      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid var(--text-color);
        border-radius: 5px;
        padding: 2px;
        margin-right: 4px;
      }
    }
    
    &__input {
      display: none;
    
      &:checked + .filter__label::before {
        background-color: var(--text-color);
        background-clip: content-box;
      }
    }
    
    &__button-wrap {
      position: fixed;
      bottom: 10px;
      z-index: 12;
      left: 0;
      padding: 0 15px;
      width: 100%;
      
      .button {
        width: 100%;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
        border-radius: 4px;
      }
    }
  }

@media all and (min-width: 769px) {
  .filter {
    &__header {
      flex-direction: row;
      padding: 90px 100px 0;
    }
    
    &__content {
      padding: 90px 100px 0;
    }
    
    &__controls-wrap {
      flex-direction: row;
      padding: 0;
    }
    
    &__controls {
      font-size: 1rem;
      flex-basis: auto;
      margin-left: 30px;
    }
    
    &__field-name {
      font-size: 2rem;
    }
    
    &__label {
      margin: 0 30px 10px 0;
    }
    
    &__button-wrap {
      position: static;
      padding: 0;
      width: auto;
      
      .button {
        border-radius: 4px;
        box-shadow: none;
        width: auto;
      }
    }
    
    &__inputs-wrap {
      padding: 40px 0 50px;
      flex-direction: row;
    }
  }
}

@media all and (min-width: 1740px) {
  .filter {
    &__header {
      padding: 80px 15px;
      flex-direction: row;
    }
    
    &__label {
      margin: 0 40px 30px 0;
    }
    
    &__content {
      padding: 80px 15px;
    }
  }
}
`;

export default React.memo(CatalogFilterModal);
