const getProductByAlias = (alias, categoriesObj) => {
  const matchProductObj = {};
  // eslint-disable-next-line no-unused-expressions
  (categoriesObj && alias) ? (
    categoriesObj.map(catItem => catItem.products.map(catItemKey => ((alias === catItemKey.alias)
      ? (Object.assign(
        matchProductObj,
        catItemKey,
        {
          parentCatId: catItem.id,
          parent_title_ru: catItem.title_ru,
          parent_title_ua: catItem.title_ua,
        },
      )
      )
      : null)))
  )
    : false;
  return matchProductObj;
};
export default getProductByAlias;
