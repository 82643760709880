/* eslint-disable no-plusplus */
import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../../modules/shared/keyByLang';

import { arrowUp, arrowDown } from '../../../assets/icons/svgIcons';

class AccessorieComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropdownList = React.createRef();
    this.activeItem = React.createRef();

    this.scrollToActive = this.scrollToActive.bind(this);
  }

  scrollToActive() {
    this.activeItem.current.scrollIntoView({ block: 'center' });
  }

  render() {
    const {
      accessory,
      currentLanguage,
      toggleDropdown,
      activeDropdown,
      changeAccessoryCount,
      countFreeAccessoriesById,
      freeAccessoriesAverage,
      order,
    } = this.props;

    const title = keyByLang('title', currentLanguage);

    let currentCount = 0;

    if (order && order.freeAccessories) {
      currentCount = order.freeAccessories[accessory.id]
        + (order.extraAccessories[accessory.id] || 0);
    }

    const availableFreeCount = freeAccessoriesAverage[accessory.accessory_type_id]
      - countFreeAccessoriesById(accessory.id, accessory.accessory_type_id);

    let currentData;

    if (order && order.extraAccessories && order.extraAccessories[accessory.id]) {
      currentData = `${currentCount} (+${order.extraAccessories[accessory.id] * accessory.price}грн)`;
    } else {
      currentData = currentCount;
    }

    const createDropdown = (price, id) => {
      const dropdownItems = [];
      const quantity = (id === '29' || id === '30') ? 21 : 101;
      for (let i = 0; i < quantity; i++) {
        dropdownItems.push(
          <li
            key={`${accessory.title_ru}${id}${i}`}
            className={
              currentCount === i
                ? 'accessorie__add accessorie__add--active'
                : 'accessorie__add'
            }
            ref={currentCount === i ? this.activeItem : null}
            onClick={() => changeAccessoryCount({ id, num: i })}
          >
            {i <= availableFreeCount ? i : `${i} (+${(i - availableFreeCount) * price}грн)`}
          </li>,
        );
      }
      return dropdownItems;
    };

    return (
      <>
        <StyledAccessorie className="accessorie">
          <img
            src={accessory.accessory_type_image}
            alt={accessory[title]}
            className="accessorie__image"
            decoding="async"
            loading="lazy"
          />
          <p className="accessorie__title">{accessory[title]}</p>
          <div className="accessorie__dropdown-wrap">
            <div
              className={activeDropdown
                ? 'accessorie__dropdown accessorie__dropdown--active'
                : 'accessorie__dropdown'}
              onClick={() => {
                toggleDropdown(accessory.id);
                this.scrollToActive();
              }}
            >
              <p className="accessorie__quantity">{currentData}</p>
              <div className="accessorie__icon">{activeDropdown ? arrowDown : arrowUp}</div>
            </div>
            <ul className="accessorie__add-list" ref={this.dropdownList}>
              {createDropdown(
                accessory.price,
                accessory.id,
              )}
            </ul>
          </div>
        </StyledAccessorie>
      </>
    );
  }
}

const StyledAccessorie = styled.li`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --dropdown-background: #1C1C1C;
  --border-color: #313131;

  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding: .6rem 0;
  box-sizing: content-box;

  .accessorie {

    &__image {
      width: 40px;
      height: 40px;
      object-fit: contain;
      overflow: hidden;
    }

    &__figure {
      display: flex;
      align-items: center;
    }

    &__image-wrap {
      display: flex;
      align-items: center;

    }

    &__title {
      color: var(--active-text-color);
      padding: 0 .8rem;
      flex-grow: 1;
    }

    &__dropdown-wrap {
      width: 100%;
      position: relative;
      max-width: 120px;
    }

    &__dropdown {
      width: 100%;
      display: flex;
      padding: .7rem;
      background-color: var(--dropdown-background);
      border-radius: 4px;
      border: 1px solid var(--border-color);
      transition: .15s;

      &:hover {
        cursor: pointer;
        background-color: var(--border-color);
        color: var(--active-text-color);
      }

      &--active + .accessorie__add-list {
        opacity: 1;
        pointer-events: unset;
      }
    }

    &__quantity {
      width: 100%;
    }

    &__add-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: var(--dropdown-background);
      z-index: 11;
      border-radius: 0 0 4px 4px;
      overflow-y: scroll;
      height: 175px;
      -ms-overflow-style: none; // IE 10+
      overflow: -moz-scrollbars-none; // Firefox
      opacity: 0;
      pointer-events: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #777;
      }

    }

    &__add {
      padding: .5rem;
      border-bottom: 1px solid var(--border-color);
      transition: .15s;

      &--active,
      &:hover {
        background-color: var(--border-color);
        color: var(--active-text-color);
      }

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media all and (min-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    width: 100%;
    flex-grow: 1;
    padding: 1rem;
    box-sizing: content-box;

    .accessorie {
      &__figure {
        flex-direction: column;
      }

      &__dropdown-wrap {
        margin-top: 1rem;
        max-width: unset;
      }

      &__title {
        padding: 1rem 0 0;
      }
    }
  }

  @media all and (min-width: 768px) {
    .accessorie {
      
      &__image {
        width: 60px;
        height: 60px;
      }
    }
  }
  
  @media (min-width: 1024px) {
    .accessorie {

      &__title {
        flex-grow: unset;
      }
      
      &__image {
        width: 100%;
        height: unset;
        flex-grow: 1;
      }
    }
  }
`;

export default AccessorieComponent;
