import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../../modules/shared/keyByLang';

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelectOpen: false,
    };

    this.wrapper = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapper = node;
  };

  handleClickOutside = (event) => {
    if (!this.wrapper.contains(event.target) && this.state.isSelectOpen) {
      this.setState({
        isSelectOpen: false,
      });
    }
  };

  showHideSelectHandler = () => {
    this.setState(prevState => ({
      isSelectOpen: !prevState.isSelectOpen,
    }));
  };

  render() {
    const {
      options, input, label, icon, language, initialValue, chooseItemId,
    } = this.props;
    const { onChange, value, onBlur } = input;
    const { isSelectOpen } = this.state;
    const { error, touched } = this.props.meta;

    const title = keyByLang('title', language);
    let chosenOption = null;

    if (options && initialValue && !value) {
      chosenOption = (<p className="choosed-option">{initialValue}</p>);
    } else if (value) {
      chosenOption = (<p className="choosed-option">{value}</p>);
    }

    const selectVisibilityClass = isSelectOpen ? 'open' : 'hide';

    const selectIcon = icon
      ? (
        <div className="input-icon">
          <img className="input-icon__image" src={icon} alt="icon" decoding="async" loading="lazy" />
        </div>
      )
      : null;

    const selectIconLine = icon
      ? <div className="input-line" />
      : null;

    if (options) {
      return (
        <StyledSelect
          className={`${selectVisibilityClass} ${icon ? '' : 'default'}`}
          error={error && touched && !isSelectOpen}
        >
          <div
            className={`select-head ${value.length || initialValue ? 'not-empty' : 'empty'}`}
            ref={this.setWrapperRef}
            onClick={() => {
              onChange(value);
              onBlur();
              this.showHideSelectHandler();
            }}
          >
            <div className="label">
              {label}
            </div>
            {selectIcon}
            {selectIconLine}
            {chosenOption}
            <div className="tick" />
          </div>
          <div className="select-body">
            <ul className="select-list">
              {options.map(item => (
                <li
                  key={item.id}
                  className="select-list-item"
                  onClick={() => {
                    onChange(item[title]);
                    this.showHideSelectHandler();
                    if (chooseItemId) {
                      chooseItemId(item.id);
                    }
                  }}
                >
                  {item[title]}
                </li>
              ))}
            </ul>
          </div>
        </StyledSelect>
      );
    }
    return null;
  }
}

const StyledSelect = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  background-color: #1C1C1C;
  margin-bottom: 20px;
    
  .select-head {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #313131;
    border: 1px solid ${props => (props.error ? 'red' : '#313131')};

    &.not-empty .label {
      transform: scale(0.85) translateY(-150%);
    }
  }
    
  .choosed-option {
    height: 100%;
    width: 100%;
    padding-left: 77px;
    padding-top: 28px;
    color: #8e8e8e;
  }
  .tick {
    position: absolute;
    top: 55%;
    right: 16px;
    transform: translateY(-50%) rotate(-135deg);
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      height: 1px;
      background: #616161;
    }
    &:after {
      position: absolute;
      content: "";
      display: block;
      height: 6px;
      width: 1px;
      background: #616161;
    }
  }

  .label {
    position: absolute;
    top: 50%;
    left: 77px;
    font-size: 15px;
    color: #616161;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
    transform-origin: left center;
    pointer-events: none;
  }

  .select-body {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    display: none;
    background-color: #1C1C1C;
    border: 1px solid #313131;
    border-radius: 4px;
    box-shadow: 0px 15px 10px -10px rgba(0,0,0, 0.5);
    z-index: 1;

    .select-list {
      padding: 0;
      
      .select-list-item {
        -webkit-appearance: none;
        background-color: transparent;
        box-shadow: none;
        border: none;
        color: #8e8e8e;
        cursor: pointer;
        display: block;
        width: 100%;
        margin: 0;
        padding: 8px 15px;
        text-align: left;
        
        &:hover {
          background-color: #2C2C2C;
        }
      }
     }
   }

  .input-icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%,-50%);
    
    &__image {
      opacity: .6;
    }
  }

  .input-line {
    position: absolute;
    top: 50%;
    left: 60px;
    height: 75%;
    width: 2px;
    transform: translateY(-50%);
    background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
    background-size: 100% 8px;
  }

  &.open {
    .select-head {
      .tick {
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
      }
    }
    .select-body {
      display: block;
    }
  }

  &.default {
    .select-head {
      .choosed-option {
        padding-left: 15px;
      }
    }
    .label {
      left: 15px;
    }
  }
`;

export default Select;
