import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage, Translate, getTranslate } from 'react-localize-redux';
import Lottie from 'react-lottie';

import { Link } from 'react-router-dom';
import MainBtn from '../shared/MainBtn';
import * as storage from '../../utils/storage';

import shop from '../../assets/icons/word_shop.svg';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import * as actions from '../../actions/actions';
import keyByLang from '../../modules/shared/keyByLang';
import ModalWrapper from '../shared/ModalWrapper';
import DeliveryForm from '../delivery/DeliveryForm';

import store from '../../assets/icons/form/pickup.svg';
import { pickUpIcon } from '../../assets/icons/lottieIcons';

class StoresDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      isStoreModalActive: false,
    };

    this.storeOnChange = this.storeOnChange.bind(this);
    this.findClosestStores = this.findClosestStores.bind(this);
    this.calculateDistance = this.calculateDistance.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sortStoresByDistance = this.sortStoresByDistance.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return this.props.actions.getStores();
  }

  toggleModal() {
    this.setState(prevState => ({
      isStoreModalActive: !prevState.isStoreModalActive,
    }));
  }

  updateAddress(e) {
    this.props.actions.updateDelivery(JSON.parse(storage.session.getItem('takeOutStore')).address);
    this.toggleModal();
    this.props.closeDropdown();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  sortStoresByDistance(storesArr, x, y) {
    return storesArr.sort((store1, store2) => {
      const currentDistance = this.calculateDistance(
        +store1.geoposition.split(',')[0] - x,
        +store1.geoposition.split(',')[1] - y,
      );
      const prevDistance = this.calculateDistance(
        +store2.geoposition.split(',')[0] - x,
        +store2.geoposition.split(',')[1] - y,
      );
      if (currentDistance < prevDistance) {
        return -1;
      } if (currentDistance > prevDistance) {
        return 1;
      }
      return 0;
    });
  }

  findClosestStores() {
    const lastDeliveryAddress = JSON.parse(storage.local.getItem('lastDeliveryAddress'));
    let storesToShow = [...this.props.stores];
    if (lastDeliveryAddress && lastDeliveryAddress.location) {
      storesToShow = this.sortStoresByDistance(
        storesToShow,
        lastDeliveryAddress.location.lat,
        lastDeliveryAddress.location.lng,
      );
    } else if (lastDeliveryAddress && lastDeliveryAddress.coordinates) {
      storesToShow = this.sortStoresByDistance(
        storesToShow,
        lastDeliveryAddress.coordinates.split(',')[0],
        lastDeliveryAddress.coordinates.split(',')[1],
      );
    }
    return storesToShow.slice(0, 3);
  }

  calculateDistance(x, y) {
    return Math.pow(x, 2) + Math.pow(y, 2);
  }

  /* storeOnFocus() {
    this.setState({
      isListVisible: true,
    });
  } */

  storeOnChange(event) {
    this.setState({
      inputValue: event.target.value,
    });
  }

  render() {
    const {
      closeDropdown, currentLanguage, stores, languagePrefix, translate,
    } = this.props;
    const { isStoreModalActive } = this.state;

    const title = keyByLang('title', currentLanguage);
    const address = keyByLang('address', currentLanguage);

    const checkedStore = JSON.parse(storage.session.getItem('takeOutStore'));
    const checkedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));

    let filteredStores = [];

    if (this.state.inputValue && this.state.inputValue.length > 1 && stores && stores[0]) {
      filteredStores = stores.filter((storeData) => {
        const storeName = storeData[address].toLowerCase();
        return storeName.indexOf(this.state.inputValue.toLowerCase()) > -1;
      });
    }

    if (checkedStore && lastDeliveryType === 'takeOutStore'
      && !filteredStores[0] && !this.state.inputValue.length) {
      filteredStores = [{ ...checkedStore.address }];
    }

    if (checkedAddress && lastDeliveryType === 'deliveryAddress'
      && !filteredStores[0] && !this.state.inputValue.length) {
      filteredStores = this.findClosestStores();
    }

    let storesList = filteredStores && filteredStores[0]
      ? filteredStores.slice(0, 3).map(storeData => (
        <figure className="stores-dropdown__store-wrap" key={storeData.id_1c}>
          <img
            className="stores-dropdown__logo"
            src={shop}
            alt="shop-logo"
            decoding="async"
            loading="lazy"
          />
          <figcaption className="stores-dropdown__address">{storeData[address]}</figcaption>
          <Link
            className="stores-dropdown__link"
            to={`${languagePrefix}/stores/${storeData.id}`}
            onClick={closeDropdown}
          >
            {translate('ShowOnTheMap')}
          </Link>
        </figure>
      )) : [];

    if (!storesList || !storesList[0]) {
      storesList = <p className="stores__no-coincidences">{translate('NoMatches')}</p>;
    }

    let takeOutStores = stores;

    return (
      <StyledStoresDropdown className="stores-dropdown" storesCount={storesList.length > 2} storesList={storesList}>
        <div className="stores-dropdown__stores">
          {/* this.state.isListVisible && this.state.inputValue.length > 1 */ storesList && storesList[0] ? (
            <React.Fragment>
              <div className="stores-dropdown__header">
                <p className="stores-dropdown__heading">
                  {storesList.length === 1 ? translate('YourShop') : translate('NearesShops')}:
                </p>
                <p
                  className="stores-dropdown__link"
                  onClick={this.toggleModal}
                >
                  {translate('ChangeShop')}
                </p>
              </div>
              <div className="stores-dropdown__list">
                {storesList}
              </div>
            </React.Fragment>
          ) : null }
        </div>
        <div className="stores-dropdown__search">
          <div className="stores-dropdown__search-block">
            <div className="stores-dropdown__input-wrap">
              <input
                className="stores-dropdown__input"
                type="text"
                placeholder={translate('EnterTheStoreAddress')}
                onChange={this.storeOnChange}
                value={this.state.inputValue}
              />
              <MainBtn
                type="button"
                text={<Translate id="Search" />}
                addClass="stores-dropdown__button-wrap"
                minWidth="100px"
              />
            </div>
            <Link
              className="stores-dropdown__link"
              to={`${languagePrefix}/stores`}
              onClick={closeDropdown}
            >
              <Translate id="ShowAllShops" />
            </Link>
          </div>
        </div>
        {isStoreModalActive ? (
          <ModalWrapper handleModalClose={this.toggleModal} modalId="StoreModal">
            <StyledStoreModal>
              <header className="delivery__header">
                <div className="delivery__logo-wrap">
                  <div className="delivery__logo delivery__logo--house">
                    <Lottie
                      options={pickUpIcon}
                    />
                  </div>
                </div>
                <h2 className="delivery__heading">{translate('ChooseStoreForPickupText')}</h2>
              </header>
              <DeliveryForm
                placeholder={translate('EnterTheStoreAddress')}
                handleSubmit={this.updateAddress}
                takeOutStores={takeOutStores}
                currentLanguage={currentLanguage}
                stores={stores}
                translate={translate}
                showSnackBar={this.props.actions.showSnackBar}
                updateAddress={this.updateAddress}
              />
            </StyledStoreModal>
          </ModalWrapper>
        ) : null}
      </StyledStoresDropdown>
    );
  }
}

const mapStateToProps = state => ({
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  cart: state.cart ? state.cart : null,
  user: state.user ? state.user : null,
  stores: state.stores ? state.stores : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresDropdown);

const StyledStoresDropdown = styled.div`
  --page-background: #0A0A0A;
  --dashed-border-color: #8E8E8E;
  
  max-width: 1230px;
  padding: 1.5rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  
  .stores-dropdown {
    &__stores {
      height: 100%;
      position: relative;
      padding-bottom: 1.25rem;
      
      &::after {
        content: '';
        display: ${props => (props.storesList[0] ? 'block' : 'none')};
        min-height: 8rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        background-image: linear-gradient(to top, var(--dashed-border-color) 20%, transparent 20%, transparent 80%, var(--dashed-border-color) 80%);
        background-size: 2px 6px;
      }
    }
    &__heading {
      color: var(--text-color);
      padding-right: 2.5rem;
    }
    
    &__list {
      padding-top: 2rem;
      display: flex;
      height: 100%;
    }
    
    &__store-wrap {
      padding-right: 1rem;
      min-width: 10rem;
      flex-grow: 1;
      flex-basis: 33%;
    }
    
    &__address {
      padding: 1rem 0 .6rem;
      font-size: 0.8rem;
    }
    
    &__link {
      color: var(--links-color);
      cursor: pointer;
      font-size: .9rem;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 1;
      flex-basis: ${props => (props.storesCount ? '34%' : '50%')};
      margin: 2.5rem 0 3.5rem;
    }
    
    &__search-block {
      width: 100%;
      margin: 0 2.5rem;
    }
    
    &__input-wrap {
      position: relative;
      display: flex;
      padding-bottom: .6rem;
    }
    
    &__input {
      min-width: 16rem;
      flex-grow: 1;
      border: none;
      border-radius: 4px 0 0 4px;
      padding: .7rem;
      height: 42px;
    }
    
    &__button-wrap {
      width: auto;
      
      button {
        border-radius: 0 4px 4px 0;
      }
    }
    
    &__header {
      display: flex;
      justify-content: space-between;
      padding-right: 2rem;
    }
  }
`;

const StyledStoreModal = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;

  max-width: 470px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  
  .delivery {
    
    &__header {
      padding-top: 5rem;
      text-align: center;
    }
    
    &__content {
      padding-top: 1.2rem;
    }
    
    &__logo-wrap {
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__logo {
      
      &--house {
        width: 4.2rem;
      }
      
      &--moto {
        width: 6.8rem;
      }
    }
    
    &__heading {
      text-transform: none;
      font-size: 1.1rem;
      text-align: center;
      font-weight: 100;
      padding: 2.5rem 0 1rem;
      color: var(--text-color);
    }
    
    &__tabs-list {
      display: flex;
    }
      
    &__tab {
      padding: .8rem;
      background-color: transparent;
      border-radius: 4px;
      color: var(--active-text-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background);
      }
      
      &--active {
        background-color: var(--active-background);
      }
    }
  }
  
  .search-field {
    color: var(--text-color);
  
    &__input {
      flex-grow: 1;
      height: 42px;
      border-radius: 4px;
      border: none;
      padding: 0 10px;
      width: 100%;
      padding-right: 110px;
    }
    
    &__button {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      
      button {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`;
