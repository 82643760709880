import React from 'react';
import getStatusById from '../../../modules/shared/getStatusById';
import ProfileOrdersListItem from './ProfileOrdersListItem';
import getProductById from '../../../modules/shared/getProductById';

const ProfileOrdersList = ({
  orders,
  statuses,
  deliveryMethods,
  paymentMethods,
  isOrderActive,
  currentLanguage,
  addToCart,
  isMobile,
  languagePrefix,
  isTimeActive,
  isDayActive,
  getProductData,
  redirectTo,
  imageEmpty,
  translate,
}) => {
  const ordersItems = orders.map((order) => {
    let address;
    if (order.delivery_method_id === 2) {
      const parsedAddress = JSON.parse(order.address);
      address = `${parsedAddress.street}, ${parsedAddress?.house_number}`;
    }
    const status = getStatusById(order.status_id, statuses);
    const products = order.products.map((product) => getProductData(product));
    return (
      <ProfileOrdersListItem
        key={order.id}
        order={order}
        address={address}
        status={status}
        products={products}
        statuses={statuses}
        deliveryMethods={deliveryMethods}
        paymentMethods={paymentMethods}
        isOrderActive={isOrderActive}
        currentLanguage={currentLanguage}
        addToCart={addToCart}
        isMobile={isMobile}
        languagePrefix={languagePrefix}
        isTimeActive={isTimeActive}
        isDayActive={isDayActive}
        imageEmpty={imageEmpty}
        redirectTo={redirectTo}
        translate={translate}
      />
    );
  });

  return (
    <ul>{ordersItems}</ul>
  );
};

export default ProfileOrdersList;
