import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import keyByLang from '../../modules/shared/keyByLang';
import PageHeaderBlock from '../shared/PageHeaderBlock';
import TitleBlock from '../shared/TitleBlock';
import headerImage from '../../assets/images/shop_header_image.png';

const NewsItemPage = ({
  newsData, currentLanguage, languagePrefix, dateToShow, bannerImage, translate,
}) => {
  const title = keyByLang('title', currentLanguage);
  const text = keyByLang('text', currentLanguage);

  return (
    <StyledNewsItemPage className="page news-item default">
      {
        newsData[`meta_title_${currentLanguage}`] ? (
          <>
            <Helmet>
              <title>
                {newsData[`meta_title_${currentLanguage}`]}
              </title>
              <meta name="title" content={newsData[`meta_title_${currentLanguage}`]} />
              <meta name="description" content={newsData[`meta_description_${currentLanguage}`]} />
              <meta name="keywords" content={newsData[`meta_keywords_${currentLanguage}`]} />
            </Helmet>
          </>
        ) : null
      }
      <PageHeaderBlock image={bannerImage || headerImage} addClass="stores stores-list" />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <article className="col">
              <h1 className="news-page-title">
                {newsData[title]}
              </h1>
              <div className="info-block">
                <p className="info-block__date">
                  {translate('DateAdded')}
                  :
                  {' '}
                  {dateToShow}
                </p>
                <Link className="info-block__link" to={`${languagePrefix}/news`}>{translate('ViewAllNews')}</Link>
              </div>
              <hr className="solid margin-line" />
              <div className="news">
                <img
                  className="news__image"
                  src={newsData.image_filename}
                  alt={newsData[`meta_image_title_${currentLanguage}`]}
                  title={newsData[`meta_image_title_${currentLanguage}`]}
                  decoding="async"
                  loading="lazy"
                />
                {/* <p className="news__text">{newsData[text]}</p> */}
                {newsData && newsData[text].includes('<') ? (
                  <div className="news-text" dangerouslySetInnerHTML={{ __html: newsData[text] }} />
                ) : (
                  <p className="news-text">{newsData[text]}</p>
                )}
              </div>
            </article>
          </div>
        </div>
      </div>
    </StyledNewsItemPage>
  );
};

const StyledNewsItemPage = styled.div`
  --text-color: #8E8E8E;
  --link-color: #E74E1A;
  background-color: #0A0A0A;
  
  .news-page-title {
    text-align: center;
    margin: 20px 0;
  }
  
  .title-wrap {
    padding-bottom: 20px;
    
    &::after {
      content: ""; 
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 50%, rgba(0,0,0,0) 100%);
      position: absolute;
      bottom: 0;
    }
  }
  
  .info-block {
    display: flex;
    justify-content: space-between;
    
    &__date {
      color: var(--text-color);
      padding: 5px;
    }
    
    &__link {
      text-decoration: none;
      color: var(--link-color);
      padding: 5px;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  
  .news {
    padding: 20px 0;
    color: var(--text-color);
    
    &__image {
      float: left;
      padding: 0 0 20px;
      width: 100%;
    }
    
    &__text {
      text-align: justify;
      line-height: 1.3em;
    }
  }
  
  .margin-line {
    margin-top: 15px;
  }
  
  @media all and (min-width: 1024px) {
    .news__image {
      max-width: 50%;
      padding: 0 20px 20px 0;
    }
  }
`;

export default NewsItemPage;
