/* eslint-disable import/no-mutable-exports */
import React, { useEffect, useRef, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import Input from '../shared/form/Input';
import AnimatedButton from '../shared/buttons/AnimatedButton';
import validate from './authValidation/smsCodeValidation';

import smsCode from '../../assets/icons/form/sms-code.svg';

import { arrowBack } from '../../assets/icons/svgIcons';
import useTimer from '../../hooks/useTimer';

let GetSmsCodeStep = ({
  handleSubmit,
  buttonIcon,
  showNumberStep,
  showSnackBar,
  sendSmsAgain,
  isCheckoutPage,
  isMobile,
  translate,
}) => {
  const [sec, start] = useTimer();

  const resendSms = () => {
    start();
    sendSmsAgain();
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <StyledSmsForm className="form__wrapper" onSubmit={handleSubmit} isCheckoutPage={isCheckoutPage}>
      {isCheckoutPage ? (
        <div className="form__name-wrap">
          {buttonIcon}
          <h2 className="form__header-text form__header-text--checkout">{translate('LoginRegistration')}</h2>
        </div>
      ) : (
        <>
          <h2 className="form__header-text">{translate('LoginRegistration')}</h2>
          <hr className="solid form__line" />
        </>
      )}
      <h3 className={isCheckoutPage
        ? 'form__heading form__heading--checkout'
        : 'form__heading'}
      >
        {translate('EnterCodeFromSms')}
      </h3>
      <hr className="solid" />
      <Field
        name="smscode"
        component={Input}
        autoFocus
        icon={smsCode}
        type="text"
        label={translate('SmsCode')}
        iconName="store"
        showSnackBar={showSnackBar}
        autoComplete="off"
      />
      {sec ? (
        <div className="form__re-send-wrap form__re-send-wrap--blocked">
          {translate('SmsCodeBlockMessage')}
          {' '}
          {sec}
          {' '}
          секунд
        </div>
      ) : (
        <div className="form__re-send-wrap">
          <p className="form__question">{translate('DidNotReceiveTheSmsCode')}</p>
          <p className="form__re-send" onClick={resendSms}>{translate('SendAgain')}</p>
        </div>
      )}
      {!isCheckoutPage ? (<hr className="solid padded form__line" />) : null}
      <div className={isCheckoutPage ? 'form__buttons-wrap' : 'form__actions-wrap'}>
        {/* <AnimatedButton */}
        {/*  icon={arrowBack} */}
        {/*  minWidth={isMobile ? '150px' : '200px'} */}
        {/*  stepnum="3" */}
        {/*  text={isMobile ? translate('BackButton') : translate('BackButtonFull')} */}
        {/*  handleClick={showNumberStep} */}
        {/*  addClass="form__back-button" */}
        {/*  align="left" */}
        {/* /> */}
        <AnimatedButton
          icon={buttonIcon}
          minWidth={isMobile ? '150px' : '200px'}
          isSubmit
          stepnum="3"
          text={isMobile ? translate('SendCodeButton') : translate('SendCodeButtonFull')}
          handleClick={handleSubmit}
          addClass="form__button"
          align="right"
        />
      </div>
    </StyledSmsForm>
  );
};

GetSmsCodeStep = reduxForm({
  // a unique name for the form
  form: 'userDataFormStep2',
  onSubmit: null,
  validate,
  destroyOnUnmount: false,
})(GetSmsCodeStep);

const StyledSmsForm = styled.form`
  --text-color:  #FFFFFF;
  --link-color: #E74E1A;
  
  //height: 100vh;
  min-height: 520px;
  
  .form {
    
    &__header-text {
      text-transform: none;
      font-weight: 200;
      padding: 2.4rem 0 1.4rem;
      color: var(--text-color);
      font-size: 1.2rem;
      
      &--checkout {
        padding: 0 0 0 .7rem;
      }
    }
    
    &__heading {
      padding-top: 1.25rem;
      text-transform: none;
      font-weight: 100;
      font-size: 1rem;
      padding-bottom: 1rem;
      
      &--checkout {
        text-align: left;
        padding-top: 1rem;
      }
    }
    
    &__name-wrap {
      display: flex;
      align-items: center;
    }
    
    &__re-send-wrap {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      padding-top: .5rem;
      
      &--blocked {
        justify-content: center;
        text-align: center;
      }
    }
    
    &__question {
      font-size: .9rem;
      color: var(--text-color);
    }
    
    &__buttons-wrap {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 0;
    }
    
    &__actions-wrap {
      display: flex;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 10px;
      position: absolute;
    }
    
    &__re-send {
      font-size: .9rem;
      color: var(--link-color);
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__line {
      display: none;
    }
    
    &__button {
      margin-bottom: 0;
      
      .button {
        border-radius: ${(props) => (props.isCheckoutPage ? '4px' : '4px 4px 0 0')};
      }
    }
    
    &__back-button {
      margin-bottom: 0;
      & .button {
        background-color: transparent;
        box-shadow: 0 0 0 1px #E74E1A inset;
        box-sizing: border-box;
        color: #E74E1A;
        border-radius: ${(props) => (props.isCheckoutPage ? '4px' : '4px 4px 0 0')};
        
        & path {
          fill: #E74E1A;
        }
        
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  
  .padded {
    margin-bottom: 2rem;
  }
  
  @media all and (min-width: 480px) {
    .form {
      &__buttons-wrap {
        flex-direction: row;
      }
      
      &__line {
        display: block;
      }
    }
  }
  
  @media all and (min-width: 768px) {
  .form {
    &__heading {
      font-size: 1.2rem;
      text-align: center;
      padding-top: 6rem;
      
      &--checkout {
        text-align: left;
        padding-top: 1.4rem;
      }
    }
    
    &__question {
      font-size: 1rem;
    }
  
    &__header-text {
      font-size: 1.4rem;
      text-align: center;
    }
    
    &__re-send-wrap {
      padding-top: 0;
    }
    
    &__re-send {
      font-size: 1rem;
    }
    
    &__actions-wrap {
      position: static;
      padding: 0;
    }
    
    &__back-button {
      .button {
        border-radius: 4px;
      }
    }
    
    &__button {
      .button {
        border-radius: 4px;
      }
    }
  }
}
`;

export default GetSmsCodeStep;
