/* eslint-disable no-param-reassign */
const smsValidation = (value) => {
  const errors = {};
  const searchLetters = /^[0-9]+$/;
  if (value.smscode && !searchLetters.test(value.smscode)) {
    value.smscode = value.smscode.replace(/[^0-9]/gim, '');
  }
  if (value.smscode && value.smscode.length > 6) {
    value.smscode = value.smscode.slice(0, 6);
  }
  return errors;
};

export default smsValidation;
