import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import * as actions from '../actions/actions';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import MemorizedTextPages from '../components/text-pages/TextPages';

import FourZeroFourth from '../components/404';

class TextPagesContainer extends React.Component {
  render() {
    const {
      currentLanguage, textPages, languagePrefix, history
    } = this.props;
    const { pathname } = history.location;
    let textPage = null;

    if (textPages && textPages[0]) {
      textPage = textPages.find(page => page.alias === pathname.slice(pathname.lastIndexOf('/') + 1));
    }

    if (textPages && textPages[0] && !textPage) {
      return (
        <FourZeroFourth {...this.props} notWait />
      );
    }

    return (
      <MemorizedTextPages
        currentLanguage={currentLanguage}
        textPages={textPages}
        languagePrefix={languagePrefix}
      />
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  textPages: state.textPages ? state.textPages : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TextPagesContainer));
