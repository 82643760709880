import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../../modules/shared/keyByLang';

const JobOfferModal = ({
  activeVacancyId, vacancies, currentLanguage, validate,
}) => {
  let activeVacancy = null;
  const title = keyByLang('title', currentLanguage);
  const description = keyByLang('description', currentLanguage);

  if (activeVacancyId && vacancies && vacancies[0]) {
    activeVacancy = vacancies.find(vacancy => vacancy.id === activeVacancyId);
  }

  return (activeVacancy ? (
    <StyledJobOffer className="job-offer">
      <figure className="job-offer__figure">
        <div className="job-offer__icon-wrap">
          <img
            className="job-offer__icon"
            src={activeVacancy.image_filename}
            alt="job-icon"
            decoding="async"
            loading="lazy"
          />
        </div>
        <figcaption className="job-offer__title">{activeVacancy[title]}</figcaption>
      </figure>
      <p className="job-offer__description">{activeVacancy[description]}</p>
    </StyledJobOffer>
  ) : null);
};

const StyledJobOffer = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  

  max-width: 630px;
  padding: 0 15px;
  margin: 0 auto;

  .job-offer {
  
    &__figure {
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    &__icon-wrap {
      border: 1px solid var(--text-color);
      border-radius: 4px;
      width: 7.3rem;
      height: 7.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__title {
      color: var(--active-text-color);
      padding-top: 1rem;
      font-size: 1.5rem;
    }
    
    &__description {
      color: var(--text-color);
      padding: 2rem 0 6rem;
      text-align: justify;
    }
  }
  
  @media all and (min-width: 769px) {
    .job-offer {
      &__figure {
        padding-top: 9rem;
      }
      
      &__description {
        padding: 4rem 0 6rem;
      }
    }
  }
`;

export default JobOfferModal;
