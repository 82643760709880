/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';

class TimeSlotsList extends React.Component {
  checkDay = () => {
    if (!this.props.day) {
      return true;
    }
    const now = new Date();
    const deliveryDateArr = this.props.day.split('-');
    return (now.getFullYear() === +deliveryDateArr[0]
        && now.getMonth() + 1 === +deliveryDateArr[1]
        && now.getDate() === +deliveryDateArr[2]);
  }

  checkItemActivivty = (item, time) => {
    if (!this.checkDay()) {
      return true;
    }
    const itemArr = item.split(':');
    const timeArr = time.split(':');
    return !(+itemArr[0] < +timeArr[0]
        || (+itemArr[0] === +timeArr[0] && +itemArr[1] < +timeArr[1]));
  }

  checkStoreTime = (item, timeArea) => {
    if (!timeArea) {
      return false;
    }
    const itemTime = +`${item.split(':').join('')}`;
    const startTime = +`${timeArea.startHours}${timeArea.startMinutes}`;
    const endTime = +`${timeArea.endHours}${timeArea.endMinutes}`;
    return itemTime >= startTime && itemTime <= endTime;
  }

  render() {
    const {
      timeSlotsInPeriodArr, handleDeferredChange, orderTime, chosenTimeStep, activeTimeArea,
    } = this.props;
    const timeSlotsListItems = timeSlotsInPeriodArr && timeSlotsInPeriodArr.map((item, index) => {
      const isItemActive = this.checkItemActivivty(item, orderTime)
        && this.checkStoreTime(item, activeTimeArea);
      const activeClass = chosenTimeStep === item ? 'active' : '';
      return (
        <li
          key={index}
          onClick={isItemActive ? () => {
            handleDeferredChange({ timeStep: item });
          } : null}
          className={isItemActive ? activeClass : 'disabled'}
        >
          <span className="text">{item}</span>
        </li>
      );
    });

    return (
      <StyledTimeSlotsList>
        {timeSlotsListItems}
      </StyledTimeSlotsList>
    );
  }
}

const StyledTimeSlotsList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    cursor: pointer;
    width: 25%;
    padding: 1rem;
    box-sizing: border-box;

    .text {
      border: 1px solid transparent;
      padding: 4px;
      border-radius: 4px;
      color: #FFFFFF;
    }

    &.active {
      .text {
        border: 1px solid #E74E1A;
        color: #E74E1A;
      }
    }
    
    &.disabled {
      .text {
        color: #616161;
      }
    }
  }
  
@media all and (min-width: 769px) { 
  li {
    flex-basis: 50%;
  }
}
`;

export default TimeSlotsList;
