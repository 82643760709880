import React from 'react';
import styled from 'styled-components';

import OrderProductItem from './OrderProductItem';

const OrderProductsList = ({ products, categories, currentLanguage, getProductData, translate }) => (
  <StyledProductsList className="products-list">
    {
        products && products[0] && categories[0] ? products.map(orderProduct => (
          <OrderProductItem
            key={`${orderProduct.id}-${Math.random()}`}
            orderProduct={orderProduct}
            categories={categories}
            currentLanguage={currentLanguage}
            getProductData={getProductData}
            translate={translate}
          />
        )) : null
      }
  </StyledProductsList>
);

const StyledProductsList = styled.ul`
  --border-color: #313131;
  --price-color: #FFFFFF;
  
  @media all and (min-width: 769px) {
    padding-top: 1rem;
  }
`;

export default OrderProductsList;
