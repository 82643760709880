import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import keyByLang from '../../../modules/shared/keyByLang';
import { activeLike, cart } from '../../../assets/icons/svgIcons';
import plus from '../../../assets/icons/plus.svg';
import AnimatedButton from '../../shared/buttons/AnimatedButton';
import checkIfItBusiness from '../../../modules/shared/checkIfItBusiness';

class ProfileOrderItemProductsListItem extends React.Component {
  render() {
    const {
      product,
      currentLanguage,
      addToCart,
      removeFromFavourites,
      languagePrefix,
      isTimeActive,
      isDayActive,
      isMobile,
      cartData,
      translate,
      categories,
    } = this.props;

    const businessIsShown = checkIfItBusiness(product.product_category_id, categories);

    const title = keyByLang('title', currentLanguage);
    const isProductAllowed = isTimeActive(product) && isDayActive(product);

    let buttonData = null;
    let isInCart = false;

    if (cartData && cartData.content && cartData.content[0]) {
      isInCart = !!cartData.content.find(prod => +prod.product_id === +product.id);
    }

    if (isMobile && !isProductAllowed) {
      buttonData = (
        <div className="favourite-item__time-wrap favourite-item__time-wrap--mobile">
          <p>Доступно с </p>
          <p>{product.available_since} до {product.available_till}</p>
        </div>
      );
    } else if (isMobile && isProductAllowed && !isInCart) {
      buttonData = (
        <div
          className="favourite-item__mobile-button"
          onClick={() => addToCart(product)}
        >
          {/* cart */}
          <img src={plus} alt="plus" decoding="async" loading="lazy" />
        </div>
      );
    } else if (isMobile && isProductAllowed && isInCart) {
      buttonData = (
        <div
          className="favourite-item__mobile-button favourite-item__mobile-button--active"
          onClick={() => addToCart(product)}
        >
          <img src={plus} alt="plus" decoding="async" loading="lazy" />
        </div>
      );
    } else if (!isMobile && !isProductAllowed) {
      buttonData = (
        <div className="favourite-item__time-wrap">
          <p>{translate('OnlyAvailableFrom')}</p>
          <p>{product.available_since} до {product.available_till}</p>
        </div>
      );
    } else if (!isMobile && isProductAllowed) {
      buttonData = (
        <AnimatedButton
          href="#"
          minWidth="130px"
          icon={cart}
          text={translate('AddToCartButton')}
          iconName="store"
          handleClick={() => addToCart(product)}
        />
      );
    }

    const productCategory = categories.find(cat => product.product_category_id === cat.id).alias;

    return (
      <StyledLi className="favourite-item">
        <div className="favourite-item__content">
          <div className="favourite-item__data-wrap">
            <Link
              className="favourite-item__link"
              to={`${languagePrefix}/${productCategory}/${product.alias}`}
            />
            <img
              className="favourite-item__image"
              src={product.preview_image_filename}
              alt="sushi"
              decoding="async"
              loading="lazy"
            />
            <div className="favourite-item__text">
              <p
                className="favourite-item__title"
              >
                {product[title]}
              </p>
              <div className="favourite-item__price-wrap">
                <div className="favourite-item__price">{product.price} грн</div>
                {isMobile ? (
                  <React.Fragment>
                    <div className="favourite-item__break-line" />
                    <p className="favourite-item__portion">{product.portion_quantity} шт</p>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
          <div className="favourite-item__actions">
            {/* isProductAllowed || !isMobile ? (
              <div className="favourite-item__like" onClick={() => removeFromFavourites(product.id)}>
                {activeLike}
              </div>
            ) : null */}
            <div className="favourite-item__like" onClick={() => removeFromFavourites(product.id)}>
              {activeLike}
            </div>
            <div className="favourite-item__button-wrap">
              {product.is_active === '1' && businessIsShown ? buttonData : translate('ProductNotAvailable')}
            </div>
          </div>
        </div>
        <hr className="dashed" />
      </StyledLi>
    );
  }
}

const StyledLi = styled.li`
  width: 100%;
  
  .favourite-item {
    &__content {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 1rem 0;
    }
    
    &__data-wrap {
      display: flex;
      position: relative;
    }
    
    &__image {
      max-width: 3rem;
      max-height: 3rem;
      object-fit: contain;
      margin-right: 10px;
    }
    
    &__text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    &__title {
      color: var(--text-color);
      font-weight: 700;
    }
    
    &__actions {
      display: flex;
      align-items: center;
      
      justify-content: space-between;
      min-width: 6rem;
    }
    
    &__like:hover {
      cursor: pointer;
    }
    
    &__button-wrap {
      margin-left: .75rem;
    }
    
    &__time-wrap {
      width: 130px;
      font-size: .9rem;
      text-align: center;
      
      &--mobile {
        font-size: .75rem;
        width: 4rem;
      }
    }
    
    &__price-wrap {
      display: flex;
      align-items: center;
    }
    
    &__portion {
      color: var(--text-color);
    }
    
    &__price {
      font-size: 1.2rem;
    }
    
    &__break-line {
      width: 2px;
      height: 16px;
      background-image: linear-gradient(to top, #8E8E8E 30%, transparent 30%, transparent 70%, #8E8E8E 70%);
      background-size: 3px 8px;
      margin: 0 8px;
    }
    
    &__mobile-button {
      width: 40px;
      height: 40px;
      border: 1px solid var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      transition: background-color 6s ease, border-color 6s ease;
      will-change: background-color, border-color;
      
      &:active {
        transition-duration: 0s;
        background-color: var(--link-color);
        border-color: var(--link-color);
      }
      
      path {
        fill: var(--text-color);
      }
    }
    
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  @media all and (min-width: 768px) {
    .favourite-item {
      
      &__time-wrap {
        &--mobile {
          width: 5.5rem;
        }
      }
      
      &__actions {
        min-width: 7.5rem;
      }
    }
  }
  
  @media all and (min-width: 1024px) {
    .favourite-item {
      &__actions {
        min-width: 11rem;
      }
    }
  }
`;

export default ProfileOrderItemProductsListItem;
