import React from 'react';
import styled from 'styled-components';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import * as storage from '../../../utils/storage';
import DeliveryOrTakeoutContainer from '../../../containers/DeliveryOrTakeoutContainer';
import ModalWrapper from '../../shared/ModalWrapper';
import CheckoutDeferredOrder from '../../checkout/deferredOrder/CheckoutDeferredOrder';

import keyByLang from '../../../modules/shared/keyByLang';
import getlanguagePrefix from '../../../modules/shared/getLanguagePrefix';
import * as actions from '../../../actions/actions';

import pickup from '../../../assets/icons/form/pickup.svg';
import moto from '../../../assets/icons/form/deliveryForm.svg';

class CatalogDeliveryComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDeliveryChosen: false,
      isDeferredOrderChosen: false,
      takeOutStore: this.setInitialTakeoutStore(),
      deliveryAddress: this.setInitialDeliveryAddress(),
      tabToOpen: null,
    };

    this.openWindow = this.openWindow.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.cartSubmitInProgressHandler = this.cartSubmitInProgressHandler.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.setTabToOpen = this.setTabToOpen.bind(this);
    this.openDeferredOrder = this.openDeferredOrder.bind(this);
    this.getOrderTime = this.getOrderTime.bind(this);
    this.getTimeToReady = this.getTimeToReady.bind(this);
    this.formatOrderDate = this.formatOrderDate.bind(this);
    this.setInitialDeliveryAddress = this.setInitialDeliveryAddress.bind(this);
    this.setInitialTakeoutStore = this.setInitialTakeoutStore.bind(this);
    this.checkTakeOutTime = this.checkTakeOutTime.bind(this);
    this.checkDeliveryTime = this.checkDeliveryTime.bind(this);
    this.checkTimeIntervals = this.checkTimeIntervals.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { user, stores } = nextProps;
    if (user && user.favourite_restaurant_id && !prevState.takeOutStore && stores && stores[0]) {
      const savedUserStore = stores.find(store => store.id_1c === user.favourite_restaurant_id);
      if (savedUserStore) {
        storage.session.setItem('takeOutStore', JSON.stringify({ address: savedUserStore }));
      }
      return {
        takeOutStore: (savedUserStore ? {
          address: savedUserStore,
        } : null),
      };
    }
    if (user && user.addresses && user.addresses[0] && !prevState.deliveryAddress) {
      const favouriteAddress = user.addresses.find(address => !!address.is_favourite);
      if (favouriteAddress) {
        storage.session.setItem('deliveryAddress', JSON.stringify(favouriteAddress));
      }
      return {
        deliveryAddress: favouriteAddress || null,
      };
    }
    return null;
  }

  componentDidMount() {
    this.fetchData();
  }

  static formatTimeAverage(data) {
    return +data < 10 ? `0${data}` : data;
  }

  setInitialDeliveryAddress() {
    const savedAddress = JSON.parse(storage.session.getItem('deliveryAddress'));
    return savedAddress || null;
  }

  setInitialTakeoutStore() {
    const savedStore = JSON.parse(storage.session.getItem('takeOutStore'));
    return savedStore || null;
  }

  setTabToOpen(targetTab) {
    this.setState({ tabToOpen: targetTab });
  }

  cartSubmitInProgressHandler(bool) {
    return bool;
  }

  openWindow(data) {
    this.setTabToOpen(data);
    this.setState({ isDeliveryChosen: true });
  }

  openDeferredOrder() {
    this.setState({ isDeferredOrderChosen: true });
  }

  handleModalClose() {
    this.setState({
      isDeliveryChosen: false,
      isDeferredOrderChosen: false,
    });
  }

  updateAddress() {
    this.setState({
      takeOutStore: JSON.parse(storage.session.getItem('takeOutStore')),
      deliveryAddress: JSON.parse(storage.session.getItem('deliveryAddress')),
      isDeliveryChosen: false,
    });
  }

  getOrderTime(timeSlot) {
    const additionalTime = this.deliveryMethod === 1
        ? timeSlot.order_total_time_pickup
        : timeSlot.order_total_time_delivery;
    const time = new Date();
    return this.getTimeToReady(time, additionalTime);
  }

  formatOrderDate(date) {
    const { translate } = this.props;
    const dateData = new Date(date.split(' ')[0]);
    const today = new Date();
    if (dateData.getDate() === today.getDate()
        && dateData.getFullYear() === today.getFullYear()
        && dateData.getMonth() === today.getMonth()) {
      return `${translate('Today')}, ${date.slice(date.lastIndexOf(' '), date.lastIndexOf(':'))}`;
    }
    return `${date.slice(0, date.lastIndexOf(':'))}`;
  }

  getTimeToReady(minutes) {
    let hours = 0;
    if (minutes >= 60) {
      const difference = Math.floor(minutes / 60);
      hours += difference;
      minutes -= difference * 60;
    }
    minutes = CheckoutDeferredOrder.formatTimeAverage(minutes);
    return hours
      ? `${hours} ${this.props.translate('Hour')} ${minutes} ${this.props.translate('Minutes')}`
      : `${minutes} ${this.props.translate('Minutes')}`;
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getDeliveryMethods(),
      this.props.actions.getStores(),
    ]);
  }

  formatTimeToReady(time) {
    const timeArr = time.split(':');
    if (+timeArr[0]) {
      return `${timeArr[0]}${this.props.translate('Hour')} ${timeArr[1]} ${this.props.translate('Minutes')}`;
    }
    return `${timeArr[1]} ${this.props.translate('Minutes')}`;
  }

  checkTakeOutTime(timeToReady) {
    const { takeOutStore } = this.state;
    if (!takeOutStore || !takeOutStore.address) {
      return this.getTimeToReady(timeToReady);
    }
    return this.checkTimeIntervals(
      timeToReady,
      `${takeOutStore.address.opened_since_hour}:${takeOutStore.address.opened_since_minute}`,
      `${takeOutStore.address.opened_till_hour}:${takeOutStore.address.opened_till_minute}`,
    );
  }

  checkDeliveryTime(timeToReady) {
    const { stores } = this.props;
    const earliestTime = stores.reduce((res, store) => {
      const time = `${store.opened_since_hour}:${store.opened_since_minute}`;
      return (time && !res) || (time && res && time < res) ? time : res;
    }, '');
    const latestTime = stores.reduce((res, store) => {
      const time = `${store.opened_till_hour}:${store.opened_till_minute}`;
      return (time && !res) || (time && res && time > res) ? time : res;
    }, '');
    return this.checkTimeIntervals(timeToReady, earliestTime, latestTime);
  }

  checkTimeIntervals(timeToReady, startTime, endTime) {
    if (!startTime || !endTime || startTime >= endTime) {
      return this.getTimeToReady(timeToReady);
    }
    const now = moment().format('HH:mm');
    const nowData = moment(now, 'HH:mm');
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    end.subtract(timeToReady, 'minutes');
    if (nowData < start) {
      start.add(timeToReady, 'minutes');
      const minutesDiff = start.diff(nowData, 'minutes');
      const diff = moment().hour(0).minute(minutesDiff);
      const intervalData = diff.format('HH:mm');
      return this.formatTimeToReady(intervalData);
    }
    if (nowData > end) {
      const midNight = moment('24:00', 'HH:mm');
      const morning = moment('00:00', 'HH:mm');
      start.add(timeToReady, 'minutes');
      const minutesDiff = midNight.diff(nowData, 'minutes') + start.diff(morning, 'minutes');
      const diff = moment().hour(0).minute(minutesDiff);
      const intervalData = diff.format('HH:mm');
      return this.formatTimeToReady(intervalData);
    }
    return this.getTimeToReady(timeToReady);
  }

  render() {
    const {
      currentLanguage,
      timeSlots,
      deliveryMethods,
      user,
      updateDeliveryBlock,
      translate,
      ordersHistory,
    } = this.props;
    const {
      isDeliveryChosen, takeOutStore, deliveryAddress, tabToOpen, isDeferredOrderChosen,
    } = this.state;
    let content = null;
    let lastActiveOrder = null;

    const address = keyByLang('address', currentLanguage);
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));

    let deliveryMethodId = 1;
    let deliveryMethod = null;
    const title = keyByLang('title', currentLanguage);

    if (lastDeliveryType === 'deliveryAddress') {
      deliveryMethodId = 2;
    }

    if (deliveryMethods && deliveryMethods[0]) {
      deliveryMethod = deliveryMethods.find(method => +method.id === deliveryMethodId);
    }

    if (ordersHistory && ordersHistory[0] && user && user.id) {
      lastActiveOrder = [...ordersHistory].reverse().find(order => +order.status_id <= 5);
    }

    const orderData = {
      Orders: {
        delivery_method_id: deliveryMethodId,
      },
    };

    let timeToReady = null;

    const savedOrderTime = JSON.parse(storage.session.getItem('orderTime'));

    if (timeSlots && timeSlots[0] && deliveryMethodId === 1 && !savedOrderTime) {
      timeToReady = this.checkTakeOutTime(timeSlots[0].order_total_time_pickup);
    }
    if (timeSlots && timeSlots[0] && deliveryMethodId === 2 && !savedOrderTime) {
      timeToReady = this.checkDeliveryTime(timeSlots[0].order_total_time_delivery);
    }
    if (savedOrderTime && timeSlots && timeSlots[0]) {
      timeToReady = this.formatOrderDate(savedOrderTime.time, timeSlots);
    }

    let currentAddress = null;
    if (!lastDeliveryType && deliveryAddress) {
      currentAddress = deliveryAddress;
      storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
    } else if (!lastDeliveryType && takeOutStore) {
      currentAddress = takeOutStore;
      storage.local.setItem('lastDeliveryType', JSON.stringify('takeOutStore'));
    } else {
      currentAddress = this.state[lastDeliveryType];
    }

    if (updateDeliveryBlock && deliveryMethodId === 1) {
      currentAddress = JSON.parse(storage.session.getItem('takeOutStore'));
    }

    if ((lastDeliveryType === 'takeOutStore' && (!takeOutStore || !takeOutStore.address))
        || (lastDeliveryType === 'deliveryAddress' && (!deliveryAddress || !deliveryAddress.address))
        || (!lastDeliveryType || (!isDeliveryChosen && !takeOutStore && !deliveryAddress))) {
      content = (
        <div className="delivery-line__content delivery-line__content--main">
          <h3 className="delivery-line__heading delivery-line__heading--main">{translate('EnterTheDeliveryAddress')}</h3>
          <p className="delivery-line__link" onClick={this.openWindow}>{translate('EnterTheAddress')}</p>
        </div>
      );
    }
    if (!isDeliveryChosen && lastDeliveryType === 'takeOutStore' && takeOutStore && takeOutStore.address) {
      const timeData = savedOrderTime ? (
        <p>На {timeToReady}</p>
      ) : (
        <React.Fragment>
          <p className="delivery-line__text">{translate('TheOrderWillBeReady')}</p>
          <p>{timeToReady}</p>
        </React.Fragment>
      );
      content = (
        <div className="delivery-line__content">
          <div className="delivery-line__icon-wrap">
            <img
              className="delivery-line__icon"
              src={pickup}
              alt="delivery icon"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div className="delivery-line__column delivery-line__column--first">
            <p className="delivery-line__heading">{deliveryMethod ? deliveryMethod[title] : ''}</p>
            <p
              className="delivery-line__link"
              onClick={() => {
                storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
                if (deliveryAddress) {
                  this.updateAddress();
                } else {
                  this.openWindow();
                }
              }}
            >
              {translate('ChangeToAddressDelivery')}
            </p>
          </div>
          <div className="delivery-line__column">
            <div className="delivery-line__time-wrap">
              {timeData}
            </div>
            <p
              className="delivery-line__link"
              onClick={this.openDeferredOrder}
            >
              {translate('Change')}
            </p>
          </div>
          <div className="delivery-line__column">
            <p className="delivery-line__heading">{currentAddress.address[address]}</p>
            <p
              className="delivery-line__link"
              onClick={() => this.openWindow('takeOutStore')}
            >
              {translate('Change')}
            </p>
          </div>
        </div>
      );
    }
    if (!isDeliveryChosen && lastDeliveryType === 'deliveryAddress' && deliveryAddress) {
      const timeData = savedOrderTime ? (
        <p className="delivery-line__heading">На {timeToReady}</p>
      ) : (
        <React.Fragment>
          <p className="delivery-line__text">{translate('TheOrderWillBeReady')}</p>
          <p className="delivery-line__heading">{timeToReady}</p>
        </React.Fragment>
      );
      content = (
        <div className="delivery-line__content">
          <div className="delivery-line__icon-wrap">
            <img
              className="delivery-line__icon"
              src={moto}
              alt="delivery icon"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div className="delivery-line__column delivery-line__column--first">
            <p className="delivery-line__heading">{deliveryMethod ? deliveryMethod[title] : ''}</p>
            <p
              className="delivery-line__link"
              onClick={() => {
                storage.local.setItem('lastDeliveryType', JSON.stringify('takeOutStore'));
                if (takeOutStore && takeOutStore.address) {
                  this.updateAddress();
                } else {
                  this.openWindow('takeOutStore');
                }
              }}
            >
              {translate('ChangeToPickup')}
            </p>
          </div>
          <div className="delivery-line__column">
            <div className="delivery-line__time-wrap">
              {timeData}
            </div>
            <p
              className="delivery-line__link"
              onClick={this.openDeferredOrder}
            >
              {translate('Change')}
            </p>
          </div>
          <div className="delivery-line__column">
            <p className="delivery-line__heading">
              {/* eslint-disable-next-line camelcase */}
              {`${JSON.parse(currentAddress.address).street}, ${JSON.parse(currentAddress.address)?.house_number}`}
            </p>
            <p
              className="delivery-line__link"
              onClick={() => this.openWindow('deliveryAddress')}
            >
              {translate('Change')}
            </p>
          </div>
        </div>
      );
    }
    if (isDeliveryChosen) {
      content = (
        <ModalWrapper handleModalClose={this.handleModalClose} modalId="DeliveryOrTakeout">
          <DeliveryOrTakeoutContainer
            updateAddress={this.updateAddress}
            cartSubmitInProgressHandler={this.cartSubmitInProgressHandler}
            showSnackBar={this.props.actions.showSnackBar}
            targetTab={tabToOpen}
          />
        </ModalWrapper>
      );
    }
    if (isDeferredOrderChosen) {
      content = (
        <CheckoutDeferredOrder
          handleModalClose={this.handleModalClose}
          orderData={orderData}
          showSnackBar={this.props.actions.showSnackBar}
        />
      );
    }

    return (
      <StyledCatalogDelivery
        className="delivery-line"
        isTrackerActive={!!lastActiveOrder}
      >
        {content}
      </StyledCatalogDelivery>
    );
  }
}

const mapStateToProps = state => ({
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  stores: state.stores ? state.stores : null,
  // districts: state.districts ? state.districts : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  user: state.user ? state.user : null,
  timeSlots: state.settings ? state.settings.timeslots : null,
  deliveryMethods: state.deliveryMethods ? state.deliveryMethods : null,
  updateDeliveryBlock: state.updateDeliveryBlock ? state.updateDeliveryBlock : null,
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDeliveryComponent);

const StyledCatalogDelivery = styled.div`
  --active-text-color: #FFFFFF;
  --links-color: #E74E1A;
  --border-color: #8E8E8E;
  
  position: -webkit-sticky;
  position: sticky;
  height: 0;
  z-index: 6;
  align-items: center;
  //background: linear-gradient(180deg, rgba(0,0,0, .4), rgba(0,0,0,0.6) 65%, rgba(0,0,0,0.8) 100%);
  background-color: #000000;
  top: ${props => (props.isTrackerActive ? 'calc(6rem + 14px)' : '6rem')};
  display: none;
  
  .delivery-line {
    &__heading {
      text-transform: none;
      color: var(--active-text-color);
      font-weight: 400;
      font-size: .75rem;
      
      &--main {
        padding-bottom: .5rem;
      }
    }
    
    &__text {
      color: var(--border-color);
      padding-right: .6rem;
      
      font-size: .75rem;
    }
    
    &__content {
      padding: 0 15px;
      
      display: flex;
      
      &--main {
        flex-direction: column;
      }
    }
    
    &__link {
      color: var(--links-color);
      font-size: .7rem;
      
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
    &__column {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 12rem;
      
      &--first {
        padding-left: 1rem;
        
        min-width: 9rem;
      }
    }
    
    &__time-wrap {
      display: flex;
      font-size: .75rem;
    }
    
    &__icon-wrap {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__icon {
      width: auto;
      height: auto;
      opacity: .6;
    }
  }
  
@media all and (min-width: 768px) {
  display: flex;
  margin-top: -50px;
  height: 50px;
}

@media all and (min-width: 769px) {
   .delivery-line {
      &__content {
        padding: 0 2rem;
      }
   }
}
  
@media all and (min-width: 1023px) {
  margin-top: -70px;
  height: 70px;

  .delivery-line {
    &__content {
      padding: 0 3rem;
    }
    
    &__heading {
      font-size: .9rem;
    }
    
    &__text {
      font-size: .9rem;
    }
    
    &__link {
      font-size: .9rem;
    }
    
    &__time-wrap {
      font-size: 1rem;
    }
    
    &__column {
      padding-left: 1.25rem;
    }
  }
}

@media all and (min-width: 1400px) {
  .delivery-line {
    &__content {
      padding: 0 6rem;
    }
    
    &__column {
      padding-left: 2.5rem;
    }
  }
}
`;
