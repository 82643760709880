import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

const BtnLink = (
  {
    align,
    iconClass,
    clickHandle,
    addClass,
    children,
    linkTo,
    text,
    translateId,
  },
) => {
  const translatedOrOriginal = translateId
    ? <Translate id={translateId} />
    : text;

  const linkOrText = linkTo
    ? (
      <Link to={linkTo}>
        {translatedOrOriginal}
      </Link>
    )
    : translatedOrOriginal;

  return (
    <StyledBtnLink
      align={align}
      className={`${addClass || ''}`}
    >
      <div onClick={() => clickHandle()}>
        <span className={`icon ${iconClass}`} />
        {linkOrText}
      </div>
    </StyledBtnLink>
  );
};

export default BtnLink;

const StyledBtnLink = styled.div`
    text-align: ${props => (props.align ? props.align : 'center')};
    display: inline-block;
    width: 100%;
    
    div {
      color: #E74E1A;
      font-size: 14px;
      line-height: 17px;
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
      -webkit-appearance: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
`;
