import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/actions';

import ProfileBlockTitle from './ProfileBlockTitle';
import MainBtn from '../shared/MainBtn';

import notification from '../../assets/icons/profile/notification.svg';
import envelope from '../../assets/icons/form/email.svg';
import { filterDone } from '../../assets/icons/svgIcons';

class ProfileNotificationsBlock extends React.Component {
  constructor(props) {
    super(props);

    this.subscribeNews = this.subscribeNews.bind(this);
    this.unsubscribeNews = this.unsubscribeNews.bind(this);
  }

  subscribeNews() {
    console.log('SubscribeNews');
  }

  unsubscribeNews() {
    console.log('unsubscribeNews');
  }

  render() {
    const { user, currentLanguage, translate } = this.props;
    const actionButton = user && user.newsletters_subscribed
      ? <MainBtn align="left" text="Подписаться" minWidth="130px" clickHandle={this.subscribeNews} />
      : <MainBtn align="left" text="Отписаться" minWidth="130px" clickHandle={this.unsubscribeNews} />;
    const notificationContent = user ? (
      <div className="notifications__button-wrap">
        {actionButton}
      </div>
    ) : null;

    return (
      <StyledNotifications className="notifications">
        <ProfileBlockTitle
          icon={notification}
          headerBlockAction="action"
          headerBlockActionText={translate('Add')}
          withoutBorder
        >
          Управление оповещениями
        </ProfileBlockTitle>
        <hr className="solid" />
        <div className="notifications__content">
          <figure className="notifications__messages">
            <div className="notifications__icon-wrap">
              <img className="notifications__icon" src={envelope} alt="message" decoding="async" loading="lazy" />
              <div className="notifications__active-wrap">
                {filterDone}
              </div>
            </div>
            <figcaption className="notifications__name">Новостная рассылка</figcaption>
          </figure>
          <div className="col100">
            {notificationContent}
          </div>
        </div>
        <hr className="solid" />
      </StyledNotifications>
    );
  }
}

const StyledNotifications = styled.div`
  .notifications {
    &__content {
      padding: 2.5rem 0;
    }
    
    &__icon-wrap {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--text-color);
      border-radius: 4px;
      position: relative;
    }
    
    &__active-wrap {
      position: absolute;
      top: -.6rem;
      right: -.6rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: var(--link-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--page-background-color);
      
      svg {
        width: .7rem;
        height: .7rem;
      }
    }
    
    &__icon {
      opacity: .6;
    }
    
    &__messages {
      display: flex;
      align-items: center;
      color: var(--text-color);
    }
    
    &__name {
      margin-left: 1.25rem;
    }
    
    &__button-wrap {
      margin: .5rem 0 0 3.8rem;
    }
  }
`;

const mapStateToProps = state => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNotificationsBlock);
