import React from 'react';
import styled from 'styled-components';

import MainBtn from '../../shared/MainBtn';

import keyByLang from '../../../modules/shared/keyByLang';

import moto from '../../../assets/icons/delivery/moto.svg';
import store from '../../../assets/icons/delivery/store.svg';

const OrderDeliveryData = ({
  lastOrder, deliveryMethod, currentLanguage, currentRestaurant, showStore, storeToShow, translate,
}) => {
  const title = keyByLang('title', currentLanguage);
  const address = keyByLang('address', currentLanguage);
  let addressToShow;
  if (+deliveryMethod.id === 2) {
    const formattedAddress = JSON.parse(lastOrder.address);
    addressToShow = `${formattedAddress.street}, ${formattedAddress?.house_number}`;
  }

  return (
    <StyledDeliveryData className="order-delivery">
      <h2 className="order-delivery__heading">{translate('DeliveryMethod')}</h2>
      <div className="order-delivery__data-wrap">
        <div className="order-delivery__icon-wrap">
          <img
            className="order-delivery__icon"
            src={+deliveryMethod.id === 1 ? store : moto}
            alt="delivery-icon"
            decoding="async"
            loading="lazy"
          />
        </div>
        <div className="order-delivery__data">
          <h3 className="order-delivery__type">{deliveryMethod[title]}</h3>
          {+deliveryMethod.id === 1 ? (
            <React.Fragment>
              <p className="order-delivery__address">
                {currentRestaurant
                  ? currentRestaurant[title]
                  : null}
              </p>
              <MainBtn
                text={translate('ViewOnMap')}
                minWidth="200px"
                align="left"
                addClass="address-button"
                clickHandle={() => showStore(currentRestaurant)}
              />
            </React.Fragment>
          ) : (
            <p className="order-delivery__address">{addressToShow}</p>
          )}
        </div>
      </div>
      {+deliveryMethod.id === 2 && storeToShow ? (
        <React.Fragment>
          <p className="order-delivery__type">Доставка из магазина по адресу</p>
          <p className="order-delivery__address">{storeToShow[address]}</p>
        </React.Fragment>
      ) : null}
    </StyledDeliveryData>
  );
};

const StyledDeliveryData = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --disabled-text-color: #313131;
  
  .order-delivery {
    &__heading {
      font-size: 1.25rem;
      font-weight: 200;
      text-transform: none;
    }
    
    &__data-wrap {
      padding: 2.5rem 0 1.1rem;
      display: flex;
      justify-content: space-between;
    }
    
    &__icon-wrap {
      border: 1px solid var(--text-color);
      width: 4rem;
      height: 4rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__data {
      padding-left: 1.25rem;
      flex-basis: 70%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    
    &__type {
      text-transform: none;
      font-size: 1rem;
      font-weight: 200;
    }
    
    &__address {
      padding-top: .6rem;
      color: var(--active-text-color);
    }
  }
  
  .address-button {
    padding-top: 2.5rem;
  }
`;

export default OrderDeliveryData;
