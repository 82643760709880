/* eslint-disable consistent-return */
/* global google */
import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import { Translate } from 'react-localize-redux';
import GPlacesDeliveryForm from '../checkout/GPlacesDeliveryForm';
import DeliveryForm from './DeliveryForm';
import * as storage from '../../utils/storage';

import { pickUpIcon, deliveryIcon } from '../../assets/icons/lottieIcons';
import NewProfileAddressForm from '../profile/NewProfileAddressForm';

class DeliveryOrTakeoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTakeOutTabActive: this.setInitialActiveTab(),
      addressInputRef: React.createRef(),
    };

    this.convertDeliveryZonesFormat = this.convertDeliveryZonesFormat.bind(this);
    this.isChosenLocationInDeliveryZones = this.isChosenLocationInDeliveryZones.bind(this);
    this.handleSubmitDelivery = this.handleSubmitDelivery.bind(this);
  }

  setInitialActiveTab = () => {
    const type = JSON.parse(storage.local.getItem('lastDeliveryType'));
    if (this.props.targetTab) {
      return this.props.targetTab !== 'takeOutStore';
    }
    return type !== 'takeOutStore';
  }

  setDeliveryTabActiveHandler = () => {
    this.setState({
      isTakeOutTabActive: false,
    });
  }

  setTakeOutTabActiveHandler = () => {
    this.setState({
      isTakeOutTabActive: true,
    });
  }

  handleSubmitTakeout = (e) => {
    e.preventDefault();
  }

  convertDeliveryZonesFormat(arr) {
    const filteredArr = arr.split(';').filter((item) => item);
    return filteredArr.map((item) => {
      const itemElem = item && item.split(',');
      return { lat: parseFloat(itemElem[0]), lng: parseFloat(itemElem[1]) };
    });
  }

  isChosenLocationInDeliveryZones(loc, address) {
    /* storage.local.setItem('deliveryAddress', JSON.stringify({
      address, location: loc,
    })); */
    storage.session.setItem('deliveryAddress', JSON.stringify({
      address, location: loc,
    }));
    storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
    this.props.updateAddress();
  }

  handleSubmitDelivery(data) {
    if (!data || !data.locationNameInput || !data.locationNameInput.placeId) {
      return this.props.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
    }
    geocodeByAddress(data.locationNameInput.address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.isChosenLocationInDeliveryZones(latLng, data.locationNameInput.address))
      .catch((error) => console.error('Error', error));
  }

  render() {
    const { isTakeOutTabActive } = this.state;
    const {
      takeOutStores, currentLanguage, stores, updateAddress, showSnackBar, translate,
    } = this.props;

    const activeTabContent = (
      <>
        <header className="delivery__header">
          <div className="delivery__logo-wrap">
            <div className={!isTakeOutTabActive
              ? 'delivery__logo delivery__logo--house'
              : 'delivery__logo delivery__logo--moto'}
            >
              <Lottie
                options={!isTakeOutTabActive ? pickUpIcon : deliveryIcon}
              />
            </div>
          </div>
          <h2 className="delivery__heading">
            {!isTakeOutTabActive ? translate('ChooseStoreForPickupText') : translate('EnterYourShippingAddressText')}
          </h2>
        </header>
        <section className="delivery__content">
          <ul className="delivery__tabs-list">
            <li
              id="deliveryTab"
              className={`delivery__tab ${isTakeOutTabActive ? 'delivery__tab--active' : ''}`}
              onClick={this.setTakeOutTabActiveHandler}
            >
              <span className="text">Доставка</span>
            </li>
            <li
              id="takeOutTab"
              className={`delivery__tab ${!isTakeOutTabActive ? 'delivery__tab--active' : ''}`}
              onClick={this.setDeliveryTabActiveHandler}
            >
              <span className="text">{translate('PickupTab')}</span>
            </li>
          </ul>
          {!isTakeOutTabActive ? (
            <DeliveryForm
              placeholder={translate('EnterTheStoreAddress')}
              handleSubmit={this.handleSubmitTakeout}
              takeOutStores={takeOutStores}
              currentLanguage={currentLanguage}
              stores={stores}
              showSnackBar={showSnackBar}
              updateAddress={updateAddress}
              translate={translate}
            />
          ) : (
            <NewProfileAddressForm
              translate={translate}
              updateAddress={updateAddress}
              ref={this.state.addressInputRef}
              isCheckout={false}
            />
          )}
        </section>
      </>
    );

    return (
      <StyledDelivery className="delivery">
        {activeTabContent}
      </StyledDelivery>
    );
  }
}

const StyledDelivery = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --active-background: #313131;

  max-width: 600px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  color: var(--text-color);

  .delivery {
    
    &__header {
      padding-top: 5rem;
      text-align: center;
    }
    
    &__content {
      padding-top: 1.2rem;
    }
    
    &__logo-wrap {
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__logo {
      
      &--house {
        width: 4.2rem;
      }
      
      &--moto {
        width: 6.8rem;
      }
    }
    
    &__heading {
      text-transform: none;
      font-size: 1.1rem;
      text-align: center;
      font-weight: 100;
      padding-top: 1.5rem;
    }
    
    &__tabs-list {
      display: flex;
    }
      
    &__tab {
      padding: .8rem;
      background-color: transparent;
      border-radius: 4px 4px 0 0;
      color: var(--active-text-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background);
      }
      
      &--active {
        background-color: var(--active-background);
      }
    }
  }
  
  //.search-field {
  //
  //  &__input {
  //    flex-grow: 1;
  //    height: 42px;
  //    border-radius: 4px;
  //    border: none;
  //    padding: 0 10px;
  //    width: 100%;
  //    padding-right: 110px;
  //  }
  //  
  //  &__button {
  //    position: absolute;
  //    width: auto;
  //    top: 0;
  //    right: 0;
  //    
  //    button {
  //      border-radius: 0 4px 4px 0;
  //    }
  //  }
  //}
  
  @media all and (min-width: 768px) {
    .delivery {
      &__heading {
        padding-top: 2.5rem;
      }
    }
  }
`;

export default React.memo(DeliveryOrTakeoutModal);
