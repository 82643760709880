import * as types from "../actions/actionTypes";

const authIsSmsIsValidReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SMS_VALID_SUCCESS:
            return action.payload;
    default:
        return state;
  }
};

const initialState = false

export default authIsSmsIsValidReducer;
