import React from 'react';
import GoogleMapReact from 'google-map-react';

import Pin from './Pin';
import * as CONSTANTS from '../../modules/shared/mapApiConstants'
// eslint-disable-next-line react/prefer-stateless-function
class MapStores extends React.PureComponent {
  static defaultProps = {
    center: {
      lat: 46.452461,
      lng: 30.728825,
    },
    zoom: 12,
    options: {
      styles: [
        { elementType: 'geometry', stylers: [{ color: '#2B2B2B' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#2B2B2B' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#333333' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#6b9a76' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#212a37' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9ca5b3' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#746855' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f2835' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#f3d19c' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#17263c' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#515c6d' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#17263c' }],
        },
      ],
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      visibleStoreId: null,
    };

    this.changeStoreDetails = this.changeStoreDetails.bind(this);
    this.hideAllDetails = this.hideAllDetails.bind(this);
  }

  changeStoreDetails(id) {
    this.setState({
      visibleStoreId: id,
    });
  }

  hideAllDetails() {
    this.setState({ visibleStoreId: null });
  }

  render() {
    const {
      stores, languagePrefix, currentLanguage, checkStoreStatus, translate,
    } = this.props;
    const { visibleStoreId } = this.state;

    const pinItems = stores && stores.map((store) => {
      const { geoposition, id } = store;
      const geoArr = geoposition.split(',');
      return (
        <Pin
          key={id}
          store={store}
          lat={geoArr[0]}
          lng={geoArr[1]}
          languagePrefix={languagePrefix}
          currentLanguage={currentLanguage}
          visibleStoreId={visibleStoreId}
          changeStoreDetails={this.changeStoreDetails}
          checkStoreStatus={checkStoreStatus}
          translate={translate}
        />
      );
    });

    const centerByFirstPin = stores && stores.length
      ? stores[0].geoposition.split(',')
        .map(item => parseFloat(item)) // google map component cant works with string values -(
      : null;

    return (
    // Important! Always set the container height explicitly
      <div
        className="google-map-wrap"
        onMouseLeave={this.hideAllDetails}
        style={{ height: '100%', width: '100%' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: CONSTANTS.gApiKey }}
          defaultCenter={centerByFirstPin || this.props.center}
          defaultZoom={this.props.zoom}
          options={this.props.options}
        >
          {pinItems}
        </GoogleMapReact>
      </div>
    );
  }
}

export default MapStores;
