import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const settingsApi = {
  getSettings() {
    return axiosInstance.post('settings',
      {},
      { headers: headers.json });
  },
  getPaymentMethods() {
    return axiosInstance.post('payment-methods',
      {},
      { headers: headers.json });
  },
  getDeliveryMethods() {
    return axiosInstance.post('delivery-methods',
      {},
      { headers: headers.json });
  },
  getOrderStatuses() {
    return axiosInstance.post('order-statuses',
      {},
      { headers: headers.json });
  },
  /*setInitRequest() {
    return CartApi.getCart();
  },*/
};

export default settingsApi;
