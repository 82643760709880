import React, { Fragment } from 'react';
import styled from 'styled-components';
import keyByLang from '../../modules/shared/keyByLang';

const CartItemModal = ({
  qty,
  product,
  cartProduct,
  qtyHandler,
  cartItemId,
  removeCartItemHandler,
  currentLanguage,
  addonsList,
  translate,
}) => {
  const title = keyByLang('title', currentLanguage);

  const createAddonsList = addons => addons.map((addon, i) => (
    <span key={`${addon[title]}${addon.id}`} className="cart-product__addon">
      {i < addons.length - 1 ? `${addon[title]}, ` : `${addon[title]}`}
    </span>
  ));

  return (
    <Fragment>
      <StyledLi className="cart-product">
        <img
          className="cart-product__image"
          src={product.preview_image_filename}
          alt={product[title]}
          decoding="async"
          loading="lazy"
        />
        <div className="cart-product__description">
          <div className="cart-product__title">{product[title]}</div>
          <div className="cart-product__addonsList">
            {addonsList && addonsList[0].id ? createAddonsList(addonsList) : null}
          </div>
          <div className="cart-product__price-wrap">
            <p className="cart-product__price">{cartProduct && cartProduct.total || 0} грн</p>
            {/*{(product.portion_quantity && product.portion_quantity > 1) ? (*/}
              {/*<React.Fragment>*/}
                {/*<div className="cart-product__break-line" />*/}
                {/*<p className="cart-product__pieces">{product.portion_quantity} шт</p>*/}
              {/*</React.Fragment>*/}
            {/*) : null}*/}
          </div>
        </div>
        <div className="cart-product__controls-wrap">
          <button
            className="cart-product__button cart-product__button--decrease"
            type="button"
            onClick={() => qtyHandler(cartProduct, 'DEC')}
          />
          <p className="cart-product__current-count">{cartProduct.quantity}</p>
          <button
            className="cart-product__button cart-product__button--increase"
            type="button"
            onClick={() => qtyHandler(cartProduct, 'INC')}
          />
        </div>
        <button
          className="cart-product__button cart-product__button--delete"
          type="button"
          onClick={() => removeCartItemHandler(cartProduct.id)}
        />
      </StyledLi>
      <hr className="dashed" />
    </Fragment>
  );
};

const StyledLi = styled.li`
  --active-color: #fff;
  --background-color: #020202;
  --button-color: #8E8E8E;
  --button-size: 24px;
  --list-item-padding: .6rem;
  --transition-time: .2s;

  display: flex;
  padding: 10px 0;
  position: relative;
  align-items: center;
  overflow: hidden;
  transition: var(--transition-time);

  .cart-product {
    
    &__image {
      max-width: 40px;
      max-height: 40px;
      margin-right: 10px;
    }
    
    &__description {
      flex-grow: 1;
    }
    
    &__title {
      font-size: 1em;
      font-weight: 700;
    }
    
    &__addons-list {
    
    }
    
    &__addon {
      font-size: .8em;
      font-weight: 200;
    }
    
    &__price-wrap {
      display: flex;
      align-items: center;
      padding-top: 10px;
    }
    
    &__price {
      color: var(--active-color);
    }
    
    &__break-line {
      width: 2px;
      height: 12px;
      background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
      background-size: 2px 6px;
      margin: 0 8px;
    }
    
    &__controls-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    &__current-count {
      padding: 0 2px 0 6px;
    }
    
    &__button {
      width: var(--button-size);
      height: var(--button-size);
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid var(--button-color);
      background-color: var(--background-color);
      margin-left: 6px;
      
      &:hover {
        cursor: pointer;
        background-color: var(--button-color);
        
        &::before,
        &::after {
          background-color: var(--active-color);
        }
      }
      
      &--increase {
        position: relative;
        
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
        }
        
        &::before {
          width: 10px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 5px);
        }
        
        &::after {
          width: 2px;
          height: 10px;
          top: calc(50% - 5px);
          left: calc(50% - 1px);
        }
      }
      
      &--decrease {
        position: relative;
        
        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
          width: 10px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 5px);
        }
      }
      
      &--delete {
        border: none;
        /*position: absolute;
        right: -30px;
        transition: right var(--transition-time);*/
        position: relative;
        
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
        }
        
        &::before {
          width: 18px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 9px);
          transform: rotate(45deg);
        }
        
        &::after {
          width: 18px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 9px);
          transform: rotate(135deg);
        }
      }
    }
  }
  
  @media all and (min-width: 768px) {
  
  //delete button animation
  
  &:hover {
    //padding-right: 50px;
    //z-index: 12;
    //  
    //.cart-product__button--delete {
    //  right: 10px;
    //}
  }
    
  //.cart-product {
  //  &__button {
  //    &--delete {
  //      position: absolute;
  //      right: -30px;
  //      transition: right var(--transition-time);
  //    }
  //  }
  //}
}
`;


export default CartItemModal;
