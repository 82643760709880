/* eslint-disable no-param-reassign,no-mixed-operators */
import React from 'react';
import { Translate } from 'react-localize-redux';

const validatePaymentForm = (values) => {
  const errors = {};
  const searchLetters = /^\+[0-9]+$/;
  let isAvailableBonuses = true;

  if (!searchLetters.test(values.bonuses_used) && values.bonuses_used) {
    values.bonuses_used = values.bonuses_used.replace(/[^0-9]/gim, '');
  }
  if (values.user && values.user.id && values.bonuses_used) {
    isAvailableBonuses = +values.user.bonuses_total >= values.bonuses_used;
  }
  if (!isAvailableBonuses) {
    errors.bonuses_used = <Translate id="NotEnoughBonuses" />;
  }
  return errors;
};

export default validatePaymentForm;
