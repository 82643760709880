import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const NewsApi = {
  getNews() {
    return axiosInstance.post('news',
      {},
      { headers: headers.json });
  },
};

export default NewsApi;
