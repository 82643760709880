import * as types from '../actions/actionTypes';

const orderStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDER_STATUSES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = [];

export default orderStatusesReducer;
