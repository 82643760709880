import { combineReducers } from 'redux';
import { localeReducer as locale } from 'react-localize-redux';

import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import categories from './categoriesReducer';
import homePageStaticData from './homePageStaticData';
import allergens from './allergensReducer';
import ingredients from './ingredientsReducer';
import productTags from './productTagsReducer';
import stores from './storesReducer';
import cart from './cartReducer';
import waitSms from './AuthWaitSmsReducer';
import smsValid from './AuthIsSmsValidReducer';
import user from './userReducer';
import ordersHistory from './ordersHistoryReducer';
import statuses from './orderStatusesReducer';
import deliveryMethods from './deliveryMethodsReducer';
import paymentMethods from './paymentMethodsReducer';
import news from './newsReducer';
import faq from './faqReducer';
import deliveryZones from './deliveryZonesReducer';
import snackBarData from './snackBarReducer';
import deferredOrder from './deferredOrderReducer';
import settings from './settingsReducer';
import headers from './headersReducer';
import checkout from './checkoutReducer';
import init from './initReducer';
import order from './orderReducer';
import accessories from './accessoriesReducer';
import cartToCheckoutSteps from './cartToCheckoutStepsReducer';
import textPages from './textPagesReducer';
import banners from './bannersReducer';
import activeCatalogLink from './setActiveCatalogLinkReducer';
import accessoriesTypes from './accessoriesTypesReducer';
import bonusData from './bonusReducer';
import vacancies from './vacanciesReduces';
import vacancyReviews from './vacancyReviewsReducer';
import isMobile from './mobileSizeReducer';
import productSize from './productSizeReducer';
import filterData from './filterReducer';
import seoHomePage from './seoHomePageReducer';
import updateDeliveryBlock from './updateDeliveryReducer';
import deliveryPosition from './deliveryPositionReducer';
import promotions from './promotionsReducer';
import promocodeValue from './promocodeReducer';
import appBar from './appBarReducer';
import cities from './citiesReducer';
import addedState from './addedProduct';
import faqPopupReducer from './faqPopupReducer';
import productReviews from './productReviewsReducer';

const rootReducer = (history) => combineReducers({
  locale,
  news,
  promotions,
  faq,
  deliveryZones,
  ordersHistory,
  statuses,
  deliveryMethods,
  paymentMethods,
  init,
  textPages,
  router: connectRouter(history),
  form: formReducer,
  settings,
  categories,
  homePageStaticData,
  allergens,
  ingredients,
  cart,
  stores,
  waitSms,
  smsValid,
  user,
  snackBarData,
  deferredOrder,
  headers,
  order,
  checkout,
  banners,
  productTags,
  activeCatalogLink,
  accessories,
  accessoriesTypes,
  cartToCheckoutSteps,
  bonusData,
  vacancies,
  vacancyReviews,
  isMobile,
  productSize,
  filterData,
  seoHomePage,
  updateDeliveryBlock,
  deliveryPosition,
  promocodeValue,
  appBar,
  cities,
  addedState,
  faqPopupReducer,
  productReviews,
});

export default rootReducer;
