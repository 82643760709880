// import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const HomeStaticDataApi = {

  getTopBannerData() {
    return axiosInstance.get('homePageBlocks');
  },

  getBestSetsData() {
    return axiosInstance.get('homePageBlocks');
  },

  getBonusClubBannerData() {
    return axiosInstance.get('homePageBlocks');
  },

};

export default HomeStaticDataApi;
