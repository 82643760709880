import React from 'react';
import styled from 'styled-components';

const Checkbox = (props) => {
  const { label, id, input } = props;

  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        id={id}
        {...input}
      />
      <label htmlFor={id}>{label}</label>
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.div`
    margin: 1rem 0 .6rem;
    font-size: .68rem;

    input {
        display: none;

        &:checked ~ label {
            &:before {
                background-color: #8e8e8e;
            }
        }
    }
    label {
        position: relative;
        padding-left: 30px;
        display: block;

        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 2px solid #8e8e8e;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            box-shadow: inset 0 0 0px 2px #131313;
            background-color: #131313;
        }
        
        &:hover {
          cursor: pointer;
        }
    }
    
    @media all and (min-width: 360px) {
      font-size: .75rem;
    }
    
    @media all and (min-width: 768px) {
      margin-bottom: 0 0 20px;
      font-size: 1rem;
    }
`;

export default Checkbox;
