import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MapStores from './MapStores';
import logoWhite from '../../assets/images/logo_white.svg';
import keyByLang from '../../modules/shared/keyByLang';

import storeBanner from '../../assets/images/stores/Store.jpg';
import { Helmet } from "react-helmet";

class Store extends React.PureComponent {
  constructor(props) {
    super(props);

    this.time = new Date();

    this.checkStoreStatus = this.checkStoreStatus.bind(this);
  }


  checkStoreStatus(store) {
    const hours = this.time.getHours();
    const minutes = this.time.getMinutes();
    const openTimeHours = +store.opened_since_hour;
    const workTillHours = +store.opened_till_hour;
    const openTimeMinutes = +store.opened_since_minute;
    const workTillMinutes = +store.opened_till_minute;
    if (openTimeHours > hours || (openTimeHours === hours && openTimeMinutes > minutes)) {
      return false;
    } if (hours > workTillHours || (workTillHours === hours && minutes > workTillMinutes)) {
      return false;
    }
    return true;
  }

  render() {
    // !important -  arr this.props.stores - have
    // only one current object stores[0] - our current store

    const {
      stores, languagePrefix, currentLanguage, bannerImage, translate,
    } = this.props;

    const store = stores && stores[0];
    const address = keyByLang('address', currentLanguage);
    const { phone } = store;
    const dateNow = new Date();
    const dayNow = dateNow.getDay();

    const phones = phone.split(',');

    const timeSince = `${store.opened_since_hour}:${store.opened_since_minute}`;
    const timeTill = `${store.opened_till_hour}:${store.opened_till_minute}`;

    return (
      <StyledDiv className="store" bannerImage={bannerImage || storeBanner}>
        {
          store[`meta_title_${currentLanguage}`] ? (
            <>
              <Helmet>
                <title>
                  {store[`meta_title_${currentLanguage}`]}
                </title>
                <meta name="title" content={store[`meta_title_${currentLanguage}`]} />
                <meta name="description" content={store[`meta_description_${currentLanguage}`]} />
                <meta name="keywords" content={store[`meta_keywords_${currentLanguage}`]} />
              </Helmet>
            </>
          ) : null
        }
        <div className="store__header" />
        <div className="store__content">
          <div className="store__name">
            <div className="store__title">
              <img className="store__image" src={logoWhite} alt="logo" decoding="async" loading="lazy" />
              <Link className="store__link" to={`${languagePrefix}/stores`}>{translate('ShowAllShops')}</Link>
            </div>
            <hr className="solid" />
            <div className="store__info">
              <p className="store__address">
                {translate('Address')}
                :
                {' '}
                <span className="text-white">{store[address]}</span>
              </p>
              <p className="text-white">{this.checkStoreStatus(store) ? translate('IsOpen') : translate('IsClose')}</p>
              <p className="store__phones">
                {translate('BhtThPhone')}
                :
                {phones.map((tel) => (
                  <a href={`tel:${tel}`} key={tel} className="store__phone">{tel}</a>
                ))}
              </p>
            </div>
          </div>
          <div className="store__main">
            <div className="store__map">
              <hr className="solid store__line" />
              <Link className="store__link store__link--mobile" to={`${languagePrefix}/stores`}>{translate('ShowAllShops')}</Link>
              <MapStores
                stores={stores}
                checkStoreStatus={this.checkStoreStatus}
                languagePrefix={languagePrefix}
                currentLanguage={currentLanguage}
                translate={translate}
              />
              <a
                className="store__link store__link--mobile"
                href={`https://www.google.com/maps/search/?api=1&query=${store.geoposition}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {translate('BuildRoute')}
              </a>
            </div>
            <div className="store__sidebar">
              <h6 className="store__sidebar__title">
                {translate('StoreOpeningHours')}
                :
              </h6>
              <ul className="store__list">
                <li className={`${dayNow === 1 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Monday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 2 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Tuesday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 3 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Wednesday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 4 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Thursday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 5 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Friday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 6 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Saturday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
                <li className={`${dayNow === 0 ? 'store__day store__day-active' : 'store__day'}`}>
                  <span>{translate('Sunday')}</span>
                  <span className="time">
                    {timeSince}
                    -
                    {timeTill}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <a
            className="store__link"
            href={`https://www.google.com/maps/search/?api=1&query=${store.geoposition}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {translate('BuildRoute')}
          </a>
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`

  background-color: #0A0A0A;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 5rem;
  color: #8E8E8E;
  
  .store__day-active, .text-white {
    color: #FFFFFF;
  }
  
  .store {
  
    &__header {
      height: 130px;
      text-align: center;
      background: radial-gradient(420.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%),
                  url(${(props) => (props.bannerImage ? props.bannerImage : 'null')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
    
    &__content {
      padding: 0 15px;
    }
    
    &__link {
      color: #E74E1A;
      display: none;
      
      &--mobile {
        display: block;
        margin: 1rem 0 1rem 15px;
      }
    }
    
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 0 1.25rem;
    }
    
    &__image {
      max-width: 8rem;
    }
    
    &__info {
      min-height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    
    &__main {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
    
    &__map {
      height: 18rem;
      flex-grow: 1;
      margin: 0 -15px;
      margin-bottom: 6rem;
    }
    
    &__sidebar {
      display: flex;
      flex-direction: column;
      // margin-left: 2.5rem;
      
      &__title {
        margin-bottom: 1rem;
      }
    }
    
    &__list {
      flex-grow: 1;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    &__day {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      position: relative;
      padding: .75rem 0;
      
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        display: block;
        height: 2px;
        background-image: linear-gradient(90deg, #333, #555 30%, transparent 40%, transparent 100%);
        background-size: 6px;
        
      }
    }
    
    &__line {
      display: block;
    }
    
    &__phone {
      text-decoration: none;
      color: #fff;
      
      &:hover {
        color: #E74E1A;
      }
      
      &:not(:last-child)::after {
        content: ',';
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .store {
      &__header { 
        height: 250px;
      }
      
      &__image {
        max-width: auto;
      }
      
      &__link {
        display: block;
        
        &--mobile {
          display: none;
        }
      }
      
      &__map {
         margin-bottom: 0;
      }
      
      &__title {
        justify-content: space-between;
        padding: 2rem 0;
      }
      
      &__phone {
        margin-top: 1rem;
      }
      
      &__main {
        flex-direction: row;
      }
      
      &__line {
        display: none;
      }
      
      &__sidebar {
        margin-left: 2.5rem;
      }
      
      &__list {
        min-width: 17rem;
      }
      
      &__day {
        padding: .5rem 0;
      }
    }
  }
`;

export default React.memo(Store);
