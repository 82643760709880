export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_TOPBANNER_DATA_SUCCESS = 'GET_TOPBANNER_DATA_SUCCESS';
export const GET_BESTSETS_DATA_SUCCESS = 'GET_BESTSETS_DATA_SUCCESS';
export const GET_BONUSCLUB_BANNER_DATA_SUCCESS = 'GET_BONUSCLUB_BANNER_DATA_SUCCESS';
export const GET_BESTSETS_PRODUCTS_DATA_SUCCESS = 'GET_BESTSETS_PRODUCTS_DATA_SUCCESS';
export const GET_ALLERGENS_SUCCESS = 'GET_ALLERGENS_SUCCESS';
export const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_WAIT_SMS_SUCCESS = 'GET_WAIT_SMS_SUCCESS';
export const GET_SMS_VALID_SUCCESS = 'GET_SMS_VALID_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const GET_ORDERS_HISTORY_SUCCESS = 'GET_ORDERS_HISTORY_SUCCESS';
export const GET_ORDER_STATUSES_SUCCESS = 'GET_ORDER_STATUSES_SUCCESS';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_DELIVERY_METHODS_SUCCESS = 'GET_DELIVERY_METHODS_SUCCESS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_DELIVERY_ZONES_SUCCESS = 'GET_DELIVERY_ZONES_SUCCESS';
export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS';
export const HIDE_SNACKBAR_SUCCESS = 'HIDE_SNACKBAR_SUCCESS';
export const SET_DEFERRED_ORDER_DATA = 'SET_DEFERRED_ORDER_DATA';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_HEADERS_SUCCESS = 'GET_HEADERS_SUCCESS';
export const SET_ORDER = 'SET_ORDER';
export const SET_INIT = 'SET_INIT';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const GET_ACCESSORIES_SUCCESS = 'GET_ACCESSORIES_SUCCESS';
export const SET_CARTTOCHECKOUTSTEPS_SUCCESS = 'SET_CARTTOCHECKOUTSTEPS_SUCCESS';
export const GET_TEXT_PAGES = 'GET_TEXT_PAGES';
export const GET_HOMEBANNER_DATA_SUCCESS = 'GET_HOMEBANNER_DATA_SUCCESS';
export const GET_PRODUCT_TAGS = 'GET_PRODUCT_TAGS';
export const SET_ACTIVE_CATALOG_LINK_SUCCESS = 'SET_ACTIVE_CATALOG_LINK_SUCCESS';
export const SET_FAVOURITES = 'SET_FAVOURITES';
export const GET_ACCESSORIES_TYPES_SUCCESS = 'GET_ACCESSORIES_TYPES_SUCCESS';
export const SET_SEPARATED_ACCESSORIES = 'SET_SEPARATED_ACCESSORIES';
export const SET_ACCESSORIES_UPDATE_SUCCESS = 'SET_ACCESSORIES_UPDATE_SUCCESS';
export const GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS';
export const ADD_DELIVERY_ADDRESS_SUCCESS = 'ADD_DELIVERY_ADDRESS_SUCCESS';
export const REMOVE_DELIVERY_ADDRESS_SUCCESS = 'REMOVE_DELIVERY_ADDRESS_SUCCESS';
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = 'UPDATE_DELIVERY_ADDRESS_SUCCESS';
export const GET_VACANCIES_SUCCESS = 'GET_VACANCIES_SUCCESS';
export const GET_VACANCY_REVIEWS_SUCCESS = 'GET_VACANCY_REVIEWS_SUCCESS';
export const SET_MOBILE_SIZE_SUCCESS = 'SET_MOBILE_SIZE_SUCCESS';
export const SET_PRODUCTS_FILTER_DATA = 'SET_PRODUCTS_FILTER_DATA';
export const SET_PRODUCT_SCREEN_SIZE = 'SET_PRODUCT_SCREEN_SIZE';
export const SET_FILTERED_CATEGORIES_SUCCESS = 'SET_FILTERED_CATEGORIES_SUCCESS';
export const GET_SEO_HOME_PAGE_SUCCESS = 'GET_SEO_HOME_PAGE_SUCCESS';
export const UPDATE_DELIVERY_BLOCK_SUCCESS = 'UPDATE_DELIVERY_BLOCK_SUCCESS';
export const UPDATE_DELIVERY_POSITION = 'UPDATE_DELIVERY_POSITION';
export const SET_PROMOCODE = 'SET_PROMOCODE';
export const SET_CITIES = 'SET_CITIES';
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';
export const IS_BAR_SHOWN = 'IS_BAR_SHOWN';
export const ADDED_PRODUCT = 'ADDED_PRODUCT';
export const SET_ON_THANK_YOU_PAGE = 'SET_ON_THANK_YOU_PAGE';
export const SET_IS_POPUP_ACTIVE = 'SET_IS_POPUP_ACTIVE';
export const GET_PRODUCT_REVIEWS_BY_ID = 'GET_PRODUCT_REVIEWS_BY_ID';
export const RESET_PRODUCT_REVIEWS = 'RESET_PRODUCT_REVIEWS';
