import React from 'react';
import styled from 'styled-components';

import * as storage from '../../utils/storage';
import keyByLang from '../../modules/shared/keyByLang';
import MainBtn from '../shared/MainBtn';
import CheckoutInput from './CheckoutInput';

import pickup from '../../assets/icons/form/pickup.svg';

class DeliveryForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isListVisible: false,
      inputValue: this.setInitAddress(this.props.takeOutStore),
    };
  }

  setInitAddress = (takeoutStore) => {
    if (takeoutStore) {
      const title = takeoutStore.address[keyByLang('title', this.props.currentLanguage)];
      const address = takeoutStore.address[keyByLang('address', this.props.currentLanguage)];
      return `${address}`;
    }
    return '';
  }


  setChosenStore = (storeName) => {
    this.setState({
      inputValue: storeName,
      isListVisible: false,
    });
    setTimeout(() => this.saveDeliveryAddress(null, this.props.stores), 0);
  };

  storeOnFocus = () => {
    this.setState({
      isListVisible: true,
    });
  }

  storeOnChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  saveDeliveryAddress = (e, stores) => {
    if (e) {
      e.preventDefault();
    }
    const checkedStore = stores.find((store) => {
      const title = store[keyByLang('title', this.props.currentLanguage)];
      const address = store[keyByLang('address', this.props.currentLanguage)];
      return this.state.inputValue === `${address}`;
    });
    if (checkedStore) {
      storage.session.setItem('takeOutStore', JSON.stringify({ address: checkedStore }));
      if (!this.props.isCheckout) {
        storage.local.setItem('lastDeliveryType', JSON.stringify('takeOutStore'));
      }
      this.props.updateAddress();
    } else {
      this.props.showSnackBar(this.props.translate('ChooseStoreFromDropdown'), 4000, false);
    }
  }

  render() {
    const {
      placeholder, currentLanguage, takeOutStores, stores, isCheckout, storeRef, translate,
    } = this.props;

    const title = keyByLang('title', currentLanguage);
    const address = keyByLang('address', currentLanguage);

    let filteredStores = null;

    if (this.state.inputValue && stores && stores[0]) {
      filteredStores = stores.filter((store) => {
        const storeName = `${store[address]}`.toLowerCase();
        if (storeName.indexOf(this.state.inputValue.toLowerCase()) > -1) return store;

      });
    } else {
      filteredStores = [...stores];
    }

    let storesList = filteredStores && filteredStores[0]
      ? (
        <div className="stores__lsit">
          {filteredStores.map(store => (
            <p
              className="stores__store-name"
              key={store.id}
              onClick={() => this.setChosenStore(`${store[address]}`)}
            >
              {`${store[address]}`}
            </p>
          ))}
        </div>
      ) : null;

    if (storesList && storesList[0]) {
      storesList = <p className="stores__no-coincidences">{translate('NoMatches')}</p>;
    }

    return (
      <StyledSearch className="search-field" onSubmit={e => this.saveDeliveryAddress(e, stores)}>
        {isCheckout ? (
          <CheckoutInput
            label="label"
            icon={pickup}
            type="type"
            storeOnFocus={this.storeOnFocus}
            storeOnChange={this.storeOnChange}
            placeholder={placeholder}
            inputValue={this.state.inputValue}
            handleSubmit={this.saveDeliveryAddress}
            storeRef={storeRef}
          />
        ) : (
          <React.Fragment>
            <input
              className="search-field__input"
              type="text"
              onFocus={this.storeOnFocus}
              onChange={this.storeOnChange}
              placeholder={placeholder}
              value={this.state.inputValue}
            />
            <MainBtn
              text={translate('Find')}
              minWidth="100px"
              addClass="search-field__button"
              clickHandle={e => this.saveDeliveryAddress(e, stores)}
              type="submit"
            />
          </React.Fragment>
        )}
        <div className={this.state.isListVisible ? 'stores' : 'stores stores--hidden'}>
          {!isCheckout ? (
            <div className="stores__logo-line">
              <img className="stores__logo" src={pickup} alt="logo" decoding="async" loading="lazy" />
              <p className="stores__name">{translate('TakeoutByAddress')}: </p>
            </div>
          ) : null}
          <div className="stores__dropdown">
            {storesList}
          </div>
        </div>
      </StyledSearch>
    );
  }
}

const StyledSearch = styled.form`
  --dropdown-background: #1C1C1C;
  --active-dropdown-menu: #313131;
  --active-text-color: #FFFFFF;
  --active-background-color: #313131;
  
  width: 100%;
  position: relative;
  display: flex;
  
  .stores {
    width: 100%;
    position: absolute;
    top: 100%;
    flex-grow: 1;
    z-index: 2;
    
    &--hidden {
      visibility: hidden;
      height: 0;
    }
    
    &__input {
      height: 42px;
      border-radius: 4px;
      border: none;
      padding: 0 110px 0;
      width: 100%;
    }
    
    &__logo-line {
      display: flex;
      padding: 6px 20px;
      align-items: center;
    }
    
    &__logo {
      opacity: .6;
      margin-right: 10px;
    }
    
    &__name {
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 700;
    }
    
    &__dropdown {
      background-color: var(--dropdown-background);
      width: 100%;
      padding: 0 10px;
      border-radius: 0 0 4px 4px;
    }
    
    &__district {
      padding: 10px;
      font-size: .9rem;
    }
    
    &__store-name {
      padding: 10px;
      color: var(--active-text-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-dropdown-menu);
      }
      
      &:not(:last-child) {
        border-bottom: 1px solid var(--active-background-color);
      }
    }
    
    &__no-coincidences {
      color: var(--active-text-color);
      padding: 1rem;
    }
  }
  
  .search-field {
    &__input {
      flex-grow: 1;
    }
    &__button {
      width: auto;
      margin-bottom: 0;
    }
  }
  
  @media all and (min-width: 768px) {
    .stores {
      &__store-name {
        padding: .5rem;
        font-size: 1rem;
      }
    }
  }
`;

export default DeliveryForm;
