import React from 'react';
import styled from 'styled-components';
import Item from './Item';

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.setCatDataCatalogState = this.props.setCatDataCatalogState;
    this.categoryRef = React.createRef();
  }

  isInFavorites = product => !!this.props.user.favourites
    .find(favourite => +favourite === +product.id)

  render() {
    const {
      id,
      title,
      products,
      productTags,
      currentLanguage,
      addToCart,
      categoriesRefs,
      currentSize,
      user,
      addToFavourites,
      imageEmpty,
      removeFromFavourites,
      currentTime,
      isMobile,
      languagePrefix,
      cart,
      translate,
      activeCategoryId,
    } = this.props;

    const productsToShow = products.filter(prod => prod.is_active === '1').sort((prod1, prod2) => {
      if (+prod1.position_index > +prod2.position_index) {
        return 1;
      }
      if (+prod1.position_index < +prod2.position_index) {
        return -1;
      }
      if (+prod1.position_index === +prod2.position_index) {
        return 0;
      }
    });

    const productsList = productsToShow && productsToShow[0] ? productsToShow.map(product => (
      <Item
        product={product}
        productTags={productTags}
        key={`${product.id}${id}`}
        currentLanguage={currentLanguage}
        addToCart={addToCart}
        imageEmpty={imageEmpty}
        user={user}
        addToFavourites={addToFavourites}
        removeFromFavourites={removeFromFavourites}
        currentTime={currentTime}
        isInFavorite={user && user.favourites ? this.isInFavorites(product) : false}
        currentSize={currentSize}
        isMobile={isMobile}
        languagePrefix={languagePrefix}
        cartData={cart}
        translate={translate}
      />
    )) : null;

    return (
      <StyledDiv>
        <div
          className="category"
          data-id={id}
          ref={categoriesRefs ? categoriesRefs[id] : 'empty'}
        >
          {productsToShow && productsToShow[0] ? (
            <>
              {id === activeCategoryId ? (
                <h1 className="category__heading">{title}</h1>
              ) : (
                <h2 className="category__heading">{title}</h2>
              )}
              <hr className="solid" />
              {isMobile ? (
                <ul className="category__mobile">
                  {productsList}
                </ul>
              ) : (
                <ul
                  className="category__items-wrap"
                >
                  {productsList}
                </ul>
              )}
            </>
          ) : null}
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  --text-color: #fff;
  --heading-padding-top: 80px;
  font-family: 'fs_elliot_pro';
  text-align: center;

  .category {
  
    & > .solid {
      margin-top: 40px;
    }
  
    &__heading {
      text-transform: none;
      font-weight: 200;
      font-size: 2em;
      padding: var(--heading-padding-top) 1rem 0;
    }
    
    &__items-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      margin: 0 -15px;
      position: relative;
      padding: 1rem 0;
      
      &--large {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      }
    }
    
    &__mobile {
      width: 100%;
    }
  }
  
  @media all and (min-width: 768px) {
    .category {
      &__heading {
        padding: var(--heading-padding-top) 8rem 0;
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .category__heading {
      padding: var(--heading-padding-top) 1rem 0;
    }
    .category__items-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export default React.memo(Category);
