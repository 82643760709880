import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import Icon from './Icon';

const MainBtn = ({
  minWidth,
  fullWidth,
  align,
  iconName,
  clickHandle,
  linkTo,
  text,
  type = '',
  addClass,
  animated,
  translateId,
  marginBottom,
  wrapperWidth,
  sendingData,
}) => {
  const translatedOrOriginal = translateId
    ? <Translate id={translateId} />
    : text;

  const linkOrText = linkTo
    ? (
      <Link to={linkTo}>
        {translatedOrOriginal}
      </Link>
    )
    : translatedOrOriginal;

  const iconBlock = animated
    ? <Icon name={iconName} color="inherit" />
    : null;

  return (
    <StyledMainBtn
      align={align}
      minWidth={minWidth}
      fullWidth={fullWidth}
      className={`main-btn ${addClass || ''}`}
      marginBottom={marginBottom}
      wrapperWidth={wrapperWidth}
    >
      <button
        onClick={clickHandle}
        type={type}
        className={`button ${animated ? 'animated' : ''} ${sendingData ? 'button--disabled' : ''}`}
      >
        {iconBlock}
        <span className="text">{linkOrText}</span>
      </button>
    </StyledMainBtn>
  );
};

export default MainBtn;

export const StyledMainBtn = styled.div`
  text-align: ${props => (props.align ? props.align : 'center')};
  margin-bottom: ${props => (props.marginBottom ? '20px' : 0)};
  display: inline-block;
  width: ${props => (props.wrapperWidth ? props.wrapperWidth : '100%')};
    
  .button {
    position: relative;
    color: #fff;
    border-radius: 4px;
    background-color: #E74E1A;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    padding: 13px 10px;
    text-align: center;
    box-sizing: border-box;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: ${props => (props.minWidth ? `${props.minWidth}` : 'auto')};
    width: ${props => (props.fullWidth ? '' + '100%' : 'auto')};
    overflow: hidden;
    transition: background-color 0.4s ease-in-out;
    
    &--disabled {
      background-color: #555555;
      pointer-events: none;
    }

    &:hover {
      background-color: #BB3517;
    }
    
    &:active {
      box-shadow: 0 0 15px rgba(0,0,0, .4) inset;
    }

    &.animated {

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% + 40px));
        transition: transform 0.15s ease-in-out;
      }
      
      .text {
        display: inline-block;
        transform: translateY(0);
        transition: transform 0.15s ease-in-out;
      }

      &:hover {
          
        .text {
          transform: translateY(-40px);
        }
        .icon {
          transform: translate(-50%, calc(-50% + 0px));
        }
      }
    }
  }
`;
