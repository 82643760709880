import * as types from '../actions/actionTypes';

const initialState = true;

const productSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCT_SCREEN_SIZE:
      return action.payload;
    default:
      return state;
  }
};

export default productSizeReducer;