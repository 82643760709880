import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import avatar from '../../assets/icons/profile/avatarHeaderIcon.svg';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import * as actions from '../../actions/actions';

import { lkNotification, lkWishlist, lkOrder } from '../../assets/icons/svgIcons';

const CheckoutDropdown = (props) => {
  const {
    closeDropdown, redirectTo, user, languagePrefix, translate,
  } = props;
  return (
    <StyledCheckoutDropdown className="header-dropdown">
      <div className="header-dropdown__greeting-wrap">
        <div className="header-dropdown__user-data">
          <div className="header-dropdown__icon-wrap">
            <img src={avatar} alt="avatar" decoding="async" loading="lazy" />
          </div>
          <div className="header-dropdown__name">
            <p className="header-dropdown__text">{translate('Welcome')}, {user && user.name ? user.name : null}</p>
            <p className="header-dropdown__text header-dropdown__text--grow">{translate('NiceToSeeYouAgain')}!</p>
            <p
              className="header-dropdown__link"
              onClick={() => {
                props.actions.logOutUser();
                closeDropdown();
                redirectTo(`${languagePrefix}/`);
              }}
            >
              {translate('LogOut')}
            </p>
          </div>
        </div>
        <div className="header-dropdown__links">
          <p className="header-dropdown__text header-dropdown__text--light">{translate('HelpIsNeeded?')}</p>
          <p className="header-dropdown__text">
            {translate('GoToTheSupportPageAndAskUsAQuestion1')} <a href="https://t.me/Budusushibot" target="_blank" rel="noreferrer" className="header-dropdown__link" onClick={closeDropdown}>{translate('GoToTheSupportPageAndAskUsLink')}</a> {translate('GoToTheSupportPageAndAskUsAQuestion2')}<br />
            {translate('GoToTheSupportPageAndAskUsAQuestion3')}
          </p>
        </div>
      </div>
      <div className="header-dropdown__captures">
        <div className="header-dropdown__profile" onClick={() => redirectTo(`${languagePrefix}/profile`)}>
          <div className="header-dropdown__heading-wrap">
            {lkNotification}
            <p className="header-dropdown__heading">{translate('MyProfile')}</p>
          </div>
          <p className="header-dropdown__text">{translate('TextHereYouCanEdit')}</p>
        </div>
        <div className="header-dropdown__parts">
          <div className="header-dropdown__part" onClick={() => redirectTo(`${languagePrefix}/profile/orders-history`)}>
            <div className="header-dropdown__heading-wrap">
              {lkOrder}
              <p className="header-dropdown__heading">{translate('MyOrders')}</p>
            </div>
            <p className="header-dropdown__text header-dropdown__text--dark">
              {translate('TextViewTheStatusOfYourActiveOrders')}
            </p>
          </div>
          <div className="header-dropdown__part" onClick={() => redirectTo(`${languagePrefix}/profile/favourites`)}>
            <div className="header-dropdown__heading-wrap">
              {lkWishlist}
              <p className="header-dropdown__heading">{translate('MyFavorites')}</p>
            </div>
            <p className="header-dropdown__text header-dropdown__text--dark">
              {translate('TextEditYourFavoriteProducts')}
            </p>
          </div>
        </div>
      </div>
    </StyledCheckoutDropdown>
  );
};

const StyledCheckoutDropdown = styled.div`
  --page-background: #0A0A0A;
  --transition-time: .2s;

  max-width: 1230px;
  padding: 1.5rem 0;
  margin: 0 auto;
  display: flex;
  
  .header-dropdown {
  
    &__icon-wrap {
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--text-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    &__greeting-wrap {
      flex-basis: 35%;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    &__user-data {
      display: flex;
    }
    
    &__name {
      padding-left: 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    
    &__text {
      font-size: .9rem;
      transition: color var(--transition-time);
      line-height: 1.2rem;
      
      &--grow {
        flex-grow: 1;
      }
      
      &--light {
        color: var(--active-text-color);
      }
      
      &--dark {
        color: var(--text-color);
        font-size: .9rem;
      }
    }
    
    &__link {
      color: var(--links-color);
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__links {
      color: var(--text-color);
      padding-top: 2rem;
    }
    
    &__captures {
      flex-basis: 64%;
    }
    
    &__profile {
      color: var(--text-color);
      text-align: justify;
      padding-bottom: 1rem;
      margin-bottom: .6rem;
    }
    
    &__parts {
      display: flex;
      justify-content: space-between;
    }
    
    &__part {
      flex-basis: 40%;
    }
    
    &__profile:hover,
    &__part:hover {
      cursor: pointer;
      
      .header-dropdown__heading {
        color: var(--links-color);
      }

      .header-dropdown__text {
        color: var(--active-text-color);
      }
      
      path {
        stroke: var(--links-color);
      }
    }
    
    &__heading-wrap {
      display: flex;
      padding-bottom: .5rem;
      
      path {
        transition: var(--transition-time);
      }
    }
    
    &__heading {
      margin-left: 1rem;
      color: var(--active-text-color);
      text-transform: uppercase;
      font-weight: 100;
      transition: color var(--transition-time);
    }
  }
`;

const mapStateToProps = state => ({
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  cart: state.cart ? state.cart : null,
  user: state.user ? state.user : null,
  stores: state.stores ? state.stores : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDropdown);
