export default function getPluralTranslate(count) {
  const mod = count % 10;

  if (mod === 1 && count < 10) {
    return 'One';
  }
  if (mod >= 2 && mod <= 4) {
    return 'Few';
  }
  return 'Other';
}
