import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../modules/shared/keyByLang';

import vacancies from '../../assets/icons/vacancies.svg';

const TopicsList = ({
  faq, currentLanguage, clickHandler, redirectToVacancies,
}) => {
  const title = keyByLang('title', currentLanguage);
  let topicList = null;
  const vacancyBlock = {
    image_filename: vacancies,
    id: 'faq_vacancies',
    title_ru: 'Вакансии',
    title_ua: 'Вакансії',
    faqs: [],
  };
  if (faq && faq[0]) {
    topicList = [...faq, vacancyBlock];
  }
  return (
    <StyledTopicList>
      {topicList && topicList[0] && topicList.map((item, i) => (
        <li
          className="topic-list__item-wrap"
          onClick={() => (i !== topicList.length - 1 ? clickHandler(item.id, item[title]) : redirectToVacancies())}
          key={item.id}
        >
          <label className="topic-list-item">
            <input className="topic-list-item__radio" type="radio" name="support-category" hidden />
            <figure className="topic-list-item__figure">
              <div className="topic-list-item__image-wrap">
                <img
                  className="topic-list-item__image" src={item.image_filename}
                  alt={`icon${item.id}`}
                  decoding="async"
                  loading="lazy"
                />
              </div>
              <figcaption className="topic-list-item__figcaption">{item[title]}</figcaption>
            </figure>
          </label>
        </li>
      ))}
    </StyledTopicList>
  );
};


const StyledTopicList = styled.ul`
  --main-color: #fff;
  --secondary-color: #8E8E8E;
  --calm-opacity: .6;
  --active-opacity: 1;

  position: relative;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 50px;
    height: 2px;
    background-image: linear-gradient(90deg, #8E8E8E, #8E8E8E 50%, transparent 50%, transparent 100%);
    background-size: 8px 2px, 100% 3px;
    opacity: .3;
  }
  
.topic-list__item-wrap {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 20px;
  text-align: center;
}
  
.topic-list-item {
  cursor: pointer;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main-color);
  
  &__radio:checked + .topic-list-item__figure {
    opacity: var(--active-opacity);
  }
  
  &__figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: var(--calm-opacity);
    transition: opacity .1s;
  }

  &__image-wrap {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main-color);
    border-radius: 5px;
  }
  
  &__image {
    max-width: 100%;
    max-width: 3.5rem;
    max-height: 3.5rem;
  }
    
  &__figcaption {
    padding-top: 10px;
    transition: .1s;
    max-width: 120px;
    font-size: 1rem;
  }
  
  &:hover {
    cursor: pointer;
    
    & > .topic-list-item__figure {
      opacity: var(--active-opacity);
      
      & > .topic-list-item__image-wrap {
        box-shadow: 0 0 150px rgba(255, 255, 255, .3) inset;
      }
    }
  }
}

@media screen and (min-width: 360px) {
  &::after {
    left: 10px;
    right: 10px;
  }
  .topic-list__item-wrap {
    flex-basis: calc(100% / 3);
  }
}

@media screen and (min-width: 768px) { 
  &::after {
    left: 50px;
    right: 50px;
  }
  .topic-list__item-wrap {
    flex-basis: 25%;
  }
}
`;

export default TopicsList;
