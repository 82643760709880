import React from 'react';
import styled from 'styled-components';

import ProgressBar from './progress-bar/ProgressBar';
import OrderProductsList from './orderProductsList/OrderProductsList';
import OrderAmount from './order-amount/OrderAmount';
import OrderDeliveryData from './order-delivery/OrderDeliveryData';
import OrderFooter from './order-footer/OrderFooter';
import keyByLang from '../../modules/shared/keyByLang';
import thankYou from '../../assets/images/thankYou.jpg';

const ThankYouPage = ({
  lastOrder,
  categories,
  currentLanguage,
  orderAccessories,
  deliveryMethod,
  paymentMethod,
  currentRestaurant,
  showStore,
  currentStatuses,
  isMobile,
  setTimeToShow,
  settings,
  languagePrefix,
  storeToShow,
  getProductData,
  translate,
}) => {
  const createdTime = setTimeToShow(lastOrder.created_at);
  const doneTime = setTimeToShow(lastOrder.delivery_date);

  const title = keyByLang('title', currentLanguage);
  const description = keyByLang('description', currentLanguage);
  let footerFaqData = null;
  let pageData = null;
  let imagePath = null;

  if (settings && settings.syspages) {
    footerFaqData = settings.syspages.find(page => page.page_const === 'feedback');
    pageData = settings.syspages.find(page => page.page_const === 'thankyou');
  }

  if (pageData) {
    imagePath = pageData.image_filename_header;
  }

  if (imagePath && (imagePath.indexOf('(') || imagePath.indexOf(')'))) {
    const imageArr = imagePath.split('');
    for (let i = imageArr.length - 1; i >= 0; i--) {
      if (imageArr[i] === '(' || imagePath[i] === ')') {
        imageArr.splice(i, 0, '\\');
      }
    }
    imagePath = imageArr.join('');
  }

  return (
    <StyledThankYouPage className="thank-you" pageBanner={imagePath ? imagePath : thankYou}>
      <div className="thank-you__container">
        <div className="thank-you__header">
          <ProgressBar
            className="thank-you__progress"
            currentStatuses={currentStatuses}
            currentLanguage={currentLanguage}
            lastOrder={lastOrder}
            isMobile={isMobile}
            startDate={createdTime}
            deliveryDate={doneTime}
          />
        </div>
        <div className="thank-you__content">
          <h1 className="thank-you__heading">{translate('ThanksForYourOrder')}</h1>
          <hr className="solid thank-you__line" />
          <div className="thank-you__order-wrap">
            <div className="thank-you__order-data">
              <div className="thank-you__order-name-wrap">
                <p className="thank-you__order-name">{translate('YourOrderNumber')} № {lastOrder.id}</p>
                <hr className="solid thank-you__line" />
              </div>
              <OrderProductsList
                products={lastOrder.products}
                categories={categories}
                currentLanguage={currentLanguage}
                getProductData={getProductData}
                translate={translate}
              />
              <p className="thank-you__order-name thank-you__order-name--padded">{translate('ItemsIncluded')}</p>
              <div className="thank-you__accessories-wrap">
                {orderAccessories && orderAccessories[0] ? orderAccessories.map(accessorie => (
                  <p
                    className="thank-you__accessorie"
                    key={accessorie.id}
                  >
                    {accessorie[title]} x{accessorie.orderQuantity}
                  </p>
                )) : null}
              </div>
              <hr className="dashed" />
              <OrderAmount
                lastOrder={lastOrder}
                paymentMethod={paymentMethod}
                currentLanguage={currentLanguage}
                translate={translate}
              />
            </div>
            <div className="thank-you__delivery-data">
              {deliveryMethod && lastOrder ? (
                <OrderDeliveryData
                  deliveryMethod={deliveryMethod}
                  currentLanguage={currentLanguage}
                  lastOrder={lastOrder}
                  currentRestaurant={currentRestaurant}
                  showStore={showStore}
                  storeToShow={storeToShow}
                  translate={translate}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <OrderFooter
        isMobile={isMobile}
        footerFaqData={footerFaqData}
        title={title}
        description={description}
        languagePrefix={languagePrefix}
        translate={translate}
      />
    </StyledThankYouPage>
  );
};

const StyledThankYouPage = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --disabled-text-color: #313131;
  
  color: var(--text-color);
  font-family: 'fs_elliot_pro';
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 1rem);
  
  .thank-you {
  
    &__container {
      flex-grow: 1;
    }
    
    &__header {
      height: 250px;
      text-align: center;
      position: relative;
      background: radial-gradient(640.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%),
                  url(${props => (props.pageBanner ? props.pageBanner : 'null')});
      background-repeat: no-repeat;
      background-position: center;
    }
    
    &__content {
      max-width: 1230px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
    }
    
    &__heading {
      color: var(--active-text-color);
      font-weight: 100;
      font-size: 1.4rem;
      margin: 2.4rem 0 1.4rem;
      text-align: center;
      position: absolute;
      top: 5rem;
      left: 0;
      width: 100%;
    }
    
    &__order-wrap {
      padding: 1rem 0 2rem;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
    }
    
    &__order-name-wrap {
      display: inline-block;
    }
    
    &__order-name {
      font-size: 1.25rem;
      
      &--padded {
        padding-top: 2.5rem;
        padding-bottom: .8rem;
      }
    }
    
    &__accessories-wrap {
      padding-bottom: 1.25rem;
      display: flex;
      flex-wrap: wrap;
    }
    
    &__accessorie {
      padding-right: .6rem;
      
      &:not(:last-child)::after {
        content: ',';
      }
    }
    
    &__line {
      display: none;
    }
  }
  
  @media all and (min-width: 769px) {
    .thank-you{
      &__order-wrap {
        flex-direction: row;
      }
      
      &__order-data {
        flex-basis: 50%;
      }
      
      &__delivery-data {
        flex-basis: 50%;
        padding: 0 12%;
      }
      
      &__heading {
        color: var(--text-color);
        position: static;
      }
      
      &__line {
        display: block;
      }
      
      &__order-name {
        padding-bottom: 1rem;
      }
    }
  }
`;

export default ThankYouPage;
