import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const AccessoriesApi = {
  getAccessoriesList() {
    return axiosInstance.post('accessories',
      {},
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
  getAccessoriesTypesList() {
    return axiosInstance.post('accessory-types',
      {},
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default AccessoriesApi;
