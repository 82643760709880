import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ModalWrapper from '../shared/ModalWrapper';

import keyByLang from '../../modules/shared/keyByLang';

import { questionsIcon } from '../../assets/icons/lottieIcons';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';

import * as actions from '../../actions/actions';

class BonusesPage extends React.PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return this.props.actions.getTextPages();
  }

  render() {
    const { textPages, currentLanguage } = this.props;
    const { pathname } = this.props.history.location;
    const currentAlias = pathname.slice(pathname.lastIndexOf('/') + 1);
    let pageData = null;

    const title = keyByLang('title', currentLanguage);
    const text = keyByLang('text', currentLanguage);

    if (textPages && textPages[0]) {
      pageData = textPages.find(data => data.alias === currentAlias);
    }

    return (
      <ModalWrapper modalId="BonusPage">
        <StyledBonusPage className="bonus-page">
          <div className="bonus-page__icon-wrap">
            <Lottie
              options={questionsIcon}
              width={60}
            />
          </div>
          {pageData ? (<h1 className="bonus-page__heading">{pageData[title]}</h1>) : null}
          <hr className="solid" />
          {pageData ? (
            <div className="bonus-page__content" dangerouslySetInnerHTML={{ __html: pageData[text] }} />
          ) : null}
        </StyledBonusPage>
      </ModalWrapper>
    );
  }
}

const StyledBonusPage = styled.div`
  --text-color: #8E8E8E;

  max-width: 630px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  .bonus-page {
  
    &__icon-wrap {
      margin-top: 5rem;
      text-align: center;
    }
    
    &__heading {
      color: var(--text-color);
      font-weight: 200;
      text-align: center;
      font-size: 1.5rem;
      margin: 3rem 0 1.3rem;
    }
    
    &__content {
      margin: 2.5rem 0;
      text-align: justify;
    }
  }
`;

const StyledTextPage = styled.div`
  --text-color: #8E8E8E;
  
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  padding: 40px 15px;
  font-family: 'fs_elliot_pro';
  
  .text-page__heading {
     font-size: 1.4rem;
     color: #fff;
     position: relative;
     padding-bottom: 20px;
     font-weight: 100;
     color: var(--text-color);
     
     &::after {
        content: ""; 
        width: 100%;
        height: 1px;
        display: block;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 50%, rgba(0,0,0,0) 100%);
        position: absolute;
        bottom: 0;
    }
  }
  
  .text-page__text-wrap {
    padding: 20px 0;
  }
`;

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  textPages: state.textPages ? state.textPages : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusesPage);
