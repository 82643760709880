import * as types from '../actions/actionTypes';

const cartToCheckoutStepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CARTTOCHECKOUTSTEPS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  cartSubmitInProgress: false,
  activeCrossSaleCategories: null,
  skipCrossSaleChoosing: false,
  submitCrossSaleChoosing: false,
  crossSaleStepsSubmitedOnce: false,
  cartSubmitted: false,
  activeTabIndex: 0,
};

export default cartToCheckoutStepsReducer;
