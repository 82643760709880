import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import * as actions from '../../actions/actions';
import ProfileOrdersList from './orders/ProfileOrdersList';

import lkOrder from '../../assets/icons/profile/lk_order.svg';
import lkHistory from '../../assets/icons/profile/lk_history.svg';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import getProductById from '../../modules/shared/getProductById';

import imageEmpty from '../../assets/icons/empty_product.svg';
import ProfileOrdersHistory from './orders/ProfileOrdersHistory';
import filterAddonsByTime from '../../modules/shared/filterAddonsByTime';

class ProfileOrdersHistoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.today = new Date();

    this.addToCart = this.addToCart.bind(this);
    this.checkAddonsBeforeAddToCart = this.checkAddonsBeforeAddToCart.bind(this);
    this.isTimeActive = this.isTimeActive.bind(this);
    this.isDayActive = this.isDayActive.bind(this);
    this.getProductData = this.getProductData.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }

  addToCart(product) {
    const addons = (product.tabs && product.tabs.length > 0 && product.tabs[0].addons)
      ? product.tabs[0].addons
      : null;
    this.props.actions.cartAdd(product.id, addons);
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Add To Cart',
        eventCategory: 'Customer Actions',
        eventAction: 'Add To Cart',
        eventLabel: `${product.id}`,
        eventValue: `${product.price}`,
      });
    }
  }

  checkAddonsBeforeAddToCart(product) {
    const { languagePrefix, categories } = this.props;
    let filteredAddons = null;
    if (product.tabs && product.tabs[0]) {
      filteredAddons = filterAddonsByTime(product.tabs);
    }
    if (product.tabs && product.tabs[0] && filteredAddons && filteredAddons[0]) {
      const productCategory = categories.find(cat => product.product_category_id === cat.id).alias;
      this.props.history.push(`${languagePrefix}/${productCategory}/${product.alias}`);
    } else {
      this.addToCart(product);
    }
  }

  isDayActive = (product) => {
    const since = new Date(product.visible_since).getTime();
    const till = new Date(product.visible_till).getTime();
    if (!product.visible_since && !product.visible_till) {
      return true;
    }
    return this.today >= since && this.today <= till;
  }

  isTimeActive = (product) => {
    const hours = this.today.getHours();
    let minutes = this.today.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const currentTime = +`${hours}${minutes}`;
    if (product.available_since && product.available_till) {
      const since = +product.available_since.split(':').join('');
      const till = +product.available_till.split(':').join('');
      return currentTime > since && currentTime < till;
    }
    return true;
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getOrdersHistory(),
      this.props.actions.getOrderStatuses(),
      this.props.actions.getCategories(),
      this.props.actions.getDeliveryMethods(),
      this.props.actions.getPaymentMethods(),
      this.props.actions.getUser(),
    ]);
  }

  redirectTo(path) {
    window.scrollTo(0, 0);
    this.props.history.push(path);
  }

  getProductData(orderProduct) {
    const categoriesProduct = getProductById(orderProduct.id, this.props.categories);
    if (!categoriesProduct.id) {
      return orderProduct;
    }
    if (categoriesProduct.id) {
      return {
        ...categoriesProduct,
        price: categoriesProduct.price,
        quantity: categoriesProduct.quantity,
        id: categoriesProduct.id,
      };
    }
  }

  render() {
    const {
      ordersHistory,
      statuses,
      currentLanguage,
      deliveryMethods,
      paymentMethods,
      isMobile,
      languagePrefix,
      translate,
    } = this.props;
    let activeOrders = null;
    let completedOrders = null;

    if (ordersHistory && ordersHistory[0]) {
      activeOrders = ordersHistory.filter((order) => +order.status_id !== 5);
      completedOrders = ordersHistory.filter((order) => +order.status_id === 5);
    }

    // isOrderActive & completedOrder - just props that shows different behaviour
    return (
      <StyledOrders className="orders">
        <div className="profile__heading-wrap">
          <img src={lkOrder} className="profile__heading-icon" alt="user" decoding="async" loading="lazy" />
          <h2 className="profile__heading">{translate('ActiveOrders')}</h2>
        </div>
        <hr className="solid" />
        <div className="orders__content">
          {activeOrders && activeOrders[0] ? (
            <ProfileOrdersList
              orders={activeOrders.reverse()}
              statuses={statuses}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              currentLanguage={currentLanguage}
              addToCart={this.checkAddonsBeforeAddToCart}
              isMobile={isMobile}
              languagePrefix={languagePrefix}
              isTimeActive={this.isTimeActive}
              isDayActive={this.isDayActive}
              getProductData={this.getProductData}
              imageEmpty={imageEmpty}
              redirectTo={this.redirectTo}
              translate={translate}
              isOrderActive
            />
          ) : (
            <p className="orders__empty">{translate('CurrentlyNoActiveOrders')}</p>
          )}
        </div>
        <div className="profile__heading-wrap">
          <img src={lkHistory} className="profile__heading-icon" alt="user" decoding="async" loading="lazy" />
          <h2 className="profile__heading">{translate('PurchaseHistory')}</h2>
        </div>
        <hr className="solid" />
        <div className="orders__content orders__content--second">
          {completedOrders && completedOrders[0] ? (
            <ProfileOrdersHistory
              orders={completedOrders.reverse()}
              statuses={statuses}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              addToCart={this.checkAddonsBeforeAddToCart}
              languagePrefix={languagePrefix}
              isTimeActive={this.isTimeActive}
              isDayActive={this.isDayActive}
              isMobile={isMobile}
              getProductData={this.getProductData}
              redirectTo={this.redirectTo}
              translate={translate}
              isOrderActive
            />
          ) : (
            <p className="orders__empty">{translate('OrderHistoryIsEmpty')}</p>
          )}
        </div>
      </StyledOrders>
    );
  }
}

const mapStateToProps = (state) => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  statuses: state.statuses ? state.statuses : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  categories: state.categories ? state.categories : null,
  paymentMethods: state.paymentMethods ? state.paymentMethods : null,
  deliveryMethods: state.deliveryMethods ? state.deliveryMethods : null,
  isMobile: state.isMobile ? state.isMobile : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const StyledOrders = styled.div`
  --dashed-border-color: #313131;

  .orders {
    &__content {
      padding: 1.25rem 0 0;
      
      &--second {
        padding: 1rem 0 6rem;;
      }
    }
    
    &__empty {
      color: var(--text-color);
      font-size: 1.5rem;
      text-align: center;
    }
  }
  
@media all and (min-width: 769px) {
  .orders {
    &__content {
      padding: 2.5rem 0 6rem;
    }
  }
}
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileOrdersHistoryPage));
