import React from 'react';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/actions';
import Profile from '../components/profile/Profile';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import fixImagePath from '../modules/shared/fixImagePath';

class ProfileContainer extends React.Component {

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getUser(),
      this.props.actions.getBonusData(),
    ]);
  }

  render() {
    const {
      user, currentLanguage, languagePrefix, isMobile, settings, translate,
    } = this.props;

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find(page => page.page_const === 'profile');
      bannerImagePath = fixImagePath(pageData);
    }

    return (user ? (
      <Profile
        bannerImage={bannerImagePath}
        user={user}
        currentLanguage={currentLanguage}
        languagePrefix={languagePrefix}
        logOut={this.props.actions.logOutUser}
        getUser={this.props.actions.getUser}
        translate={translate}
        isMobile={isMobile}
      />
    ) : null);
  }
}

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  user: state.user ? state.user : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  isMobile: state.isMobile,
  settings: state.settings ? state.settings : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
