/* eslint-disable operator-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import Lottie from 'react-lottie';

import * as storage from '../../../utils/storage';
import ModalWrapper from '../../shared/ModalWrapper';
import DatePickerInlineHOC from '../../shared/DatePickerInlineHOC';
import DeferredOrderPeriods from './DeferredOrderPeriods';
import DeferredOrderTimeSlots from './DeferredOrderTimeSlots';
import MainBtn from '../../shared/MainBtn';

import * as actions from '../../../actions/actions';
import { hourGlassIcon } from '../../../assets/icons/lottieIcons';

class CheckoutDeferredOrder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: this.setSavedDate(),
      activePeriodId: this.setSavedPeriod(),
      timeStep: this.setSavedTimeStep(),
    };

    this.deliveryMethod = this.props.orderData.Orders.delivery_method_id;

    this.handleDeferredChange = this.handleDeferredChange.bind(this);
    this.submitTime = this.submitTime.bind(this);
    this.getOrderTime = this.getOrderTime.bind(this);
    this.getOrderDate = this.getOrderDate.bind(this);
    this.compareDate = this.compareDate.bind(this);
    this.setSavedDate = this.setSavedDate.bind(this);
    this.setSavedPeriod = this.setSavedPeriod.bind(this);
    this.setSavedTimeStep = this.setSavedTimeStep.bind(this);
    this.generateTimeArea = this.generateTimeArea.bind(this);
  }

  static formatTimeAverage(data) {
    return +data < 10 ? `0${data}` : data;
  }

  setSavedDate() {
    const orderTime = JSON.parse(storage.session.getItem('orderTime'));
    if (orderTime && orderTime.time) {
      return orderTime.time.split(' ')[0];
    }
    return null;
  }

  setSavedPeriod() {
    const orderTime = JSON.parse(storage.session.getItem('orderTime'));
    if (orderTime && orderTime.timeSlot) {
      return orderTime.timeSlot;
    }
    return '1';
  }

  setSavedTimeStep() {
    const orderTime = JSON.parse(storage.session.getItem('orderTime'));
    if (orderTime && orderTime.time) {
      const timeToShow = orderTime.time.split(' ')[1];
      return timeToShow.slice(0, timeToShow.lastIndexOf(':'));
    }
    return null;
  }

  getTimeToReady(date, additionalTime) {
    let minutes = date.getMinutes() + +additionalTime;
    let hours = date.getHours();
    const seconds = CheckoutDeferredOrder.formatTimeAverage(date.getSeconds());
    if (minutes >= 60) {
      const difference = Math.floor(minutes / 60);
      hours = hours + difference;
      minutes = minutes - difference * 60;
    }
    hours = CheckoutDeferredOrder.formatTimeAverage(hours);
    minutes = CheckoutDeferredOrder.formatTimeAverage(minutes);
    return `${hours}:${minutes}:${seconds}`;
  }

  getDateWithTimeStep(date, timeStep) {
    if (!date) {
      const time = new Date();
      let month = time.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      date = `${time.getFullYear()}-${month}-${time.getDate()}`;
    }
    return `${date} ${timeStep}:00`;
  }

  getOrderTime(timeSlot) {
    const additionalTime = this.deliveryMethod === 1
      ? timeSlot.order_total_time_pickup
      : timeSlot.order_total_time_delivery;
    const time = new Date();
    return this.getTimeToReady(time, additionalTime);
  }

  getOrderDate(date) {
    const tergetDate = date ? new Date(date) : new Date();
    const month = CheckoutDeferredOrder.formatTimeAverage(tergetDate.getMonth() + 1);
    const day = CheckoutDeferredOrder.formatTimeAverage(tergetDate.getDate());
    return `${tergetDate.getFullYear()}-${month}-${day}`;
  }

  handleDeferredChange(data) {
    this.setState(prevState => ({
      ...prevState,
      ...data,
    }));
  }

  compareDate(date) {
    const dateData = new Date(date.split(' ')[0]);
    const today = new Date();
    return dateData.getDate() === today.getDate()
      && dateData.getFullYear() === today.getFullYear()
      && dateData.getMonth() === today.getMonth();
  }

  submitTime() {
    const activeTimeSlot = this.props.timeSlots
      .find(slot => +slot.id === +this.state.activePeriodId);
    const targetDate = this.getOrderDate(this.state.date);
    // if (+activeTimeSlot.id === 1 && (!this.state.date || this.compareDate(this.state.date))) {
    //   storage.session.removeItem('orderTime');
    // }
    // if (+activeTimeSlot.id === 1 && this.state.date && !this.compareDate(this.state.date)) {
    //   const orderTime = this.getOrderTime(activeTimeSlot);
    //   storage.session.setItem('orderTime', JSON.stringify({
    //     time: `${targetDate} ${orderTime}`,
    //     activePeriodId: this.state.activePeriodId,
    //   }));
    //   this.props.handleModalClose();
    //   return;
    // }
    if (!this.state.timeStep) {
      return this.props.showSnackBar(this.props.translate('ChooseTime'), 4000, false);
    }
    if (this.state.timeStep) {
      storage.session.setItem('orderTime', JSON.stringify({
        time: `${targetDate} ${this.state.timeStep}:00`,
        timeSlot: this.state.activePeriodId,
      }));
      this.props.handleModalClose();
      return;
    }
    this.props.handleModalClose();
  }

  generateTimeArea() {
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));
    const takeoutStore = JSON.parse(storage.session.getItem('takeOutStore'));
    const lastAddress = JSON.parse(storage.session.getItem('deliveryAddress'));

    let store = null;

    if (takeoutStore && takeoutStore.address && takeoutStore.address.id && this.props.stores) {
      store = this.props.stores.find(storeData => +storeData.id === +takeoutStore.address.id);
    }

    if (lastDeliveryType === 'takeOutStore' && store && store.id) {
      return {
        startHours: store.opened_since_hour,
        startMinutes: store.opened_since_minute,
        endHours: store.opened_till_hour,
        endMinutes: store.opened_till_minute,
      };
    } else if (lastDeliveryType === 'deliveryAddress' && lastAddress) {
      const minStartTime = this.props.stores.reduce((res, storeData) => {
        const startPrevTime = +`${res.opened_since_hour}${res.opened_since_minute}`;
        const startCurrentTime = +`${storeData.opened_since_hour}${storeData.opened_since_minute}`;
        return startPrevTime < startCurrentTime ? res : storeData;
      });
      const maxEndTime = this.props.stores.reduce((res, storeData) => {
        const endPrevTime = +`${res.opened_till_hour}${res.opened_till_minute}`;
        const endCurrentTime = +`${storeData.opened_till_hour}${storeData.opened_till_minute}`;
        return endPrevTime > endCurrentTime ? res : storeData;
      });
      return {
        startHours: minStartTime.opened_since_hour,
        startMinutes: minStartTime.opened_since_minute,
        endHours: maxEndTime.opened_till_hour,
        endMinutes: maxEndTime.opened_till_minute,
      };
    }
    return {
      startHours: '10',
      startMinutes: '00',
      endHours: '22',
      endMinutes: '00',
    };
  }

  render() {
    const {
      handleModalClose,
      timeSlots,
      currentLanguage,
      timeSlotInterval,
      isMobile,
      translate,
    } = this.props;
    const { activePeriodId, date, timeStep } = this.state;
    let orderTime;
    if (timeSlots && timeSlots[0] && activePeriodId) {
      const activeTimeSlot = timeSlots.find(slot => +slot.id === +activePeriodId) || timeSlots[0];
      orderTime = this.getOrderTime(activeTimeSlot);
    }

    const timeArea = this.generateTimeArea();

    const timeSlotsContent = timeSlots && timeSlots[0] ? (
      <DeferredOrderTimeSlots
        activePeriodId={activePeriodId}
        deliveryMethod={this.deliveryMethod}
        timeSlots={timeSlots}
        timeslotInterval={timeSlotInterval}
        handleDeferredChange={this.handleDeferredChange}
        orderTime={orderTime}
        chosenTimeStep={timeStep}
        activeTimeArea={timeArea}
        day={date}
        translate={translate}
      />
    ) : null;

    return (
      <ModalWrapper handleModalClose={handleModalClose} modalId="DeferredOrder">
        <StyledDeferredOrder className="deferred-order container">
          <header className="deferred-order__header">
            <div className="deferred-order__icon-wrap">
              <Lottie
                options={hourGlassIcon}
              />
            </div>
            <h1 className="deferred-order__heading">{translate('DeferredOrder')}</h1>
            <p className="deferred-order__description">
              {translate('SelectTimeInterval')}
            </p>
            <hr className="solid padded" />
          </header>
          <div className="deferred-order__content">
            <div className="deferred-order__item-wrap">
              <p className="deferred-order__description deferred-order__description--top">
                {translate('ChooseDay')}
              </p>
              <DatePickerInlineHOC
                handleDeferredChange={this.handleDeferredChange}
                startDate={this.state.date}
                lang={currentLanguage}
                className="checkout-calendar"
                inline
              />
            </div>
            <hr className="solid deferred-order__line" />
            <div className="deferred-order__item-wrap  deferred-order__item-wrap--central">
              <p className="deferred-order__description deferred-order__description--secondary">
                {translate('ChoosePeriod')}
              </p>
              <DeferredOrderPeriods
                activePeriodId={activePeriodId}
                timeSlots={timeSlots}
                currentLanguage={currentLanguage}
                handleDeferredChange={this.handleDeferredChange}
              />
              <hr className="solid deferred-order__line" />
            </div>
            <div className="deferred-order__item-wrap deferred-order__item-wrap--right">
              <p className="deferred-order__description deferred-order__description--bottom">
                {translate('ChooseTime')}
              </p>
              {timeSlotsContent}
            </div>
          </div>
          <div className="deferred-order__conclusion">
            {!isMobile ? (
              <p className="deferred-order__description deferred-order__description--secondary">
                {translate('PleaseIndicateConvenientTime')}
              </p>
            ) : null}
            <MainBtn
              href="#"
              minWidth="130px"
              iconName="store"
              text={translate('ConfirmButton')}
              clickHandle={this.submitTime}
              addClass="deferred-order__button"
            />
          </div>
        </StyledDeferredOrder>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => ({
  timeSlots: state.settings ? state.settings.timeslots : null,
  settings: state.settings ? state.settings : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  headers: state.settings ? state.headers : null,
  timeSlotInterval: state.settings ? state.settings.timeslot_interval : null,
  isMobile: state.isMobile,
  stores: state.stores ? state.stores : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeferredOrder);

const StyledDeferredOrder = styled.div`
  --text-color: #8E8E8E;
  --secondary-text-color: #FFFFFF;
  --disabled-color: #616161;
  
  color: var(--text-color);
  
  .deferred-order {
  
    &__header {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    &__icon-wrap {
      max-width: 4rem;
    }
    
    &__heading {
      font-size: 1.4rem;
      font-weight: 100;
      padding: 1rem 0;
    }
    
    &__description {
      text-align: center;
      font-size: .9rem;
      color: var(--secondary-text-color);
      
      &--top {
        padding-bottom: 1.25rem;
      }
      
      &--secondary {
        padding: 2rem 1rem 1.25rem;
      }
      
      &--central {
        text-align: center;
      }
      
      &--bottom {
        padding: 2rem 1rem 1.25rem;
        text-align: center;
      }
    }
    
    &__content {
      padding: 1.4rem 0 5rem;
      display: flex;
      flex-direction: column;
    }
    
    &__item-wrap {
      position: relative;

      
      &--right {
        text-align: center;
      }
    }
    
    &__conclusion {
      padding-top: 1.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    &__message {
      color: var(--secondary-text-color);
      display: inline-block;
      text-align: center;
      min-width: 15.4rem;
    }
    
    &__line {
      display: block;
    }
    
    &__button {
      position: fixed;
      bottom: 0;
      z-index: 12;
      left: 0;
      padding: 0 15px;
      margin-bottom: 0;
  
      .button {
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
        width: 100%;
        border-radius: 4px 4px 0 0;
      }
    }
  }
  
  .padded {
    margin-top: 1.2rem;
    max-width: 30rem;
  }
  
@media all and (min-width: 769px) {
  .deferred-order {
    &__header {
      margin-top: 5rem;
    }
    
    &__description {
      text-align: left;
      
      &--top {
        
      }
      
      &--secondary {
        padding: 0 0 1.25rem;
      }
      &--bottom {
        text-align: center;
        padding: 0 0 1.25rem;
      }
    }
    
    &__content {
      padding: 1.4rem 0;
      /*display: flex;*/
      flex-direction: row;
    }
    
    &__item-wrap {
      padding: 0 1rem;
    }
    
    &__item-wrap {
      flex-basis: 28%;
      
      &--central {
        flex-basis: 44%;
        padding: 0 8rem;
      }
        
      &--right {
        text-align: right;
      }
      
      &:not(:first-child)::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, #333 50%, rgba(0,0,0,0) 100%);
      }
    }
    &__line {
      display: none;
    }
    
    &__message {
      text-align: left;
    }
    
    &__button {
      position: static;
      margin-bottom: 1.5rem;
      
      .button {
        width: auto;
        border-radius: 4px;
        box-shadow: 0;
      }
    }
  }
}

@media all and (min-width: 1281px) {
  .deferred-order {
    &__content {
      padding: 1.4rem 0 2rem;
    }
  }
}
`;
