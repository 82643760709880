import React from 'react';
import styled from 'styled-components';

const TitleBlock = ({ title, subtitle, secondary }) => {
  const subtitleText = subtitle
    ? <p className="subtitle">{subtitle}</p>
    : null;

  return (
    <StyledTitileBlock className="border-solid">
      <h5 className={`title ${secondary ? 'title--secondary' : ''}`}>{title}</h5>
      {subtitleText}
    </StyledTitileBlock>
  );
};

const StyledTitileBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin: 1.25rem 0 1.25rem;
    
    &--secondary {
      font-size: 20px;
    }
  }

  .subtitle {
    text-align: center;
    margin-bottom: 1rem;
  }

 
`;

export default TitleBlock;
