import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Styled components for container and button
const ReviewText = styled.p`
  &.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 6; /* Limit to 6 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.expanded {
    display: block;
    -webkit-line-clamp: unset;
    overflow: visible;
  }
`;

const ShowMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  padding: 0;
  margin-top: 5px;

  &:hover {
    color: #ddd;
  }
`;

function ProductReviewText({ reviewMessage, translate }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // const reviewRef = useRef(null);
  const collapsedRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (collapsedRef.current) {
      const fullHeight = collapsedRef.current.scrollHeight;
      const collapsedHeight = collapsedRef.current.clientHeight;

      if (fullHeight - collapsedHeight > 10) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, [reviewMessage]);

  return (
    <div>
      <ReviewText
        ref={collapsedRef}
        className={isExpanded ? 'expanded' : 'collapsed'}
        dangerouslySetInnerHTML={{ __html: reviewMessage.replace(/\r?\n|\r/g, '<br>') }}
      />

      {/* Conditionally render the "Show More" button only if overflowing */}
      {isOverflowing && (
        <ShowMoreButton onClick={toggleExpand}>
          {isExpanded ? translate('Collapse') : translate('Expand')}
        </ShowMoreButton>
      )}
    </div>
  );
}

export default ProductReviewText;
