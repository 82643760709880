import React from 'react';
import styled from 'styled-components';
import getProductById from '../../../modules/shared/getProductById';
import keyByLang from '../../../modules/shared/keyByLang';

const OrderProductItem = ({
  orderProduct,
  categories,
  currentLanguage,
  getProductData,
  translate,
}) => {
  const product = getProductData(orderProduct);
  let addons = null;

  if (orderProduct.addons) {
    const keys = Object.keys(orderProduct.addons);
    addons = keys.map(key => ({
      ...getProductById(key, categories),
      addonPrice: orderProduct.addons[key],
    }));
  }

  const title = keyByLang('title', currentLanguage);
  return (
    <React.Fragment>
      <StyledOrderProduct className="order-product products-list__item" data-product-id={product.id} key={`${product.id}-${Math.random()}`}>
        <p className="order-product__name">{orderProduct.quantity}  {product[title] || product.title}</p>
        <p className="order-product__price">{product.price * orderProduct.quantity} грн</p>
      </StyledOrderProduct>
      <hr className="dashed" />
      {addons && addons[0] && orderProduct ? addons.map(addon => (
        <React.Fragment key={`${addon}-${Math.random()}`}>
          <StyledOrderProduct className="order-product products-list__item">
            <p className="order-product__name">{orderProduct.quantity} {addon[title]}</p>
            <p className="order-product__price">{addon.addonPrice ? `${orderProduct.quantity * addon.addonPrice} грн` : translate('Free')}</p>
          </StyledOrderProduct>
          <hr className="dashed" />
        </React.Fragment>
      )) : null}
    </React.Fragment>
  );
};

const StyledOrderProduct = styled.li`
  --border-color: #313131;
  --price-color: #FFFFFF;
  
  display: flex;
  justify-content: space-between;
  padding: .6rem 0;
  
  .order-product {
    
    &__name {
      font-weight: 700;
    }
    
    &__price {
      color: var(--price-color);
    }
  }
`;

export default OrderProductItem;
