import * as types from '../actions/actionTypes';

const initialState = {
  isPopupActive: true,
  isOnThankYouPage: false,
};

const faqPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ON_THANK_YOU_PAGE:
      return {
        ...state,
        isOnThankYouPage: action.payload,
      };
    case types.SET_IS_POPUP_ACTIVE:
      return {
        ...state,
        isPopupActive: action.payload,
      };
    default:
      return state;
  }
};

export default faqPopupReducer;
