import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions/actions';

import ProfileBlockTitle from './ProfileBlockTitle';
import MainBtn from '../shared/MainBtn';
import ModalWrapper from '../shared/ModalWrapper';
import GetNumberStep from '../authorization/GetNumberStep';
import GetUserDataStep from '../authorization/GetUserDataStep';

import { personalIcon, filterDone } from '../../assets/icons/svgIcons';
import phone from '../../assets/icons/form/phone.svg';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';

class ProfileUserBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showChangePhoneHandler: false,
      changeUserData: false,
    };

    this.phonePath = /^\+380(67|68|96|97|98|50|66|95|99|63|73|93)\d{3}\d{2}\d{2}$/;
    this.emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.errors = {
      incorrectOperator: this.props.translate('SmsPasswordRequestFailedMessage'),
      emptyPhone: this.props.translate('PhoneNumberCanNotBeEmptyMessage'),
      notFullPhone: this.props.translate('NotFullPhoneNumber'),
    };
    this.showChangePhoneHandler = this.showChangePhoneHandler.bind(this);
  }

  showChangePhoneHandler = () => {
    this.setState((prevState, props) => ({
      showChangePhoneHandler: !prevState.showChangePhoneHandler,
    }));
  }

  showChangeUserData = () => {
    this.setState((prevState, props) => ({
      changeUserData: !prevState.changeUserData,
    }));
  }

  updateUserName = (data, func, form) => {
    const username = data.username ? data.username : undefined;
    if (username && username.length === 13 && this.phonePath.test(username)) {
      const valueToSend = username.slice(username.indexOf('0'));
      this.props.actions.updateUserName({ 'User[username]': valueToSend }).then((res) => {
        if (res.payload && res.payload.status) {
          this.props.actions.getUser();
          this.showChangePhoneHandler();
          this.props.history.push(`${this.props.languagePrefix}/`);
          this.props.actions.showSnackBar(this.props.translate('DataSavedSuccessfully'), 4000, true);
        } else if (res.payload && !res.payload.status) {
          this.props.actions.showSnackBar(this.props.translate('ThisUserExist'), 4000, false);
          form.reset();
        }
      });
    }
    if (username && username.length === 13 && !this.phonePath.test(username)) {
      this.props.actions.showSnackBar(this.errors.incorrectOperator, 4000, false);
    } else if (username && username.length === 4) {
      this.props.actions.showSnackBar(this.errors.emptyPhone, 4000, false);
    } else if (username && username.length > 4 && username.length < 13) {
      this.props.actions.showSnackBar(this.errors.notFullPhone, 4000, false);
    }
  }

  handleUserUpdate = (data, func, form) => {
    const { translate } = this.props;
    const {
      name, sex, email, birth_date,
    } = data;
    const userDataObj = {};
    const birthDate = new Date(birth_date);

    if (name && sex && email && birthDate) {
      const dateToSend = `${birthDate.getFullYear()}-${birthDate.getMonth() + 1}-${birthDate.getDate()}`;
      userDataObj['User[name]'] = name;
      userDataObj['User[sex]'] = sex;
      userDataObj['User[email]'] = email;
      userDataObj['User[birth_date]'] = dateToSend;
    }
    this.props.actions.updateUser(userDataObj).then((res) => {
      if (res.status === 200 && res.data && res.data.status) {
        this.props.actions.showSnackBar(translate('DataChangedSuccessfully'), 2000, true);
        setTimeout(() => this.showChangeUserData(), 1000);
      } else if (res.status === 200 && res.data && !res.data.status) {
        this.props.actions.showSnackBar(translate('EmailExist'), 4000, false);
      }
    });
  }

  render() {
    const { user, isMobile, translate } = this.props;
    const { showChangePhoneHandler, changeUserData } = this.state;
    let sex = '';
    if (user && +user.sex === 1) {
      sex = `${translate('MaleSex')}`;
    }

    if (user && +user.sex === 0) {
      sex = `${translate('FemaleSex')}`;
    }

    return (
      <StyledUserData className="user-data">
        <ProfileBlockTitle
          icon={personalIcon}
          headerBlockAction="action"
          actionText={translate('Edit')}
          action={this.showChangeUserData}
        >
          {translate('PersonalData')}
        </ProfileBlockTitle>
        <hr className="solid" />
        <div className="user-data__content">
          <div className="user-data__block">
            <div className="user-data__field-wrap">
              <p className="user-data__field-name">{translate('FirstAndLastName')}:</p>
              <p className="user-data__information">{user ? user.name : ''}</p>
            </div>
            <div className="user-data__field-wrap">
              <p className="user-data__field-name">E-mail:</p>
              <p className="user-data__information">{user ? user.email : ''}</p>
            </div>
            <div className="user-data__field-wrap">
              <p className="user-data__field-name">{translate('Gender')}:</p>
              <p className="user-data__information">{sex}</p>
            </div>
            <div className="user-data__field-wrap">
              <p className="user-data__field-name">{translate('BirthDay')}:</p>
              <p className="user-data__information">{user ? user.birth_date : ''}</p>
            </div>
          </div>
          <div className="user-data__block">
            <div className="user-data__field-wrap">
              <p className="user-data__field-name">{translate('YourPhone')}:</p>
              <p className="user-data__information">{user && user.id ? `+38${user.username}` : ''}</p>
            </div>
            {/*<div className="user-data__button-wrap">*/}
            {/*  <MainBtn*/}
            {/*    align="left"*/}
            {/*    clickHandle={this.showChangePhoneHandler}*/}
            {/*    text={translate('ChangePhoneNumberButton')}*/}
            {/*    minWidth="180px"*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        {isMobile ? (
          <hr className="dashed user-data--with-padding" />
        ) : (
          <hr className="solid" />
        )}
        {showChangePhoneHandler ? (
          <ModalWrapper handleModalClose={this.showChangePhoneHandler} modalId="ProfileNumberStep">
            <StyledModalContent className="user-data__modal-content">
              <GetNumberStep
                icon={phone}
                buttonIcon={filterDone}
                username={user ? user.username : ''}
                onSubmit={this.updateUserName}
                showSnackBar={this.props.actions.showSnackBar}
                heading={translate('ChangingPhoneText')}
                buttonText={translate('ChangePhoneNumberButton')}
                translate={translate}
              />
            </StyledModalContent>
          </ModalWrapper>
        ) : null}
        {changeUserData ? (
          <ModalWrapper handleModalClose={this.showChangeUserData} modalId="ProfileUserDataStep">
            <StyledModalContent className="user-data__modal-content">
              <GetUserDataStep
                onSubmit={this.handleUserUpdate}
                authState={user}
                initialValues={user}
                showSnackBar={actions.showSnackBar}
                translate={translate}
                enableReinitialize
              />
            </StyledModalContent>
          </ModalWrapper>
        ) : null}
      </StyledUserData>
    );
  }
}

const StyledUserData = styled.div`
  .user-data {
    &__content {
      padding: 2.5rem 0 2rem;
      display: flex;
      flex-direction: column;
    }
    
    &__block {
       flex-basis: 50%;
       display: flex;
       position: relative;
       flex-direction: column;
    }
    
    &__field-wrap {
      display: flex;
      padding-bottom: 1rem;
      font-size: .9rem;
    }
    
    &__field-name {
      color: var(--text-color);
      padding-right: 1rem;
    }
    
    &__button-wrap {
      padding-top: 1rem;
      
      .button {
        width: 100%;
      }
    }
    
    &--with-padding {
      margin-bottom: 1rem;
    }
  }
  
  @media all and (min-width: 768px) {
    .user-data {
      &__field-wrap {
        padding-bottom: 1.5rem;
        font-size: 1rem;
      }
      
      &__field-name {
        min-width: 11.2rem;
      }
      
      &__information {
      }
    }
  }
  
  @media all and (min-width: 1025px) {
    .user-data {
      &__field-wrap {
        padding-bottom: 1.5rem;
      }
      
      &__field-name {
        min-width: 11.2rem;
      }
      
      &__information {
        
      }
      
      &__block {
        &:first-child::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        background-image: linear-gradient(to top, var(--dashed-border-color) 20%, transparent 20%, transparent 80%, var(--dashed-border-color) 80%);
        background-size: 2px 6px;
      }
      
        &:last-child {
          margin-left: 2.5rem;
        }
      }
      &__content {
        flex-direction: row;
      }
      
      &__button-wrap {
        padding-top: 1rem;
        
        .button {
           width: auto;
        }
      }
    }
  }
`;

const StyledModalContent = styled.div`
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
`;

const mapStateToProps = state => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  form: state.form ? state.form : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  isMobile: state.isMobile,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileUserBlock));
