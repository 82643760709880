import * as types from '../actions/actionTypes';

const initialState = null;

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORDER:
      return action.payload;
    case types.SET_SEPARATED_ACCESSORIES:
      return {
        ...state,
        freeAccessories: {
          ...action.payload.freeAccessories,
        },
        extraAccessories: {
          ...action.payload.extraAccessories,
        },
        accessories: {
          ...action.payload.accessories,
        },
        freeAccessoriesByTypes: {
          ...action.payload.freeAccessoriesByTypes,
        },
        extraAccessoriesByTypes: {
          ...action.payload.extraAccessoriesByTypes,
        },
      };
    case types.SET_ACCESSORIES_UPDATE_SUCCESS:
      return {
        ...state,
        shouldAccessoriesUpdate: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
