import React from 'react';
import styled from 'styled-components';

const PageHeaderBlock = ({ image, addClass }) => (
  <StyledDiv
    className={`page-header-wrapper ${addClass && addClass} `}
    image={image}
  />
);

const StyledDiv = styled.div`
  min-height: 10rem;
  background: radial-gradient(630.48px at 50.41% 50.71%, rgba(0, 0, 0, 0.1) 0%, #000000 100%), url(${props => (props.image ? props.image : null)}) center no-repeat;
  background-size: contain;
  width: 100%;

  @media all and (min-width: 768px) {
    height: 250px;
  }
`;

export default PageHeaderBlock;
