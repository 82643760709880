import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm, reset } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import Input from '../shared/form/Input';
import Select from '../shared/form/Select';
import TextArea from '../shared/form/TextArea';
import MainBtn from '../shared/MainBtn';
import InputFile from '../shared/form/InputFile';

import faqFormIcons from '../../assets/images';

import validateJobForm from './validateJobForm/validateJobFrom';
import * as actions from '../../actions/actions';

// onSubmit from props -> available is handleSubmit

const JobOffersForm = (props) => {
  const {
    handleSubmit, currentLanguage, vacancies, chooseVacancyId, translate,
  } = props;
  const { valid } = props;

  const noSubmit = (e) => {
    e.preventDefault();
    const errors = props.formData.syncErrors;
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      props.actions.showSnackBar(errors[keys[0]], 4000, false);
    }
  };

  return (
    <StyledFaqForm onSubmit={valid ? handleSubmit : noSubmit} id="job-offer-form">
      <Field
        name="username"
        component={Input}
        type="tel"
        label={translate('BhtThPhone')}
        icon={faqFormIcons.phone}
      />
      <Field
        name="name"
        component={Input}
        type="text"
        label={translate('FirstAndLastName')}
        icon={faqFormIcons.name}
      />
      <Field
        name="email"
        component={Input}
        type="email"
        label="Email"
        icon={faqFormIcons.email}
      />
      {vacancies && vacancies[0] ? (
        <Field
          name="profession"
          component={Select}
          type="select"
          label={translate('Vacancy')}
          icon={faqFormIcons.problem}
          language={currentLanguage}
          options={vacancies}
          chooseItemId={chooseVacancyId}
        />
      ) : null}
      <Field
        name="message"
        component={TextArea}
        type="textarea"
        label={translate('YourMessagePlaceholder')}
        icon={faqFormIcons.pickup}
      />
      <Field
        name="cv"
        component={InputFile}
        id="cv_input"
        buttonText={translate('ChooseFileButton')}
        label={translate('ChooseResumeText')}
        accept=".pdf, .doc, .docx"
      />
      <div className="submit-wrap">
        <MainBtn
          text={translate('SendButton')}
          align="center"
          type="submit"
          minWidth="200px"
        />
      </div>
    </StyledFaqForm>
  );
};

const StyledFaqForm = styled.form`
  --disabled-button: #616161;
  .submit-wrap {
    padding: 20px;
  }
  
  .add-file {
    display: flex;
    align-items: center;
    padding-bottom: 1.25rem;
    
    &__title {
      margin-right: 1rem;
    }
    
    &__button {
      border: 1px solid #E74E1A;
      border-radius: 4px;
      background-color: transparent;
      color: #E74E1A;
      padding: .4rem 1.25rem;
      
      &:hover {
        cursor: pointer;
      }
    }
    
    &__submit {
      & button {
        background-color: var(--disabled-button);
        
        &:hover {
          background-color: var(--disabled-button);
        }
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
  formData: state.form ? state.form['job-offer'] : null,
  initialValues: state.user && state.user.id ? {
    username: `+38${state.user.username}`,
    email: `${state.user.email}`,
    name: `${state.user.name}`,
  } : {
    username: '+380',
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'job-offer',
    validate: validateJobForm,
    enableReinitialize: true,
  }),
)(JobOffersForm);
