import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import SimpleInput from '../../shared/form/SimpleInput';
import MainBtn from '../../shared/MainBtn';

const CartPromocode = ({ translate, onChange, value }) => {
  const [isPromocodeActive, setPromocodeActivity] = useState(false);
  // const [inputValue, changeInputValue] = useState('');

  return (
    <StyledCartPromocode className="cart-promocode">
      {isPromocodeActive ? (
        <Fragment>
           <input
             className="cart-promocode__input"
             type="text"
             placeholder={`${translate('SpecifyPromocode')}...`}
             onChange={e => onChange(e.target.value)}
             defaultValue={value}
           />
          <button
            className="cart-promocode__button"
            type="button"
            onClick={() => setPromocodeActivity(false)}
          >
            Ok
          </button>
        </Fragment>
      ) : (
        <p
          className="cart-promocode__link"
          onClick={() => setPromocodeActivity(true)}
        >
          {translate('HavePromotionalCode')}
        </p>
      )}
    </StyledCartPromocode>
  );
};

const StyledCartPromocode = styled.div`
  display: flex;
  justify-content: space-between;
  .cart-promocode {
    width: 100%;
    
    &__link {
      text-decoration: none;
      font-weight: 100;
      color: var(--links-color);
      font-size: 0.75rem;
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    &__input {
      width: 100%;
      flex-grow: 1;
      background-color: #1C1C1C;
      border-radius: 4px;
      border: 1px solid ${props => (props.error ? 'red' : '#313131')};
      color: #616161;
      margin-right: 10px;
      font-size: 12px;
      height: 32px;
      padding: 5px;
      font-weight: 100;
      margin-bottom: 0;
    }
    
    &__button {
      color: #fff;
      border-radius: 4px;
      background-color: #E74E1A;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
      font-size: 12px;
      text-align: center;
      box-sizing: border-box;
      font-weight: 900;
      text-transform: uppercase;
      text-decoration: none;
      border: none;
      outline: none;
      cursor: pointer;
      min-width: 40px;
      overflow: hidden;
  
      &:hover {
        background-color: #BB3517;
      }
      
      &:active {
        box-shadow: 0 0 15px rgba(0,0,0, .4) inset;
      }
    }
  }
  
  @media all and (min-width: 1440px) {
    .cart-promocode {
      &__button {
        min-width: 60px;
      }
    }
  }
`;

export default React.memo(CartPromocode);
