import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const IngredientsApi = {
  getIngredientsList() {
    return axiosInstance.post('ingredients',
      {},
      { headers: headers.json });
  },
};

export default IngredientsApi;
