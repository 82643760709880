/* eslint-disable no-param-reassign */
import React from 'react';
import { Translate } from 'react-localize-redux';

const userDataValidation = (value) => {
  const errors = {};
  const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (value.name) {
    value.name = value.name.replace(/[^a-zA-Zа-яА-Яа-яА-ЯЇїІіЙйҐґ ]/g, '');
  }
  if (value.name && value.name.length > 38) {
    value.name = value.name.slice(0, 38);
  }
  if (!value.name) {
    errors.name = <Translate id="FillTheName" />;
  }
  if (!value.email) {
    errors.email = <Translate id="FillEmail" />;
  }
  if (!emailRegExp.test(value.email)) {
    errors.email = <Translate id="IncorrectEmail" />;
  }
  if (!value.birth_date) {
    errors.birth_date = 'empty';
  }
  return errors;
};

export default userDataValidation;
