/* eslint-disable no-param-reassign */
import React, { createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getActiveLanguage, Translate, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import Lottie from 'react-lottie';

import HeaderDropdown from './shared/headerDropdown/HeaderDropdown';
import * as storage from '../utils/storage';

import {
  logoIcon, cartIcon, storeIcon, loginIcon, menuIcon, franchiseIcon,
} from '../assets/icons/lottieIcons';


import LanguageSelector from './LanguageSelector';
import getLanguagePrefix from '../modules/shared/getLanguagePrefix';
import * as actions from '../actions/actions';


class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHoveredStore: false,
      isHoveredCart: false,
      isHoveredUser: false,
      isHoveredMenu: false,
      isHoveredFranchise: false,
      isDropdownActive: false,
      dropdownType: null,
      isHideable: false,
      headerStatus: true,
    };

    this.dropdownTransitionTime = 0.6;
    this.timerToHide = null;
    this.deliveryLine = null;

    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    //this.onScroll = this.onScroll.bind(this);
    this.hideHeader = this.hideHeader.bind(this);
    this.showHeader = this.showHeader.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const { history, catalogPaths } = props;
    if (catalogPaths.includes(history.location.pathname) !== prevState.isHideable) {
      return {
        isHideable: catalogPaths.includes(history.location.pathname),
      };
    }
    return null;
  }

  componentDidMount() {
    this.fetchData();
    // this.props.actions.getCityDisctrics();
    // window.addEventListener('scroll', this.onScroll);
    // this.deliveryLine = document.querySelector('.delivery-line');
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.onScroll);
  // }

  onHover = (element) => {
    this.setState({
      [element]: true,
    });
  };

  onLeave = (element) => {
    this.setState({
      [element]: false,
    });
  };

  // onScroll(e) {
  //   const { isHideable, headerStatus } = this.state;
  //   if (!isHideable || !this.deliveryLine || this.deliveryLine.offsetWidth > 1366) {
  //     return;
  //   }
  //   const offsetTop = this.deliveryLine.offsetWidth <= 768 ? 718 : 730;
  //   if (this.deliveryLine.offsetTop > offsetTop && headerStatus) {
  //     this.hideHeader();
  //     this.props.actions.attachDelivery(false);
  //   }
  //   if (this.deliveryLine.offsetTop <= offsetTop && !headerStatus) {
  //     this.showHeader();
  //     this.props.actions.attachDelivery(true);
  //   }
  // }

  hideHeader() {
    this.setState({ headerStatus: false });
  }

  showHeader() {
    this.setState({ headerStatus: true });
  }

  getProductsCount = products => products.reduce((prev, prod) => prev += prod.quantity || 1, 0);

  fetchData() {
    return this.props.actions.getUser();
  }

  showDropdown(type) {
    clearTimeout(this.timerToHide);
    this.setState({
      isDropdownActive: true,
      dropdownType: type,
    });
  }

  hideDropdown() {
    this.timerToHide = setTimeout(() => {
      this.setState({ dropdownType: null });
    }, this.dropdownTransitionTime * 1000);
    this.setState({
      isDropdownActive: false,
    });
  }

  redirectTo(path) {
    window.scrollTo(0, 0);
    this.hideDropdown();
    this.props.history.push(path);
  }

  render() {
    const {
      user, languagePrefix, scrollToTop, cart, translate, catalogPaths,
    } = this.props;
    const { headerStatus, isHideable } = this.state;
    let cartQuantity = 0;
    const checkedStore = JSON.parse(storage.session.getItem('takeOutStore'));
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));

    if (cart && cart.content) {
      cartQuantity = this.getProductsCount(cart.content);
    }

    let storesPath = `${languagePrefix}/stores`;

    if (checkedStore && checkedStore.address && lastDeliveryType === 'takeOutStore') {
      storesPath = `${languagePrefix}/stores/${checkedStore.address.id}`;
    }

    const userActionBtn = user && user.id ? (
      <Link
        className="header-link"
        to={`${languagePrefix}/profile`}
        onMouseEnter={() => {
          this.onHover('isHoveredUser');
          this.showDropdown('checkout');
        }}
        onMouseLeave={() => {
          this.onLeave('isHoveredUser');
        }}
        onClick={this.hideDropdown}
      >
        <span className="link-name" hidden>{user.name}</span>
        <span className="nav-icon nav-icon--login nav-icon--active">
          <Lottie
            options={loginIcon}
            isStopped={!this.state.isHoveredUser}
          />
        </span>
        <p className="nav__user-bonuses">{user.bonuses_total}</p>
      </Link>
    ) : (
      <Link
        className="header-link"
        to={{
          pathname: (`${languagePrefix}/authorization`),
          state: { modal: true },
        }}
        onMouseEnter={() => this.onHover('isHoveredUser')}
        onMouseLeave={() => this.onLeave('isHoveredUser')}
      >
        <span className="link-name" hidden><Translate id="LoginRegistration" /></span>
        <span className="nav-icon nav-icon--login">
          <Lottie
            options={loginIcon}
            isStopped={!this.state.isHoveredUser}
          />
        </span>
      </Link>
    );

    return (
      <StyledHeader
        className="main-header"
        onMouseLeave={this.hideDropdown}
        isVisible={headerStatus}
      >
        <nav className="wide-container">
          <ul className="nav">
            <li className="nav__home nav-item" onClick={scrollToTop} onMouseEnter={this.hideDropdown}>
              <Link className="logo" to={`${languagePrefix}/`}>
                <Lottie options={logoIcon} />
              </Link>
            </li>
            <li className="nav__menu nav-item">
              <div
                className="header__navigation-open header-link"
                onMouseEnter={() => this.onHover('isHoveredMenu')}
                onMouseLeave={() => this.onLeave('isHoveredMenu')}
              >
                <span className="nav-icon nav-icon--menu">
                  <Lottie
                    options={menuIcon}
                    isStopped={!this.state.isHoveredMenu}
                  />
                </span>
                <span className="link-name" hidden>Меню</span>
              </div>
              {/* <div className="header__navigation-open header-link"> */}
              {/* <img className="nav-icon nav-icon__menu" src={menuIcon} alt="menu" /> */}
              {/* <span className="link-name" hidden>Меню</span> */}
              {/* </div> */}
            </li>
            <li className="nav__stores nav-item">
              <a
                className="header-link"
                href="https://franchise.budusushi.ua"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => {this.onHover('isHoveredFranchise')}}
                onMouseLeave={() => this.onLeave('isHoveredFranchise')}
                onClick={this.hideDropdown}
              >
                <span className="link-name" hidden>Франшиза</span>
                <span className="nav-icon nav-icon--store">
                  <Lottie
                    options={franchiseIcon}
                    isStopped={!this.state.isHoveredFranchise}
                  />
                </span>
              </a>
            </li>
            <li className="nav__stores nav-item">
              <Link
                className="header-link"
                to={storesPath}
                onMouseEnter={() => {
                  this.onHover('isHoveredStore');
                  this.showDropdown('stores');
                }}
                onMouseLeave={() => this.onLeave('isHoveredStore')}
                onClick={this.hideDropdown}
              >
                <span className="link-name" hidden><Translate id="OurStore" /></span>
                <span className="nav-icon nav-icon--store">
                  <Lottie
                    options={storeIcon}
                    isStopped={!this.state.isHoveredStore}
                  />
                </span>
              </Link>
            </li>
            <li className="nav__cart nav-item">
              <Link
                className="header-link"
                to={{
                  pathname: `${languagePrefix}/cart`,
                  state: { modal: true },
                }}
                onMouseEnter={() => {
                  this.onHover('isHoveredCart');
                }}
                onClick={this.hideDropdown}
                onMouseLeave={() => this.onLeave('isHoveredCart')}
              >
                <span className="link-name"><Translate id="Cart" /></span>
                <span className={cartQuantity ? 'nav-icon nav-icon--cart nav-icon--active' : 'nav-icon nav-icon--cart'}>
                  <Lottie
                    options={cartIcon}
                    isStopped={!this.state.isHoveredCart}
                  />
                </span>
                {cartQuantity ? (
                  <p className="nav__cart-quantity">{cartQuantity}</p>
                ) : null}
              </Link>
            </li>
            <li className="nav__login nav-item">
              {userActionBtn}
            </li>
            <li className="nav__language nav-item">
              <LanguageSelector />
            </li>
            <li className="nav__toggle-btn nav-item">
              <label className="menu-btn" htmlFor="toggle-sidebar">
                <span />
                <span />
                <span />
              </label>
              <input id="toggle-sidebar" type="checkbox" hidden />
            </li>
          </ul>
        </nav>
        <HeaderDropdown
          type={this.state.dropdownType}
          closeDropdown={this.hideDropdown}
          redirectTo={this.redirectTo}
          isVisible={this.state.isDropdownActive}
          transitionTime={this.dropdownTransitionTime}
          languagePrefix={languagePrefix}
        />
      </StyledHeader>
    );
  }
}

const mapStateToProps = state => ({
  languagePrefix: getLanguagePrefix(getActiveLanguage(state.locale).code),
  cart: state.cart ? state.cart : null,
  user: state.user ? state.user : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

const StyledHeader = styled.div`
  --links-color: #E74E1A;
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  
  display: flex;
  align-items: stretch;
  position: relative;
  top: 0;
  width: 100%;
  //background: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.8) 65%, transparent 100%);
  //background: linear-gradient(180deg, #000000 0%, #000000 40%, rgba(0,0,0,0.8) 70%, rgba(0,0,0,0.4) 100%);
  background-color: #000000;
  z-index: 25;
  height: 4rem;
  
  &::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: -9px;
    height: 10px;
    background-color: #0A0A0A;
  }
  
  .header {
    &__navigation-open {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    padding: 0;
    width: 80px;
    height: 47px;

    &__img {
      width: 100%;
    }

    &:hover {
      opacity: 1;
      filter: drop-shadow(0 0 1px #777);
    }
  }

  .header-link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    padding-left: .5rem;
    color: #999;
    transition: color .2s ease-out;
    cursor: pointer;
    user-select: none;
    
    path {
      transition: fill .2s ease-out;
    }

    &:hover {
      color: var(--links-color);
      
      path {
        fill: var(--links-color);
      }
    }
  }

  .nav {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .5rem;

    &-item {
      display: flex;
      font-size: 1rem;

      a {
        display: flex;
        align-items: center;
      }

      .icon {
        width: 1rem;
        margin: 0 .5rem;
      }

      &__side-menu-btn {
        width: 1rem;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }
    }
    
    &-icon {
      width: 1rem;
      height: 1rem;
      margin-left: .4rem;
      transition: background-color .2s ease-out;
      
      &--store {
        width: 20px;
        height: 20px;
      }
        
      &--login {
        height: 26px;
        width: 22px;
      }
        
      &--cart {
        width: 16px;
        height: 20px;
        margin-bottom: .15rem;
      }
      
      &--menu {
        margin-bottom: .15rem;
        margin-right: .4rem;
      }
      
      &--active {
        path {
          fill: #FFFFFF;
        }
      }
      
      &__menu {
        margin-right: .3rem;
        min-width: 19px;
      }
    }

    &__menu {
      .link-name {
        display: inline-block;
        font-weight: 700;
      }
    }

    &__login,
    &__stores,
    &__language {
      display: none;
    }

    &__cart .link-name {
      display: none;
    }
    
    &__cart-quantity {
      height: 18px;
      min-width: 18px;
      padding: 0 3px;
      border-radius: 50%;
      background-color: var(--links-color);
      color: #FFFFFF;
      text-align: center;
      padding-top: 3px;
      margin-left: 3px;
      font-size: .6rem;
      font-weight: 100;
    }
    
    &__user-bonuses {
      //width: 18px;
      height: 18px;
      min-width: 18px;
      padding: 0 3px;
      border-radius: 50%;
      background-color: var(--links-color);
      color: #FFFFFF;
      text-align: center;
      padding-top: 3px;
      font-size: .6rem;
      font-weight: 100;
    }

    .menu-btn {
      margin-left: 1rem;
      width: 2rem;
      height: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;

      > span {
        width: 100%;
        height: 2px;
        background-color: #fff;

        :nth-child(2) {
          width: 85%;
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent 10%, #555 50%, transparent 60%);
  }

  @media (min-width: 768px) {
    height: 6rem;
    //top: ${props => (props.isVisible ? 0 : '-100%')};
    //display: ${props => (props.isVisible ? 'block' : 'none')};
    transform: translate(0, ${props => (props.isVisible ? '0' : '-100px')});
    transition: 1s;
    
    .menu {
      display: none;
    }

    .logo {
      margin-right: 0.5rem;
      width: 5rem;
    }

    .nav {
      padding: 0 .5rem;

      &-item {
        padding: 0 .1rem;
        a {
          display: flex;
          align-items: center;
        }

        .icon {
          width: 1rem;
          margin: 0 .5rem;
        }
      }
      
      
      
      &-icon {
        width: 1rem;
        height: 1rem;
        
        &--store {
          width: 21px;
          height: 20px;
        }
        
        &--login {
          height: 26px;
          width: 22px;
        }
        
        &--cart {
          width: 16px;
          height: 20px;
          margin-right: 0;
        }
      }

      &__login,
      &__stores,
      &__language {
        display: inline-block;
      }
    }
  }
  
  .menu-btn {
    margin-left: .5rem;
  }
  
  @media (min-width: 769px) {
    .nav {
      &__menu {
        //visibility: hidden;
      }
    }
  }

  @media (min-width: 1024px) {
    height: 6rem;

    .logo {
      padding: 0;
      width: 100px;
      height: 59px;
    }

    .nav {
      &-item {
        padding: 0 .5rem;

        .link-name {
          display: block;
        }

        a {
          display: flex;
          align-items: center;
        }

        .icon {
          width: 1rem;
          margin: 0 .5rem;
        }
      }
    }
  }
  
  @media (min-width: 1367px) {
    transform: none;
  }
`;
