import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Consumer } from './context';

export default class Portal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canUseDOM: false,
    };
  }

  componentDidMount() {
    this.setState({ canUseDOM: true });
  }

  render() {
    const { children, selector, portalId } = this.props;
    const { canUseDOM } = this.state;

    if (!portalId) {
      throw Error('Missing required prop portalId in Portal');
    }

    return (
      <Consumer>
        {(addServerTag) => {
          if (addServerTag == null) {
            throw Error('<PortalProvider /> should be in the tree');
          }

          if (canUseDOM) {
            return ReactDOM.createPortal(children, document.querySelector(selector));
          }

          addServerTag(children, selector, portalId);
          return null;
        }}
      </Consumer>
    );
  }
}
