import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../modules/shared/keyByLang';

const QuestionsList = ({
  activeTopicId, faq, currentLanguage, questionClickHandler,
}) => {
  const activeTopicArr = faq
    ? faq.filter(item => (parseInt(item.id, 10) === parseInt(activeTopicId, 10))) : null;

  const activeTopicObj = faq ? activeTopicArr[0] : null;

  const question = keyByLang('question', currentLanguage);

  if (activeTopicObj && activeTopicObj.faqs) {
    return (
      <StyledQuestionsList>
        {
          activeTopicObj.faqs.map(item => (
            <label className="question-list-item" key={item.id} onClick={() => questionClickHandler(item.id)}>
              <input className="question-list-item__radio" type="radio" name="questionList" />
              <span className="question-list-item__text">{item[question]}</span>
            </label>
          ))
        }
      </StyledQuestionsList>
    );
  }
  return null;
};

const StyledQuestionsList = styled.div`
  --main-color: #fff;
  --secondary-color: #8E8E8E;
  --calm-opacity: .6;
  --active-opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  
  &::after {
    content: '';
    display: block;
    margin: 30px 0 20px;
    max-width: 600px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg, var(--secondary-color) 50%, var(--secondary-color) 50%,
                      transparent 50%, transparent 100%);
    background-size: 8px 2px, 600px 3px;
    opacity: .3;
  }
  
.question-list-item {
  width: 100%;
  text-align: center;
  padding: 10px;
  position: relative;
  font-size: 15px;
  font-weight: 700;
  color: var(--secondary-color);
  transition: .2s;
  
  &__radio {
    visibility: hidden;
    height: 0;
    width: 0;
    
    &:checked + .question-list-item__text {
      color: var(--main-color);
    }
  }
  
  &::before {
    content: ""; 
    width: 100%;
    height: 1px;
    display: block;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 50%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
  }
  
  &:hover {
    cursor: pointer;
    color: var(--main-color);
  }
}
`;

export default QuestionsList;
