import logo from './lottie/Logo.json';
import store from './lottie/Stores.json';
import cart from './lottie/Cart.json';
import login from './lottie/Login.json';
import buyProduct from './lottie/Cart added.json';
import buyProduct2 from './lottie/cart added static.json';
import delivery from './lottie/Delivery.json';
import pickUp from './lottie/Pick_up.json';
import Hourglass from './lottie/Hourglass.json';
import questions from './lottie/a-q.json';
import franchise from './lottie/Franchise.json';
import Menu from './lottie/Menu.json';

export const logoIcon = {
  loop: true,
  autoplay: true,
  animationData: logo,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const cartIcon = {
  loop: true,
  autoplay: false,
  animationData: cart,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const menuIcon = {
  loop: true,
  autoplay: false,
  animationData: Menu,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const storeIcon = {
  loop: true,
  autoplay: false,
  animationData: store,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const loginIcon = {
  loop: true,
  autoplay: false,
  animationData: login,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const cartAddIcon = {
  loop: false,
  autoplay: true,
  animationData: buyProduct,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const cartAddStatic = {
  loop: false,
  autoplay: true,
  animationData: buyProduct2,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const deliveryIcon = {
  loop: true,
  autoplay: true,
  animationData: delivery,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const pickUpIcon = {
  loop: true,
  autoplay: true,
  animationData: pickUp,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const hourGlassIcon = {
  loop: true,
  autoplay: true,
  animationData: Hourglass,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const questionsIcon = {
  loop: true,
  autoplay: true,
  animationData: questions,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const franchiseIcon = {
  loop: true,
  autoplay: true,
  animationData: franchise,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
