import * as React from 'react';
import { Provider } from './context';

export default class PortalProvider extends React.Component {
  addServerTag = (node, selector, portalId) => {
    const { portals = {} } = this.props;
    portals[portalId] = { node, selector };
  }

  render() {
    const { portals, children } = this.props;

    if (typeof window === 'undefined' && !portals) {
      throw Error(
        'portals object should be passed to <PortalProvider /> in node',
      );
    }

    return <Provider value={this.addServerTag}>{children}</Provider>;
  }
}
