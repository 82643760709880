import React from 'react';
import { Helmet } from "react-helmet";
// import { Meta, Title } from 'react-head';

const CatalogMeta = ({ activeMeta }) => {

  return activeMeta && activeMeta.title ? (
    <>
      <Helmet>
        <title>{activeMeta.title}</title>
        <meta name="title" content={activeMeta.title} />
        <meta name="description" content={`${activeMeta.description} ${activeMeta.seoText}`} />
        <meta name="keywords" content={activeMeta.keywords} />
      </Helmet>
    </>
  ) : null;
};

export default CatalogMeta;