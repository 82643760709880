/* eslint-disable no-param-reassign,no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions/actions';

import ProfileBlockTitle from './ProfileBlockTitle';
import SimpleInput from '../shared/form/SimpleInput';

import networks from '../../assets/icons/profile/networks.svg';

import { facebook, google } from '../../assets/icons/svgIcons';
import MainBtn from '../shared/MainBtn';

class ProfileSocialsBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      facebookValue: this.props.user.facebook_account ? this.props.user.facebook_account : '',
      googleValue: this.props.user.google_account ? this.props.user.google_account : '',
    };

    this.onChangeFacebook = this.onChangeFacebook.bind(this);
    this.onChangeGoogle = this.onChangeGoogle.bind(this);
    this.submitUserUpdate = this.submitUserUpdate.bind(this);
  }

  onChangeFacebook(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      facebookValue: value,
    }));
  }

  onChangeGoogle(event) {
    const { value } = event.target;
    this.setState(prevState => ({
      googleValue: value,
    }));
  }

  submitUserUpdate(e) {
    e.preventDefault();
    const { translate } = this.props;
    const userData = {
      'User[facebook_account]': this.state.facebookValue,
      'User[google_account]': this.state.googleValue,
    };
    this.props.actions.updateUser(userData).then(res => {
      if (res.status === 200) {
        this.props.actions.showSnackBar(translate('DataChangedSuccessfully'), 2000, true);
      }
    });
  }

  render() {
    const { isMobile, translate } = this.props;
    const { facebookValue, googleValue } = this.state;

    return (
      <StyledSocialNetworks className="social-networks" isMobile={isMobile}>
        <ProfileBlockTitle
          icon={networks}
          headerBlockAction="action"
          paddingTop="60"
          withoutBorder
        >
          {translate('SocialNetwork')}
        </ProfileBlockTitle>
        <hr className="solid" />
        <form className="social-networks__content">
          <div className="social-networks__input-wrap">
            <SimpleInput
              label="Facebook"
              icon={facebook}
              type="text"
              value={facebookValue}
              onChange={this.onChangeFacebook}
            />
          </div>
          <div className="social-networks__input-wrap">
            <SimpleInput
              label="Google+"
              icon={google}
              type="text"
              value={googleValue}
              onChange={this.onChangeGoogle}
            />
          </div>
          <MainBtn
            clickHandle={this.submitUserUpdate}
            text={translate('ConfirmButton')}
            align="left"
            addClass="social-networks__button"
            type="submit"
          />
        </form>
      </StyledSocialNetworks>
    );
  }
}

const StyledSocialNetworks = styled.div`
  .social-networks {
    &__content {
      padding: 1.25rem 0 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    
    &__input-wrap {
      min-width:  ${props => props.isMobile ? '100%' : '440px'}; ;
    }
    
    &__button {
      .button {
        width: 100%;
      }
    }
  }
  
@media all and (min-width: 768px) {
  .social-networks {
    &__content {
      padding: 2.5rem 0 4rem;
    }
    
    &__button {
      .button {
        width: auto;
      }
    }
  }
}
`;

const mapStateToProps = state => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  isMobile: state.isMobile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSocialsBlock);
