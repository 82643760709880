import React from 'react';
import styled from 'styled-components';

import ModalWrapper from '../shared/ModalWrapper';
import AuthorizationSteps from './AuthorizationSteps';

import logo from '../../assets/images/logo.svg';

class AuthorizationModal extends React.Component {
  render() {
    return (
      <ModalWrapper addClass="auth" authModal modalId="authModal">
        <StyledModal className="modal-main">
          <header className="authorization__logo-wrap">
            <img className="authorization__logo-image" src={logo} alt="logo" decoding="async" loading="lazy" />
            <hr className="solid authorization__line" />
          </header>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <AuthorizationSteps {...this.props} isAuthPage showSnackBar={this.showSnackBar} />
        </StyledModal>
      </ModalWrapper>
    );
  }
}

const StyledModal = styled.div`
  --text-color: #FFFFFF;
  --placeholder-color: #616161;

  max-width: 470px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  
  .authorization {
    text-align: center;
    
    &__logo-wrap {
      width: 100%;
      text-align: left;
      
    }
    
    &__logo-image {
      width: 4.5rem;
      margin-bottom: .5rem;
    }
    
    &__header-text {
      padding: 2.4rem 0 1.4rem;
      color: var(--text-color);
      font-size: 1.4rem;
      text-align: center;
    }
    
    &__line {
      display: block;
    }
  }
  
  @media all and (min-width: 768px) {
    //padding-top: 4rem;
    
    .authorization {
      &__logo-wrap {
        padding-top: 60px;
        text-align: center;
      }
      
      &__logo-image {
        width: auto;
        margin-bottom: 0;
      }
      
      &__line {
        display: none;
      }
    }
  }
`;

export default AuthorizationModal;
