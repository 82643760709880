import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import * as actions from '../../actions/actions';

import Category from './Category';
import keyByLang from '../../modules/shared/keyByLang';
import getProductById from '../../modules/shared/getProductById';

import imageEmpty from '../../assets/icons/empty_cart.svg';
import { filterIcon, catalogSize, catalogSizeBig } from '../../assets/icons/svgIcons';

class Categories extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  getTimeToCompare = () => {
    const hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return +`${hours}${minutes}`;
  };

  addToFavourites = (id) => {
    this.props.actions.addToFavourites(id);
  };

  removeFromFavourites = (id) => {
    this.props.actions.removeFromFavourites(id);
  };

  checkIfAllCategoriesEmpty = categories => categories
    .every(category => !category.products || !category.products.length)

  fetchData() {
    return this.props.actions.getUser();
  }

  render() {
    const {
      productTags,
      currentLanguage,
      addToCart,
      categoriesRefs,
      acticvateFilter,
      filteredCategories,
      user,
      productSize,
      isMobile,
      languagePrefix,
      cart,
      translate,
      allCategoriesRef,
      activeCatalogLink,
    } = this.props;
    const title = keyByLang('title', currentLanguage);
    const time = this.getTimeToCompare();

    const categoriesWithFavourites = [...filteredCategories];

    if (user && user.id && user.favourites) {
      categoriesWithFavourites[0].products = user.favourites.reduce((res, favourite) => {
        const product = getProductById(favourite, filteredCategories);
        if (product.id) {
          res.push(product);
        }
        return res;
      }, []);
    }

    const isAllCategoriesEmpty = this.checkIfAllCategoriesEmpty(categoriesWithFavourites);

    return (
      <StyledContentMiddle className="categories">
        <div className="filter">
          <button
            className="filter__button"
            type="button"
            onClick={() => acticvateFilter()}
          >
            {filterIcon}
          </button>
          <button
            className="filter__button"
            type="button"
            onClick={() => this.props.actions.setProductScreenSize(!productSize)}
          >
            {productSize ? catalogSize : catalogSizeBig}
          </button>
        </div>
        <div className="categories__all" ref={allCategoriesRef} data-id="menu" />
        {categoriesWithFavourites.map(category => (
          <Category
            user={user}
            key={category.id}
            id={category.id}
            title={category[title]}
            categoriesRefs={categoriesRefs}
            currentLanguage={currentLanguage}
            products={category.products}
            productTags={productTags}
            addToCart={addToCart}
            currentSize={productSize}
            imageEmpty={imageEmpty}
            addToFavourites={this.addToFavourites}
            removeFromFavourites={this.removeFromFavourites}
            currentTime={time}
            isMobile={isMobile}
            languagePrefix={languagePrefix}
            cart={cart}
            translate={translate}
            activeCategoryId={activeCatalogLink.activeCategory}
          />
        ))}
        {isAllCategoriesEmpty ? (
          <div className="empty-block">
            <div className="empty-block__content-wrap">
              <h2 className="empty-block__heading">{translate('NoResultsMatchingSearchText')}</h2>
              <img
                className="empty-block__image"
                src={imageEmpty}
                alt="empty category"
                decoding="async"
                loading="lazy"
              />
              <p className="empty-block__text">{translate('NoMatchingItemsText')}</p>
            </div>
          </div>
        ) : null}
      </StyledContentMiddle>
    );
  }
}

const StyledContentMiddle = styled.div`
  --active-button-color: #313131;
  --button-color: #1C1C1C;
  --border-radius: 5px;
  
  padding-bottom: 40px;
  position: relative;
  top: 0;
  
  .categories__all {
    height: 300px;
    width: 100%;
    position: absolute;
    top: -100px;
    pointer-events: none;
  }
  
  .filter {
    position: absolute;
    top: 20px;
    right: 0;
    display: flex;
    width: 100%;
    
    &__button {
      /*width: 80px;*/
      width: 50%;
      height: 30px;
      border: 1px solid var(--active-button-color);
      background-color: var(--button-color);
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      
      &:first-child {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
      }
      
      &:last-child {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
      }
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-button-color);
        
        svg {
          opacity: .6;
        }
      }
    }
    
    & svg {
      opacity: .4;
    }
  }
  
  .empty-block {
    color: #8E8E8E;
    
    &__heading {
      text-transform: none;
      font-weight: 200;
      font-size: 1.4rem;
      padding-top: var(--heading-padding-top);
    }
    
    &__content-wrap {
      width: 100%;
      text-align: center;
      padding: 8rem 0 10rem;
    }
    
    &__image {
      margin-top: 4rem;
    }
    
    &__text {
      padding-top: .5rem;
      font-size: 1.2rem;
    }
  }
  
  @media all and (min-width: 767px) {
    .filter {
      top: 80px;
      width: auto;
      
      &__button {
        width: 60px;
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .empty-block__heading {
      font-size: 2em;
    }
  }
  
  @media all and (min-width: 1280px) {
    .filter {
      top: 80px;
      right: 0;
      
      &__button {
        height: 40px;
      }
    }
  }
`;

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  user: state.user ? state.user : null,
  productSize: state.productSize,
  isMobile: state.isMobile,
  cart: state.cart ? state.cart : null,
  translate: getTranslate(state.locale),
  activeCatalogLink: state.activeCatalogLink ? state.activeCatalogLink : null,
  productTags: state.productTags ? state.productTags : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Categories));
