import React from 'react';
import { Translate } from 'react-localize-redux';

const cvValidation = (value) => {
  const errors = {};
  if (!value.cv) {
    errors.cv = <Translate id="AddYourResumeMessage" />;
    return errors;
  }
  const objectType = value.cv.name.split('.').pop();
  if (objectType !== 'doc' && objectType !== 'docx' && objectType !== 'pdf') {
    errors.cv = <Translate id="InvalidFileFormatMessage" />;
  }
  return errors;
};

export default cvValidation;
