import * as types from '../actions/actionTypes';

const snackBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR_SUCCESS:
      return {
        isVisible: true,
        ...action.payload,
      };
    case types.HIDE_SNACKBAR_SUCCESS:
      return {
        isVisible: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState = null;

export default snackBarReducer;
