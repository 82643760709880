import React from 'react';

const ProductHeaderTabItem = ({
  tabIndex,
  tabOnclickHandler,
  activeTabFromState,
  isTabsBlocked,
  buttonCallBack,
  translate,
}) => (
  <li
    className={
      activeTabFromState === tabIndex
        ? 'tabs-block__tab tabs-block__tab--active'
        : 'tabs-block__tab'
    }
    onClick={
      isTabsBlocked
        ? () => buttonCallBack()
        : () => tabOnclickHandler(tabIndex)
    }
  >
    <p className="tabs-block__number">{tabIndex + 1}</p>
    <p className="tabs-block__button-name">{translate('AdditionalDish')}</p>
  </li>
);

export default ProductHeaderTabItem;
