import * as types from "../actions/actionTypes";

const deliveryMethodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DELIVERY_METHODS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const initialState = [];

export default deliveryMethodsReducer;
