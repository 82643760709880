import React from 'react';
import styled from 'styled-components';
import scriptLoader from 'react-async-script-loader';
import LocationSearchInput from '../checkout/LocationSearchInput';
import * as CONSTANTS from '../../modules/shared/mapApiConstants';

class LocationSearchInputLoader extends React.Component {
  constructor(props) {
    super(props);
    this.initLoader = this.initLoader.bind(this);
    this.state = {
      isExternalScriptsLoaded: false,
    };
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.initLoader();
    }
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
      if (isScriptLoadSucceed) {
        this.initLoader();
      } else this.props.onError();
    }
  }

  initLoader = () => {
    this.setState({ isExternalScriptsLoaded: true });
  }

  render() {
    const { isExternalScriptsLoaded } = this.state;
    const content = isExternalScriptsLoaded
      ? <LocationSearchInput {...this.props} />
      : null;

    return (
      <StyledLocation className="styled-location">
        {content}
      </StyledLocation>
    );
  }
}

const StyledLocation = styled.div`
  flex-grow: 1;
`;

export default scriptLoader(
  [`${
    CONSTANTS.gMapsScript + CONSTANTS.gApiKey
  }&libraries=${
    CONSTANTS.gLibraries.places
  },${
    CONSTANTS.gLibraries.geometry
  }`,
  ],
)(LocationSearchInputLoader);
