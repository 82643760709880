import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import AnimatedButton from '../shared/buttons/AnimatedButton';
import keyByLang from '../../modules/shared/keyByLang';

import { activeLike, like } from '../../assets/icons/svgIcons';
import plus from '../../assets/icons/plus.svg';
import getIconByTag from '../../modules/shared/getIconByTag';

const Item = ({
  product,
  productTags,
  currentLanguage,
  addToCart,
  imageEmpty,
  user,
  addToFavourites,
  removeFromFavourites,
  isInFavorite,
  currentTime,
  isMobile,
  languagePrefix,
  cartData,
  currentSize,
  translate,
  categories,
}) => {
  let productTag = null;
  let isProductAvailable = true;
  let isInCart = false;
  let buttonData = null;
  let portionQuantity;
  const productIcon = getIconByTag(product.tag_id);

  const productCategory = categories.find((cat) => product.product_category_id === cat.id).alias;

  if (product) {
    portionQuantity = product.portion_quantity;
  }
  // if (product && (+product.is_set || +product.is_lunchbox) && categories && categories[0]) {
  //   portionQuantity = product.products.reduce((res, prodId) => {
  //     const portion = +getProductById(prodId, categories).portion_quantity;
  //     res = portion > 0 ? res + portion : res;
  //     return res;
  //   }, 0);
  // }

  const title = keyByLang('title', currentLanguage);

  if (product.tag_id && productTags && productTags[0]) {
    productTag = productTags.find((tag) => tag.id === product.tag_id);
  }

  if (product.available_since && product.available_till) {
    const since = +product.available_since.split(':').join('');
    const till = +product.available_till.split(':').join('');
    isProductAvailable = currentTime > since && currentTime < till;
  }

  if (cartData && cartData.content && cartData.content[0]) {
    isInCart = !!cartData.content.find((prod) => +prod.product_id === +product.id);
  }

  if (isMobile && !isProductAvailable) {
    buttonData = (
      <div className={currentSize ? 'mobile-product__not-available' : 'mobile-product__not-available--large'}>
        <p>Доступно с </p>
        <p>
          {product.available_since}
          {' '}
          до
          {' '}
          {product.available_till}
        </p>
      </div>
    );
  } else if (isMobile && isProductAvailable && !isInCart) {
    buttonData = (
      <div
        className="mobile-product__button"
        onClick={() => addToCart(product)}
      >
        {/* cart */}
        <img src={plus} alt="plus" decoding="async" loading="lazy" />
      </div>
    );
  } else if (isMobile && isProductAvailable && isInCart) {
    buttonData = (
      <div
        className="mobile-product__button mobile-product__button--active"
        onClick={() => addToCart(product)}
      >
        <img src={plus} alt="plus" decoding="async" loading="lazy" />
      </div>
    );
  }

  return (isMobile ? (
    <StyledMobileItem className="mobile-product">
      <Link className="mobile-product__link" to={`${languagePrefix}/${productCategory}/${product.alias}`} />
      {productIcon && <img src={productIcon} decoding="async" loading="lazy" alt={productTag ? productTag[title] : null} className="mobile-product__icon" />}
      <div className={currentSize ? 'mobile-product__figure' : 'mobile-product__figure mobile-product__figure--large'}>
        <img
          className={currentSize ? 'mobile-product__image' : 'mobile-product__image mobile-product__image--large'}
          src={product.preview_image_filename ? product.preview_image_filename : imageEmpty}
          alt={`${product[title]} заказать суши min`}
          title={`${product[title]} заказать суши min Budusushi`}
          decoding="async"
          loading="lazy"
        />
        <div className={currentSize ? 'mobile-product__text-block' : 'mobile-product__text-block mobile-product__text-block--large'}>
          {/* <p className={currentSize ? 'mobile-product__tag' : 'mobile-product__tag mobile-product__tag--large'}>{productTag ? productTag[title] : null}</p> */}
          <p className={currentSize ? 'mobile-product__title' : 'mobile-product__title mobile-product__title--large'}>{product[title]}</p>
          <div className={currentSize ? 'mobile-product__price-wrap' : 'mobile-product__price-wrap mobile-product__price-wrap--large'}>
            <p className="mobile-product__price">
              {+product.old_price !== 0 && (
                <span className="mobile-product__old-price">
                  {product.old_price}
                  {' '}
                  грн
                </span>
              )}
              {product.price}
              {' '}
              грн
            </p>
            <div className="mobile-product__break-line" />
            <p className="mobile-product__portion">
              {(parseInt(portionQuantity, 10) !== 0) ? portionQuantity : 1}
              {' '}
              шт
            </p>
          </div>
        </div>
        <div className={currentSize ? 'mobile-product__buttons-wrap' : 'mobile-product__buttons-wrap mobile-product__buttons-wrap--large'}>
          {user && user.id ? (
            <div
              className={currentSize ? 'mobile-product__like' : 'mobile-product__like mobile-product__like--large'}
              onClick={isInFavorite
                ? () => removeFromFavourites(product.id)
                : () => addToFavourites(product.id)}
            >
              {isInFavorite ? activeLike : like}
            </div>
          ) : null}
          {buttonData}
        </div>
      </div>
      <hr className="dashed" />
    </StyledMobileItem>
  ) : (
    <StyledItem className="product" currentSize={currentSize} data-product-id={product.id}>
      {productIcon && <img src={productIcon} decoding="async" loading="lazy" alt={productTag ? productTag[title] : null} className="product__icon" />}
      <Link className="product__link" to={`${languagePrefix}/${productCategory}/${product.alias}`} />
      {user && user.id ? (
        <div
          className="product__like"
          onClick={isInFavorite
            ? () => removeFromFavourites(product.id)
            : () => addToFavourites(product.id)}
        >
          {isInFavorite ? activeLike : like}
        </div>
      ) : null}
      <figure className="product__figure">
        <img
          className="product__image"
          src={product.preview_image_filename ? product.preview_image_filename : imageEmpty}
          alt={`${product[title]} заказать суши min`}
          title={`${product[title]} заказать суши min Budusushi`}
          decoding="async"
          loading="lazy"
        />
        <figcaption className="product__figcaption">
          {/* <p className="product__tag">{productTag ? productTag[title] : null}</p> */}
          <h3 className="product__name">{product[title]}</h3>
        </figcaption>
      </figure>
      <div className="product__dynamic-wrap">
        <div className="product__info">
          <p className="product__price">
            {+product.old_price !== 0 && (
              <span className="product__old-price">
                {product.old_price}
                {' '}
                грн
              </span>
            )}
            {product.price}
            {' '}
            грн
          </p>
          <div className="product__break-line" />
          <p className="product__portion">
            {(parseInt(portionQuantity, 10) !== 0) ? portionQuantity : 1}
            {' '}
            шт
          </p>
        </div>
        {isProductAvailable ? (
          <div className="product__button-wrap">
            <AnimatedButton
              minWidth="103px"
              icon={plus}
              text={translate('AddToCartButton')}
              addClass="product__buy"
              handleClick={() => addToCart(product)}
            />
          </div>
        ) : (
          <p className="product__time-interval">
            {translate('OnlyAvailableFrom')}
            {' '}
            {product.available_since}
            {' '}
            до
            {' '}
            {product.available_till}
          </p>
        )}
      </div>
      <hr className="dashed" />
    </StyledItem>
  ));
};

const StyledMobileItem = styled.li`
  --main-text-color: #8E8E8E;
  --secondary-text-color: #fff;
  --links-color: #E74E1A;
  
  position: relative;

  .mobile-product {
    
    &__icon { 
      display: block;
      margin-top: .5rem;
      pointer-events: none;
    }
  
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &__figure {
      display: flex;
      align-items: center;
      padding: .5rem 0;
      
      &--large {
        flex-direction: column;
      }
    }
    
    &__image {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
      
      &--large {
        margin-top: 2rem;
        width: 15rem;
        height: 15rem;
      }
    }
    
    &__text-block {
      margin-left: 1rem;
      text-align: left;
      flex-grow: 1;
      
      &--large {
        margin: 1rem 0;
        text-align: center;
      }
    }
    
    &__tag {
      color: var(--secondary-text-color);
      &--large {
        font-size: 1.2rem;
      }
    }
    
    &__title {
      color: var(--main-text-color);
      &--large {
        font-size: 1.3rem;
        padding: .4rem 0;
      }
    }
    
    &__break-line {
      width: 2px;
      height: 16px;
      background-image: linear-gradient(to top, #8E8E8E 30%, transparent 30%, transparent 70%, #8E8E8E 70%);
      background-size: 3px 8px;
      margin: 0 8px;
    }
    
    &__price-wrap {
      display: flex;
      align-items: flex-end;
      padding-top: .3rem;
      
      &--large {
        justify-content: center;
      }
    }
    
    &__price {
      font-size: 1.1rem;
      color: var(--secondary-text-color);
      display: flex;
      flex-direction: column;
    }
    
    &__old-price {
      text-decoration: line-through;
      color: var(--main-text-color);
    }
    
    &__portion {
      color: var(--main-text-color);
    }
    
    &__buttons-wrap {
      display: flex;
      align-items: center;
      
      &--large {
        margin: 1rem 0 2rem;
      }
    }
   
    &__like {
      z-index: 2;
      padding: .5rem;
      display: none;
      
      &--large {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
    
    &__button {
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid var(--main-text-color);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: .5rem;
      z-index: 2;
      transition: background-color 6s ease, border-color 6s ease;
      will-change: background-color, border-color;
      
      &:active {
        transition-duration: 0s;
        background-color: var(--links-color);
        border-color: var(--links-color);
      }
    }
    
    &__not-available {
      font-size: .6rem;
      line-height: 1rem;
      min-width: 4.2rem;
      
      &--large {
        font-size: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
  
  @media all and (min-width: 769px) {
    .mobile-product {
      &__like {
        display: block;
      }
      
      &__buttons-wrap {
        width: 7rem;
        justify-content: space-between;
        
        &--large {
          width: auto;
        }
      }
    }
  }   
`;

const StyledItem = styled.li`
  --main-text-color: #8E8E8E;
  --secondary-text-color: #fff;
  --button-wrap-height: 42px;
  --button-wrap-width: 103px;
  --button-transition: .3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main-text-color);
  font-weight: 700;
  padding: ${(props) => (props.currentSize ? '15px 16px' : '25px 26px')};
  position: relative;
  transition: .2s;
  max-width: ${(props) => (props.currentSize ? '190px' : '230px')};
  box-sizing: border-box;
  font-size: ${(props) => (props.currentSize ? '15px' : '20px')};
  margin: ${(props) => (props.currentSize ? '0' : '20px')};
  
  & > .dashed {
    margin-top: 10px;
  }
  
  .product {
    position: relative;
    
    &__icon {
      position: absolute;
      left: 10px;
      top: 0;
    }
    
    &__figure {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: ${(props) => (props.currentSize ? '138px' : '188px')};
    }
    
    &__image {
      width: 100%;
      min-height: 0;
      object-fit: contain;
    }
    
    &__figcaption {
      padding-top: 0.76em;
    }
    
    &__tag {
      height: 1em;
      font-size: ${(props) => (props.currentSize ? '10px' : '14px')};
      color: var(--secondary-text-color);
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    
    &__name {
      color: var(--main-text-color);
      font-size: ${(props) => (props.currentSize ? '15px' : '20px')};
      text-transform: none;
      font-weight: 700;
    }
    
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    
    &__dynamic-wrap {
      width: 100%;
      position: relative;
      min-height: var(--button-wrap-height);
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    
    &__info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      transition: var(--button-transition);
      height:  var(--button-wrap-height);
      font-size: ${(props) => (props.currentSize ? '15px' : '20px')};
    }
    
    &__button-wrap {
      position: relative;
      left: 0;
      z-index: 2;
      top: 0;
    }
    
    &__price {
      color: var(--secondary-text-color);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;
    }
    
    &__old-price {
      text-decoration: line-through;
      color: var(--main-text-color);
      font-weight: 400;
      font-size: 14px;
    }
    
    &__time-interval {
      padding-top: .15rem;
      left: 0;
      transition: var(--button-transition);
      color: var(--secondary-text-color);
      font-size: ${(props) => (props.currentSize ? '15px' : '20px')};
      font-weight: 200;
      width: 100%;
      min-height: var(--button-wrap-height);
    }
   
    &__break-line {
      width: 2px;
      min-height: 18px;
      background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
      background-size: 2px 6px;
      margin: 0 8px;
    }
    
    &__like {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 28px;
      height: 26px;
      padding: 5px;
      overflow: hidden;
      border-radius: 50%;
      z-index: 1;
      
      &:hover {
        box-shadow: 0 0 10px rgba(255, 0, 0, .4) inset, 
                    0 0 20px rgba(255, 0, 0, .4);
      }
    }
    
    &__label:hover {
      cursor: pointer;
    }
    
    &__checkbox {
      position: absolute;
      left: -15px;
    }
  }
  
    
  @media all and (min-width: 1025px) {
  
    &:hover {
      cursor: pointer;
      
      & .product__info {
        position: absolute;
        top: -42px;
      }
      
      & .product__button-wrap,
      & .product__time-interval {
        top: 0;
      }
    }
    .product {
      &__dynamic-wrap {}
      &__info {
        position: absolute;
        top: 0;
        left: 0;
      }
      
      &__figure {
        padding-bottom: 10px;
      }
      
      &__button-wrap {
        position: absolute;
        padding-top: 0;
        left: calc(50% - 103px/2);
        transition: var(--button-transition);
        top: 45px;
      }
      
      &__time-interval {
        position: absolute;
        top: 45px;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  categories: state.categories,
});

export default connect(mapStateToProps, {})(React.memo(Item));

// export default React.memo(Item);
