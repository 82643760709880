/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { Carousel } from 'react-responsive-carousel';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';
import { isEmpty } from 'lodash-es';

import * as actions from '../../actions/actions';

// import CatalogDeliveryComponent from './catalogDelivery/CatalogDeliveryComponent';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import keyByLang from '../../modules/shared/keyByLang';

class CatalogTopBanner extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate() {
    const { banners, location, catalogPaths } = this.props;
    return isEmpty(banners) || !catalogPaths.includes(location.pathname);
  }

  fetchData() {
    return this.props.actions.getStores();
  }

  render() {
    const {
      banners, currentLanguage,
    } = this.props;

    let topBannerData;
    const interval = 4500;
    const slideTransition = 500;

    if (banners) {
      topBannerData = banners.filter(banner => +banner.banner_type_id === 1);
    }
    return topBannerData && topBannerData[0] ? (
      <StyledTopSection>
        <div className="carousel">
          <Carousel
            initialized
            autoPlay
            infiniteLoop
            interval={interval}
            showThumbs={false}
            showStatus={false}
            transitionTime={slideTransition}
            swipeable={false}
          >
            {topBannerData.map(banner => (
              <div className="banner" key={banner.id}>
                <a className="banner__link" href={banner.destination_link} />
                <img
                  className="banner__image"
                  src={banner.image_filename}
                  alt={banner.meta_image_alt_ru}
                  decoding="async"
                />
                <div className="banner__text-wrap">
                  <p className="banner__heading banner__heading--top">{banner[keyByLang('title', currentLanguage)]}</p>
                  <h1 className="banner__heading banner__heading--main">{banner[keyByLang('description', currentLanguage)]}</h1>
                </div>
              </div>
            ))}
          </Carousel>
          {/* <div className="delivery-wrap"> */}
          {/* {user && stores[0] ? ( */}
          {/* <CatalogDeliveryComponent */}
          {/* stores={stores} */}
          {/* user={user} */}
          {/* /> */}
          {/* ) : null} */}
          {/* </div> */}
        </div>
      </StyledTopSection>
    ) : null;
  }
}

const mapStateToProps = state => ({
  banners: state.banners ? state.banners : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogTopBanner);

const StyledTopSection = styled.section`
  --text-color: #fff;
  --text-shadow: grey;
  --max-height: 800px;
  
  .carousel {
    width: 100%;
    //height: 560px;
    position: relative;
    
    & > .control-dots {
      margin-bottom: 1.2rem;
    }
    
    & > .dot {
      width: 10px;
      height: 10px;
      outline: none;
    }
  }
  
  .banner {
    position: relative;
    font-family: 'fs_elliot_pro';
    font-weight: 700;
    color: var(--text-color);
    text-align: center;
    
    &:hover {
      cursor: pointer;
    }
    
    &__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 10;
    }
    
    &__image {
      //height: 560px;
      object-fit: cover;
      max-width: 100%;
      min-height: calc(100vw / 11 * 4);
    }
    
    &__text-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }
    
    &__heading {
      text-align: center;
      text-shadow: 0 0 5px var(--text-shadow);
    
      &--top {
        font-size: 1.2rem;
      }
      
      &--main {
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: 900;
      }
      
      &--bottom {
        font-size: 1.1rem;
      }
    }
  }
  
  .delivery-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 70px;
    height: 100%;
    display: none;
  }
  
@media all and (min-width: 768px) {
  .carousel {
    height: auto;
    
    & > .control-dots {
      margin-bottom: 5.2rem;
    }
  }
  
  .banner {
    height: 100%;
  
    &__image {
      max-height: var(--max-height);
      height: 100%;
    }
    
    &__heading {
      &--top {
        font-size: 2.3rem;
      }
        
      &--main {
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 900;
      }
        
      &--bottom {
        font-size: 2.1rem;
      }
    }
  }
  .delivery-wrap {
    display: block;
  }
}
  
@media all and (min-width: 1024px) {
  .banner {
    &__heading {
      &--top {
        font-size: 2.8rem;
      }
        
      &--main {
        text-transform: uppercase;
        font-size: 6rem;
        font-weight: 900;
      }
        
      &--bottom {
        font-size: 2.6rem;
      }
    }
  }
}
  
@media all and (min-width: 1200px) {
  .banner__heading {
    &--top {
      font-size: 3.6rem;
    }
      
    &--main {
      font-size: 8rem;
    }
      
    &--bottom {
      font-size: 3.2rem;
    }
  }
}
`;
