import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';

import { cartAddStatic } from '../../assets/icons/lottieIcons';

const LoadingSuspense = () => {
  return (
    <StyledLoading className="loading">
      <div className="loading__content">
        <Lottie
          options={cartAddStatic}
        />
        <p className="loading__text">Loading...</p>
      </div>
    </StyledLoading>
  );
};

export default LoadingSuspense;

const StyledLoading = styled.div`
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #0A0A0A;
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .loading {
    &__text {
      padding-top: 2rem;
      text-align: center;
    }
  }
`;