import React from 'react';
import styled from 'styled-components';

import ProductsGrid from '../../shared/ProductsGrid';

const CrossSaleCategory = ({
  category, currentLanguage, currentState, onChangeHandleClickOnPaidItem,
}) => (
  <StyledCategory className="category">
    <h3 className="category__heading">{category.mquestion}</h3>
    <ProductsGrid
      products={category.products}
      currentLanguage={currentLanguage}
      onChangeHandleClickPaid={onChangeHandleClickOnPaidItem}
      isTabFree={false}
      currentState={currentState}
    />
  </StyledCategory>
);


const StyledCategory = styled.div`

  .category {
    &__heading {
      text-align: center;
      text-transform: none;
      font-weight: 100;
      font-size: 1.6rem;
    }
  }
`;

export default CrossSaleCategory;
