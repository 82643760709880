import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage } from 'react-localize-redux';

import * as actions from '../../actions/actions';
import { Portal } from '../Portal';

class MainSnackBar extends React.Component {
  constructor(props) {
    super(props);

    this.snackbarContent = '';
    this.isMounteD = false;
    this.snackBarTimeout = null;
    this.prevStateVisibility = null;
    this.isConfirm = null;
  }

  componentDidMount() {
    this.fetchData();
    this.isMounteD = true;
  }

  componentWillUpdate() {
    if (this.props.snackBarData && this.props.snackBarData.content) {
      this.snackbarContent = this.props.snackBarData.content;
      this.isConfirm = this.props.snackBarData.isConfirm;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.snackBarData && this.props.snackBarData.isVisible
      && !prevProps.snackBarData.isVisible) {
      this.snackBarTimeout = setTimeout(this.checkBeforeHide, this.props.snackBarData.time);
    }
  }

  componentWillUnmount() {
    this.isMounteD = false;
  }

  checkBeforeHide = () => {
    if (!this.isMounteD) {
      return null;
    }
    this.props.actions.hideSnackBar();
  }

  fetchData() {
    return this.props.actions.hideSnackBar();
  }

  render() {
    const { snackBarData, currentLanguage } = this.props;
    if (!snackBarData) {
      return null;
    }

    return (
      <Portal selector="#root" portalId="MainSnackBar">
        <StyledSnackBar
          className={snackBarData.isVisible ? 'active' : ''}
          isConfirm={snackBarData.isVisible ? snackBarData.isConfirm : this.isConfirm}
        >
          <p className="snackbar__content">{snackBarData.content || this.snackbarContent}</p>
        </StyledSnackBar>
      </Portal>
    );
  }
}

const StyledSnackBar = styled.div`
  position: fixed;
  width: 100%;
  left: 50%;
  top: 0;
  padding: 5px 10px;
  font-size: .75rem;
  transform: translateX(-50%) translateY(calc(-100% - 20px));
  text-align: center;
  background-color: ${props => props.isConfirm ? '#4BB25D' : '#E74E1A'};
  transition: transform 0.45s ease-in-out;
  z-index: 150;

  &.active {
    transform: translateX(-50%) translateY(0);
  }

  &__content {
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
    
  @media all and (min-width: 768px) {
    padding: 7px 100px;
    font-size: 1rem;
  }
`;

const mapStateToProps = state => ({
  snackBarData: state.snackBarData ? state.snackBarData : null,
  currentLanguage: getActiveLanguage(state.locale).code,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MainSnackBar));
