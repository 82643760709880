import React from 'react';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/actions';

import JobOffersPage from '../components/job-offers/JobOfferPage';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

class JobOffersContainer extends React.PureComponent {
  componentDidMount() {
    this.fetchData();
    window.scrollTo(0, 0);
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getVacancies(),
      this.props.actions.getVacancyReviews(),
      this.props.actions.getUser(),
    ]);
  }

  render() {
    const {
      currentLanguage, languagePrefix, vacancies, vacancyReviews, settings, translate,
    } = this.props;

    return (
      <JobOffersPage
        vacancies={vacancies}
        settings={settings}
        currentLanguage={currentLanguage}
        languagePrefix={languagePrefix}
        vacancyReviews={vacancyReviews}
        sendCV={this.props.actions.sendCV}
        showSnackBar={this.props.actions.showSnackBar}
        translate={translate}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  vacancies: state.vacancies ? state.vacancies : null,
  settings: state.settings ? state.settings : null,
  vacancyReviews: state.vacancyReviews ? state.vacancyReviews : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobOffersContainer);
