const fixImagePath = (pageData) => {
  let imagePath = pageData.image_filename_header;

  if (imagePath && (imagePath.indexOf('(') || imagePath.indexOf(')') || imagePath.indexOf('_'))) {
    const imageArr = imagePath.split('');
    for (let i = imageArr.length - 1; i >= 0; i--) {
      if (imageArr[i] === '(' || imagePath[i] === ')' || imagePath[i] === '_') {
        imageArr.splice(i, 0, '\\');
      }
    }
    imagePath = imageArr.join('');
  }
  return imagePath;
};

export default fixImagePath;
