/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveLanguage } from 'react-localize-redux';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import * as actions from '../actions/actions';

import MemorizedCatalog from '../components/catalog/Catalog';
import FooterContainer from './FooterContainer';

import favouritesIcon from '../assets/images/sidebar-navigation/Favourites.jpg';
import filterAddonsByTime from '../modules/shared/filterAddonsByTime';

class CatalogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.filterNames = {
      allergens: 'allergens_',
      ingredients: 'ingredients_',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.activeCatalogLink && nextProps.activeCatalogLink.shouldCatalogUpdate;
  }

  componentWillUnmount() {
    this.props.actions.setActiveCatalogLink({
      shouldCatalogUpdate: true,
    });
  }

  renderFilterFields = (ingredients, allergens) => ({

    ...this.createFilterField(ingredients, this.filterNames.ingredients),
    ...this.createFilterField(allergens, this.filterNames.allergens),
  });

  createFilterField = (dataArray, type) => dataArray.reduce((result, filterItem) => {
    result[`${type}${filterItem.id}`] = true;
    return result;
  }, {})

  addToCart = (product) => {
    this.props.actions.cartAdd(product.id);
    this.props.actions.addedProduct(true);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Add To Cart',
        eventCategory: 'Customer Actions',
        eventAction: 'Add To Cart',
        eventLabel: `${product.id}`,
        eventValue: `${product.price}`,
      });
    }
  }

  checkAddonsBeforeAddToCart = (product) => {
    let filteredAddons = null;
    const productCategory = this.props.categories.find((cat) => product.product_category_id === cat.id).alias;

    if (product.tabs && product.tabs[0]) {
      filteredAddons = filterAddonsByTime(product.tabs);
    }
    if (product.tabs && product.tabs[0] && filteredAddons && filteredAddons[0]) {
      this.props.history.push(`${this.props.languagePrefix}/${productCategory}/${product.alias}`);
    } else {
      this.addToCart(product);
    }
  }

  setActiveCatalogLink = (data) => {
    this.props.actions.setActiveCatalogLink(data);
  }

  redirectToLink = (link) => {
    this.props.history.push(link);
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getHomeBannerData(),
      this.props.actions.getProductTags(),
      this.props.actions.getUser(),
      this.props.actions.getCart(),
    ]);
  }

  render() {
    const {
      categories,
      currentLanguage,
      match,
      banners,
      ingredients,
      allergens,
      languagePrefix,
      user,
    } = this.props;

    const favouriteCategory = {
      id: 'favouriteCategory',
      alias: 'favourites',
      products: [],
      title_ru: 'Избранное',
      title_ua: 'Обране',
      image_filename: favouritesIcon,
    };

    let catAliasFromRoute = match.path.replace('/', '');
    const isLanguagePrefix = catAliasFromRoute.indexOf('/');
    if (isLanguagePrefix > 0) {
      catAliasFromRoute = catAliasFromRoute.slice(isLanguagePrefix + 1);
    }
    let catIdFromAlias = null;
    let sortedCategories = null;

    categories.forEach((catItem) => {
      if (catItem.alias === catAliasFromRoute) {
        catIdFromAlias = catItem.id;
      }
    });

    const isBusinessShow = (() => {
      const hours = new Date().getHours();
      return ((hours >= 10) && (hours < 16));
    })();

    if (categories && categories[0]) {
      if (isBusinessShow) {
        sortedCategories = categories
          .sort((cat1, cat2) => +cat1.position_index - +cat2.position_index);
      } else {
        sortedCategories = categories
          .sort((cat1, cat2) => +cat1.position_index - +cat2.position_index)
          .filter((cat) => cat.alias !== 'business');
      }
    }

    const categoriesWithFavourites = categories && categories[0]
      ? [favouriteCategory, ...sortedCategories] : null;


    return categories[0] && ingredients[0] ? (
      <>
        <MemorizedCatalog
          categories={categoriesWithFavourites}
          catAliasFromRoute={catAliasFromRoute}
          catIdFromAlias={catIdFromAlias}
          ingredients={ingredients}
          allergens={allergens}
          user={user}
          addToCart={this.checkAddonsBeforeAddToCart}
          languagePrefix={languagePrefix}
          setActiveCatalogLink={this.setActiveCatalogLink}
          activeCatalogLink={this.props.activeCatalogLink}
        />
        <FooterContainer />
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  banners: state.banners ? state.banners : null,
  categories: state.categories ? state.categories : null,
  ingredients: state.ingredients ? state.ingredients : null,
  allergens: state.allergens ? state.allergens : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  activeCatalogLink: state.activeCatalogLink ? state.activeCatalogLink : null,
  user: state.user ? state.user : null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogContainer);
