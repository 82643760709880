import _serialize from './helpers/serialize';
import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const AddressApi = {
  addDeliveryAddress(obj) {
    return axiosInstance.post('user/add-delivery-address',
      _serialize(obj),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  removeDeliveryAddress(addressId) {
    return axiosInstance.post('user/remove-delivery-address',
      _serialize({ id: addressId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  updateDeliveryAddress(obj) {
    return axiosInstance.post('user/update-delivery-address',
      _serialize(obj),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default AddressApi;
