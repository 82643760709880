import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import * as actions from '../actions/actions';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import FaqPage from '../components/faq/FaqPage';

class FaqContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.redirectToVacancies = this.redirectToVacancies.bind(this);
    this.redirectToMain = this.redirectToMain.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getFaq(),
      this.props.actions.getStores(),
      this.props.actions.getUser(),
    ]);
  }

  redirectToVacancies() {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/vacancies`);
  }

  redirectToMain() {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/`);
  }

  render() {
    const {
      faq, currentLanguage, stores, languagePrefix, settings, translate,
    } = this.props;

    return (
      <FaqPage
        faq={faq}
        settings={settings}
        currentLanguage={currentLanguage}
        stores={stores}
        languagePrefix={languagePrefix}
        redirectToVacancies={this.redirectToVacancies}
        redirectToMain={this.redirectToMain}
        sendReview={this.props.actions.sendReview}
        showSnackBar={this.props.actions.showSnackBar}
        translate={translate}
      />
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  faq: state.faq ? state.faq : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  settings: state.settings ? state.settings : null,
  stores: state.stores ? state.stores : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqContainer);
