/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import Input from '../shared/form/Input';
import AnimatedButton from '../shared/buttons/AnimatedButton';

import validate from './authValidation/phoneValidation';

const GetNumberStep = ({
  handleSubmit,
  icon,
  buttonIcon,
  showSnackBar,
  isCheckoutPage,
  heading,
  buttonText,
  translate,
  isDisabled,
}) => (
  <StyledForm>
    <form className="form" onSubmit={handleSubmit}>
      {isCheckoutPage ? (
        <div className="form__name-wrap">
          {buttonIcon}
          <h2 className="form__header-text form__header-text--checkout">{heading}</h2>
        </div>
      ) : (
        <>
          <h2 className="form__header-text">{heading}</h2>
          <hr className="solid form__line" />
        </>
      )}
      <h3 className={isCheckoutPage
        ? 'form__heading form__heading--checkout'
        : 'form__heading'}
      >
        <Translate id="EnterYourPhoneNumber" />
      </h3>
      <Field
        name="username"
        component={Input}
        autoFocus
        autoComplete="tel"
        icon={icon}
        type="text"
        label={translate('PhoneNumber')}
        iconName="store"
        showSnackBar={showSnackBar}
      />
      {!isCheckoutPage ? (
        <hr className="solid padded form__line" />
      ) : null}
      <AnimatedButton
        icon={buttonIcon}
        isSubmit
        minWidth="200px"
        stepnum="3"
        text={buttonText || translate('GetSmsButton')}
        isDisabled={isDisabled}
        addClass={isCheckoutPage ? 'form__button-wrap' : 'authorization-submit'}
      />
    </form>
  </StyledForm>
);

let InitializeFromStateForm = reduxForm({
  form: 'userDataFormStep1',
  validate,
  destroyOnUnmount: true,
  // onSubmitSuccess: afterSubmit,
})(GetNumberStep);

InitializeFromStateForm = connect(
  () => ({
    initialValues: {
      username: '+380',
    },
  }),
  {},
)(InitializeFromStateForm);

export default InitializeFromStateForm;

const StyledForm = styled.div`
  --text-color: #FFFFFF;
  
  .form {
  
    &__header-text {
      text-transform: none;
      font-weight: 200;
      padding: 2.4rem 0 1.4rem;
      color: var(--text-color);
      font-size: 1.2rem;
      
      &--checkout {
        padding: 0 0 0 .7rem;
      }
    }
    
    &__heading {
      padding-top: 1.25rem;
      text-transform: none;
      font-weight: 100;
      font-size: 1rem;
      padding-bottom: 1rem;
      
      &--checkout {
        text-align: left;
        padding-top: 1rem;
      }
    }
    
    &__name-wrap {
      display: flex;
      align-items: center;
    }
    
    &__line {
      display: none;
    }
    
    &__button-wrap {
      margin-top: 1rem;
    }
  }
  
  .padded {
    margin-bottom: 2rem;
  }
  
@media all and (min-width: 768px) {
  .form {
  
    &__header-text {
      font-size: 1.4rem;
      text-align: center;
    }
    
    &__heading {
      font-size: 1.2rem;
      text-align: center;
      padding-top: 6rem;
      
      &--checkout {
        padding-top: 1.4rem;
        text-align: left;
      }
    }
    
    &__line {
      display: block;
    }
  }
}
`;
