import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const ProductReviewsApi = {
  getProductReviews(id) {
    return axiosInstance.post(`product-reviews/${id}/get`, {}, { headers: headers.json });
  },
  sendReview(data) {
    return axiosInstance.post('product-reviews/send', data, { headers: headers.json });
  },
};

export default ProductReviewsApi;
