function getStorage() {
  return {
    length: 0,
    clear() {},
    getItem() { return null; },
    key() { return null; },
    removeItem() {},
    setItem() {},
  };
}

const [local, session] = process.env.IS_SERVER
  ? [getStorage(), getStorage()]
  : [localStorage, sessionStorage];

export {
  local,
  session,
};
