export const citiesData = {
  odessa: {
    translationName: 'OdessaTown',
    href: 'https://budusushi.ua',
  },
  kyiv: {
    translationName: 'KyivTown',
    href: 'https://kyiv.budusushi.ua',
  },
  nikolaev: {
    translationName: 'NikolaevTown',
    href: 'https://nikolaev.budusushi.ua',
  },
  chernomorsk: {
    translationName: 'ChernomorskTown',
    href: 'https://budusushi.ua',
  },
};
