import * as types from '../actions/actionTypes';


const initialState = [];

const productReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRODUCT_REVIEWS_BY_ID:
      return action.payload;
    case types.RESET_PRODUCT_REVIEWS:
      return initialState;
    default:
      return state;
  }
};

export default productReviewsReducer;
