import React from 'react';
import styled from 'styled-components';
// import pattern_min from "../assets/images/pattern_min.svg";
// something wrong when use svg - vertical scroll page not smoooth
import { Translate } from 'react-localize-redux';
import LoadingSuspense from './shared/LoadingSuspense';
import backgroundJPG from '../assets/images/pattern_min_JPG.jpg';
import MainBtn from './shared/MainBtn';
import error from '../assets/icons/404.svg';

const FourZeroFourth = (props) => {
  const {
    categories, languagePrefix, history, notWait,
  } = props;

  const goToMain = () => {
    history.push(`${languagePrefix}/`);
  };

  return (
    <Translate>
      {translate => (
        <Styled404Page backgroundJPG={backgroundJPG} className="page page404">
          {notWait || (categories && categories[0]) ? null : <LoadingSuspense />}
          <div className="content404">
            <img
              className="large-text"
              src={error}
              alt="Error 404"
              decoding="async"
              loading="lazy"
            />
            <div className="small-text">{translate('TextSorryThePageNotFound')}</div>
            <MainBtn
              text={translate('GoToMainPageButton')}
              clickHandle={goToMain}
            />
          </div>
        </Styled404Page>
      )}
    </Translate>

  );
};

const Styled404Page = styled.div`

    justify-content: center;
    align-items: center;
    background-image: url(${props => (props.backgroundJPG ? props.backgroundJPG : '')});
    background-repeat: repeat;

    .content404 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .large-text {
            width: 80%;
            filter: drop-shadow(10px 20px 20px #000000)
        }
        .small-text {
            text-align: center;
            font-size: 20px;
            margin: 1rem 1rem 2rem;
        }
    }

    @media (min-width: 1024px) {
        
        .small-text {
            font-size: 24px;

        }   
    }

`;

export default FourZeroFourth;
