import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../../modules/shared/keyByLang';
import AnimatedButton from '../../shared/buttons/AnimatedButton';
import CartItemModal from '../CartItemModal';
import {Link} from "react-router-dom";

const CartCrossSaleBlock = ({
  currentLanguage,
  icon,
  languagePrefix,
  crossSaleCategories,
  qtyHandler,
  removeCartItemHandler,
  redirectToCategory,
  translate,
}) => {
  const title = keyByLang('title', currentLanguage);
  const mQuestion = keyByLang('mquestion', currentLanguage);
  const crossSaleWithProducts = crossSaleCategories.map((category) => {
    if (category.productsInCart && category.productsInCart[0]) {
      return (
        <div className="cross-sale__category cross-sale__category--full" key={category.id}>
          <h2 className="cross-sale__heading">{category[title]}</h2>
          <ul className="cross-sale__products-list">
            {category.productsInCart.map(cartProduct => (
              <CartItemModal
                key={cartProduct.id}
                qty={cartProduct.quantity}
                product={cartProduct.product}
                cartProduct={cartProduct}
                qtyHandler={qtyHandler}
                removeCartItemHandler={removeCartItemHandler}
                currentLanguage={currentLanguage}
                addonsList={cartProduct.addons}
                translate={translate}
              />
            ))}
          </ul>
        </div>
      );
    }
  });

  const emptyCrossSale = crossSaleCategories.map((category) => {
    if (!category.productsInCart || category.productsInCart.length === 0) {
      return (
        <div className="cross-sale__category" key={category.id}>
          <div className="cross-sale__content">
            <div className="cross-sale__title">{category[mQuestion]}</div>
            <p className="cross-sale__text">{translate('InYourOrderIsMissing')} {category[title]}</p>
          </div>
          <div className="cross-sale__button-wrap">
            <Link to={`${languagePrefix}/${category.alias}`} className="cart-modal__link">{`${translate('Add')} ${category[title]}`}</Link>
            {/*<AnimatedButton*/}
              {/*text={`${translate('Add')} ${category[title]}`}*/}
              {/*icon={icon}*/}
              {/*align="right"*/}
              {/*minWidth="180px"*/}
              {/*handleClick={() => redirectToCategory(category.alias)}*/}
            {/*/>*/}
          </div>
        </div>
      );
    }
  });

  return (
    <StyledCrossSale className="cross-sale">
      {crossSaleWithProducts}
      {emptyCrossSale}
    </StyledCrossSale>
  );
};

const StyledCrossSale = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;

  width: 100%;
  
  .cross-sale {
    
    &__heading {
      text-transform: none;
      font-weight: 100;
      font-size: 1.3rem;
      padding-bottom: 1rem;
    }
    
    &__category {
      padding-top: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      
      &--full {
        display: block;
      }
    }
    
    &__title {
      font-size: 1.3rem;
    }
    
    &__text {
      color: var(--active-text-color);
      padding-top: .4rem;
    }
  
    &__content {
      flex-grow: 1;
    }
    
    &__button-wrap {
      display: flex;
      align-items: center;
    }
  }
  
@media all and (min-width: 769px) {
  
  .cross-sale {
    &__category {
      flex-direction: row;
    }
    &__button-wrap {
      padding-top: 0;
    }
  }
}
`;

export default CartCrossSaleBlock;
