import React from 'react';
import styled from 'styled-components';

const DeliveryCheckbox = ({
  name, handleChange, id, activeCheckboxes,
}) => {
  const isActive = activeCheckboxes[id];

  return (
    <StyledCheckbox className="delivery-checkbox">
      <input
        className="delivery-checkbox__checkbox"
        type="checkbox"
        hidden="hidden"
        id={id}
        onChange={() => handleChange(id)}
        checked={isActive}
      />
      <label
        className="delivery-checkbox__label"
        htmlFor={id}
      >
        {name}
      </label>
    </StyledCheckbox>
  );
};

export default DeliveryCheckbox;

const StyledCheckbox = styled.div`
  
  .delivery-checkbox {
    &__label {
      position: relative;
      user-select: none;
      display: flex;
      align-items: center;
      padding-top: .6rem;
      font-size: .75rem;
      
      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        margin-right: .7rem;
        border: 2px solid var(--text-color);
      }
      
      &:hover {
        cursor: pointer;
        color: var(--active-text-color);
      }
    }
    
    &__checkbox:checked + .delivery-checkbox__label::before {
      background-color: var(--text-color);
      background-clip: content-box;
      padding: 2px;
    }
  }
  
  @media all and (min-width: 768px) {
    .delivery-checkbox {
      padding-top: 1.2rem;
      
      &__label {
        font-size: 1rem;
        padding-top: 1.25rem;
      }
    }
  }
`;
