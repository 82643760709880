import React, { useState } from 'react';
import styled from 'styled-components';
import StarRating from '../../../starRating/StarRating';
import MainBtn from '../../../shared/MainBtn';
import ProductReviewText from './ProductReviewsText';

function ProductReviews({ reviews, name, translate }) {
  const [visibleReviews, setVisibleReviews] = useState(3);

  // Function to show MoreReviews
  const showMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 5);
  };

  return (
    <Container id="reviews">
      <Title>
        {translate('OrdersForProduct')} {name}:
      </Title>

      {reviews.length > 0 ? reviews.slice(0, visibleReviews).map((review) => (
        <ReviewItem key={review.id}>
          <ReviewItemTop>
            <ReviewAvatar>

              <svg height="20" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.4339 4.94077C12.4339 6.92326 10.8189 8.52859 8.82558 8.52859C6.83134 8.52859 5.21631 6.92326 5.21631 4.94077C5.21631 2.96059 6.83114 1.35583 8.82558 1.35583C10.8187 1.35603 12.4339 2.96059 12.4339 4.94077Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M17.0341 19.2598C17.0341 21.7787 1.01318 21.7787 1.01318 19.2598C1.01318 15.482 4.59819 12.63 9.02355 12.63C13.4503 12.63 17.0341 15.482 17.0341 19.2598Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </ReviewAvatar>
            <ReviewName>
              {review.name}
            </ReviewName>
            <StarRating readOnly value={review.rate} size={18} />
          </ReviewItemTop>
          {review.message ? (
            <ProductReviewText reviewMessage={review.message} translate={translate} />
          ) : null}
        </ReviewItem>
      )) : (
        <ReviewsEmpty>
          {translate('EmptyReviews')}
        </ReviewsEmpty>
      )}

      {visibleReviews < reviews.length && (
        <MainBtn addClass="custom-btn" text={translate('MoreReviews')} clickHandle={showMoreReviews} />
      )}
    </Container>
  );
}

// Styled Components
const Container = styled.div`
    color: #616161;
    border-radius: 10px;
    margin-top: 20px;
    
    .custom-btn {
        margin-bottom: 20px;
    }
`;

const Title = styled.h2`
    color: var(--text-color);
    font-size: 12px;
    margin-bottom: 20px;
    font-weight: 700;
`;

const ReviewAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;
    border-radius: 50%;
    flex-shrink: 0;
    border: 1px solid;
    margin-right: 10px;
`;

const ReviewItem = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
`;

const ReviewItemTop = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ReviewName = styled.p`
    margin-right: 10px;
`;

const ReviewsEmpty = styled.p`
    padding: 0 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

export default ProductReviews;
