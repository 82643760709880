import * as types from "../actions/actionTypes";

const authWaitSmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_WAIT_SMS_SUCCESS:
        return action.payload;
    default:
        return state;
  }
};

const initialState = false;

export default authWaitSmsReducer;
