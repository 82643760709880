import * as types from '../actions/actionTypes';

const initialState = null;

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCTS_FILTER_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default filterReducer;