import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../modules/shared/keyByLang';

const CartItemSidebar = ({
  cartProduct,
  product,
  qtyHandler,
  removeCartItemHandler,
  currentLanguage,
  addonsList,
}) => {
  const title = keyByLang('title', currentLanguage);

  const createAddonsList = addons => addons.map((addon, i) => (
    <span
      className="sidebar-product__addon"
      key={addon.id}
    >
      {addon[title]}{i === addons.length - 1 ? '' : ', '}
    </span>
  ));

  return (
    <StyledLi className="sidebar-product">
      <div className="sidebar-product__info-wrap">
        <p className="sidebar-product__number">{cartProduct.quantity}</p>
        <div className="sidebar-product__title-wrap">
          <p className="sidebar-product__title">{product[title] || `Removed product ID=${cartProduct.id}`}</p>
          {addonsList ? createAddonsList(addonsList) : null}
        </div>
        <p className="sidebar-product__price">{/*product.price * cartProduct.quantity || */cartProduct.total} грн</p>
        <button
          className="sidebar-product__button sidebar-product__button--delete"
          type="button"
          onClick={() => removeCartItemHandler(cartProduct.id)}
        />
      </div>
      <hr className="dashed" />
      <div className="sidebar-product__controls-wrap">
        <button
          className="sidebar-product__button sidebar-product__button--decrease"
          type="button"
          onClick={() => qtyHandler(cartProduct, 'DEC')}
        />
        <button
          className="sidebar-product__button sidebar-product__button--increase"
          type="button"
          onClick={() => qtyHandler(cartProduct, 'INC')}
        />
      </div>
    </StyledLi>
  );
};

const StyledLi = styled.li`
  --active-color: #fff;
  --background-color: #020202;
  --button-color: #8E8E8E;
  --button-size: 24px;
  --list-item-padding: .6rem;
  --transition-time: .2s;
  
  font-weight: 700;
  position: relative;
  padding: 0 var(--list-item-padding);
  overflow: hidden;
  font-size: .75rem;
  width: calc(100% + 65px);
  padding-left: calc(65px + var(--list-item-padding));
  left: -65px;

  .sidebar-product {
    
    &__info-wrap {
      display: flex;
      align-items: center;
    }
    
    &__number {
      font-weight: 200;
      font-size: .75rem;
    }
    
    &__title-wrap {
      padding: var(--list-item-padding);
      padding-left: .8rem;
    }
    
    &__title {
      font-size: .75rem;
    }
    
    &__addon {
      font-size: .7rem;
      font-weight: 200;
    }
    
    &__price {
      color: var(--active-color);
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
      transition: var(--transition-time);
      padding-right: calc(6px + var(--button-size));
    }
    
    &__controls-wrap {
      display: flex;
      position: absolute;
      top: calc(50% - var(--button-size)/2);
      left: 6px;
    }
    
    &__button {
      width: var(--button-size);
      height: var(--button-size);
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid var(--button-color);
      background-color: var(--background-color);
      margin-left: 4px;
      
      &:hover {
        cursor: pointer;
        background-color: var(--button-color);
        
        &::before,
        &::after {
          background-color: var(--active-color);
        }
      }
      
      &--increase {
        position: relative;
        
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
        }
        
        &::before {
          width: 10px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 5px);
        }
        
        &::after {
          width: 2px;
          height: 10px;
          top: calc(50% - 5px);
          left: calc(50% - 1px);
        }
      }
      
      &--decrease {
        position: relative;
        
        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
          width: 10px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 5px);
        }
      }
      
      &--delete {
        border: none;
        position: absolute;
        right: 10px;
        transition: right var(--transition-time);
        
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--button-color);
        }
        
        &::before {
          width: 18px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 9px);
          transform: rotate(45deg);
        }
        
        &::after {
          width: 18px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 9px);
          transform: rotate(135deg);
        }
      }
    }
  }
  
  @media all and (min-width: 1025px) {
    width: 100%;
    padding-left: 0;
    left: 0;
    padding: 0 var(--list-item-padding);
    
    &:hover {
      cursor: pointer;
      z-index: 11;
      width: calc(100% + 65px);
      left: -65px;
      padding-left: calc(65px + var(--list-item-padding));
        
      .sidebar-product__controls-wrap {
        visibility: visible;
      }
        
      .sidebar-product__price {
        padding-right: calc(6px + var(--button-size));
        transition: var(--transition-time);
      }
        
      .sidebar-product__button--delete {
        right: 10px;
        transition: var(--transition-time);
      }
    }
  
    .sidebar-product {
      &__controls-wrap {
        visibility: hidden;
      }
      
      &__price {
        padding-right: 0;
      }
      
      &__button {
        &--delete {
          right: -30px;
        }
      }
    }
  }
`;

export default CartItemSidebar;
