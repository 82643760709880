/* eslint-disable no-param-reassign,no-mixed-operators */
import React from 'react';
import { Translate } from 'react-localize-redux';

const phoneValidation = (value) => {
  const errors = {};
  const phoneStartPath = /^\+380(67|68|96|97|98|50|66|95|99|63|73|93)/;
  const searchLetters = /^\+[0-9]+$/;
  if (value.username && value.username.length < 4 || !value.username) {
    value.username = '+380';
    return errors;
  }
  if (value.username && value.username.slice(0, 4) !== '+380' || !value.username) {
    value.username = `+380${value.username}`;
  }
  if (!searchLetters.test(value.username.slice(1))) {
    value.username = `+${value.username.replace(/[^0-9]/gim, '')}`;
  }
  if (value.username && value.username.length >= 6 && !phoneStartPath.test(value.username)) {
    errors.username = <Translate id="InvalidOperatorCodeMessage" />;
  }
  if (value.username && value.username.length > 13) {
    value.username = value.username.slice(0, 13);
    return errors;
  }
  return errors;
};

export default phoneValidation;
