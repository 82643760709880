import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import FooterBottom from '../components/footer/FooterBottom';
import FooterTop from '../components/footer/FooterTop';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import * as actions from '../actions/actions';

// eslint-disable-next-line react/prefer-stateless-function
class FooterContainer extends React.PureComponent {
  render() {
    const {
      languagePrefix, currentLanguage, translate, textPages, currentCity,
    } = this.props;
    let footerLinks = null;
    if (textPages && textPages[0]) {
      footerLinks = textPages.filter(page => !!(parseInt(page.show_in_footer, 10)));
    }
    return (
      <StyledFooter className="main-footer">
        <hr className="solid" />
        <FooterTop
          currentLanguage={currentLanguage}
          languagePrefix={languagePrefix}
          translate={translate}
          footerLinks={footerLinks}
          currentCity={currentCity}
        />
        <hr className="dashed" />
        <FooterBottom
          translate={translate}
        />
      </StyledFooter>
    );
  }
}

const StyledFooter = styled.footer`
    padding: 0;
    display: grid;
    grid-template-rows: repeat(2, auto);
    background-color: #000000;

  .flex {
    display: flex;
  }

  .footer__link {
    color: #e74e1a ;
    text-decoration: none;
  }

  @media (min-width: 768px) {
     padding: 0 1rem;
  }
`;


const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
  textPages: state.textPages ? state.textPages : null,
  currentCity: state.settings?.google_places_city?.toLowerCase(),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
