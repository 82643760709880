import React from 'react';
import styled from 'styled-components';

import CheckoutDropdown from '../../checkout/CheckoutDropdown';
import StoresDropdown from '../../stores/StoresDropdown';

const HeaderDropdown = ({
  type, closeDropdown, redirectTo, isVisible, transitionTime, languagePrefix,
}) => {
  let content = null;

  const backgroundTransitionTime = transitionTime * 1.2;

  if (type === 'checkout') {
    content = (
      <CheckoutDropdown
        closeDropdown={closeDropdown}
        redirectTo={redirectTo}
      />
    );
  } else if (type === 'stores') {
    content = (
      <StoresDropdown
        closeDropdown={closeDropdown}
        languagePrefix={languagePrefix}
      />
    );
  }

  return (
    <StyledDropdown
      className="header-dropdown"
      isVisible={isVisible}
      transitionTime={transitionTime}
      backgroundTransitionTime={backgroundTransitionTime}
    >
      {content}
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  --page-background: #0A0A0A;
  --header-height: 6rem;
  --transition-time: ${props => (props.transitionTime ? `${props.transitionTime}s` : '.2s')};
  --background-time: ${props => (props.backgroundTransitionTime ? `${props.backgroundTransitionTime}s` : '.3s')};
  
   transition: all var(--transition-time), background-color var(--background-time);
   width: 100%;
   background-color: ${props => (props.isVisible ? 'black' : 'transparent')};
   position: absolute;
   top: ${props => (props.isVisible ? '0' : 'calc(-100% - var(--header-height))')};
   z-index: -1;
   padding-top: ${props => (props.isVisible ? 'var(--header-height)' : '0')};
`;

export default HeaderDropdown;
