import React from 'react';
import styled from 'styled-components';

import ProfileOrderItemProduct from './ProfileOrderItemProduct';
import ProfileActiveOrderTimeLine from './ProfileActiveOrderTimeLine';
import BtnLink from '../../shared/BtnLink';
import keyByLang from '../../../modules/shared/keyByLang';

import moto from '../../../assets/icons/delivery/moto.svg';
import pickUp from '../../../assets/icons/delivery/store.svg';
import { arrowDown, arrowUp } from '../../../assets/icons/svgIcons';
import getDeliveryMethodById from "../../../modules/getDeliveryMethodById";

class ProfileOrdersListItem extends React.Component {
  static setTimeToShow(date) {
    if (!date) {
      return '';
    }
    const dateArr = date.split(' ');
    const dayArr = dateArr[0].split('-');
    const timeArr = dateArr[1].split(':');
    return `${dayArr[2]}.${dayArr[1]}/${timeArr[0]}:${timeArr[1]}`;
  }
  constructor(props) {
    super(props);

    this.state = {
      isProductsListVisible: false,
    };
    this.showHideProductsHandler = this.showHideProductsHandler.bind(this);
  }

  showHideProductsHandler() {
    this.setState(prevState => ({
      isProductsListVisible: !prevState.isProductsListVisible,
    }));
  }

  render() {
    const {
      order,
      address,
      status,
      products,
      statuses,
      paymentMethods,
      deliveryMethods,
      currentLanguage,
      addToCart,
      isMobile,
      languagePrefix,
      isTimeActive,
      isDayActive,
      imageEmpty,
      redirectTo,
      translate,
    } = this.props;
    const { isProductsListVisible } = this.state;
    const { delivery_method_id: deliveryMethodId, payment_method_id: paymentMethodId } = order;
    const title = keyByLang('title', currentLanguage);

    const currentDeliveryMethod = getDeliveryMethodById(deliveryMethodId, deliveryMethods);
    let currentStatuses = [];

    if (+currentDeliveryMethod.id === 1) {
      let filteredByDelivery = statuses.filter(item => item.for_self_delivery === '1');
      currentStatuses = [
        filteredByDelivery[filteredByDelivery.length - 1],
        ...filteredByDelivery.slice(0, filteredByDelivery.length - 2)
      ]
    } else {
      currentStatuses = [
        statuses[statuses.length - 1],
        ...statuses.slice(0, statuses.length - 2)
      ];
    }

    const orderDate = order.created_at.split(' ')[0].split('-').reverse().join('.');
    const createdTime = ProfileOrdersListItem.setTimeToShow(order.created_at);
    const deliveryTime = ProfileOrdersListItem.setTimeToShow(order.delivery_date);
    const time = order.created_at.split(' ')[1].slice(0, 5);

    const orderTimeLine = (
      <ProfileActiveOrderTimeLine
        status={status}
        statuses={statuses}
        deliveryMethodId={deliveryMethodId}
        paymentMethodId={paymentMethodId}
        deliveryMethods={deliveryMethods}
        paymentMethods={paymentMethods}
        currentLanguage={currentLanguage}
        createdTime={createdTime}
        deliveryTime={deliveryTime}
        currentStatuses={currentStatuses}
      />
    );

    const orderFooterAction = isMobile ? (
      <div className="active-order__mobile-action" onClick={this.showHideProductsHandler}>
        <p className="a">{`${isProductsListVisible ? translate('Collapse') : translate('ViewOrder')}`}</p>
        {isProductsListVisible ? arrowDown : arrowUp}
      </div>
    ) : (
      <div className="active-order__action">
        <BtnLink
          align="right"
          addClass="active-order__button"
          clickHandle={this.showHideProductsHandler}
          text={`${isProductsListVisible ? translate('Collapse') : translate('ViewOrder')}`}
        />
      </div>
    );

    return (
      <StyledActiveOrderLi className="active-order" length={currentStatuses.length}>
        <div className="active-order__header">
          <p className="active-order__status">
            {isMobile ? `Заказ № ${order.id}` : `${translate('OrderState')} № ${order.id} | ${orderDate}`}
          </p>
          <p className="active-order__status active-order__status--right">
            {isMobile ? `Статус: ${status[title]}` : `${translate('OrderStatus')}: ${status[title]}`}
          </p>
        </div>
        {orderTimeLine}
        <div className="active-order__footer">
          <div className="active-order__block">
            <p>
              {translate('OrderDate')}: <span className="active-order__date">{orderDate}, {time}</span>
            </p>
            <div className="active-order__address-wrap">
              <div className="active-order__image-wrap">
                <img
                  src={+deliveryMethodId === 1 ? pickUp : moto}
                  alt="delivery"
                  decoding="async"
                  loading="lazy"
                />
              </div>
              <div className="active-order__address">
                <p>
                  {+deliveryMethodId === 1
                    ? `${translate('PickupFromStoreAt')}:`
                    : `${translate('CourierAddressDelivery')}:`
                  }
                </p>
                <p className="active-order__address-data">{address}</p>
              </div>
            </div>
          </div>
          <div className="active-order__block active-order__block--payment">
            <div className="active-order__payment-row">
              <p className="active-order__field-name">{translate('PaymentMethod')}:</p>
              <p className="active-order__field-data">
                {paymentMethods && paymentMethods[0]
                  ? paymentMethods[paymentMethodId - 1][title]
                  : null
                }
              </p>
            </div>
            <div className="active-order__payment-row">
              <p className="active-order__field-name">{translate('BonuseAccruedForOrder')}:</p>
              <p className="active-order__field-data">{order.bonuses_earned}</p>
            </div>
            <div className="active-order__payment-row active-order__payment-row--sum">
              <p className="active-order__field-name">{translate('TotalAmount')}</p>
              <p className="active-order__field-data">{order.to_pay} грн</p>
            </div>
          </div>
          <hr className="solid is-mobile" />
        </div>
        {orderFooterAction}
        {isProductsListVisible ? (
          <ul className="order-list">
            {products && products[0] && order ? products.map((product, i) => (
              <ProfileOrderItemProduct
                product={product}
                isVisible={isProductsListVisible}
                currentLanguage={currentLanguage}
                order={order}
                addToCart={addToCart}
                languagePrefix={languagePrefix}
                isTimeActive={isTimeActive}
                key={`${order.id}-${product.id}-${i}`}
                i={i}
                productsLength={products.length}
                isDayActive={isDayActive}
                imageEmpty={imageEmpty}
                redirectTo={redirectTo}
              />
            )) : null}
          </ul>
        ) : (
          <hr className={isMobile ? 'dashed' : 'solid padded'} />
        )}
      </StyledActiveOrderLi>
    );
  }
}

const StyledActiveOrderLi = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  color: var(--text-color);
  .active-order {

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
      
    &__status {
      font-weight: 700;
      color: var(--active-text-color);
      
      &--right {
        color: var(--text-color);
      }
    }
    
    &__footer {
      display: flex;
      flex-direction: column;
    }
    
    &__date {
      color: var(--active-text-color);
    }
    
    &__address-wrap {
      padding-top: 1rem;
      display: flex;
      align-items: center;
    }
    
    &__address {
      flex-basis: 20%;
      flex-grow: 1;
      margin: 0 1rem;
      font-size: .9rem;
      height: 100%;
    }
    
    &__address-data {
      margin-top: .5rem;
      color: var(--active-text-color);
    }
    
    &__action {
      padding: .8rem 0;
      text-align: right;
    }
    
    &__payment-row {
      display: flex;
      justify-content: space-between;
      font-size: .9rem;
      margin-bottom: .9rem;
      
      &--sum {
        color: var(--active-text-color);
        font-size: 1.2rem;
      }
    }
    
    &__field-data {
      font-weight: 700;
      text-align: right;
    }
    
    &__image-wrap {
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--text-color);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__block {
      margin-top: 1.3rem;
      flex-basis: 50%;
      position: relative;
    }
    
    &__button {
      width: auto;
    }
    
    &__timeline-head {
      display: flex;
      flex-grow: 1;
    }
    
    &__timeline-list {
       display: flex;
       flex-grow: 1;
       overflow: hidden;
       border-radius: 4px;
    }
    
    &__head-item {
      min-width: calc(100% / ${props => props.length});
      padding: 0 .5rem .5rem .2rem;
      position: relative;
      font-size: .75rem;
      white-space: nowrap;
      
      &:not(:last-child)::after {
        content: '';
        display: block;
        height: calc(100% );
        position: absolute;
        right: 0;
        bottom: -15px;
        width: 2px;
        background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
        background-size: 2px 7.5px;
        z-index: 2;
      }
    }
    
    &__status-text {
      opacity: 0;
      overflow: hidden;
    }
    
    &__mobile-action {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0;
    }
    
    &__list-item {
      position: relative;
      width: calc(100% / ${props => props.length});
      height: 12px;
      background: #313131;
      
      &:after{
        content: "";
        width: 200%;
        height: 100%;
        position: absolute;
        display: block;
        background: #8e8e8e;
        left: 0%;
        border-radius: 4px;
        transform: translateX(-75%);
        z-index: 1;
      }
      
      &--active {
        &::after {
          content: "";
          width: 200%;
          height: 100%;
          position: absolute;
          display: block;
          background: #8e8e8e;
          left: 0%;
          transform: translateX(-75%);
          border-radius: 4px;
          z-index: 1;
        }
      }
      
      &--active ~ .active-order__list-item::after {
        display: none;
      }
    }
  }
  
  .padded {
    margin: .5rem 0 0;
  }
  
  .is-mobile {
    display: block;
  }

@media all and (min-width: 768px) {
  .active-order {
    &__status {
      color: var(--text-color);
    }
    &__head-item {
      font-size: 1rem;
      padding: 0 1rem .5rem .5rem;
    }
    
    &__block {
      &::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        background-image: linear-gradient(to top, var(--dashed-border-color) 20%, transparent 20%, transparent 80%, var(--dashed-border-color) 80%);
        background-size: 2px 8px;
      }
      
      &--payment {
        padding: 0 0 .8rem 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &::after {
          display: none;
        }
      }
    }
    
    &__footer {
      flex-direction: row;
    }
    
    &__payment-row {
      margin-bottom: 0;
    }
  }
  
  .is-mobile {
    display: none;
  }
  
  .padded {
    margin: 1rem 0 1rem;
  }
}

@media all and (min-width: 1024px) {
  .active-order {
    &__status-text {
      opacity: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media all and (min-width: 1280px) {
  .active-order {
    &__payment-row {
      font-size: 1.1rem;
    }
    &__address {
      font-size: 1rem;
    }
    &__status-text {
      opacity: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
`;

export default ProfileOrdersListItem;
