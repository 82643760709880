import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../../modules/shared/keyByLang';
import {Translate} from "react-localize-redux";

const ProgressBar = ({
  startDate, deliveryDate, currentStatuses, currentLanguage, lastOrder,
}) => {
  const title = keyByLang('title', currentLanguage);
  let activePosition = 1;

  if (currentStatuses && currentStatuses[0] && lastOrder) {
    activePosition = currentStatuses
      .indexOf(currentStatuses.find(status => +status.id === lastOrder.status_id));
  }

  const checkClassName = (currentPosition, lastPosition) => {
    let isFirst = '';
    let isLast = '';
    let isActive = '';
    let isCompleted = '';
    if (currentPosition === 0) {
      isFirst = 'progress-bar__progress--first';
    }
    if (currentPosition === lastPosition) {
      isLast = 'progress-bar__progress--last';
    }
    if (currentPosition < activePosition) {
      isCompleted = 'progress-bar__progress--completed';
    }
    if (currentPosition === activePosition) {
      isActive = 'progress-bar__progress--active';
    }
    return `progress-bar__progress ${isFirst} ${isLast} ${isActive} ${isCompleted}`;
  };

  return (
    <StyledProgressBar className="progress-bar" length={currentStatuses.length}>
      <h2 className="progress-bar__heading"><Translate id="OrderStatus" /></h2>
      <div className="progress-bar__line">
        {currentStatuses && currentStatuses[0] ? currentStatuses.map((status, i) => (
          <div className="progress-bar__block" key={status.id}>
            <p className="progress-bar__action">
              {`${status[title]} `}
              {i === 0 ? startDate : null}
              {i === currentStatuses.length - 1 ? deliveryDate : null}
            </p>
            <div
              className={checkClassName(i, currentStatuses.length - 1)}
            />
          </div>
        )) : null}
      </div>
    </StyledProgressBar>
  );
};

const StyledProgressBar = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --disabled-text-color: #313131;
  
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0, .7);
  color: var(--text-color);
  height: auto;
  width: 100%;
  align-items: center;
  padding: .5rem;
  
  .progress-bar {
    &__heading {
      text-transform: none;
      font-size: 1rem;
      font-weight: 600;
      min-width: 7.5rem;
      text-align: left;
      margin-bottom: .5rem;
    }
    
    &__line {
      display: flex;
      width: 100%;
      min-width: 0;
    }
    
    &__block {
      width: calc(100% / ${props => props.length});
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      
      &:not(:last-child)::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
        background-size: 2px 8px;
      }
    }
    
    &__action {
      padding-bottom: .3rem;
      margin-left: .2rem;
      flex-grow: 1;
      font-size: .75rem;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    &__progress {
      height: .6rem;
      width: 100%;
      background-color: var(--disabled-text-color);
      position: relative;
      overflow: hidden;
      
      &--first {
        border-radius: 4px 0 0 4px;
      }
      
      &--last {
        border-radius: 0 4px 4px 0;
        
      }
      
      &--active {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 50%;
          background-color: var(--text-color);
          border-radius: 0 4px 4px 0;
        }
      }
      
      &--completed {
        background-color: var(--text-color);
      }
    }
  }
  
  @media all and (min-width: 769px) {
    padding: 0 5rem;
    display: flex;
    height: 70px;
    
    .progress-bar {
      &__heading {
        margin-bottom: 0;
      }
      &__line {
        padding-left: 2rem;
      }
      
      &__action {
        font-size: 1rem;
        margin-left: .5rem;
      }
    }
  }
`;

export default ProgressBar;
