import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, getActiveLanguage, getTranslate } from 'react-localize-redux';

import { Link } from 'react-router-dom';
import ProfileBlockTitle from './ProfileBlockTitle';
import MainBtn from '../shared/MainBtn';

import * as actions from '../../actions/actions';

import cardLogo from '../../assets/icons/profile/cardLogo.svg';
import bonuscartimage from '../../assets/images/bonuscartimage.svg';

import bonusIcon from '../../assets/icons/form/bonus.svg';
import questions from '../../assets/icons/profile/questions.svg';
import getlanguagePrefix from '../../modules/shared/getLanguagePrefix';

class ProfileBonusesBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHistoryBlock: false,
    };

    this.showHideBonusHisotryHadler = this.showHideBonusHisotryHadler.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }

  showHideBonusHisotryHadler = () => {
    this.setState((prevState, props) => ({
      showHistoryBlock: !prevState.showHistoryBlock,
    }));
  }

  fetchData() {
    return this.props.actions.getOrdersHistory();
  }

  refresh() {
    this.props.actions.getUser();
  }

  render() {
    const {
      user, bonusData, languagePrefix, isMobile, translate,
    } = this.props;

    const ordersHistoryTableItems = this.props.ordersHistory
      ? [...this.props.ordersHistory].reverse().map(order => (
        <div className="bonuses-history__row" key={order.id}>
          <p className="bonuses-history__cell">{order.id}</p>
          {!isMobile ? (
            <p className="bonuses-history__cell">{user ? `+38${user.username}` : ''}</p>
          ) : null}
          <p className="bonuses-history__cell">{order.to_pay}</p>
          <p className="bonuses-history__cell">{order.bonuses_earned}</p>
          <p className="bonuses-history__cell">{order.bonuses_used}</p>
        </div>
      )) : null;

    const bonusHistoryBlock = (
      <StyledBonusesHistory className="bonuses-history" isMobile={isMobile}>
        <div className="bonuses-history__table">
          <div className="bonuses-history__row bonuses-history__row--head">
            <p className="bonuses-history__cell"><Translate id="BhtThNumber" /></p>
            {!isMobile ? (
              <p className="bonuses-history__cell"><Translate id="BhtThPhone" /></p>
            ) : null}
            <p className="bonuses-history__cell"><Translate id="BhtThPrice" /></p>
            <p className="bonuses-history__cell"><Translate id="BhtThBonusesEarned" /></p>
            <p className="bonuses-history__cell"><Translate id="BhtThBonusesSpent" /></p>
          </div>
          {ordersHistoryTableItems}
        </div>
      </StyledBonusesHistory>
    );

    return (
      <StyledProfileBonuses className="bonuses">
        <ProfileBlockTitle
          icon={bonusIcon}
          action={this.refresh}
          actionText={translate('Refresh')}
          paddingTop="60"
        >
          <Translate id="profileBonusBlockTitle" />
        </ProfileBlockTitle>
        <hr className="solid" />
        <div className="bonuses__content">
          {isMobile ? (
            <div className="bonuses__field-wrap bonuses__field-wrap--card">
              <p className="bonuses__field">{translate('bonusCard')}:</p>
              <p className="bonuses__number">8439 4468 0031 9693</p>
            </div>
          ) : null}
          <div className="bonuses__block bonuses__block--first">
            <StyledCard className="card">
              <img
                className="card__image"
                src={bonuscartimage}
                alt="bonuses"
                decoding="async"
                loading="lazy"
              />
              <div className="card__content">
                <div className="card__title">
                  <Translate id="bonusCardTitle" />
                </div>
                <img className="card__logo" src={cardLogo} alt="mini-logo" decoding="async" loading="lazy" />
                <div className="card__number">8439 4468 0031 9693</div>
              </div>
            </StyledCard>
            <div className="bonuses__card-description">
              {!isMobile ? (
                <div className="bonuses__field-wrap bonuses__field-wrap--number">
                  <p className="bonuses__field">{translate('bonusCard')}:</p>
                  <p className="bonuses__number">8439 4468 0031 9693</p>
                </div>
              ) : null}
              <div className="bonuses__field-wrap bonuses__field-wrap--horizontal">
                <p className="bonuses__field">{translate('bonusCardAvailableBonuses')}:</p>
                <p className="bonuses__sum">{bonusData ? bonusData.bonuses_total : null}</p>
              </div>
              <div className="bonuses__field-wrap">
                <p className="bonuses__field">{translate('bonusCardAllBonuses')}: </p>
                <p className="bonuses__number">{bonusData ? bonusData.bonuses_total : null}</p>
              </div>
            </div>
          </div>
          <div className="bonuses__block bonuses__block--last">
            <div className="bonuses__using">
              {!isMobile ? (
                <div className="cabinet__icon-wrap">
                  <img src={questions} alt="message" decoding="async" loading="lazy" />
                </div>
              ) : null}
              <div className="bonuses__links">
                <Link className="bonuses__link" to={`${languagePrefix}/pages/spend-bonuses`}>
                  <Translate id="howToUseBonuses" />
                </Link>
                <Link className="bonuses__link" to={`${languagePrefix}/pages/collect-bonuses`}>
                  <Translate id="howBonusesSaveUp" />
                </Link>
              </div>
            </div>
            {bonusData && bonusData.bonuses_expiration_date ? (
              <div className="bonuses__time-wrap">
                <div className="bonuses__date">
                  <Translate id="hurryToHandOutBonuses" />
                  <p className="bonuses__day">{bonusData.bonuses_expiration_date}</p>
                </div>
                <p><Translate id="bonusesHasExpDate" /></p>
              </div>
            ) : null}
            <MainBtn
              align="left"
              clickHandle={this.showHideBonusHisotryHadler}
              translateId="earnBonusesHistory"
              addClass="bonuses__button-wrap"
            />
          </div>
        </div>
        <div className="bonuses__table-wrap">
          {isMobile && !this.state.showHistoryBlock ? (
            <hr className="dashed" />
          ) : null}
          {!isMobile && !this.state.showHistoryBlock ? (
            <hr className="solid" />
          ) : null}
          {this.state.showHistoryBlock ? bonusHistoryBlock : null}
        </div>
      </StyledProfileBonuses>
    );
  }
}

const mapStateToProps = state => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  bonusData: state.bonusData ? state.bonusData : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  user: state.user ? state.user : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBonusesBlock);

const StyledProfileBonuses = styled.div`
  --dashed-border-color: #313131;

  .bonuses {
    &__content {
      padding: 2.5rem 0 2rem;
      display: flex;
      flex-direction: column;
    }
    
    &__block {
       flex-basis: 50%;
       display: flex;
       position: relative;
      
       
      &--last {
        flex-direction: column;
        
        justify-content: space-between;
      }
    }
    
    &__card-description {
      position: relative;
      padding-left: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: .5rem;
    }
    
    &__image {
      position: absolute;
    }
    
    &__field-wrap {
      display: flex;
      
      &--horizontal {
        flex-direction: column;
      }
      
      &--number {
        
      } 
      
      &--card {
        padding-bottom: 1rem;
      }
    }
    
    &__field {
      color: var(--text-color);
    }
    
    &__number {
      color: var(--active-text-color);
      padding-left: .3rem;
      flex-shrink: 0;
    }
    
    &__sum {
      color: var(--link-color);
      font-size: 3rem;
      font-weight: 700;
    }
    
    &__using {
      display: flex;
    }
    
    &__links {
      display: flex;
      flex-direction: column;
      padding: .5rem 0 1rem;
      justify-content: space-between;
    }
    
    &__link {
      margin: .5rem 0;
      color: var(--link-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__time-wrap {
      color: var(--text-color);
      font-size: .9rem;
    }
    
    &__date {
      display: flex;
    }
    
    &__day {
      margin-left: .5rem;
    }
    
    &__button-wrap {
    
      .button {
        width: 100%;
      }
    }
  }
  
@media all and (min-width: 768px) {
  .bonuses {
    &__using {
      align-items: center;
      margin-bottom: 1.25rem;
    }
    
    &__field-wrap {
      &--number {
        flex-direction: column;
      }
    }
    &__links {
      padding:  0 0 0 1.25rem;
    }
  }
}
  
@media all and (min-width: 1280px) {
  .bonuses {
    &__content {
      flex-direction: row;
    }
    
    &__card-description {
      padding-left: 2.5rem;
    }
    
    &__block {
      &--first {
        flex-direction: row;
        min-width: 500px;
        
        &::after {
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
          background-image: linear-gradient(to top, var(--dashed-border-color) 20%, transparent 20%, transparent 80%, var(--dashed-border-color) 80%);
          background-size: 2px 6px;
        }
      }
      
      &--last {
        margin-left: 2.5rem;
      }
    }
    
    &__field-wrap {
      &--card {
        margin-bottom: 0;
      }
    }
    
    &__links {
      padding:  0 0 0 1.25rem;
    }
    
    &__link {
      margin: .2rem 0;
    }
    
    &__button-wrap {
    
      .button {
        width: auto;
      }
    }
  }
}

@media all and (min-width: 1441px) {
  .bonuses {
    
    &__field-wrap {
      &--number {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}
`;

const StyledBonusesHistory = styled.div`
  background-color: var(--secondary-background-color);
  border-radius: 4px;
  max-height: 20rem;
  overflow-y: scroll;

  .bonuses-history {
    
    &__row {
      display: grid;
      grid-template-columns: ${props => (props.isMobile ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)')};;
      
      &:not(:last-child) {
        border-bottom: 1px dotted var(--dashed-border-color);
      }
      
      &--head {
        color: var(--text-color);
        font-weight: 700;
      }
    }
    
    &__cell {
      margin: .5rem;
      font-size: .9rem;
    }
  }
  
@media all and (min-width: 768px) {
  max-height: 30rem;
  .bonuses-history {
    &__cell {
      margin: 1.25rem;
      font-size: 1rem;
    }
  }
}
`;

const StyledCard = styled.div`
  position: relative;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 10rem;
  
  .card {
    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: .5rem 0;
    }
    
    &__image {
      width: 100%;
    }
    
    &__logo {
      width: 4.2rem;
      padding-top: .5rem;
    }
    
    &__title {
      text-transform: uppercase;
      font-size: .7rem;
    }
    
    &__number {
      font-size: .8rem;
      padding-top: .5rem;
    }
  }
  
  @media all and (min-width: 1281px) {
  width: auto;
  .card {
    &__content {
      width: 100%;
      height: 100%;
      padding: 1rem 0;
    }
    
    &__title {
      font-size: 1rem;
    }
    
    &__logo {
      width: auto;
      padding-top: 0;
    }
    
    &__number {
      font-size: 1rem;
      padding-top: 0;
    }
  }
}
  
@media all and (min-width: 1281px) {
  width: auto;
  .card {
    &__content {
      width: 100%;
      height: 100%;
      padding: 1rem 0;
    }
    
    &__title {
      font-size: 1rem;
    }
    
    &__logo {
      width: auto;
      padding-top: 0;
    }
    
    &__number {
      font-size: 1rem;
      padding-top: 0;
    }
  }
}
`;
