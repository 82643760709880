import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/actions';

import getlanguagePrefix from '../../../modules/shared/getLanguagePrefix';

import keyByLang from '../../../modules/shared/keyByLang';
import AnimatedButton from '../../shared/buttons/AnimatedButton';
import { cart } from '../../../assets/icons/svgIcons';
import plus from '../../../assets/icons/plus.svg';
import checkIfItBusiness from '../../../modules/shared/checkIfItBusiness';

const ProfileOrderItemProduct = (props) => {
  const {
    product,
    currentLanguage,
    order,
    categories,
    addToCart,
    languagePrefix,
    isMobile,
    cartData,
    isTimeActive,
    i,
    productsLength,
    isDayActive,
    imageEmpty,
    redirectTo,
    translate,
  } = props;
  const title = keyByLang('title', currentLanguage);
  const isProductAvailable = isTimeActive(product) && isDayActive(product)
    && (product.image_filename || product.preview_image_filename);
  let isInCart = false;
  let buttonData = (
    <AnimatedButton
      href="#"
      minWidth="130px"
      icon={cart}
      text={translate('AddToCartButton')}
      iconName="store"
      handleClick={() => addToCart(product)}
      animated
    />
  );

  if (cartData && cartData.content && cartData.content[0]) {
    isInCart = !!cartData.content.find(prod => +prod.product_id === +product.id);
  }

  if (!isProductAvailable) {
    buttonData = (
      <div className="order-list__not-available">
        <p>{translate('ProductNotAvailable')}</p>
        {/* <p>Доступно с </p>
        <p>{product.available_since} до {product.available_till}</p> */}
      </div>
    );
  } else if (isMobile && isProductAvailable && !isInCart) {
    buttonData = (
      <div
        className="order-list__button"
        onClick={() => addToCart(product)}
      >
        {/* cart */}
        <img src={plus} alt="plus" decoding="async" loading="lazy" />
      </div>
    );
  } else if (isMobile && isProductAvailable && isInCart) {
    buttonData = (
      <div
        className="order-list__button order-list__button--active"
        onClick={() => addToCart(product)}
      >
        <img src={plus} alt="plus" decoding="async" loading="lazy" />
      </div>
    );
  } else if (!isMobile && isProductAvailable) {
    buttonData = (
      <AnimatedButton
        href="#"
        minWidth="130px"
        icon={cart}
        text={translate('AddToCartButton')}
        iconName="store"
        handleClick={() => addToCart(product)}
        animated
      />
    );
  }

  let linkAction = null;

  if (product.image_filename || product.preview_image_filename) {
    const productCategory = categories.find((cat) => product.product_category_id === cat.id).alias;
    linkAction = () => redirectTo(`${languagePrefix}/${productCategory}/${product.alias}`);
  } else {
    linkAction = () => props.actions.showSnackBar(translate('ProductWasDeletedMessage'), 4000, false);
  }

  const showBusiness = checkIfItBusiness(product.product_category_id, categories);

  return (
    <StyledOrderLi className="order-list__item">
      <div className="order-list__item-content">
        <div className="order-list__data-wrap">
          <button type="button" onClick={linkAction} className="order-list__link" />
          <img
            className="order-list__image"
            src={product.preview_image_filename || imageEmpty}
            alt="sushi"
            decoding="async"
            loading="lazy"
          />
          <div className="order-list__text">
            <p className="order-list__title">{product.quantity} {product[title] || product.title}</p>
            <div className="order-list__price">{product.price} грн</div>
          </div>
        </div>
        <div className="order-list__actions">
          {product.is_active === '1' && showBusiness ? buttonData : translate('ProductNotAvailable')}
        </div>
      </div>
      {i < productsLength - 1 ? (<hr className="dashed" />) : null}
    </StyledOrderLi>
  );
};

const StyledOrderLi = styled.li`
  background-color: #1C1C1C;
  
  .order-list {
  
    &__item {
    }
    
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
    }
  
    &__item-content {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: .5rem;
    }
    
    &__data-wrap {
      display: flex;
      position: relative;
    }
    
    &__image {
      max-width: 3rem;
      max-height: 3rem;
      object-fit: contain;
      margin-right: 10px;
    }
    
    &__text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    &__title {
      color: var(--text-color);
      font-weight: 700;
    }
    
    &__price {
      color: var(--active-text-color);
    }
    
    &__actions {
      display: flex;
      align-items: center;
    }
    
    &__not-available {
      color: #fff;
      font-size: .75rem;
      text-align: center;
    }
    
    &__button {
      width: 40px;
      height: 40px;
      border: 1px solid var(--text-color);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: .5rem;
      transition: background-color 6s ease, border-color 6s ease;
      will-change: background-color, border-color;
      
      &:active {
        transition-duration: 0s;
        background-color: var(--link-color);
        border-color: var(--link-color);
      }
      
      path {
        fill: var(--text-color);
      }
    }
  }
  
  @media all and (min-width: 768px) {
    .order-list {
      &__item-content {
        padding: 1rem;
      }
      
      &__not-available {
        color: #fff;
        font-size: 1rem;
        text-align: center;
      }
    }
  }
`;

const mapStateToProps = state => ({
  cartData: state.cart ? state.cart : null,
  isMobile: state.isMobile,
  translate: getTranslate(state.locale),
  categories: state.categories,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOrderItemProduct);
