import React from 'react';
import styled from 'styled-components';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const InputFile = (props) => {
  const {
    input: {
      value: omitValue,
      onChange,
      onBlur,
      ...inputProps
    },
    meta: omitMeta,
    id,
    label,
    buttonText,
    ...otherPrpos
  } = props;
  const { error } = omitMeta;

  return (
    <StyledInputFile className="input-file" error={error}>
      <input
        className="input-file__button"
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        id={id}
        {...inputProps}
        {...otherPrpos}
      />
      <label htmlFor={id}>
        <span className="label-text">{label}</span>
        <span className="button-text">{omitValue ? omitValue.name : buttonText}</span>
      </label>
    </StyledInputFile>
  );
};

const StyledInputFile = styled.div`
    margin-bottom: 20px;
    cursor: pointer;

    input[type="file"] {
        display: none;
    }

    .label-text {
        vertical-align: middle;
        display: inline-block;
        //border: ${props => (props.error ? '1px solid red' : 'none')};
        //padding: ${props => (props.error ? '5px' : 'none')};
    }

    .button-text {
        border: 1px solid #E74E1A;
        border-radius: 4px;
        margin: 0 15px;
        cursor: pointer;
        color: #E74E1A;
        padding: 5px 12px;
        max-width: 180px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

`;

export default InputFile;
