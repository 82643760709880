import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import CartSidebar from './CartSidebar';
import CartEmpty from './CartEmpty';

import emptyCart from '../../assets/icons/empty_cart.svg';
import { cart } from '../../assets/icons/svgIcons';
import MainBtn from '../shared/MainBtn';
import { Portal } from '../Portal';

const CartContentSidebar = ({
  cartData,
  categories,
  productsCount,
  piecesCount,
  qtyHandler,
  currentLanguage,
  removeCartItemHandler,
  languagePrefix,
  cartSubmit,
  isMobile,
  translate,
  openAccessoriesModal,
}) => {
  let cartComponent = null;

  if (isMobile && cartData && cartData.content.length) {
    cartComponent = (
      <Portal selector="#root" portalId="CartContentSidebar">
        <div className="mobile-cart__wrap">
          <MainBtn
            minWidth="90%"
            clickHandle={cartSubmit}
            text={`${translate('CheckoutButton')}: ${cartData.total} грн`}
            type="button"
            addClass="mobile-cart__submit"
          />
        </div>
      </Portal>
    );
  } else if (isMobile && (!cartData || !cartData.content.length)) {
    cartComponent = null;
  } else if (!isMobile && cartData && cartData.content.length) {
    cartComponent = (
      <CartSidebar
        cartData={cartData}
        categories={categories}
        productsCount={productsCount}
        currentLanguage={currentLanguage}
        qtyHandler={qtyHandler}
        removeCartItemHandler={removeCartItemHandler}
        languagePrefix={languagePrefix}
        cartSubmit={cartSubmit}
        translate={translate}
        openAccessoriesModal={openAccessoriesModal}
      />
    );
  } else if (!isMobile && (!cartData || !cartData.content.length)) {
    cartComponent = (
      <CartEmpty emptyCartImage={emptyCart} />
    );
  }

  return (
    <StyledDiv className="cart-sidebar" isMobile={isMobile}>
      {!isMobile ? (
        <>
          <div className="cart-sidebar__header">
            {cart}
            <p className="cart-sidebar__title">{translate('Cart')}</p>
            {piecesCount ? (
              <p className="cart-sidebar__total-price">
                {piecesCount}
                {' '}
                шт
              </p>
            ) : null}
          </div>
          <hr className="solid line-margin" />
        </>
      ) : null}
      <div className="cart-content">
        {cartComponent}
      </div>
    </StyledDiv>
  );
};


const StyledDiv = styled.div`
  position: sticky;
  top: 11.5rem;
  margin: 0 auto;
  z-index: ${(props) => (props.isMobile ? '10' : '1')};
  color: #8E8E8E;
  padding-bottom: ${(props) => (props.isMobile ? '0' : '2rem')};

  .cart-sidebar {
  
    &__header {
      display: flex;
      align-items: center;
      padding: 10px 10px 20px;
      
      & svg {
        margin-right: .6em;
        opacity: .6;
      }
    }
    
    &__title {
      padding-top: .3em;
      text-transform: uppercase;
      font-weight: 700;
      flex-grow: 1;
      font-size: 14px;
    }
    
    &__total-price {
      padding-top: .3em;
      font-size: .75rem;
    }
  }
  
  .line-margin {
    margin-bottom: 20px;
  }
  
  .mobile-cart {
    &__wrap {
      background-color: #0A0A0A;
    }
    
    &__submit {
      position: fixed;
      bottom: 0;
      z-index: 12;
      
      & .button {
        border-radius: 4px 4px 0 0;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
      }
    }
  }
  
  .sidebar-cart-list {
    min-height: 5rem;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    scrollbar-width: none;
    margin-left: -70px;
    padding-left: 70px;
      
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media all and(min-width: 1025px) {
    .sidebar-cart-list {
      margin-left: 0;
      padding-left: 0;
      
      &:hover {
        margin-left: -70px;
        padding-left: 70px;
      }
    }
  }
  
  @media all and (min-width: 1440px) {
    .sidebar-cart-list {
      &:hover {
        margin-left: -80px;
        padding-left: 80px;
      }
    }
  }

  /*.mobile-cart-submit {
    position: fixed;
    bottom: 0;
    z-index: 12;
    
    & .button {
      border-radius: 4px 4px 0 0;
      box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
    }
  }*/

  /*.cart-footer {
  
    .show-all-link {
      color: #E74E1A;
      float: right;
    }
    
    .total-row {
      font-size: 20px;
      
      .total-price {
        float: right;
      }
    }
  }*/
`;

export default React.memo(CartContentSidebar);
