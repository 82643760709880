import React from 'react';
import styled from 'styled-components';
import {Translate} from "react-localize-redux";

const UserName = ({
  user, icon, logOut,
}) => (
  <StyledUserName className="user-name">
    <div className="user-name__wrap">
      {icon}
      <p className="user-name__text">{user.name ? user.name : `+38${user.username}`}</p>
      <p className="user-name__link" onClick={logOut}><Translate id="ChangeProfile" /></p>
    </div>
    <p className="user-name__greeting">{user.name
      ? <Translate id="NiceToSeeYouAgain" />
      : <Translate id="Welcome" />}
    </p>
  </StyledUserName>
);

export default UserName;

const StyledUserName = styled.div`
  --text-color: #FFFFFF;
  --text-color-secondary: #8E8E8E;
  --link-color: #E74E1A;
  
  position: relative;

  .user-name {
  
    &__wrap {
      display: flex;
      align-items: center;
      
      & path {
        stroke: var(--text-color-secondary);
      }
    }
  
    &__text {
      text-transform: none;
      font-weight: 200;
      color: var(--text-color-secondary);
      font-size: 1.4rem;
      margin-left: .7rem;
      flex-grow: 1;
    }
    
    &__link {
      color: var(--link-color);
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__greeting {
      color: var(--text-color-secondary);
      padding-top: .6rem;
      font-size: .9rem;
    }
  }
  
  @media all and (min-width: 768px) {
    .user-name {
      
      &__link {
        position: static;
      }
      
      &__greeting {
        font-size: 1rem;
        padding-top: 1.3rem;
      }
    }
  }
`;
