const getPaymentMethodById = (id, paymentMethods) => {
    let matchObj = {};
  // eslint-disable-next-line no-unused-expressions
    (paymentMethods && id) ? (
        paymentMethods.map( method => {
          // eslint-disable-next-line no-unused-expressions
            ( parseInt(id) === parseInt(method.id) )
            ?   ( Object.assign(
                    matchObj,
                    method,
                ))
            : null
        })
    )
    : false;
    return matchObj;
  }
  export default getPaymentMethodById;
