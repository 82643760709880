import React from 'react';
import styled from 'styled-components';

import keyByLang from '../../modules/shared/keyByLang';

const AnswerBlock = ({
  faq,
  activeTopicId,
  activeQuestionId,
  currentLanguage,
}) => {
  let activeTopicObj;
  let activeQuestion;
  const answer = keyByLang('answer', currentLanguage);

  const compareIds = (item, itemToCompare) => parseInt(item.id, 10) === parseInt(itemToCompare, 10);

  if (faq) {
    activeTopicObj = faq.find(item => compareIds(item, activeTopicId));
  }

  if (activeTopicObj && activeTopicObj.faqs) {
    activeQuestion = activeTopicObj.faqs.find(item => compareIds(item, activeQuestionId));
  }

  if (activeQuestion) {
    return (
      <StyledActiveAnswer>
        <p className="answer">{activeQuestion[answer]}</p>
      </StyledActiveAnswer>
    );
  }
  return null;
};

const StyledActiveAnswer = styled.div`
  max-width: 600px;
  margin: 20px auto 40px;
  text-align: justify;
  
  .answer {
    font-size: 15px;
    line-height: 19px;
  }
  
  @media all and (min-width: 769px) {
    text-align: left;
  }
`;

export default AnswerBlock;
