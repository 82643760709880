import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const HomeBannerApi = {
  getHomeBannerData() {
    return axiosInstance.post('banners',
      {},
      { headers: headers.json });
  },
};

export default HomeBannerApi;
