/* eslint-disable operator-assignment,no-param-reassign,no-return-assign,no-unused-vars,max-len,no-else-return */
import React from 'react';
import { bindActionCreators } from 'redux';
import { getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../../actions/actions';

import AccessorieComponent from './accessorie/AccessorieComponent';

class AccessoriesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDropdown: null,
    };

    this.freeAccessoriesAverage = {};
  }

  componentDidMount() {
    this.fetchData();
    if (!this.props.order || (this.props.order && this.props.order.shouldAccessoriesUpdate)) {
      this.props.actions.setSeparatedAccessories({
        accessories: this.setInitialAccessoriesData(this.props.collectedAccessories),
        freeAccessories: this.setInitialAccessoriesData(this.props.collectedAccessories),
        extraAccessories: {},
        freeAccessoriesByTypes: this.collectAccessoriesByTypes(this.props.collectedAccessories),
        extraAccessoriesByTypes: {},
      });
    }
    this.freeAccessoriesAverage = this.collectAccessoriesByTypes(this.props.collectedAccessories);
  }

  componentWillUnmount() {
    this.props.actions.setAccessoriesUpdate(false);
  }

  setInitialFreeAccessories = (data) => {
    const result = { ...data };
    const keys = Object.keys(result);
    keys.forEach(key => result[key] = result[key].count);
    return result;
  }

  getAccessoryType = accessoryId => this.props.collectedAccessories
    .find(accessory => accessory.id === accessoryId).accessory_type_id;

  changeAccessoryCount = ({ id, num }) => {
    const type = this.getAccessoryType(id);
    this.separateAccessories(id, type, num);
    this.toggleDropdown(id);
  };

  separateAccessories = (id, type, count) => {
    const result = { ...this.props.order };
    const availableFreeCount = this.freeAccessoriesAverage[type] - this.countFreeAccessoriesById(id, type);
    result.accessories[id] = count;
    if (count > availableFreeCount && availableFreeCount >= 0) {
      result.freeAccessories[id] = availableFreeCount;
      result.extraAccessories[id] = count - availableFreeCount;
    } else if (count <= availableFreeCount) {
      result.freeAccessories[id] = count;
      delete result.extraAccessories[id];
    }
    const resultToSend = {
      accessories: result.accessories,
      freeAccessories: result.freeAccessories,
      extraAccessories: result.extraAccessories,
      freeAccessoriesByTypes: this.collectChosenAccessories(result.freeAccessories),
      extraAccessoriesByTypes: this.collectChosenAccessories(result.extraAccessories),
    };
    this.regulateExtraAccessories(type, resultToSend, id);
    this.props.actions.setSeparatedAccessories(resultToSend);
    /*this.props.actions.setSeparatedAccessories({
      accessories: result.accessories,
      freeAccessories: result.freeAccessories,
      extraAccessories: result.extraAccessories,
      freeAccessoriesByTypes: this.collectChosenAccessories(result.freeAccessories),
      extraAccessoriesByTypes: this.collectChosenAccessories(result.extraAccessories),
    });*/
  }

  regulateExtraAccessories = (type, result, id) => {
    const freeDifference = this.freeAccessoriesAverage[type] - result.freeAccessoriesByTypes[type];
    if (!freeDifference || !Object.keys(result.extraAccessories).length) {
      return;
    }
    const anotherAccessories = this.props.collectedAccessories.filter(accessorie => accessorie.accessory_type_id === type && accessorie.id !== id);
    const extraInCart = anotherAccessories.filter(accessorie => result.extraAccessories[accessorie.id] > 0);
    if (Object.keys(extraInCart).length < 1) {
      return;
    }
    extraInCart.sort((extra1, extra2) => {
      if (+extra1.price > +extra2.price) {
        return 1;
      } else if (+extra1.price < +extra2.price) {
        return -1;
      }
      return 0;
    });
    const accessorieToChange = extraInCart[0];
    if (freeDifference <= result.extraAccessories[accessorieToChange.id]) {
      result.freeAccessories[accessorieToChange.id] = result.freeAccessories[accessorieToChange.id] + freeDifference;
      result.extraAccessories[accessorieToChange.id] = result.extraAccessories[accessorieToChange.id] - freeDifference;
      if (result.extraAccessories[accessorieToChange.id] === 0) {
        delete result.extraAccessories[accessorieToChange.id];
      }
    } else if (freeDifference > result.extraAccessories[accessorieToChange.id]) {
      result.freeAccessories[accessorieToChange.id] = result.freeAccessories[accessorieToChange.id] + result.extraAccessories[accessorieToChange.id];
      delete result.extraAccessories[accessorieToChange.id];
      this.regulateExtraAccessories(type, result, id);
    }
  }

  countFreeAccessoriesById = (id, accessoryType) => {
    if (!this.props.order || !this.props.order.freeAccessories) {
      return 0;
    }
    const keys = Object.keys(this.props.order.freeAccessories);
    return keys.reduce((res, key) => {
      const type = this.getAccessoryType(key);
      if (type === accessoryType && key !== id) {
        res = res + this.props.order.freeAccessories[key];
      }
      return res;
    }, 0);
  }

  collectChosenAccessories = (data) => {
    const keys = Object.keys(data);
    return keys.reduce((res, key, i) => {
      const type = this.getAccessoryType(key);
      res[type] = (res[type] || 0) + data[key];
      return res;
    }, {});
  }

  setInitialAccessoriesData = accessories => accessories.reduce((res, accessory) => {
    res[accessory.id] = accessory.cartQuantity || 0;
    return res;
  }, {});

  mapAccessoriesWithTypes = (accessories, types) => accessories.map(accessory => ({
    ...accessory,
    type: types.find(type => type.id === accessory.accessory_type_id),
  }));

  collectAccessoriesByTypes = accessories => accessories.reduce((res, accessorie) => {
    res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id] || 0;
    res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id] + accessorie.cartQuantity;
    return res;
  }, {});

  toggleDropdown = (id) => {
    this.setState(prevState => ({
      activeDropdown: prevState.activeDropdown === id ? null : id,
    }));
  };

  closeAllDropdowns = () => {
    this.setState({ activeDropdown: null });
  }

  clearAccessoryField = (data) => {
    const result = { ...data };
    const keys = Object.keys(data);
    keys.forEach(key => result[key] = 0);
    return result;
  }

  clearAccessories = () => {
    this.closeAllDropdowns();
    const result = { ...this.props.order };
    this.props.actions.setSeparatedAccessories({
      freeAccessories: this.clearAccessoryField(result.freeAccessories),
      extraAccessories: this.clearAccessoryField(result.extraAccessories),
      accessories: {},
      freeAccessoriesByTypes: {},
      extraAccessoriesByTypes: {},
    });
  };

  setStandartAccessories = () => {
    this.closeAllDropdowns();
    this.props.actions.setSeparatedAccessories({
      freeAccessories: this.setInitialAccessoriesData(this.props.collectedAccessories),
      accessories: this.setInitialAccessoriesData(this.props.collectedAccessories),
      extraAccessories: {},
      freeAccessoriesByTypes: this.collectAccessoriesByTypes(this.props.collectedAccessories),
      extraAccessoriesByTypes: {},
    });
  };

  fetchData() {
    return this.props.actions.getAccessoriesTypes();
  }

  render() {
    const {
      collectedAccessories, currentLanguage, order, accessoriesTypes, translate,
    } = this.props;

    if (collectedAccessories && collectedAccessories[0]) {
      this.freeAccessoriesAverage = this.collectAccessoriesByTypes(collectedAccessories);
    }
    return (
      <StyledAccessoriesModal className="accessories-modal">
        <p
          className="accessories-modal__link"
          onClick={this.clearAccessories}
        >
          {translate('IDoNotNeedKit')}
        </p>
        <ul className="accessories-modal__items-list">
          {collectedAccessories && collectedAccessories[0] && order
            ? collectedAccessories.map(accessory => (
              <AccessorieComponent
                key={accessory.id}
                order={order}
                accessory={accessory}
                currentLanguage={currentLanguage}
                toggleDropdown={this.toggleDropdown}
                activeDropdown={this.state.activeDropdown === accessory.id}
                changeAccessoryCount={this.changeAccessoryCount}
                countFreeAccessoriesById={this.countFreeAccessoriesById}
                freeAccessoriesAverage={this.freeAccessoriesAverage}
              />
            )) : null
          }
        </ul>
        <p
          className="accessories-modal__link"
          onClick={this.setStandartAccessories}
        >
          {translate('ResetToDefault')}
        </p>
      </StyledAccessoriesModal>
    );
  }
}

const StyledAccessoriesModal = styled.section`
  --text-color: #8E8E8E;
  --links-color: #E74E1A;
  --active-text-color: #FFFFFF;
  
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  width: 100%;
  
  .accessories-modal {
    
    &__link {
      padding-top: 1.5rem;
      color: var(--links-color);
      text-align: center;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__items-list {
      padding: 1.2rem 15px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  
  .is-mobile {
    display: block;
  }
  
@media all and (min-width: 360px) {
  .accessories-modal__link {
    text-align: start;
  }
}

@media all and (min-width: 480px) {
  .accessories-modal {
    &__link {
      padding-top: 2.6rem;
    }
    &__items-list {
      padding-top: 1.6rem;
    }
  }
  
  .is-mobile {
    display: none;
  }
}
`;

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  accessoriesTypes: state.accessoriesTypes ? state.accessoriesTypes : null,
  cart: state.cart ? state.cart : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AccessoriesModal));
