import * as types from '../actions/actionTypes';

const setActiveCatalogLink = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_CATALOG_LINK_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  activeCategory: null,
  shouldCatalogUpdate: true,
};

export default setActiveCatalogLink;
