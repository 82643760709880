import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const PromotionsApi = {
  getPromotions() {
    return axiosInstance.post('sales',
      {},
      { headers: headers.json });
  },
};

export default PromotionsApi;
