import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../actions/actions';
import CheckoutPage from '../components/checkout/CheckoutPage';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

class CheckoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    if (!this.props.cart.total) {
      this.props.history.push(`${this.props.languagePrefix}/`);
    }
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getDeliveryZones(),
      this.props.actions.getStores(),
      this.props.actions.getDeliveryMethods(),
      this.props.actions.getPaymentMethods(),
      this.props.actions.getCart(),
    ]);
  }

  redirectTo(link) {
    this.props.history.push(`${this.props.languagePrefix}/${link}`);
  }

  render() {
    return (
      <CheckoutPage redirectTo={this.redirectTo} />
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(withRouter(CheckoutContainer)));
