import _serialize from './helpers/serialize';
import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const CheckoutApi = {
  orderCreate(dataObj) {
    return axiosInstance.post('orders/create',
      dataObj,
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
  orderPreorder(dataObj) {
    return axiosInstance.post('orders/preorder',
      dataObj,
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },

  getPaymentPage(dataObj) {
    return axiosInstance.post('payment/liqpay',
      _serialize(dataObj),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default CheckoutApi;
