import React from 'react';
import styled from 'styled-components';
import keyByLang from '../../modules/shared/keyByLang';
import AnimatedButton from '../shared/buttons/AnimatedButton';

import { buttonRight } from '../../assets/icons/svgIcons';

const PromotionsListItem = ({
  data, currentLanguage, languagePrefix, redirectTo, translate,
}) => {
  const title = keyByLang('title', currentLanguage);
  const text = keyByLang('description', currentLanguage);
  let dateToShow = null;

  if (data.date_start && data.date_end) {
    dateToShow = data.date_start.split(' ')[0] + ' - ' + data.date_end.split(' ')[0];
  }

  return (
    <StyledPromotionsListItem
      className="news-item"
    >
      <h5 className="title">
        {data[title]}
      </h5>
      <hr className="solid news-item__line" />
      <p className="publish-date">
        {translate('DatePromotion')}: {dateToShow}
      </p>
      <img
        className="image"
        src={data.image_filename}
        alt={data.image_filename}
        decoding="async"
        loading="lazy"
      />
      <p className="news-text">{data[text]}</p>
      <hr className="dashed" />
    </StyledPromotionsListItem>
  );
};

const StyledPromotionsListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  transition: .2s;
  
  .news-item {
    &__line {
      margin: .5rem 0 .75rem;
    }
  }

  .dashed {
    width: calc(100% - 2rem);
    margin-bottom: 1.5rem;
  }

  .title {
    font-weight: 600;
    font-size: 1.2rem;
    padding-bottom: .5rem;
    width: 100%;
    text-align: center;
  }

  .publish-date {
    color: #777;
  }

  .news-text {
    padding: 1rem 0;
    margin: 0 1rem 1rem;
    height: calc(8.6rem);
    overflow-y: hidden;
    flex-grow: 1;
    color: #777;
  }

  .image {
    object-fit: contain;
    width: 100%;
    height: 18rem;
    padding: 1rem 0;
  }

  @media (min-width: 768px) {
    
  }

  @media (min-width: 1024px) {
   
  }
`;

export default PromotionsListItem;
