/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import Input from '../shared/form/Input';
import Radio from '../shared/form/Radio';
import AnimatedButton from '../shared/buttons/AnimatedButton';

import validate from './authValidation/userDataValidation';

import envelope from '../../assets/icons/form/email.svg';
import userIcon from '../../assets/icons/form/name.svg';
import birthDate from '../../assets/icons/form/birthdate.svg';
import { filterDone } from '../../assets/icons/svgIcons';
import InputDatepicker2 from '../shared/form/InputDatePicker2';

let GetUserDataStep = (props) => {
  const {
    handleSubmit, bonuses, translate, initialValues
  } = props;

  return initialValues && initialValues.id ? (
    <StyledUserDataForm className="form" onSubmit={handleSubmit}>
      <p className="form__header-text">{translate('RegistrationСompletion')}</p>
      {/*{bonuses ? (*/}
      {/*  <p className="form__bonuses">*/}
      {/*    {translate('EnterAllFieldsAndYouGetBonuses')} <b>{bonuses}</b> {translate('EnterAllFieldsAndYouGetBonuses2')}*/}
      {/*  </p>*/}
      {/*) : null}*/}
      <hr className="solid padded form__line" />
      <Field
        name="name"
        component={Input}
        type="text"
        label={translate('FirstAndLastName')}
        icon={userIcon}
      />
      <div className="form__radio-wrap">
        <Field
          component={Radio}
          type="radio"
          value="1"
          name="sex"
          label={translate('Male')}
          id="radio_sex_male"
        />
        <Field
          component={Radio}
          type="radio"
          value="0"
          name="sex"
          label={translate('Female')}
          id="radio_sex_female"
        />
      </div>
      <hr className="solid padded" />
      <Field
        name="email"
        component={Input}
        type="text"
        label={translate('EnterEmail')}
        icon={envelope}
      />
      <Field
        name="birth_date"
        component={InputDatepicker2}
        type="date"
        label={translate('EnterBirthDay')}
        icon={birthDate}
      />
      <div className="form__button-wrap">
        <AnimatedButton
          minWidth="200px"
          stepnum="3"
          isSubmit
          text={translate('ConfirmButton')}
          icon={filterDone}
          addClass="authorization-submit"
        />
      </div>
    </StyledUserDataForm>
  ) : null;
};

GetUserDataStep = reduxForm({
  // a unique name for the form
  form: 'userDataFormStep3',
  validate,
  destroyOnUnmount: true,
})(GetUserDataStep);

const StyledUserDataForm = styled.form`
  --text-color:  #FFFFFF;
  --link-color: #E74E1A;
  --secondary-text-color: #8E8E8E;
  
  .form {
    
    &__header-text {
      text-transform: none;
      font-weight: 200;
      padding: 2.4rem 0 1.4rem;
      color: var(--text-color);
      font-size: 1.4rem;
    }
    
    &__bonuses {
       color: var(--secondary-text-color);
       font-size: .84rem;
       text-align: center;
       display: none;
    }
    
    &__radio-wrap {
      padding-top: .5rem;
      display: flex;
      
      div {
        flex-basis: 50%;
        margin-bottom: 0;
        height: 1rem;
      }
    }
    
    &__button-wrap {
      padding-top: 1rem;
    }
    
    &__line {
      display: none;
    }
  }
  
  .padded {
    margin: 1.4rem 0 1rem;
  }
  
  @media all and (min-width: 768px) {
    .form {
      &__radio-wrap {
        padding-top: 0;
      }
      
      &__header-text {
        text-align: center;
      }
      
      &__bonuses {
       display: block;
      }
      
      &__line {
        display: block;
      }
    }
  }
`;

export default GetUserDataStep;
