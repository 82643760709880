/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/actions';
import HeaderTracker from '../components/shared/headerTracker/HeaderTracker';

class TrackerContainer extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if ((!prevProps.user || !prevProps.user.id) && (user && user.id)) {
      this.props.actions.getOrdersHistory();
    }
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getOrdersHistory(),
      this.props.actions.getOrderStatuses(),
    ]);
  }

  render() {
    const {
      ordersHistory, statuses, user, isMobile, translate,
    } = this.props;
    let lastActiveOrder = null;

    if (ordersHistory && ordersHistory[0] && user && user.id) {
      lastActiveOrder = [...ordersHistory].reverse().find(order => +order.status_id <= 5);
    }

    return user && user.id && lastActiveOrder && statuses[0] ? (
      <HeaderTracker
        lastActiveOrder={lastActiveOrder}
        statuses={statuses}
        isMobile={isMobile}
        translate={translate}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  ordersHistory: state.ordersHistory ? state.ordersHistory : null,
  statuses: state.statuses ? state.statuses : null,
  user: state.user ? state.user : null,
  isMobile: state.isMobile,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackerContainer);
