import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage, Translate } from 'react-localize-redux';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';

import * as actions from '../actions/actions';
import CatalogFilterModal from '../components/catalog/CatalogFilterModal';

class FilterContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.filterNames = {
      allergens: 'allergens_',
      ingredients: 'ingredients_',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  renderFilterFields = (ingredients, allergens) => Object.assign(
    {},
    this.createFilterField(ingredients, this.filterNames.ingredients),
    this.createFilterField(allergens, this.filterNames.allergens),
  );

  createFilterField = (dataArray, type) => dataArray.reduce((result, filterItem) => {
    result[`${type}${filterItem.id}`] = true;
    return result;
  }, {})

  fetchData() {
    if (!this.props.filterData && this.props.ingredients && this.props.allergens) {
      this.props.actions.setProductsFilterData(
        this.renderFilterFields(this.props.ingredients, this.props.allergens),
      );
    } else if (!this.props.ingredients || !this.props.allergens) {
      this.props.actions.setFilterData({});
    }

    return Promise.resolve();
  }

  render() {
    const {
      currentLanguage, ingredients, allergens, filterData, closeFilter, categories, translate,
    } = this.props;

    return (filterData ? (
      <CatalogFilterModal
        ingredients={ingredients}
        allergens={allergens}
        currentLanguage={currentLanguage}
        filterNames={this.filterNames}
        filterData={filterData}
        closeFilter={closeFilter}
        categories={categories}
        translate={translate}
        setProductsFilterData={this.props.actions.setProductsFilterData}
        setFilteredCategories={this.props.actions.setFilteredCategories}
      />
    ) : null);
  }
}

const mapStateToProps = state => ({
  ingredients: state.ingredients ? state.ingredients : null,
  categories: state.categories ? state.categories : null,
  allergens: state.allergens ? state.allergens : null,
  filterData: state.filterData ? state.filterData : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
