import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import * as actions from '../actions/actions';
import NewsListPage from '../components/news/NewsListPage';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import fixImagePath from '../modules/shared/fixImagePath';

class NewsListContainer extends React.PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getNews(),
      this.props.actions.getUser(),
      this.props.actions.getSettings(),
    ]);
  }

  render() {
    const {
      news, currentLanguage, languagePrefix, settings, translate,
    } = this.props;

    let bannerImagePath = null;

    if (settings && settings.syspages) {
      const pageData = settings.syspages.find(page => page.page_const === 'profile');
      bannerImagePath = fixImagePath(pageData);
    }

    return (
      <NewsListPage
        bannerImage={bannerImagePath}
        news={news}
        currentLanguage={currentLanguage}
        languagePrefix={languagePrefix}
        translate={translate}
      />
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  news: state.news ? state.news : null,
  //city: state.city ? state.city : 'Одесса - хардкод',
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  settings: state.settings ? state.settings : null,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const MemorizedNewsList = React.memo(NewsListContainer);

export default connect(mapStateToProps, mapDispatchToProps)(MemorizedNewsList);
