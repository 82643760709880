const getProductTabsDataByIds = (ids, categoriesObj) => {
  let matchProductObj = [];
  // eslint-disable-next-line no-unused-expressions
  (categoriesObj && ids) ? (
    ids.map((id) => (
      categoriesObj.map((catItem) => (
        catItem.products.map((product) => (
          (product.id === id)
            ? matchProductObj = [...matchProductObj, product]
            : null
        ))
      ))
    ))
  )
    : false;
  return matchProductObj;
};
export default getProductTabsDataByIds;
