import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import GetNumberStep from './GetNumberStep';
import GetSmsCodeStep from './GetSmsCodeStep';
import GetUserDataStep from './GetUserDataStep';
import UserName from '../checkout/UserName';

import phone from '../../assets/icons/form/phone.svg';
import { filterDone } from '../../assets/icons/svgIcons';

class AuthorizationSteps extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: undefined,
      smscode: undefined,
      name: null,
      email: null,
      birth_date: null,
      showNumberStep: true,
      isSendBtnDisabled: false,
    };

    this.phonePath = /^\+380(67|68|96|97|98|50|66|95|99|63|73|93)\d{3}\d{2}\d{2}$/;
    this.smsPath = /^[0-9]+$/;
    this.emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.errors = {
      incorrectOperator: this.props.translate('SmsPasswordRequestFailedMessage'),
      emptyPhone: this.props.translate('PhoneNumberCanNotBeEmptyMessage'),
      notFullPhone: this.props.translate('NotFullPhoneNumber'),
      smsIPLimit: 'Вы исчерпали лимит запроса смс пароля с этого IP дреса. Доступно не более 10 запросов в час',
      smsCodeShort: 'Слишком короткий смс пароль',
      smsCodeLong: 'Слишком длинный смс пароль',
      smsCodeIncorrect: 'Недопустимые символы в смс пароле',
      smsCodeEmpty: this.props.translate('CodeFromSmsCanNotBeEmptyMessage'),
      smsCodeInvalid: this.props.translate('PasswordIsIncorrectMessage'),
    };

    this.handleUserUpdate = this.handleUserUpdate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return prevState.email === null
      ? ({
        ...prevState,
        ...nextProps.user,
      })
      : null;
  }

  showNumberStep = () => {
    this.setState({
      showNumberStep: true,
    });
  }

  showSmsCodeStep = () => {
    this.setState({
      showNumberStep: false,
      isSendBtnDisabled: true,
    });
  }

  handleLogOut = () => {
    this.props.actions.logOutUser();
    this.showNumberStep();
  };

  handleGetuser = () => this.props.actions.getUser();

  handleGetNumberSubmit = (inputData, data, form) => {
    const username = inputData.username ? inputData.username : undefined;
    this.setState({
      username,
      isSendBtnDisabled: true,
    }, () => {
      if (username && username.length === 13 && this.phonePath.test(username)) {
        const valueToSend = username.slice(username.indexOf('0'));
        const uid = btoa(String(valueToSend - 22));
        this.props.actions.getSmsAuth(valueToSend, uid).then((res) => {
          if (res && res.data && !res.data.status) {
            this.props.actions.showSnackBar(res.data.message, 4000, false);
            this.setState({
              isSendBtnDisabled: false,
            });
            form.reset();
            return;
          }
          this.showSmsCodeStep();
        });
      }
    });
    if (username && username.length === 13 && !this.phonePath.test(username)) {
      this.props.actions.showSnackBar(this.errors.incorrectOperator, 4000, false);
    } else if (username && username.length === 4) {
      this.props.actions.showSnackBar(this.errors.emptyPhone, 4000, false);
    } else if (username && username.length > 4 && username.length < 13) {
      this.props.actions.showSnackBar(this.errors.notFullPhone, 4000, false);
    }
  }

  showSmsInvalid = (smsStatus, form) => {
    if (!smsStatus) {
      this.props.actions.showSnackBar(this.errors.smsCodeInvalid, 4000, false);
    } else {
      form.reset();
    }
  }

  handleIsSmsValid = (data, f, form) => {
    const smsCode = data.smscode ? data.smscode : undefined;
    if (smsCode && smsCode.length > 0 && !(smsCode.length > 6) && this.smsPath.test(smsCode)) {
      this.props.actions.getSmsIsValid(smsCode)
        .then((data) => {
          this.showSmsInvalid(data.payload.status, form);
        });
    }
    if (!smsCode) {
      this.props.actions.showSnackBar(this.errors.smsCodeEmpty, 4000, false);
    }
    if (smsCode && !this.smsPath.test(smsCode)) {
      this.props.actions.showSnackBar(this.errors.smsCodeIncorrect, 4000, false);
    }
    if (smsCode && smsCode.length > 6) {
      this.props.actions.showSnackBar(this.errors.smsCodeLong, 4000, false);
    }
  };

  handleResendSms = (name) => {
    const valueToSend = name.slice(name.indexOf('0'));
    this.props.actions.getSmsAuth(valueToSend);
  };

  handleUserUpdate(data, func, form) {
    const { translate } = this.props;
    const {
      name, sex, email, birth_date,
    } = data;
    const userDataObj = {};

    const birthDate = new Date(birth_date);

    if (name && sex && email && birthDate) {
      const dateToSend = `${birthDate.getFullYear()}-${birthDate.getMonth() + 1}-${birthDate.getDate()}`;
      userDataObj['User[name]'] = name;
      userDataObj['User[sex]'] = sex;
      userDataObj['User[email]'] = email;
      userDataObj['User[birth_date]'] = dateToSend;
    }

    this.props.actions.updateUser(userDataObj).then((res) => {
      if (res.status === 200 && res.data && res.data.status) {
        this.props.actions.showSnackBar(translate('DataChangedSuccessfully'), 2000, true);
        setTimeout(() => this.props.history.push(`${this.props.languagePrefix}/`), 1000);
      } else if (res.status === 200 && res.data && !res.data.status) {
        this.props.actions.showSnackBar(translate('EmailExist'), 4000, false);
      }
    });
  }

  render() {
    const {
      waitSms,
      smsValid,
      user,
      actions,
      isCheckoutPage,
      settings,
      languagePrefix,
      isMobile,
      translate,
    } = this.props;
    const {
      showNumberStep, username, smscode, isSendBtnDisabled,
    } = this.state;

    let bonuses = null;

    if (settings) {
      bonuses = settings.bonuses_registration;
    }

    const getNumberStepFormContent = (!user || !user.id)
    && (!waitSms || !waitSms.status || showNumberStep) ? (
      <GetNumberStep
        icon={phone}
        buttonIcon={filterDone}
        username={username}
        isDisabled={isSendBtnDisabled}
        onSubmit={this.handleGetNumberSubmit}
        showSnackBar={actions.showSnackBar}
        heading={translate('LoginRegistration')}
        isCheckoutPage={isCheckoutPage}
        translate={translate}
      />
      ) : null;

    const getSmsCodeStepContent = (!user || !user.id)
    && waitSms?.status && !showNumberStep ? (
      <GetSmsCodeStep
        icon={phone}
        buttonIcon={filterDone}
        smscode={smscode}
        waitSms={waitSms || null}
        smsValid={smsValid || null}
        onSubmit={this.handleIsSmsValid}
        showNumberStep={this.showNumberStep}
        showSnackBar={actions.showSnackBar}
        sendSmsAgain={() => { this.handleResendSms(this.state.username); }}
        isCheckoutPage={isCheckoutPage}
        isMobile={isMobile}
        translate={translate}
      />
      ) : null;

    let getUserDataStepContent = null;

    if (user && user.id && user.email && user.name
      && user.birth_date && user.sex && !isCheckoutPage) {
      getUserDataStepContent = (
        <Redirect to={`${languagePrefix}/`} />
      );
    } else if (user && user.id && (!user.email
        || !user.name || !user.birth_date || !user.sex) && !isCheckoutPage) {
      getUserDataStepContent = (
        <GetUserDataStep
          onSubmit={this.handleUserUpdate}
          authState={user}
          initialValues={user}
          showSnackBar={actions.showSnackBar}
          enableReinitialize
          bonuses={bonuses}
          translate={translate}
        />
      );
    } else if (isCheckoutPage && user && user.id) {
      getUserDataStepContent = (
        <UserName user={user} icon={filterDone} logOut={this.handleLogOut} />
      );
    }

    return (
      <StyledAuthSteps>
        {getNumberStepFormContent}
        {getSmsCodeStepContent}
        {getUserDataStepContent}
      </StyledAuthSteps>
    );
  }
}

const StyledAuthSteps = styled.div`
  max-height: 100vh;
  .authorization-submit {
    position: fixed;
    bottom: 0;
    z-index: 12;
    left: 0;
    padding: 0 15px;
    margin-bottom: 0;

    .button {
      box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
      width: 100%;
      border-radius: 4px 4px 0 0;
    }
  }
  
  @media all and (min-width: 768px) { 
    .authorization-submit {
      position: static;
      margin-bottom: 1.5rem;
      
      .button {
        width: auto;
        border-radius: 4px;
        box-shadow: 0;
      }
    }
  }
`;

export default withRouter(AuthorizationSteps);
