import * as types from '../actions/actionTypes';

const initialState = null;

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTINGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default settingsReducer;
