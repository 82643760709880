/* eslint-disable react/no-danger,no-class-assign */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import * as actions from '../../../../actions/actions';
import Input from '../../../shared/form/Input';
import TextArea from '../../../shared/form/TextArea';
import Rating from '../../../shared/form/Rating';
import MainBtn from '../../../shared/MainBtn';


class ProductReviewsForm extends React.Component {
  static validateForm(values) {
    const errors = {};
    if (!values.starRating) {
      errors.starRating = <Translate id="PleaseSelectProductRating" />;
    }
    return errors;
  }

  static onSubmit(data, _, props) {
    const {
      id, actions: actionsFromProps, translate, reset,
    } = props;
    const formData = new FormData();
    formData.append('ProductReviews[product_id]', id);
    formData.append('ProductReviews[rate]', data.starRating);
    if (data.message) {
      formData.append('ProductReviews[message]', data.message);
    }
    if (data.userName) {
      formData.append('ProductReviews[name]', data.userName);
    }
    actionsFromProps.sendProductReview(formData).then((res) => {
      if (res && res.data.status) {
        actionsFromProps.showSnackBar(translate('ReviewSentSuccessfully'), 3000, true);
        reset();
      } else {
        actionsFromProps.showSnackBar(res.data.message || translate('ReviewSentFailed'), 4000, false);
      }
    });
  }

  render() {
    const {
      translate, handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          type="text"
          component={Input}
          placeholder={translate('EnterYourName')}
          name="userName"
        />
        <Field
          component={TextArea}
          placeholder={translate('EnterYourReview')}
          name="message"
        />
        <StyledRatingBlock>
          <Field name="starRating" component={Rating} label={translate('SelectProductRating')} size={26} />
        </StyledRatingBlock>
        <MainBtn type="submit" text={translate('SendCodeButton')} />
      </form>
    );
  }
}

const StyledRatingBlock = styled.span`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

ProductReviewsForm = reduxForm({
  form: 'ProductReviewsForm',
  validate: ProductReviewsForm.validateForm,
  onSubmit: ProductReviewsForm.onSubmit,
  destroyOnUnmount: true,
})(ProductReviewsForm);

ProductReviewsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductReviewsForm);

export default ProductReviewsForm;
