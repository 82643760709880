/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';

import * as storage from '../../../utils/storage';
import GPlacesDeliveryForm from '../GPlacesDeliveryForm';
import DeliveryForm from '../../delivery/DeliveryForm';
import Hr from '../../shared/Hr';
import CheckoutDeliverySwitcher from './CheckoutDeliverySwitcher';
import AnimatedButton from '../../shared/buttons/AnimatedButton';
import DeliveryCheckbox from './DeliveryCheckbox';
import CheckoutDeferredOrder from '../deferredOrder/CheckoutDeferredOrder';

import moto from '../../../assets/icons/delivery/moto.svg';
import timer from '../../../assets/icons/delivery/timer.svg';
import store from '../../../assets/icons/delivery/store.svg';
import { filterDone } from '../../../assets/icons/svgIcons';
import keyByLang from '../../../modules/shared/keyByLang';
import NewProfileAddressForm from '../../profile/NewProfileAddressForm';


class CheckoutDelivery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      takeOutStore: JSON.parse(storage.session.getItem('takeOutStore')),
      deliveryAddress: JSON.parse(storage.session.getItem('deliveryAddress')),
      isTakeOutTabActive: this.setActiveDeliveryMethod(this.props.deliveryMethods),
      isdeferredModalVisible: false,
      activeCheckboxes: {
        takeOut: true,
        delivery: true,
      },
    };

    this.changeDeferredOrderTime = this.changeDeferredOrderTime.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.getTimeToReady = this.getTimeToReady.bind(this);
    this.formatOrderDate = this.formatOrderDate.bind(this);
  }

  /* componentDidMount() {
    this.props.actions.getCart();
  } */

  static formatTimeAverage(data) {
    return +data < 10 ? `0${data}` : data;
  }

  setActiveDeliveryMethod = (deliveryMethods) => {
    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));
    return lastDeliveryType === 'takeOutStore';
    /* const savedOrder = JSON.parse(storage.session.getItem('orderData'));
    if (savedOrder && savedOrder.Orders && savedOrder.Orders.delivery_method_id) {
      this.deliveryMethodId = savedOrder.Orders.delivery_method_id;
      return +savedOrder.Orders.delivery_method_id === 1;
    }
    const savedDelivery = JSON.parse(storage.local.getItem('lastDeliveryType'));
    if (savedDelivery) {
      return savedDelivery === 'takeOutStore';
    }
    if (deliveryMethods && deliveryMethods[0]) {
      const defaultMethod = deliveryMethods.find(method => +method.is_default === 1);
      this.deliveryMethodId = defaultMethod.id;
      return +defaultMethod.id === 1;
    }
    return true; */
  }

  setDeliveryTabActiveHandler = () => {
    this.setState({
      isTakeOutTabActive: false,
    });
  }

  setTakeOutTabActiveHandler = () => {
    this.setState({
      isTakeOutTabActive: true,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  switchDeliveryType = () => {
    this.setState((prevState) => ({
      isTakeOutTabActive: !prevState.isTakeOutTabActive,
    }));
  }

  updateAddress = () => {
    this.setState({
      takeOutStore: JSON.parse(storage.session.getItem('takeOutStore')),
      deliveryAddress: JSON.parse(storage.session.getItem('deliveryAddress')),
    });
  }

  getTimeToReady(minutes) {
    let hours = 0;
    if (minutes >= 60) {
      const difference = Math.floor(minutes / 60);
      hours += difference;
      minutes -= difference * 60;
    }
    minutes = CheckoutDelivery.formatTimeAverage(minutes);
    return hours
      ? `${hours} ${this.props.translate('Hour')} ${minutes} ${this.props.translate('Minutes')}`
      : `${minutes} ${this.props.translate('Minutes')}`;
  }

  changeCheckbox = (type) => {
    this.setState((prevState) => ({
      activeCheckboxes: {
        ...prevState.activeCheckboxes,
        [type]: !prevState.activeCheckboxes[type],
      },
    }));
  }

  setDeliveryData = (isTakeOutActive, takeoutStore, address, form, checkboxes) => {
    if (isTakeOutActive) {
      return { type: 1, address: takeoutStore, rememberRestaurant: checkboxes.takeOut };
    }
    return {
      type: 2, address, checkboxes, rememberAddress: checkboxes.delivery,
    };
  }

  changeDeferredOrderTime() {
    this.setState((prevState) => ({
      isdeferredModalVisible: !prevState.isdeferredModalVisible,
    }));
  }

  handleModalClose() {
    this.changeDeferredOrderTime();
  }

  formatOrderDate(date) {
    const { translate } = this.props;
    const dateData = new Date(date.split(' ')[0]);
    const today = new Date();
    if (dateData.getDate() === today.getDate()
      && dateData.getFullYear() === today.getFullYear()
      && dateData.getMonth() === today.getMonth()) {
      return `${translate('Today')}, ${date.slice(date.lastIndexOf(' '), date.lastIndexOf(':'))}`;
    }
    return `${date.slice(0, date.lastIndexOf(':'))}`;
  }

  render() {
    const {
      currentLanguage,
      stores,
      showSnackBar,
      submitDelivery,
      form,
      user,
      deliveryMethods,
      isDeliveryChosen,
      editDelivery,
      order,
      timeSlots,
      storeRef,
      buttonPrice,
      translate,
    } = this.props;
    const {
      takeOutStore, deliveryAddress, isTakeOutTabActive, activeCheckboxes, isdeferredModalVisible,
    } = this.state;
    const title = keyByLang('title', currentLanguage);

    const takeOutStores = stores;

    const deliveryData = this.setDeliveryData(
      isTakeOutTabActive,
      takeOutStore,
      deliveryAddress,
      form,
      activeCheckboxes,
    );

    const savedOrderTime = JSON.parse(storage.session.getItem('orderTime'));

    let timeToReady;

    const deliveryMethod = isTakeOutTabActive ? 1 : 2;

    if (timeSlots && timeSlots[0] && +deliveryMethod === 1 && !savedOrderTime) {
      const timeAdd = timeSlots[0].order_total_time_pickup;
      timeToReady = `${this.getTimeToReady(timeAdd)}`;
    }
    if (timeSlots && timeSlots[0] && +deliveryMethod === 2 && !savedOrderTime) {
      const timeAdd = timeSlots[0].order_total_time_delivery;
      timeToReady = `${this.getTimeToReady(timeAdd)}`;
    }
    if (savedOrderTime && timeSlots && timeSlots[0]) {
      timeToReady = this.formatOrderDate(savedOrderTime.time, timeSlots);
    }

    let orderData = JSON.parse(storage.session.getItem('orderData'));

    if (order && order.Orders && orderData.Orders.delivery_method_id) {
      orderData = order;
    } else if (orderData && (!orderData.Orders || !orderData.Orders.delivery_method_id)) {
      orderData = {
        Orders: {
          delivery_method_id: deliveryMethod,
        },
      };
    }

    let address = '';
    if (orderData) {
      if (orderData.Orders.delivery_method_id === '1') {
        address = orderData.Orders.address;
      } else if (orderData.Orders.delivery_method_id === '2') {
        const parsedAddress = JSON.parse(orderData.Orders.address);
        address = `${parsedAddress?.street}, ${parsedAddress?.house_number}`;
      }
    }

    const buttonData = {
      text: translate('ConfirmButton'),
      callBack: () => submitDelivery(deliveryData, buttonPrice),
      addClass: 'checkout-delivery__submit',
    };

    const checkoutDeferredOrderContent = isdeferredModalVisible ? (
      <CheckoutDeferredOrder
        handleModalClose={this.handleModalClose}
        orderData={orderData}
        showSnackBar={showSnackBar}
      />
    ) : null;

    const activeTabContent = (
      <>
        <h2 className="checkout-delivery__type">{isTakeOutTabActive ? translate('MyShop') : translate('MyAddress')}</h2>
        {isTakeOutTabActive ? (
          <DeliveryForm
            placeholder={translate('EnterTheStoreAddress')}
            handleSubmit={this.handleSubmit}
            takeOutStores={takeOutStores}
            currentLanguage={currentLanguage}
            stores={stores}
            showSnackBar={showSnackBar}
            takeOutStore={takeOutStore}
            updateAddress={this.updateAddress}
            storeRef={storeRef}
            translate={translate}
            isCheckout
          />
        ) : (
          // <GPlacesDeliveryForm
          //   deliveryAddress={deliveryAddress}
          //   updateAddress={this.updateAddress}
          //   handleSubmit={this.handleSubmit}
          //   translate={translate}
          //   isCheckout
          // />
          <NewProfileAddressForm
            translate={translate}
            updateAddress={this.updateAddress}
            setDeliveryAddress={this.props.setDeliveryAddress}
            isCheckout
          />
        )}
        <DeliveryCheckbox
          name={isTakeOutTabActive
            ? translate('SaveStoreAsDefaultStore')
            : translate('SaveAddressAsDefaultAddress')}
          handleChange={this.changeCheckbox}
          id={isTakeOutTabActive ? 'takeOut' : 'delivery'}
          activeCheckboxes={activeCheckboxes}
        />
        <Hr />
        <div className="checkout-delivery__switcher-block">
          <div className="checkout-delivery__switcher-wrap">
            <CheckoutDeliverySwitcher
              icon={isTakeOutTabActive ? store : moto}
              figcaption={isTakeOutTabActive ? translate('TheOrderWillBeReady') : translate('ExpectedDelivery')}
              linkName={isTakeOutTabActive ? translate('ChangeToDelivery') : translate('ChangeToPickup')}
              handleClick={this.switchDeliveryType}
            />
          </div>
          <div className="checkout-delivery__switcher-wrap">
            <CheckoutDeliverySwitcher
              icon={timer}
              figcaption={timeToReady}
              linkName={translate('ChangeTime')}
              handleClick={this.changeDeferredOrderTime}
            />
          </div>
        </div>
        <div className="checkout-delivery__button-wrap">
          <AnimatedButton
            minWidth="130px"
            icon={filterDone}
            text={buttonData.text}
            handleClick={buttonData.callBack}
            addClass={buttonData.addClass}
          />
        </div>
        {checkoutDeferredOrderContent}
      </>
    );

    return (
      <>
        {deliveryMethods && deliveryMethods[0] && ((!user || !user.id) || !isDeliveryChosen) ? (
          <div className={!user || !user.id
            ? 'checkout__step-heading-wrap'
            : 'checkout__step-heading-wrap checkout__step-heading-wrap--active'}
          >
            {filterDone}
            <h2 className="checkout__step-heading">
              {isTakeOutTabActive ? deliveryMethods[0][title] : deliveryMethods[1][title]}
            </h2>
          </div>
        ) : null}
        {user && user.id && stores && !isDeliveryChosen ? (
          <StyledDelivery className="checkout-delivery">
            {activeTabContent}
          </StyledDelivery>
        ) : null}
        {user && user.id && isDeliveryChosen && deliveryMethods[0] ? (
          <StyledChosenBlock className="chosen-block">
            <div className="chosen-block__heading-wrap">
              {filterDone}
              <h2 className="chosen-block__heading">
                {isTakeOutTabActive ? deliveryMethods[0][title] : deliveryMethods[1][title]}
              </h2>
              <p className="chosen-block__link" onClick={editDelivery}>{translate('Edit')}</p>
            </div>
            <div className="chosen-block__data-wrap">
              <p className="chosen-block__address">
                {orderData && orderData.Orders
                  ? address
                  : null}
              </p>
              <p className="chosen-block__time">{timeToReady}</p>
            </div>
          </StyledChosenBlock>
        ) : null}
      </>
    );
  }
}

export default React.memo(CheckoutDelivery);

const StyledDelivery = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --active-background: #313131;

  width: 100%;
  color: var(--text-color);

  .checkout-delivery {
  
    &__chosen-block {
      display: flex;
    }
    
    &__chosen-heading {
      color: var(--text-color);
      font-weight: 100;
      font-size: 1.4rem;
      margin: 2.4rem 0 1.4rem;
      text-align: center;
    }
  
    &__type {
      color: var(--text-color);
      text-transform: none;
      font-weight: 100;
      font-size: .9rem;
      padding: .6rem 0 .75rem;
    }
    
    &__switcher-block {
      display: flex;
      justify-content: space-between;
    }
    
    &__switcher-wrap {
      flex-basis: calc(50% - 10px);
    }
    
    &__button-wrap {
      position: fixed;
      bottom: 10px;
      width: 100%;
      left: 0;
      padding: 0 10px;
    }
    
    &__submit .button {
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
    }
  }
  
  @media all and (min-width: 768px) {
  .checkout-delivery {
  
    &__button-wrap {
      padding: 2.5rem;
      position: static;
    }
  
    &__type {
      font-size: 1rem;
      padding: 1.25rem 0 .75rem;
    }
  }
}
`;

const StyledChosenBlock = styled.div`
  --text-color: #8E8E8E;
  --link-color: #E74E1A;
  
  color: var(--text-color);
  
  .chosen-block {
    
    &__heading-wrap {
      display: flex;
      align-items: center;
      
      & path {
        stroke: var(--text-color);
      }
    }
    
    &__heading {
      text-transform: none;
      font-weight: 200;
      padding: 0 0 0 .7rem;
      color: var(--text-color);
      font-size: 1.4rem;
      flex-grow: 1;
    }
    
    &__link {
      color: var(--link-color);
      font-size: .9rem;
      
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
    &__data-wrap {
      display: flex;
      justify-content: space-between;
      padding-top: .9rem;
    }
    
    &__address {
      flex-basis: 75%;
      padding-right: 1rem;
      font-size: .9rem;
    }
  }
  
  @media all and (min-width: 768px) {
    .chosen-block {
      /*&__link {
        font-size: 1rem;
      }*/
      
      &__address {
        font-size: 1rem;
      }
      
      &__data-wrap {
        padding-top: 1rem;
      }
      
      &__time {
        text-align: right;
      }
    }
  }
`;
