const getProductsByIds = ( ids = [], categoriesObj = [] ) => {
    let matchProductsArr = [];
  // eslint-disable-next-line no-unused-expressions
    (categoriesObj.length && ids.length)
    ?   categoriesObj.map( catItem =>
            catItem.products.map( product =>
                ids.map( id =>
                    ( parseInt(id) === parseInt(product.id) ) ? matchProductsArr.push(product) : null
                )
            )
        )
    : false;
  return matchProductsArr;
}
export default getProductsByIds;
