/* eslint-disable consistent-return,no-prototype-builtins */
/* global google */
import React from 'react';
import { getTranslate } from 'react-localize-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import connect from 'react-redux/lib/connect/connect';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';

import * as storage from '../../utils/storage';
import * as actions from '../../actions/actions';

import delivery from '../../assets/icons/delivery/delivery.svg';
import moto from '../../assets/icons/form/deliveryForm.svg';

class LocationSearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.deliveryAddress ? this.props.deliveryAddress.address : '',
      isAlreadyChosen: true,
      suggestions: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.input.value && prevState.address === '' && prevState.isAlreadyChosen === false) {
      return {
        ...prevState,
        address: nextProps.input.value.address,
      };
    }
    return false;
  }

  setAddressToState = (address) => {
    this.setState({
      address,
      isAlreadyChosen: true,
    });
  }

  handleChange = (address) => {
    this.setAddressToState(address);
    return this.props.input.value;
  }

  handleSelect = (address, placeId) => {
    this.props.input.onChange({ address, placeId });
    this.setAddressToState(address);
    if (!placeId) {
      return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
    }
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.isChosenLocationInDeliveryZones(latLng))
      .catch(error => console.error('Error', error));
  };

  convertDeliveryZonesFormat = (arr) => {
    const filteredArr = arr.split(';').filter(item => item);
    return filteredArr.map((item) => {
      const itemElem = item && item.split(',');
      return { lat: parseFloat(itemElem[0]), lng: parseFloat(itemElem[1]) };
    });
  }

  isChosenLocationInDeliveryZones = (loc) => {
    const { deliveryZones, isProfile } = this.props;
    const currentPosition = new google.maps.LatLng(loc.lat, loc.lng);
    const result = deliveryZones.find((item) => {
      const polygonFormattedArr = this.convertDeliveryZonesFormat(item.coordinates);
      const oneOfDeliveryZone = new google.maps.Polygon({ paths: polygonFormattedArr });
      return !!google.maps.geometry.poly.containsLocation(currentPosition, oneOfDeliveryZone);
    });
    if (result && !isProfile) {
      storage.local.setItem('lastDeliveryAddress', JSON.stringify({
        address: this.props.input.value.address, location: loc,
      }));
      storage.session.setItem('deliveryAddress', JSON.stringify({
        address: this.props.input.value.address, location: loc,
      }));
      storage.local.setItem('lastDeliveryType', JSON.stringify('deliveryAddress'));
      this.props.updateAddress();
    } else if (result && isProfile) {
      this.props.updateAddress({
        address: this.props.input.value.address, location: loc,
      });
    } else {
      this.props.actions.showSnackBar(this.props.translate('NoAddressDelivery'), 4000, false);
    }
  }

  filterSuggestions = suggestions => suggestions

  renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <StyledAutocompleteBlock className="autocomplete-root">
      {!this.props.isCheckout ? (
        <input
          {...getInputProps({ className: 'my-inputtrtrt', autoFocus: true })}
          className="search-field__input"
          placeholder={this.props.placeholder}
        />
      ) : (
        <div className="styled-input">
          <input
            {...getInputProps()}
            className={getInputProps().value
              ? 'styled-input__field styled-input__field--not-empty'
              : 'styled-input__field'
              }
            type="text"
            placeholder={this.props.placeholder}
            id="GPlacesDelivery"
          />
          <div className="styled-input__image-wrap">
            <img
              className="styled-input__image"
              src={moto}
              alt="icon"
              decoding="async"
              loading="lazy"
            />
          </div>
          <label className="styled-input__label" htmlFor="GPlacesDelivery">{this.props.translate('Address')}</label>
          <div className="styled-input__line" />
        </div>
      )}
      {getInputProps().value.length > 2 && suggestions && suggestions[0] ? (
        <React.Fragment>
          {!this.props.isCheckout ? (
            <div className="address__logo-line">
              <img className="address__logo" src={delivery} alt="logo" decoding="async" loading="lazy" />
              <p className="address__name">{this.props.translate('CourierAddressDelivery')}: </p>
            </div>
          ) : null}
          <div className="address__list">
            {suggestions.map(suggestion => (
              <p className="address__item" {...getSuggestionItemProps(suggestion)}>
                {suggestion.description}
              </p>
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </StyledAutocompleteBlock>
  )


  render() {
    const { settings } = this.props;
    const searchOptions = {
      location: new google.maps.LatLng(46.465024, 30.706237),
      radius: settings ? +settings.google_places_radius : 2000,
      componentRestrictions: {
        country: 'UA',
      },
      strictBounds: true,
    };

    let inputValue = this.props.input.value;

    if (this.props.input.value.hasOwnProperty('address')) {
      inputValue = this.props.input.value.address;
    }

    return (
      <PlacesAutocomplete
        value={inputValue}
        onChange={this.props.input.onChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {this.renderFunc}
      </PlacesAutocomplete>
    );
  }
}

const StyledAutocompleteBlock = styled.div`
  --background-color: #1C1C1C;
  --text-color: #FFFFFF;
  --active-background-color: #313131;
  --secondary-text-color: #616161;
  
  width: 100%;
  position: relative;
  
  .search-field {
  
    &__input {
      flex-grow: 1;
      height: 42px;
      border-radius: 4px;
      border: none;
      padding: 0 10px;
      width: 100%;
      padding-right: 110px;
    }
  }
  
  .styled-input {
    position: relative;
    height: 60px;
    box-sizing: border-box;
    background-color: #1C1C1C;
    border-radius: 4px;
    border: 1px solid ${props => (props.error ? 'red' : '#313131')};
    
    &__field {
      height: 100%;
      background: inherit;
      border: none;
      width: 100%;
      padding-left: 77px;
      color: #8e8e8e;
      font-size: 15px;
      padding-top: 17px;
      padding-right: 15px;
      outline-color: ${props => (props.error ? 'red' : '')};
      
      &:focus ~ .styled-input__label,
      &--not-empty ~ .styled-input__label {
        transform: scale(0.85) translateY(-150%);
        color: ${props => (props.error ? 'red' : '#616161')};
      }
    }
    
    &__label {
      position: absolute;
      top: 50%;
      left: 77px;
      font-size: 15px;
      color: #616161;
      transform: translateY(-50%);
      transition: all 0.15s ease-in-out;
      transform-origin: left center;
      pointer-events: none;
    }
    
    &__line {
      position: absolute;
      top: 50%;
      left: 60px;
      height: 75%;
      width: 2px;
      transform: translateY(-50%);
      background-image: linear-gradient(to top,transparent 66.67%, #313131 33.33%);
      background-size: 100% 8px;
    }
    
    &__image-wrap {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translate(-50%,-50%);
    }
    
    &__image {
      opacity: .5;
    }
  }

  .delivery-type-input {
    height: 42px;
    border-radius: 4px 0 0 4px;
    border: none;
    padding: 0 10px;
    width: 100%;
  }
  
  .address {
    &__logo-line {
      display: flex;
      padding: 6px 20px;
      align-items: center;
    }
    
    &__logo {
      opacity: .5;
      margin-right: 10px;
      max-height: 1.8rem;
    }
    
    &__name {
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 700;
      color: var(--secondary-text-color);
    }
    
    &__list {
      position: absolute;
      z-index: 1;
      padding: .4rem 1rem;
      background-color: var(--background-color);
      border-radius: 4px;
      width: 100%;
    }
    
    &__item {
      color: #fff;
      padding: .5rem;
      background-color: var(--background-color);
      border-bottom: 1px solid var(--active-background-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background-color);
      }
    }
  }
  
  @media all and (min-width: 768px) {
  .address {
    &__item {
      font-size: 1rem;
      padding: .5rem 1rem;
    }
  }
}
`;

const mapStateToProps = state => ({
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  settings: state.settings ? state.settings : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);
