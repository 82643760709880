import React from 'react';
import styled from 'styled-components';

const Hr = ({ dotted }) => <StyledHr dotted={dotted} />;

const StyledHr = styled.hr`
    background:  ${props => (props.dotted ? 'transparent' : 'linear-gradient(90deg, rgba(0,0,0,0) 0%, #5F5F5F 50%, rgba(0,0,0,0) 100%)')};
    height: ${props => (props.dotted ? '0' : '1px')};
    border: none;
    border-bottom: ${props => (props.dotted ? '2px dotted #313131' : 'none')};
    padding: 0;
    margin: 10px 0;
    
    @media all and (min-width: 768px) { 
      margin: 20px 0;
    }
`;

export default Hr;
