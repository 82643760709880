/* eslint-disable no-param-reassign,operator-assignment */
import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import * as actions from '../actions/actions';
import * as storage from '../utils/storage';

import ModalWrapper from '../components/shared/ModalWrapper';
import CartContentModal from '../components/Cart/CartContentModal';
import CartContentSidebar from '../components/Cart/CartContentSidebar';
import CartCrossSaleModal from '../components/Cart/crossSale/CartCrossSaleModal';
import DeliveryOrTakeoutContainer from './DeliveryOrTakeoutContainer';

import isProductInUpSaleCat from '../modules/shared/isProductInUpSaleCat';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import getProductById from '../modules/shared/getProductById';
import getCrossSaleCategories from '../modules/shared/getCrossSaleCategories';
import AccessoriesModal from '../components/Cart/AccessoriesModal';

class CartContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      takeOutStore: JSON.parse(storage.session.getItem('takeOutStore')),
      deliveryAddress: JSON.parse(storage.session.getItem('deliveryAddress')),
      cartSubmitInProgress: this.props.cartToCheckoutSteps.cartSubmitInProgress,
      chosenAddItemPaid: [],
      isAccessoriesChanging: false,
    };

    this.cartData = null;

    this.shouldAccessoriesUpdate = false;
    this.modalCartClose = this.modalCartClose.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  setAccessories = () => {
    if (!this.props.order) {
      return;
    }
    const accessoriesToSave = {
      accessories: this.props.order.freeAccessoriesByTypes,
      extra_accessories: this.props.order.extraAccessories,
      free_accessories: this.props.order.freeAccessories,
    };
    storage.session.setItem('orderAccessories', JSON.stringify(accessoriesToSave));
    this.setState({
      isAccessoriesChanging: false,
    });
  }

  openAccessoriesModal = () => {
    this.setState({ isAccessoriesChanging: true });
    setTimeout(() => {
      const cartWrap = document.querySelector('.modal-wrapper');
      cartWrap.scrollTop = 0;
    }, 0);
  }

  qtyHandler = (cartItemId, type) => {
    switch (type) {
      case 'INC': {
        this.props.actions.cartInc(cartItemId.id);
        break;
      }
      case 'DEC': {
        this.props.actions.cartDec(cartItemId.id);
        break;
      }
      default:
        return false;
    }
  };

  addToCart = (products) => {
    products.forEach((productID) => {
      this.props.actions.cartAdd(productID);
    });
  }

  updateAddress = () => {
    this.setState({
      takeOutStore: JSON.parse(storage.session.getItem('takeOutStore')),
      deliveryAddress: JSON.parse(storage.session.getItem('deliveryAddress')),
    });
  }

  getProductsCount = products => products.reduce((prev, prod) => prev += prod.quantity || 1, 0);

  getPiecesCount = products => products.reduce((prev, prod) => prev += prod.quantity
    * prod.product.portion_quantity || 1, 0);

  isProductAllowed = (arr, type) => {
    let isAllowed = true;
    if (Array.isArray(arr) && arr.length > 0) {
      isAllowed = arr.every(item => this.state.currentFilterData[`${type}${item}`]);
    }
    return isAllowed;
  }

  mapCartData = (cart, categories) => ({
    ...cart,
    content: cart.content.map(cartItem => ({
      ...cartItem,
      product: getProductById(cartItem.product_id, categories),
      addons: cartItem.addons ? cartItem.addons.map(id => getProductById(id, categories)) : null,
    })),
  });

  collectAccessories = (cartContent, accessories) => accessories.map(acc => ({
    ...acc,
    cartQuantity: cartContent.reduce((res, prod) => res += prod.quantity
        * (prod.product && prod.product.id && prod.product.accessories[acc.id]) || 0, 0),
  }))

  removeCartItemHandler = (cartItemId) => {
    this.props.actions.cartRemove(cartItemId);
  };

  getUpSaleProducts = (productsList, categories) => productsList
    .filter(({ product }) => isProductInUpSaleCat(product.id, categories));

  getCrossSaleProducts = (productsList, categories) => getCrossSaleCategories(categories)
    .map(category => ({
      ...category,
      productsInCart: productsList.filter(cartProd => category.id
        === cartProd.product.product_category_id),
    }))

  checkCrossSale = crossSaleCategories => crossSaleCategories
    .filter(categoty => categoty.productsInCart && !categoty.productsInCart[0])

  cartSubmitInProgressHandler = (bool) => {
    const { cartToCheckoutSteps } = this.props;
    this.props.actions.setCartToCheckoutSteps({
      ...cartToCheckoutSteps,
      cartSubmitInProgress: bool,
    });
  };

  nextStep = () => {
    const cartWrap = document.querySelector('.modal-wrapper');
    this.setState({
      cartSubmitInProgress: true,
    });
    cartWrap.scrollTop = 0;
  };

  redirectToCart = () => {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.languagePrefix}/cart`);
    this.cartSubmitInProgressHandler(true);
  }

  redirectToCategory = (path) => {
    this.props.history.push(`${this.props.languagePrefix}/${path}`);
  }

  cartSubmitHandle = () => {
    this.props.actions.setAccessoriesUpdate(false);
    const orderAccessories = {
      accessories: this.props.order.freeAccessoriesByTypes,
      free_accessories: this.props.order.freeAccessories,
      extra_accessories: this.props.order.extraAccessories,
    };
    storage.session.setItem('orderAccessories', JSON.stringify(orderAccessories));
    this.props.history.push(`${this.props.languagePrefix}/checkout`);
  };

  onChangeHandleClickOnPaidItem = (productId) => {
    this.setState(prevState => ({
      chosenAddItemPaid: ((!prevState.chosenAddItemPaid.includes(productId))
        ? ([...prevState.chosenAddItemPaid, productId])
        : (prevState.chosenAddItemPaid.filter(val => (val !== productId)))),
    }));
  }

  setStandartAccessories = (collectedAccessories) => {
    const standartAccessories = {
      accessories: this.collectAccessoriesByTypes(collectedAccessories),
      free_accessories: this.setInitialAccessoriesData(collectedAccessories),
      extra_accessories: {},
    };
    storage.session.setItem('orderAccessories', JSON.stringify(standartAccessories));
  };

  setInitialAccessoriesData = accessories => accessories.reduce((res, accessory) => {
    res[accessory.id] = accessory.cartQuantity || 0;
    return res;
  }, {});

  collectAccessoriesByTypes = accessories => accessories.reduce((res, accessorie) => {
    res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id] || 0;
    res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id] + accessorie.cartQuantity;
    return res;
  }, {});

  fetchData() {
    return this.props.actions.getAccessories();
  }

  modalCartClose() {
    if (!this.state.cartSubmitInProgress && this.state.isAccessoriesChanging) {
      this.setAccessories();
      return;
    }
    if (!this.state.cartSubmitInProgress
      || (!this.state.takeOutStore && !this.state.deliveryAddress)) {
      this.setAccessories();
      this.props.history.goBack();
    }
    if (this.state.cartSubmitInProgress
      && (this.state.takeOutStore || this.state.deliveryAddress) && this.props.order
      && this.props.order.freeAccessoriesByTypes && this.props.order.freeAccessories
      && this.props.order.extraAccessories) {
      this.cartSubmitHandle();
      this.props.history.push(`${this.props.languagePrefix}/checkout`);
      return;
    }
    if (this.state.cartSubmitInProgress && this.state.isAccessoriesChanging
      && (this.state.takeOutStore || this.state.deliveryAddress)
      && (!this.props.order || !this.props.order.freeAccessoriesByTypes
        || !this.props.order.freeAccessories || !this.props.order.extraAccessories)) {
      this.props.actions.setAccessoriesUpdate(true);
      this.setStandartAccessories(
        this.collectAccessories(this.cartData.content, this.props.accessories),
      );
      this.props.history.push(`${this.props.languagePrefix}/checkout`);
    }
    if (this.state.cartSubmitInProgress && !this.state.isAccessoriesChanging
      && (this.state.takeOutStore || this.state.deliveryAddress)
      && (!this.props.order || !this.props.order.freeAccessoriesByTypes
        || !this.props.order.freeAccessories || !this.props.order.extraAccessories)) {
      const collected = this.collectAccessories(this.cartData.content, this.props.accessories);
      this.props.actions.setAccessoriesUpdate(true);
      this.setStandartAccessories(collected);
      this.props.history.push(`${this.props.languagePrefix}/checkout`);
    }
  }

  render() {
    const {
      languagePrefix,
      cart,
      categories,
      accessories,
      isSidebarMode,
      currentLanguage,
      order,
      isMobile,
      translate,
    } = this.props;

    const { isAccessoriesChanging } = this.state;

    let cartData = null;
    let collectedAccessories = null;
    let upSaleProducts = null;
    let crossSaleCategories = null;
    let productsCount = 0;
    let piecesCount = 0;
    let currentCartContent = null;
    let emptyCrossale = [];

    const lastDeliveryType = JSON.parse(storage.local.getItem('lastDeliveryType'));

    if (cart && cart.content && cart.content.length && categories) {
      cartData = this.mapCartData(cart, categories, accessories);
      this.cartData = cartData;
      productsCount = this.getProductsCount(cartData.content);
      piecesCount = this.getPiecesCount(cartData.content);
    }

    if (cartData && accessories && cartData.content[0].product.accessories
      && accessories[0]) {
      collectedAccessories = this.collectAccessories(cartData.content, accessories)
        .sort((acc1, acc2) => +acc1.position_index - +acc2.position_index);
    }

    if (cartData && cartData.content && categories) {
      upSaleProducts = this.getUpSaleProducts(cartData.content, categories);
      crossSaleCategories = this.getCrossSaleProducts(cartData.content, categories);
      emptyCrossale = crossSaleCategories && crossSaleCategories[0]
        ? this.checkCrossSale(crossSaleCategories) : [];
    }

    let isUpSaleChosen = false;

    isUpSaleChosen = cart && cart.content
      && cart.content.some(cartItem => isProductInUpSaleCat(cartItem.product_id, categories));

    if (this.state.cartSubmitInProgress && (
      (lastDeliveryType === 'takeOutStore' && this.state.takeOutStore)
      || (lastDeliveryType === 'deliveryAddress' && this.state.deliveryAddress)
    )) {
      currentCartContent = (
        <CartCrossSaleModal
          crossSaleCategories={emptyCrossale}
          collectedAccessories={collectedAccessories}
          addToCart={this.addToCart}
          currentLanguage={currentLanguage}
          cartSubmit={this.cartSubmitHandle}
          cartSubmitInProgressHandler={this.cartSubmitInProgressHandler}
          onChangeHandleClickOnPaidItem={this.onChangeHandleClickOnPaidItem}
          currentState={this.state}
          translate={translate}
          order={order}
        />
      );
    } else if (this.state.cartSubmitInProgress
      && (((!this.state.takeOutStore && !this.state.deliveryAddress) || !lastDeliveryType)
        || (lastDeliveryType === 'takeOutStore' && !this.state.takeOutStore)
        || (lastDeliveryType === 'deliveryAddress' && !this.state.deliveryAddress))) {
      currentCartContent = (
        <DeliveryOrTakeoutContainer
          updateAddress={this.updateAddress}
          cartSubmitInProgressHandler={this.cartSubmitInProgressHandler}
          showSnackBar={this.props.actions.showSnackBar}
        />
      );
    } else {
      currentCartContent = (
        <CartContentModal
          cartData={cartData}
          categories={categories}
          productsCount={productsCount}
          upSaleProducts={upSaleProducts}
          crossSaleCategories={crossSaleCategories}
          collectedAccessories={collectedAccessories}
          piecesCount={piecesCount}
          qtyHandler={this.qtyHandler}
          removeCartItemHandler={this.removeCartItemHandler}
          currentLanguage={currentLanguage}
          languagePrefix={languagePrefix}
          isUpSaleChosen={isUpSaleChosen}
          cartSubmitInProgressHandler={this.cartSubmitInProgressHandler}
          nextStep={this.nextStep}
          redirectToCategory={this.redirectToCategory}
          order={order}
          openAccessoriesModal={this.openAccessoriesModal}
          setAccessories={this.setAccessories}
          isAccessoriesChanging={isAccessoriesChanging}
          translate={translate}
        />
      );
    }

    return (
      <Fragment>
        {isSidebarMode ? (
          <CartContentSidebar
            cartData={cartData}
            categories={categories}
            productsCount={productsCount}
            piecesCount={piecesCount}
            qtyHandler={this.qtyHandler}
            removeCartItemHandler={this.removeCartItemHandler}
            currentLanguage={currentLanguage}
            languagePrefix={languagePrefix}
            cartSubmit={this.redirectToCart}
            openAccessoriesModal={this.openAccessoriesModal}
            isMobile={isMobile}
            translate={translate}
          />
        ) : null}
        {!isSidebarMode || (isSidebarMode && isAccessoriesChanging) ? (
          <ModalWrapper
            handleModalClose={this.modalCartClose}
            isFixed
            modalId="cartModal"
          >
            {currentCartContent}
            {isAccessoriesChanging ? (
              <AccessoriesModal
                collectedAccessories={collectedAccessories}
                currentLanguage={currentLanguage}
                setAccessories={this.setAccessories}
                order={order}
                translate={translate}
              />
            ) : null}
          </ModalWrapper>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  cart: state.cart ? state.cart : null,
  order: state.order ? state.order : null,
  categories: state.categories ? state.categories : null,
  accessories: state.accessories ? state.accessories : null,
  languagePrefix: getlanguagePrefix(getActiveLanguage(state.locale).code),
  cartToCheckoutSteps: state.cartToCheckoutSteps ? state.cartToCheckoutSteps : null,
  form: state.form ? state.form : null,
  isMobile: state.isMobile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(CartContainer)));
