import * as types from '../actions/actionTypes';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return action.payload;
    case types.LOGOUT_USER_SUCCESS:
      return false;
    case types.SET_FAVOURITES:
      return state.user;
    default:
      return state;
  }
};

const initialState = null;

export default userReducer;
