import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LayoutContainer from './containers/LayoutContainer';
import * as actions from './actions/actions';

class App extends React.Component {
  constructor(props) {
    super(props);

    const pathNameStr = this.props.router.location.pathname;
    const pathNameArr = pathNameStr.split('/');
    const codeFromUrl = pathNameArr[1] === 'ua' ? 'ua' : 'ru';
    this.props.actions.initializeLanguage(codeFromUrl);

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    // need to set cookies first time - all next requests will be use this cookies
    // this.props.actions.setInitRequest();
    this.fetchData();
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getSeoHomePage(),
      this.props.actions.getCart(),
    ]);
  }

  resize() {
    this.props.actions.setMobileSize(window.innerWidth <= 768);
  }

  render() {
    const { init } = this.props;

    return init ? (
      <LayoutContainer />
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  router: state.router ? state.router : null,
  init: state.cart ? state.cart : null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
