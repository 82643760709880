import { axiosInstance } from './axiosInstance';
import _serialize from './helpers/serialize';
import * as headers from './headers';

const AuthApi = {
  getSmsWait(userName, uid) {
    return axiosInstance.post('login',
      _serialize({
        'LoginForm[username]': userName,
      }),
      {
        headers: {
          ...headers.urlEncoded,
          "uid": uid,
        },
        withCredentials: true,
        crossDomain: true,
      });
  },
  getSmsIsValid(smsCode) {
    return axiosInstance.post('login',
      _serialize({ 'LoginForm[password]': smsCode }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default AuthApi;
