/* eslint-disable no-param-reassign */
import React from 'react';
import { Translate } from 'react-localize-redux';

const nameValidation = (value) => {
  const errors = {};
  if (value.name) {
    value.name = value.name.replace(/[^a-zA-Zа-яА-Яа-яА-ЯЇїІіЙйҐґ ]/g, '');
  }
  if (value.name && value.name.length > 38) {
    value.name = value.name.slice(0, 38);
  }
  if (!value.name) {
    errors.name = <Translate id="FillTheName" />;
  }
  return errors;
};

export default nameValidation;
