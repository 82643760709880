/* eslint-disable prefer-const,import/no-mutable-exports */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from '../../shared/form/Input';
import Hr from '../../shared/Hr';
import MainBtn from '../../shared/MainBtn';
import Radio from '../../shared/form/Radio';
import Checkbox from '../../shared/form/Checkbox';
import keyByLang from '../../../modules/shared/keyByLang';
import validate from './validatePayment/validatePaymentForm';

import name from '../../../assets/icons/form/name.svg';
import message from '../../../assets/icons/form/message.svg';
import promocodeIcon from '../../../assets/icons/form/promocode.svg';
import bonus from '../../../assets/icons/form/bonus.svg';
import banknote from '../../../assets/icons/form/banknote.svg';

let CheckoutPaymentForm = (props) => {
  const {
    handleSubmit,
    paymentMethods,
    currentLanguage,
    makeFieldVisible,
    isCommentVisible,
    isPromocodeVisible,
    isBonusVisible,
    user,
    showSnackBar,
    setChangeVisibility,
    isCashPaymentMethod,
    isMobile,
    buttonPrice,
    languagePrefix,
    sendingData,
    translate,
    changePromoStatus,
    initialValues,
    emptyPreorder,
  } = props;
  const title = keyByLang('title', currentLanguage);

  let bonuses = null;

  if (user && user.id) {
    bonuses = user.bonuses_total;
  }

  const paymentMethodsItems = paymentMethods ? paymentMethods.map((method) => (
    <div className="payment-form__radio-wrap" key={method.id}>
      <Field
        component={Radio}
        type="radio"
        value={method.id}
        name="paymentMethod"
        label={method[title]}
        id={method.id}
        handleClick={setChangeVisibility}
      />
    </div>
  )) : null;

  return (
    <StyledPaymentForm onSubmit={handleSubmit} className="payment-form">
      <Field
        type="text"
        component={Input}
        label={translate('NamePlaceholder')}
        name="name"
        icon={name}
      />
      <Hr />
      {isCommentVisible ? (
        <Field
          type="text"
          component={Input}
          label={translate('YourMessagePlaceholder')}
          name="comment"
          icon={message}
        />
      ) : (
        <p
          className="payment-form__link"
          onClick={() => makeFieldVisible('isCommentVisible')}
        >
          {translate('WantToSendUsMessage')}
        </p>
      )}
      <Hr />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isPromocodeVisible && emptyPreorder ? (
        <div className="promocode-wrapper">
          <div className="promocode__input">
            <Field
              type="text"
              component={Input}
              label={translate('SpecifyPromocode')}
              name="promocodeField"
              icon={promocodeIcon}
            />
          </div>
          <MainBtn
            minWidth="60px"
            text="OK"
            addClass="promocode__btn"
            sendingData={sendingData}
            type="button"
            clickHandle={() => {
              changePromoStatus(true);
              setTimeout(handleSubmit, 10);
            }}
          />
        </div>
      ) : !emptyPreorder ? (
        <div className="promocode-wrapper">
          <p className="promocode__complete-text">{translate('ActivatePromotionalCode')}</p>
        </div>
      ) : (
        <p
          className="payment-form__link"
          onClick={() => makeFieldVisible('isPromocodeVisible')}
        >
          {translate('HavePromotionalCode')}
        </p>
      )}
      <Hr />
      {isBonusVisible ? (
        <Field
          type="text"
          component={Input}
          label={isMobile ? translate('MobileBonuses') : translate('EnterNumberOfBonuses')}
          name="bonuses_used"
          icon={bonus}
          showSnackBar={showSnackBar}
        />
      ) : (
        <div className="payment-form__bonuses-wrap">
          <p
            className="payment-form__link"
            onClick={() => makeFieldVisible('isBonusVisible')}
          >
            {translate('WouldLikeToPayWithBonuses')}
          </p>
          {bonuses !== null ? (
            <p className="payment-form__bonuses">
              {translate('YouHaveNBonuses')}
              {' '}
              {bonuses}
              {' '}
              {translate('YouHaveNBonuses2')}
            </p>
          ) : null}
        </div>
      )}
      <Hr />
      <h2 className="payment-form__heading">
        {translate('PaymentMethod')}
        :
      </h2>
      {paymentMethodsItems}
      {isCashPaymentMethod ? (
        <Field
          type="text"
          component={Input}
          label={translate('ProvideChange')}
          name="change_required_from"
          icon={banknote}
        />
      ) : null}
      <Hr />
      <div className="payment-form__confidentiality">
        <p className="payment-form__agree">{translate('ConfirmingOrderYouAccept')}</p>
        <Link
          className="payment-form__link"
          to={`${languagePrefix}/pages/privacy-policy`}
        >
          {translate('PrivacyPolicy')}
        </Link>
      </div>
      <MainBtn
        minWidth="180px"
        text={sendingData ? `${translate('WaitPlease')}` : `${translate('BuyButton')} ${buttonPrice} грн`}
        iconName="store"
        type="submit"
        addClass="payment-form__button-wrap"
        sendingData={sendingData}
      />
    </StyledPaymentForm>
  );
};

const mapStateToProps = (state) => {
  if (state.user && state.paymentMethods[0] && state.settings) {
    return {
      initialValues: {
        name: state.user.name,
        user: state.user,
        settings: state.settings,
        paymentMethod: state.paymentMethods[0].id,
        promocodeField: state.promocodeValue,
      },
      isMobile: state.isMobile,
    };
  }
  return {};
};

let InitializedPaymentForm = connect(mapStateToProps)(reduxForm({
  form: 'checkoutPaymentForm',
  validate,
}, mapStateToProps)(CheckoutPaymentForm));

export default InitializedPaymentForm;

const StyledPaymentForm = styled.form`
  --text-color: #8E8E8E;
  --heading-color: #FFFFFF;
  --link-color: #E74E1A;
  
  color: var(--text-color);
  
  .payment-form {
    &__heading {
      color: var(--heading-color);
      font-size: 1.25rem;
      text-transform: none;
      font-weight: 200;
      padding-bottom: 1.25rem;
    }
    
    &__link {
      color: var(--link-color);
      font-size: .9rem;
      
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    
    &__radio-wrap {
    }
    
    &__bonuses-wrap {
      display: flex;
      justify-content: space-between;
    }
    
    &__bonuses {
      font-size: .9rem;
    }
    
    &__confidentiality {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0 1.25rem;
      font-size: 100;
      line-height: 1.2rem;
    }
    
    &__agree {  
      padding-bottom: .3rem;
      font-size: .9rem;
    }
    
    &__button-wrap {
      position: fixed;
      width: 100%;
      padding: 0 10px;
      bottom: 10px;
      left: 0;
      z-index: 11;
      margin: 0;
      
      .button {
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
      }
    }
  }
  
  .promocode {
    &-wrapper {
      display:flex;
    }
    
    &__input {
      flex-grow: 1;
      margin-right: 20px;
    }
    
    &__btn {
      width: auto;
      margin-bottom: 10px;
      
      & > button {
        height: 100%;
      }
    }
  }
  
  @media all and (min-width: 360px) {
    .payment-form {
      &__agree {  
        font-size: 1rem;
      }
    }
  }
  
  @media all and (min-width: 768px) {
    .payment-form {
      &__link {
        font-size: .9rem;
      }
      
      &__bonuses {
        font-size: 1rem;
      }
      
      &__agree {
        padding-bottom: .5rem;
      }
      
      &__confidentiality {
        padding: 0 0 1.5rem;
      }
      
      &__button-wrap {
        position: static;
        width: 100%;
        padding: 0;
      
        .button {
          width: auto;
          border-radius: 4px;
          box-shadow: none;
        }
      }
    }
    .promocode {        
      &__btn {
        margin-bottom: 20px;
      } 
    }
  }
`;
