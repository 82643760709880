import React from "react";
import ModalWrapper from "../components/shared/ModalWrapper";

const LoginContainer = () => (
	<ModalWrapper>
		login
	</ModalWrapper>
);

export default LoginContainer;
