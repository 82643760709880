import _serialize from './helpers/serialize';
import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const UserApi = {
  getUser() {
    return axiosInstance.post('user/profile',
      {},
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
  logOut() {
    return axiosInstance.post('logout',
      {},
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  update(obj) {
    return axiosInstance.post('user/update',
      _serialize(obj),
      {
        headers: headers.userUpdateHeaders,
        withCredentials: true,
        crossDomain: true,
      });
  },
  updateFullData(obj) {
    return axiosInstance.post('user/update',
      obj,
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  updateUserName(phoneNum) {
    return axiosInstance.post('user/update-username',
      _serialize(phoneNum),
      {
        headers: headers.sendHeaders,
        withCredentials: true,
        crossDomain: true,
      });
  },
  getOrdersHistory() {
    return axiosInstance.post('user/orders',
      {},
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  getLastOrder() {
    return axiosInstance.post('user/order',
      {},
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  addToFavourites(productId) {
    return axiosInstance.post('user/add-favourite',
      _serialize({ id: productId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  removeFromFavourites(productId) {
    return axiosInstance.post('user/remove-favourite',
      _serialize({ id: productId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  getBonusData() {
    return axiosInstance.post('user/bonus',
      {},
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default UserApi;
