import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/actions';

const StyledBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #ffc800;
  color: #fff;
  font-size: 16px;
  transform: ${props => !props.active ? 'translateY(-100%)' : 'translateY(0)'};
  transition: transform .2s ease;
  padding: 8px 20px;
  z-index: 200;
  
  
  .added-bar.active {
    transform: translateY(0);
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`;

const ProductAddBar = ({ translate, addedState, actions }) => {
  function hide() {
    if (!addedState) return;
    setTimeout(() => {
      actions.addedProduct(false);
    }, 3000);
  }

  useEffect(() => {
    hide();
  }, [addedState]);

  return (
    <StyledBar active={addedState}>
      Товар
      {' '}
      {translate('AddedToCartMessage')}
    </StyledBar>
  );
};

const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
  addedState: state.addedState,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddBar);
