import * as types from "../actions/actionTypes";

const deferredOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_DEFERRED_ORDER_DATA:
      return action.payload;
    default:
      return state;
  }
};

const initialState = null;

export default deferredOrderReducer;
