import React from 'react';
import styled from 'styled-components';


import { Link } from 'react-router-dom';
import AnimatedButton from '../shared/buttons/AnimatedButton';
import CartItemModal from './CartItemModal';

const CartUpSaleBlock = ({
  upSaleProducts,
  icon,
  languagePrefix,
  currentLanguage,
  qtyHandler,
  removeCartItemHandler,
  redirectToCategory,
  translate,
}) => (
  upSaleProducts && upSaleProducts[0] ? (
    <StyledUpSale className="up-sale">
      {/*<hr className="solid up-sale__line" />*/}
      <h2 className="up-sale__heading">{translate('MainDish')}</h2>
      <ul className="up-sale__products-list">
        {upSaleProducts.map(cartProduct => (
          <CartItemModal
            key={cartProduct.id}
            qty={cartProduct.quantity}
            product={cartProduct.product}
            cartProduct={cartProduct}
            qtyHandler={qtyHandler}
            removeCartItemHandler={removeCartItemHandler}
            currentLanguage={currentLanguage}
            addonsList={cartProduct.addons}
            translate={translate}
          />
        ))}
      </ul>
    </StyledUpSale>
  ) : (
    <StyledUpSaleEmpty className="up-sale">
      <div className="up-sale__content">
        <div className="up-sale__title">{translate('AddmainDishText')}</div>
        <p className="up-sale__text">{translate('MainDishIsMissingText')}</p>
      </div>
      <div className="up-sale__button-wrap">
        <Link to={`${languagePrefix}/favourites`} className="cart-modal__link">{translate('ChooseMainDishButton')}</Link>
        {/* <AnimatedButton */}
        {/* minWidth="180px" */}
        {/* align="right" */}
        {/* text={translate('ChooseMainDishButton')} */}
        {/* icon={icon} */}
        {/* handleClick={() => redirectToCategory('favourites')} */}
        {/* /> */}
      </div>
    </StyledUpSaleEmpty>
  )
);

const StyledUpSale = styled.div`
  .up-sale {
    
    &__heading {
      text-transform: none;
      font-weight: 100;
      font-size: 1.3rem;
      padding-bottom: 1rem;
      width: 100%;
      margin-top: 2rem;
    }
  }
  
@media all and (min-width: 640px) {
  padding-top: 40px;
  .up-sale {
    &__heading {
      text-align: left;
      margin-top: 0;
    }
    
    &__line {
     display: none;
    }
  }
}
`;

const StyledUpSaleEmpty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  
  .up-sale {

     &__content {
      flex-grow: 1;
    }
    
    &__title {
      font-size: 1.3rem;
    }
    
    &__text {
      color: var(--active-text-color);
      padding-top: .4rem;
    }
    
    &__button-wrap {
      display: flex;
      align-items: center;
    }
  }
  
@media all and (min-width: 768px) {
  padding-top: 40px;
  justify-content: space-between;
  flex-direction: row;
  .up-sale {
    &__button-wrap {
      padding-top: 0;
    }
  }
}
`;

export default CartUpSaleBlock;
