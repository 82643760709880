import { axiosInstance } from './axiosInstance';
import * as headers from './headers';

const VacanciesApi = {
  getVacancies() {
    return axiosInstance.post('vacancies',
      {},
      { headers: headers.json });
  },
  getVacancyReviews() {
    return axiosInstance.post('vacancy-reviews',
      {},
      { headers: headers.json });
  },
};

export default VacanciesApi;
