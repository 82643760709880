/* eslint-disable consistent-return,no-prototype-builtins */
/* global google */
import React from 'react';
import { getTranslate } from 'react-localize-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import connect from 'react-redux/lib/connect/connect';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions/actions';

import delivery from '../../assets/icons/delivery/delivery.svg';
import moto from '../../assets/icons/form/deliveryForm.svg';

class NewProfileAddressInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.houseInput = React.createRef();
    this.list = React.createRef();

    this.state = {
      changed: false,
    };
  }

  isChanged = (bool) => {
    this.setState(() => ({ changed: bool }));
  }

  onChange = (address) => {
    this.props.changeStreet(address);
    this.isChanged(true);
  }

  onChangeHouse = (e) => {
    const { value } = e.target;
    this.props.changeHouse(value);
  }

  handleSelect = (address, placeId) => {
    this.isChanged(true);
    if (!placeId) {
      this.isChanged(false);
      return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
    }
    this.props.setPlaceId(placeId);
    geocodeByAddress(address)
      .then((results) => {
        const houseNumberObj = results[0].address_components.find((item) => (
          item.types.includes('street_number')
        ));
        const streetName = results[0].address_components.find((item) => (
          item.types.includes('route')
        ));
        const district = results[0].address_components.find((item) => (
          item.types.includes('sublocality')
        ));
        const city = results[0].address_components.find((item) => (
          item.types.includes('locality')
        ));
        if (!streetName) {
          this.props.changeStreet('');
        } else {
          let nameToSet = streetName.long_name;
          if (district) {
            nameToSet += `, ${district.short_name}`;
          }
          if (city) {
            nameToSet += `, ${city.long_name}`;
          }
          this.props.changeStreet(nameToSet);
        }
        if (houseNumberObj) {
          this.props.changeHouse(houseNumberObj.short_name);
          getLatLng(results[0]).then((response) => {
            this.props.setCoords(response);
          });
        } else {
          this.props.changeHouse('');
        }
        this.isChanged(false);
      })
      .catch((error) => console.error('Error', error));
  }

  onBlur = (e) => {
    if (this.list.current) {
      e.preventDefault();
    }
    if (this.state.changed) {
      this.props.changeStreet('');
      return this.props.actions.showSnackBar(this.props.translate('ChooseAddressFromDropdown'), 4000, false);
    }
    this.isChanged(false);
  }

  render() {
    const {
      settings, translate, street, house, isCheckout,
    } = this.props;
    const searchOptions = {
      location: new google.maps.LatLng(50.448079, 30.468715),
      radius: settings ? +settings.google_places_radius : 2000,
      componentRestrictions: {
        country: 'UA',
      },
      strictBounds: true,
    };

    return (
      <PlacesAutocomplete
        value={street}
        onChange={this.onChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps,
        }) => (
          <>
            {!isCheckout ? (
              <>
                <input
                  {...getInputProps({
                    placeholder: translate('EnterYourAddressPlaceholder'),
                    className: 'address-edit__input address-edit__search',
                    name: 'street-name',
                  })}
                  onBlur={this.onBlur}
                  required
                />
                <div className="address-edit__separator" />
                <input
                  className="address-edit__house address-edit__input"
                  type="text"
                  placeholder="№ дома"
                  value={house}
                  onChange={this.onChangeHouse}
                  name="house-number"
                  ref={this.houseInput}
                  required
                />
              </>
            ) : (
              <StyleCheckoutField isCheckout={isCheckout}>
                <div className="icon">
                  <img className="image" src={moto} alt="icon" decoding="async" loading="lazy" />
                </div>
                <div className="field street">
                  <input
                    {...getInputProps({
                      className: `input ${street.length && 'not-empty'}`,
                      name: 'street-name',
                      id: 'street-search',
                    })}
                    onBlur={this.onBlur}
                    required
                  />
                  <label htmlFor="street-search" className="label">
                    {translate('EnterYourAddressPlaceholder')}
                  </label>
                </div>
                <div className="field house">
                  <input
                    className={`input ${house?.length && 'not-empty'}`}
                    type="text"
                    value={house}
                    onChange={this.onChangeHouse}
                    name="house-number"
                    ref={this.houseInput}
                    required
                    id="house-input"
                  />
                  <label htmlFor="house-input" className="label">
                    № дома
                  </label>
                </div>
              </StyleCheckoutField>
            )}
            {getInputProps().value.length > 3 && suggestions && suggestions[0] ? (
              <StyledResultList>
                <div className="results__head">
                  <img className="results__logo" src={delivery} alt="logo" decoding="async" loading="lazy" />
                  <p className="results__name">
                    {translate('CourierAddressDelivery')}
                    :
                    {' '}
                  </p>
                </div>
                <ul className="results__list" ref={this.list}>
                  {suggestions.map((suggestion) => (
                    <li key={suggestion.placeId} className={`results__item ${suggestion.active && 'results__item--active'}`} {...getSuggestionItemProps(suggestion)}>
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              </StyledResultList>
            ) : null}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

const StyleCheckoutField = styled.div`
  display: flex;
  width: 100%;
  
  .field {
    position: relative;
    display: flex;
    align-items: flex-end;
    
    &.street {
      flex-grow: 1;
    }
    
    &.house {
      width: 100px;
    }
    
    &:before {
      content: '';
      display:block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 75%;
      width: 2px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-image: linear-gradient(to top,transparent 66.67%,#313131 33.33%);
      background-size: 100% 8px;
    }
  }
  
  .input {
    border: none;
    background: none;
    padding: ${(props) => (props.isCheckout ? '16px' : '0')} 10px 0;
    height: ${(props) => (props.isCheckout ? '100%' : '42px')};
    width: 100%;
    color: #fff;
    
    &:focus ~ label,
    &.not-empty ~ label {
      transform: scale(0.85) translateY(-150%);
      color: #616161;
    }
  }
  
  .icon {
    width: 60px;
    height: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  
  .image {
    opacity: .5;
  }
  
  .label {
    position: absolute;
    top: 50%;
    left: 10px;
    color: #616161;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
    transform-origin: left center;
    pointer-events: none;
  }
`;

const StyledResultList = styled.div`
  --background-color: #1C1C1C;
  --text-color: #FFFFFF;
  --active-background-color: #313131;
  --secondary-text-color: #616161;
  
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  border-radius: 4px;
  padding: 10px 0;
  background-color: var(--background-color);
  z-index: 10;
    
  .results {
    &__head {
      display:flex;
      align-items:center;
      padding: 10px;
    }
    
    &__logo {
      width: 3rem;
      margin-right: 10px;
    }
    
    &__list {
      background-color: var(--bag);
    }
    
    &__item {
      padding: 10px;
      
      &:not(:last-child) {
        border-bottom: 1px solid var(--active-background-color);
      }
      
      &--active {
        background-color: var(--active-background-color);
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  settings: state.settings ? state.settings : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProfileAddressInput);
