import React from 'react';
import styled from 'styled-components';

const CheckoutDeliverySwitcher = ({
  icon, figcaption, linkName, handleClick,
}) => (
  <StyledCheckoutDeliveryButton className="delivery-switcher" onClick={handleClick}>
    <figure className="delivery-switcher__figure">
      <div className="delivery-switcher__icon-wrap">
        <img
          className="delivery-switcher__icon"
          src={icon}
          alt="switcher-icon"
          decoding="async"
          loading="lazy"
        />
      </div>
      <figcaption className="delivery-switcher__figcaption">{figcaption}</figcaption>
    </figure>
    <hr className="dashed padded" />
    <p className="delivery-switcher__link">{linkName}</p>
  </StyledCheckoutDeliveryButton>
);

export default CheckoutDeliverySwitcher;

const StyledCheckoutDeliveryButton = styled.div`
  --background-color: #1C1C1C;
  --text-color: #8E8E8E;
  --link-color: #E74E1A;
  
  background-color: var(--background-color);
  border-radius: 4px;
  width: 100%;
  padding: 1rem .75rem;
  height: 100%;
  
  &:hover {
    cursor: pointer;
    
    & .delivery-switcher__link {
      text-decoration: underline;
    }
  }
  
  .delivery-switcher {
    &__figure {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    &__icon-wrap {
      width: 60px;
      height: 60px;
      border: 1px solid var(--text-color);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &__figcaption {
      padding-top: 1rem;
      font-size: .68rem;
    }
    
    &__link {
      color: var(--link-color);
      font-weight: 100;
      font-size: .75rem;
      text-align: center;
      
      /*&:hover {
        cursor: pointer;
        text-decoration: underline;
      }*/
    }
  }
  
  .padded {
    margin: .8rem 0;
  }
  
  @media all and (min-width: 360px) {
  .delivery-switcher {
      &__figcaption {
        font-size: .8rem;
      }
    }
  }
  
  @media all and (min-width: 768px) {
    padding: 1.25rem;
    
    .delivery-switcher {
      &__label {
        font-size: 1rem;
      }
      
      &__link {
        font-size: .9rem;
      }
      
      &__figcaption {
        font-size: 1rem;
      }
    }
  }
`;
