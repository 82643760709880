/* eslint-disable no-class-assign,no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Input from '../shared/form/Input';
import Select from '../shared/form/Select';
import TextArea from '../shared/form/TextArea';
import MainBtn from '../shared/MainBtn';

import * as actions from '../../actions/actions';

import faqFormIcons from '../../assets/images';
import { Translate } from 'react-localize-redux';

// onSubmit from props -> available is handleSubmit

class FaqForm extends React.PureComponent {
  static validateForm(values) {
    const errors = {};
    const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const searchLetters = /^[0-9]+$/;
    if (values.order && !searchLetters.test(values.order)) {
      values.order = values.order.replace(/[^0-9]/gim, '');
    }
    if (!values.userName || values.userName.length < 2) {
      errors.userName = <Translate id="EnterUserName" />;
    }
    if (!values.email) {
      errors.email = <Translate id="EnterEmail" />;
    }
    if (!emailRegExp.test(values.email)) {
      errors.email = <Translate id="IncorrectEmail" />;
    }
    if (!values.message) {
      errors.message = <Translate id="EnterYourMessage" />;
    }
    if (!values.store) {
      errors.store = <Translate id="ChooseStore" />;
    }
    return errors;
  }

  constructor(props) {
    super(props);
    this.noSubmit = this.noSubmit.bind(this);
  }

  noSubmit(e) {
    e.preventDefault();
    const errors = this.props.formData.syncErrors;
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      this.props.actions.showSnackBar(errors[keys[0]], 4000, false);
    }
  }

  render() {
    const {
      faq, stores, currentLanguage, activeTopic, handleSubmit, valid, chooseStoreId, translate,
    } = this.props;
    let storesOptions = null;
    if (stores && stores[0]) {
      storesOptions = stores.map(store => ({
        id: store.id,
        id_1c: store.id_1c,
        title_ru: `${store.title_ru}`,
        title_ua: `${store.title_ua}`,
      }));
    }

    return (
      <StyledFaqForm onSubmit={valid ? handleSubmit : this.noSubmit}>
        <Field
          name="email"
          component={Input}
          type="email"
          label="Email"
          icon={faqFormIcons.email}
          onChange={this.handleChange}
        />
        <Field
          name="phone"
          component={Input}
          type="tel"
          label={translate('BhtThPhone')}
          icon={faqFormIcons.phone}
          onChange={this.handleChange}
        />
        <Field
          name="order"
          component={Input}
          type="text"
          label={<Translate id="BhtThNumber" />}
          icon={faqFormIcons.input}
          onChange={this.handleChange}
        />
        <Field
          name="products"
          component={Select}
          type="select"
          label={translate('ChosenFormTopic')}
          icon={faqFormIcons.problem}
          language={currentLanguage}
          options={faq}
          initialValue={activeTopic}
        />
        <Field
          name="store"
          component={Select}
          type="select"
          label="Магазин"
          icon={faqFormIcons.pickup}
          language={currentLanguage}
          options={storesOptions}
          chooseItemId={chooseStoreId}
        />
        <Field
          name="userName"
          component={Input}
          type="text"
          label={translate('FirstAndLastName')}
          icon={faqFormIcons.name}
          onChange={this.handleChange}
        />
        <Field
          name="message"
          component={TextArea}
          type="textarea"
          label={translate('YourMessagePlaceholder')}
          icon={faqFormIcons.pickup}
          onChange={this.handleChange}
        />
        <div className="submit-wrap">
          <MainBtn
            text={translate('SendButton')}
            align="center"
            type="submit"
          />
        </div>
      </StyledFaqForm>
    );
  }
}

const StyledFaqForm = styled.form`
  .submit-wrap {
    padding: 20px;
  }
`;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

FaqForm = reduxForm({
  form: 'faq-form',
  validate: FaqForm.validateForm,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(FaqForm);

FaqForm = connect(
  state => (state.user ? ({
    initialValues: {
      email: state.user.email,
      userName: state.user.name,
      phone: `38${state.user.username || '0'}`,
    },
    formData: state.form['faq-form'],
  }) : {}),
  mapDispatchToProps,
)(FaqForm);

export default withRouter(FaqForm);
