/* eslint-disable operator-assignment,no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import { getActiveLanguage } from 'react-localize-redux/lib/index';
import BoughtProductPopup from '../components/shared/boughtPopup/BoughtProductPopup';

import * as storage from '../utils/storage';
import * as actions from '../actions/actions';
import getProductById from '../modules/shared/getProductById';

class CartChangesContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.popupCounter = 0;
    this.timeOfActivePopup = 4500;

    this.state = {
      popups: [],
    };

    this.findDifference = this.findDifference.bind(this);
    this.collectAccessories = this.collectAccessories.bind(this);
    this.setInitialAccessories = this.setInitialAccessories.bind(this);
    this.setInitialAccessoriesData = this.setInitialAccessoriesData.bind(this);
    this.collectAccessoriesByTypes = this.collectAccessoriesByTypes.bind(this);
    this.addPopup = this.addPopup.bind(this);
    this.removePopup = this.removePopup.bind(this);
    this.findDifference = this.findDifference.bind(this);
    this.mapCartData = this.mapCartData.bind(this);
  }

  componentDidMount() {
    const savedAccessories = JSON.parse(storage.session.getItem('orderAccessories'));
    if (savedAccessories) {
      this.props.actions.setSeparatedAccessories({
        accessories: this.mergeAccessories(
          savedAccessories.free_accessories, savedAccessories.extra_accessories
        ),
        freeAccessories: savedAccessories.free_accessories,
        extraAccessories: {},
        freeAccessoriesByTypes: savedAccessories.accessories,
        extraAccessoriesByTypes: {},
      });
    }
  }

  componentDidUpdate(prevProps) {
    const newProduct = this.findDifference(this.props.cart.content, prevProps.cart.content);
    const prevProduct = this.findDifference(prevProps.cart.content, this.props.cart.content);
    if (newProduct) {
      this.addPopup(getProductById(newProduct.product_id, this.props.categories));
    }
    if (newProduct || prevProduct) {
      this.removePopup();
    }
    if ((newProduct || prevProduct) && this.props.accessories && this.props.accessories[0]
      && this.props.cart && this.props.cart.content && this.props.cart.content[0]
      && this.props.categories && this.props.categories[0]) {
      storage.session.removeItem('orderAccessories');
      this.props.actions.setAccessoriesUpdate(true);
      this.setInitialAccessoriesData(this.props.cart.content, this.props.accessories);
    }
    return true;
  }

  setInitialAccessories(accessories) {
    return accessories.reduce((res, accessory) => {
      res[accessory.id] = accessory.cartQuantity || 0;
      return res;
    }, {});
  }

  setInitialAccessoriesData(cartAccessories, accessories) {
    const collectedAccessories = this.collectAccessories(cartAccessories, accessories);
    this.props.actions.setSeparatedAccessories({
      accessories: this.setInitialAccessories(collectedAccessories),
      freeAccessories: this.setInitialAccessories(collectedAccessories),
      extraAccessories: {},
      freeAccessoriesByTypes: this.collectAccessoriesByTypes(collectedAccessories),
      extraAccessoriesByTypes: {},
    });
  }

  mergeAccessories(freeAccessoris, extraAccessories) {
    const accessories = Object.assign({}, freeAccessoris, extraAccessories);
    return accessories;
  }

  mapCartData(cartData) {
    return {
      ...cartData,
      content: cartData.map(cartItem => ({
        ...cartItem,
        product: getProductById(cartItem.product_id, this.props.categories),
        addons: cartItem.addons
          ? cartItem.addons.map(id => getProductById(id, this.props.categories)) : null,
      })),
    };
  }

  collectAccessories(cartData, accessories) {
    const mapedCart = this.mapCartData(cartData);
    return accessories.map(acc => ({
      ...acc,
      cartQuantity: mapedCart.content.reduce((res, prod) => {
        res += prod.product.id ? prod.quantity * prod.product.accessories[acc.id] : 0;
        return res;
      }, 0),
    }));
  }

  collectAccessoriesByTypes(accessories) {
    return accessories.reduce((res, accessorie) => {
      res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id] || 0;
      res[accessorie.accessory_type_id] = res[accessorie.accessory_type_id]
        + accessorie.cartQuantity;
      return res;
    }, {});
  }

  addPopup(popup) {
    const newPopup = { ...popup, isAnimate: true };
    this.setState(prevState => ({
      popups: [
        ...prevState.popups.map(popupNew => ({ ...popupNew, isAnimate: false })),
        newPopup,
      ],
    }));
    setTimeout(() => {
      this.setState((prevState) => {
        const popupToSet = prevState.popups[prevState.popups.length - 1];
        if (popupToSet) {
          popupToSet.isAnimate = false;
        }
        const newPopups = [...prevState.popups];
        newPopups.pop();
        return {
          popups: [...newPopups, popupToSet],
        };
      });
    }, this.timeOfActivePopup - 100);
  }

  removePopup() {
    setTimeout(() => {
      this.setState((prevState) => {
        const newPopups = [...prevState.popups];
        newPopups.shift();
        return {
          popups: newPopups,
        };
      });
    }, this.timeOfActivePopup);
  }

  findDifference(newCart, prevCart) {
    return newCart.find((product) => {
      const same = prevCart
        .find(prevProduct => product.id === prevProduct.id
          && (product.quantity === prevProduct.quantity
            || product.quantity < prevProduct.quantity));
      return !same ? product : null;
    });
  }

  render() {
    const {
      currentLanguage, isMobile, translate,
    } = this.props;
    const { popups } = this.state;

    return !isMobile ? (
      <StyledPopupsWrap>
        {popups.map(product => (
          product && product.id ? (
            <BoughtProductPopup
              key={`popup${product.id}${++this.popupCounter}`}
              product={product}
              currentLanguage={currentLanguage}
              translate={translate}
            />
          ) : null
        ))}
      </StyledPopupsWrap>
    ) : null;
  }
}

const StyledPopupsWrap = styled.div`
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  categories: state.categories ? state.categories : null,
  accessories: state.accessories ? state.accessories : null,
  cart: state.cart ? state.cart : null,
  translate: getTranslate(state.locale),
  isMobile: state.isMobile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartChangesContainer);
