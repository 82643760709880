/* eslint-disable no-prototype-builtins,no-underscore-dangle,no-restricted-syntax */
const _serialize = function (obj) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};
export default _serialize;
