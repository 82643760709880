import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import PromotionsListItem from './PromotionsListItem';

class PromotionsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      promotionsData: this.props.promotions,
    };

    // this.intersectionObserver = null;
    // this.intersectionRef = React.createRef();
    // this.intersectionMargin = '-15px 0px';

    // this.intersectionInit = this.intersectionInit.bind(this);
    // this.observerCallback = this.observerCallback.bind(this);
    // this.addNews = this.addNews.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  // componentDidMount() {
  //   this.intersectionInit();
  // }
  //
  // componentWillUnmount() {
  //   this.intersectionObserver.disconnect();
  // }

  // intersectionInit() {
  //   const options = {
  //     rootMargin: this.intersectionMargin,
  //   };
  //   this.intersectionObserver = new IntersectionObserver(this.observerCallback, options);
  //   this.intersectionObserver.observe(this.intersectionRef.current, options);
  // }
  //
  // addNews() {
  //   const newsLength = this.state.promotionsData.length;
  //   if (newsLength < this.props.news.length) {
  //     this.setState({
  //       promotionsData: this.props.news.slice(0, newsLength + 9),
  //     });
  //   }
  // }
  //
  // observerCallback(entities) {
  //   if (entities[0].isIntersecting) {
  //     this.addNews();
  //   }
  // }

  redirectTo(path) {
    window.scrollTo(0, 0);
    this.props.history.push(path);
  }

  render() {
    const { currentLanguage, languagePrefix, translate } = this.props;
    const { promotionsData } = this.state;
    return (
      <StyledPromotionsList className="promotions">
        <ul className="promotions__list">
          {promotionsData ? promotionsData.map((item, i) => (
            <PromotionsListItem
              key={`${item.id}-${i}`}
              data={item}
              currentLanguage={currentLanguage}
              languagePrefix={languagePrefix}
              redirectTo={this.redirectTo}
              translate={translate}
            />
          )) : null}
        </ul>
        {/* <div className="news__anchor" ref={this.intersectionRef} /> */}
      </StyledPromotionsList>
    );
  }
}

const StyledPromotionsList = styled.div`

  .promotions {
    &__list {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(15rem, 1fr) );
      grid-auto-rows: 1fr;
      grid-gap: 1.25rem;
      margin: 1.25rem 0;
    }
  }
  
  
  @media (min-width: 768px) {
  .promotions {
    &__list {
      padding: 1rem;
    }
  }

  @media (min-width: 1024px) {
  .promotions {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export default withRouter(PromotionsList);
