import React from 'react';
import styled from 'styled-components';

import CrossSaleCategory from './CrossSaleCategory';

class CrossSaleCategories extends React.Component {
  render() {
    const {
      crossSaleCategories, onChangeHandleClickOnPaidItem, currentState, currentLanguage,
    } = this.props;
    const categoriesList = crossSaleCategories && crossSaleCategories[0]
      ? crossSaleCategories.map(category => (
        <CrossSaleCategory
          key={category.id}
          category={category}
          currentLanguage={currentLanguage}
          currentState={currentState}
          onChangeHandleClickOnPaidItem={onChangeHandleClickOnPaidItem}
        />
      )) : null;

    return (
      <StyledCrossSaleWrap className="cross-sale-wrap">
        {categoriesList}
      </StyledCrossSaleWrap>
    );
  }
}

const StyledCrossSaleWrap = styled.div`
  color: #8E8E8E;
  padding-top: 2rem;
`;

export default CrossSaleCategories;
