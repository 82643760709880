import React from 'react';
import { bindActionCreators } from 'redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import connect from 'react-redux/lib/connect/connect';

import * as actions from '../actions/actions';
import DeliveryOrTakeoutModal from '../components/delivery/DeliveryOrTakeoutModal';

class DeliveryOrTakeoutContainer extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.cartSubmitInProgressHandler(false);
  }

  handleSnackBar = (content, timeWhileVisible) => {
    this.props.actions.showSnackBar(content, timeWhileVisible, false);
  }

  fetchData() {
    return Promise.all([
      this.props.actions.getDeliveryZones(),
      this.props.actions.getStores(),
      // this.props.actions.getCityDisctrics(),
    ]);
  }

  render() {
    const {
      stores, deliveryZones, currentLanguage, updateAddress, targetTab, translate,
    } = this.props;


    return (
      <DeliveryOrTakeoutModal
        deliveryZones={deliveryZones}
        stores={stores}
        currentLanguage={currentLanguage}
        takeOutStores={stores}
        updateAddress={updateAddress}
        showSnackBar={this.props.actions.showSnackBar}
        targetTab={targetTab}
        translate={translate}
      />
    );
  }
}

const mapStateToProps = state => ({
  deliveryZones: state.deliveryZones ? state.deliveryZones : null,
  stores: state.stores ? state.stores : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DeliveryOrTakeoutContainer));
