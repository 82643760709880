import React, { useEffect, useRef, useState } from 'react';

const useTimer = (defaultSec = 55) => {
  const timerRef = useRef();
  const [timerSec, setTimerSec] = useState(0);

  const start = () => {
    setTimerSec(defaultSec);
    timerRef.current = setInterval(() => {
      setTimerSec((prevState) => prevState - 1);
    }, 1000);
  };

  useEffect(() => {
    if (!timerSec) {
      clearInterval(timerRef.current);
    }
  }, [timerSec]);

  useEffect(() => () => clearInterval(timerRef.current), []);

  return [timerSec, start];
};

export default useTimer;
