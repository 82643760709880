import * as types from "../actions/actionTypes";

const homePageStaticData = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOPBANNER_DATA_SUCCESS:
      return Object.assign({}, state,{
        topBanner: action.payload
      })
    case types.GET_BESTSETS_DATA_SUCCESS:
      return Object.assign({}, state,{
        bestSets: action.payload
      })
    case types.GET_BONUSCLUB_BANNER_DATA_SUCCESS:
      return Object.assign({}, state,{
        bonusClub: action.payload
      })
    case types.GET_BESTSETS_PRODUCTS_DATA_SUCCESS:
      return Object.assign({}, state,{
        matchProducts: action.payload
      })  
    default:
      return state;
  }
};

const initialState = {};

export default homePageStaticData;
