import _serialize from './helpers/serialize';
import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const CartApi = {
  getCart() {
    return axiosInstance.post('cart/get',
      {},
      {
        headers: headers.json,
        withCredentials: true,
        crossDomain: true,
      });
  },
  add(product_id, addonsArr) {
    let dataObj = {};

    addonsArr && addonsArr.length
      // eslint-disable-next-line array-callback-return
      ?	addonsArr.map((addon, index) => {
        dataObj = Object.assign(dataObj, { product_id }, { [`addons[${index}]`]: addon });
      })
      :	dataObj = Object.assign({}, { product_id });

    return axiosInstance.post('cart/add',
      _serialize(dataObj),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  remove(cartItemId) {
    return axiosInstance.post('cart/remove',
      _serialize({ id: cartItemId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  clear() {

  },
  update() {

  },
  increase(cartItemId) {
    return axiosInstance.post('cart/increase',
      _serialize({ id: cartItemId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
  decrease(cartItemId) {
    return axiosInstance.post('cart/decrease',
      _serialize({ id: cartItemId }),
      {
        headers: headers.urlEncoded,
        withCredentials: true,
        crossDomain: true,
      });
  },
};

export default CartApi;
