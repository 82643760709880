import React from 'react';
import styled from 'styled-components';

const Icon = ({ name, color }) => {
  return (
    <StyledIcon className={`icon icon-${name}`} color={color} />
  );
};

export default Icon;

const StyledIcon = styled.span`
    display: inline-block;
    position: relative;
    color: ${props => (props.color ? props.color : '#8E8E8E')}
`;
